
<!-- <div class="sidebar" id="sidebar" *ngIf="showSidebar == true"> -->
<div class="sidebar" id="sidebar">

  <a href="javascript:" (click)="toggle()" *ngIf="toggleState == false">
    <div class="sidebarLogoBox">
      <img class="sidebarLogoClosed" src="../../assets/img/miniLogo.png" alt="...">
    </div>
  </a>

  <a href="javascript:" (click)="toggle()" *ngIf="toggleState == true">
    <div class="sidebarLogoBoxOpen">
      <img class="sidebarLogoOpen" src="../../assets/img/logo.png" alt="...">
    </div>
  </a>

  <div class="sidebarClosedListContainer" *ngIf="toggleState != true">
  </div>

  <div class="sidebarOpenListContainer" *ngIf="toggleState == true">
    <ul class="sidebarMenuList">
      <!-- <li class="sidebarMenuListItem" [ngClass]="page == 'dashboard' ? 'liHighlight' : 'liLowlight'">
        <div class="form-row searchForm">
          <div class="form-group col-md-3">
            <input type="text" (input)="searchCompanies($event.target.value)" id="inputSearch" class="form-control floating searchInput" placeholder="search companies">
            <fa-icon [icon]="faCog" size="sm"></fa-icon>
          </div>
        </div>

        <div *ngIf="showResults == true" class="companySearchListBox">
          <ul class="resultsList">
            <li *ngFor="let company of companiesSrchShow; let n = index" class="resultsListItem">
              <p>{{company.companyName}}</p>
            <li>
          </ul>
        </div>
      </li> -->
      <li class="sidebarMenuListItem" [ngClass]="page == 'dashboard' ? 'liHighlight' : 'liLowlight'">
        <fa-icon [icon]="faHome" class="fa-2x sidebarIcon"></fa-icon>
        <a href="javascript:" routerLink="/dashboard">
          <h6 class="sidebarMenuText">Home</h6>
        </a>
      </li>
      <li class="sidebarMenuListItem" [ngClass]="page == 'datamanagement' ? 'liHighlight' : 'liLowlight'" id="sidebar-datamanagement" *ngIf="showManagementProcessing == true">
        <fa-icon [icon]="faDatabase" class="fa-2x sidebarIcon"></fa-icon>
        <a href="javascript:"  [routerLink]="['/pages/company',companyId,'datamanagement']">
          <h6 class="sidebarMenuText">Data Management</h6>
        </a>
      </li>
      <li class="sidebarMenuListItem" [ngClass]="page.split('-')[0] == 'payroll' ? 'liHighlight' : 'liLowlight'" id="sidebar-payrollprocessing" *ngIf="showManagementProcessing == true">
        <fa-icon [icon]="faList" class="fa-2x sidebarIcon"></fa-icon>
        <a (click)="toggleIndicator('item3')" data-toggle="collapse" href="#collapseItem3" role="button" aria-expanded="false" aria-controls="collapseItem3">
          <h6 class="sidebarMenuText">Payroll Processing</h6>
        </a>
        <fa-icon *ngIf="item3 != true" [icon]="faChevronRight" class=" itemIcon"></fa-icon>
        <fa-icon *ngIf="item3 == true" [icon]="faChevronDown" class=" itemIcon"></fa-icon>
      </li>
      <div class="collapse subMenu" id="collapseItem3">
        <ul class="sidebarSubmenuList">
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'payroll-management' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true">
            <a href="javascript:" [routerLink]="['/pages/company',companyId, 'payroll-management']">
              <h6 class="sidebarSubmenuText">Current Year Payroll</h6>
            </a>
          </li>
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'payroll-history' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true">
            <a href="javascript:" [routerLink]="['/pages/company',companyId, 'payroll-history']">
              <h6 class="sidebarSubmenuText">Prior Year Payroll</h6>
            </a>
          </li>
        </ul>
      </div>


      <!-- <li class="sidebarMenuListItem" [ngClass]="page == 'reports' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true" id="sidebar-report">
        <fa-icon [icon]="faChartBar" class="fa-2x sidebarIcon"></fa-icon>
        <a href="javascript:" [routerLink]="['/pages/company',companyId, 'reports']">
          <h6 class="sidebarMenuText">Report</h6>
        </a>
      </li> -->

      <li class="sidebarMenuListItem" [ngClass]="page.split('-')[0] == 'reports' ? 'liHighlight' : 'liLowlight'" id="sidebar-payrollprocessing" *ngIf="showManagementProcessing == true">
        <fa-icon [icon]="faList" class="fa-2x sidebarIcon"></fa-icon>
        <a (click)="toggleIndicator('item4')" data-toggle="collapse" href="#collapseItem4" role="button" aria-expanded="false" aria-controls="collapseItem4">
          <h6 class="sidebarMenuText">Report</h6>
        </a>
        <fa-icon *ngIf="item4 != true" [icon]="faChevronRight" class=" itemIcon"></fa-icon>
        <fa-icon *ngIf="item4 == true" [icon]="faChevronDown" class=" itemIcon"></fa-icon>
      </li>
      <div class="collapse subMenu" id="collapseItem4">
        <ul class="sidebarSubmenuList">
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'reports-reports' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true">
            <a href="javascript:" [routerLink]="['/pages/company',companyId, 'reports']">
              <h6 class="sidebarSubmenuText">Reports</h6>
            </a>
          </li>
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'reports-general' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true">
            <a href="javascript:" [routerLink]="['/pages/company',companyId, 'general-reports']">
              <h6 class="sidebarSubmenuText">General Reports</h6>
            </a>
          </li>
        </ul>
      </div>



      <li class="sidebarMenuListItem" [ngClass]="page == 'dataservices' ? 'liHighlight' : 'liLowlight'" id="sidebar-datamanagement" *ngIf="showManagementProcessing == true">
        <fa-icon [icon]="faDatabase" class="fa-2x sidebarIcon"></fa-icon>
        <a href="javascript:" >
        <!-- <a href="javascript:"  [routerLink]="['/pages/company',companyId,'datamanagement']"> -->
          <h6 class="sidebarMenuText">Data Services</h6>
        </a>
      </li>
      <li class="sidebarMenuListItem">
        <fa-icon [icon]="faCog" class="fa-2x sidebarIcon"></fa-icon>
        <a (click)="toggleIndicator('item5')" data-toggle="collapse" href="#collapseItem5" role="button" aria-expanded="false" aria-controls="collapseItem5">
          <h6 class="sidebarMenuText">Setup</h6>
        </a>
        <fa-icon *ngIf="!item5" [icon]="faChevronRight" class=" itemIcon"></fa-icon>
        <fa-icon *ngIf="item5" [icon]="faChevronDown" class=" itemIcon"></fa-icon>
      </li>
      <div class="collapse subMenu" id="collapseItem5">
        <ul class="sidebarSubmenuList">
          <li *ngIf="createCompany == true" class="sidebarSubmenuListItem" [ngClass]="page == 'createCompany' ? 'liHighlight' : 'liLowlight'">
            <a  href="javascript:" routerLink="/pages/company/create">
            <h6 class="sidebarSubmenuText">Create company</h6>
            </a>
          </li>
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'charts-of-accounts' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true">
            <a href="javascript:" [routerLink]="['/pages/company',companyId, 'charts-of-accounts']">
              <h6 class="sidebarSubmenuText">Charts of account</h6>
            </a>
          </li>
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'codes' ? 'liHighlight' : 'liLowlight'" *ngIf="showManagementProcessing == true">
            <a href="javascript:" [routerLink]="['/pages/company',companyId, 'codes']">
            <h6 class="sidebarSubmenuText">Codes</h6>
            </a>
          </li>
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'taxes' ? 'liHighlight' : 'liLowlight'">
            <a href="javascript:" routerLink="/pages/taxes-deductions">
            <h6 class="sidebarSubmenuText">Taxes and defaults</h6>
            </a>
          </li>
          <li class="sidebarSubmenuListItem" [ngClass]="page == 'banking' ? 'liHighlight' : 'liLowlight'">
            <a href="javascript:" routerLink="/pages/banking">
            <h6 class="sidebarSubmenuText">Banking</h6>
            </a>
          </li>
        </ul>
      </div>
      <li class="sidebarMenuListItem" id="sidebar-datamanagement" [ngClass]="page == 'admin' ? 'liHighlight' : 'liLowlight'">
        <fa-icon [icon]="faUserTie" class="fa-2x sidebarIcon"></fa-icon>
        <a href="javascript:"  routerLink="/pages/admin/create">
          <h6 class="sidebarMenuText">Admins</h6>
        </a>
      </li>
      <li *ngIf="item5 == true" class="sidebarMenuListItem logoutItem">
        <button (click)="logout()" type="button" class="btn logoutBtn"><fa-icon [icon]="faSignOutAlt" class=" logoutIcon"></fa-icon>Logout</button>
      </li>
    </ul>
  </div>

</div>


<!-- Modal -->
<div class="modal fade" id="errorModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="..."> -->

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>

      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
