import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
import { ApiService } from 'src/app/api.service';
import { AppService } from 'src/app/app.service';
import { Company } from 'src/app/Models/Company';
import { Code } from 'src/app/Models/Code';
import { Defaults } from 'src/app/Models/Defaults';
import { ChartOfAccounts } from 'src/app/Models/ChartOfAccounts';
import { BankCode } from 'src/app/Models/BankCode';


declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-banking',
  templateUrl: './banking.component.html',
  styleUrls: ['./banking.component.css']
})
export class BankingComponent implements OnInit {


  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faClipboardList = faClipboardList;

  public url: string;

  public id: string = '';

  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';

  company: Company = Defaults.CompanyDefaults();
  public companyLogoBase64: any;

  public setting: string = 'list';

  public files: NgxFileDropEntry[] = [];
  uploading: Boolean = false;

  // bankingCodes: Array<any> = [];
  public sheetParsed: boolean = false;
  bankCode: BankCode = Defaults.BankCodeDefault()
  bankingCodes: Array<BankCode> = [];
  bankingCodeSrch: Array<BankCode> = [];
  public bankingCodeSearchField: any = 'code';
  public bankingCodeSearchQuery: any = '';
  public sortDirection: string = "Ascending";

  addBankType: string = 'batch';


  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log('file!!',droppedFile.relativePath, file, droppedFile, file.type);

          const formData = new FormData()
          formData.append(file.name, file)

          this.parseBankingCodesSheet(file)

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event){

  }

  public fileLeave(event){

  }


  constructor(
    private api: ApiService,
    private appService: AppService,
    private location:Location,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    $('.actionsBox').toggle();

    this.id = this.route.snapshot.paramMap.get('id');

    this.getBankingCodes()

    window.addEventListener('click', (e) => this.handleClick(e.target));

  }


  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
        // $('.actionsBox2').hide();
        // $('.s01Action').hide();

        // if (this.setting === 'Details') {
        //   this.setting = 'list';
        // }
      }



    }

  }

  getCompany () {
    this.api.getCompany(this.id).subscribe(companyData =>{

      this.company = companyData;
      this.api.getCompanyBaseImage(companyData.id,companyData.type).subscribe(companyLogo =>{
        this.companyLogoBase64 = companyLogo;
      });

      // this.api.getEmployees(companyData.id).subscribe(
      //   next => {
      //     this.employees = next;
      //     // this.loading = false;
      //     // $("#errorModal").modal("hide");
      //
      //     // console.log(' emplyees',next);
      //   },
      //   error => {
      //     // console.log('api request error',error);
      //   }
      // )

    });

  }

  newCode () {
    $('.actionsBox').hide();
    this.setting = 'Add';

    // this.getChartsOfAccounts();

  }

  showCode () {
    $('.actionsBox').hide();
    this.setting = 'list';
  }

  hideDetails () {
    this.setting = 'list';
    $("#errorModal").modal("hide");
  }

  codeAction (args) {
    if (args === 'edit') {
      this.setting = 'Edit';
      this.addBankType = 'single'
    }
    if (args === 'delete') {
      this.setting = 'Delete';
    }
    if (args === 'details') {
      this.setting = 'Details';
      $("#errorModal").modal("show");
    }

    console.log('selected code',this.bankCode,'add type',this.addBankType,this.setting);
  }

  parseBankingCodesSheet(file) {


    const reader: FileReader = new FileReader();
    let workbook;
    let XL_row_object;
    let json_object;

    let bankingCodes: Array<any> = [];
    reader.readAsBinaryString(file);
    new Promise((resolve, reject) => {
      reader.onload = function() {
         // console.log('reader.result',reader.result);
        let data = reader.result;
         workbook = XLSX.read(data,{type: 'binary'});


        XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

           for(const row of XL_row_object) {

             let bankingCode: BankCode = Defaults.BankCodeDefault();
              for (const [key, value] of Object.entries(row)) {
                // console.log('key',key);
                // console.log('split key',key.split("."));
                let splitKey = key.split(".");
                let value2 = value;
                if (splitKey[1]) {
                  if (splitKey[1] === 'achId') {
                    value2 = null;
                  }
                  bankingCode[splitKey[0]][splitKey[1]] = value2;
                }
                else {
                  if (splitKey[0] === 'glAccount') {
                    value2 = null;
                  }
                  bankingCode[splitKey[0]] = value2;
                }
                // bankingCode[key] = value2;
              }
              bankingCodes.push(bankingCode)
           }

           // json_object;

            resolve(bankingCodes);

      };

    })
    .then((json_object: any) => {

      this.uploadBankingCodes(json_object)


    });


  }

  getBankingCodes() {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getBankingCodes().subscribe(next => {

      this.bankingCodes = next;
      this.bankingCodeSrch = next;
      // console.log('getBankingCodes',next);


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getBankingCodes!"
      }
      $("#errorModal").modal("hide");
    })

  }

  uploadBankingCodes(data) {

    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");
    this.api.batchUploadBankingCodes(JSON.stringify(data)).subscribe(next => {

      this.loading = false;
      this.modalMessage = "Uploaded!"
      // $("#errorModal").modal("hide");

      setTimeout(()=>{
        $("#errorModal").modal("hide");
      }, 3000);


    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: batchUploadBankingCodes!"
      }
      $("#errorModal").modal("hide");
    })

  }

  actionsBox (event,code) {

    $('.actionsBox').css({'top':event.pageY-120,'left':event.pageX-50})
    $('.actionsBox').toggle();

    this.bankCode = code;

  }

  selectFile(files) {
    let file = files[0]
    const formData = new FormData()
    formData.append(file.name, file)

    this.parseBankingCodesSheet(file)
  }


  toggleAddBankType(args) {
    this.addBankType = args;
  }

  // add details in modal, fix edit, delete

  addBankCodeSingle() {
    console.log('addBankCodeSingle');
    // this.bankCode

    // this.loading = true;
    // $("#errorModal").modal("show");
    // this.api.getBankingCodes().subscribe(next => {
    //
    //   this.bankingCodes = next;
    //   console.log('getBankingCodes',getBankingCodes);
    //
    //
    //   this.loading = false;
    //   $("#errorModal").modal("hide");
    //
    // },
    // error => {
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error: getBankingCodes!"
    //   }
    //   $("#errorModal").modal("hide");
    // })

  }

  addAch() {

  }


  searchBankingCode (val) {

    if (val.length === 0) {
      this.bankingCodeSrch = this.bankingCodes;

    } else if (val.length > 0) {

      let field = this.bankingCodeSearchField;
      // console.log('field',field,val);

      let temp = this.bankingCodes.filter(d => {

        if (typeof val === 'string') {
          val = val.toLowerCase();
          return d[field].toLowerCase().indexOf(val) !== -1 || !val;
        }
        if (typeof val === 'number') {
          val = val;
          return d[field].indexOf(val) !== -1 || !val;
        }

      });

      this.bankingCodeSearchQuery = val;
      this.bankingCodeSrch = [...temp];

    }

  }
  clearBankingSearch () {
    this.bankingCodeSrch = this.bankingCodes;
    this.bankingCodeSearchField = "";
  }
  selectBankingSearchField (field) {
    this.bankingCodeSearchField = field;
  }

  sortBankCodes() {

    let direction = this.sortDirection;

    if (direction === 'Ascending') {
      this.bankingCodeSrch = this.bankingCodes.sort((a,b) => (a[this.bankingCodeSearchField] > b[this.bankingCodeSearchField]) ? 1 : ((b[this.bankingCodeSearchField] > a[this.bankingCodeSearchField]) ? -1 : 0))
    }
    if (direction === 'Descending') {
      this.bankingCodeSrch = this.bankingCodes.sort((a,b) => (a[this.bankingCodeSearchField] < b[this.bankingCodeSearchField]) ? 1 : ((b[this.bankingCodeSearchField] > a[this.bankingCodeSearchField]) ? -1 : 0))
    }


  }

  selectSortDirection(args) {
    this.sortDirection = args
  }


}
