import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { Defaults } from 'src/app/Models/Defaults';
import { Employee } from 'src/app/Models/Employee';
import { Code } from 'src/app/Models/Code';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';
import { ChartOfAccounts } from 'src/app/Models/ChartOfAccounts';
import { Location } from '@angular/common';

import { EmployeeLoan } from 'src/app/Models/EmployeeLoan';
import { EmployeeDeduction } from 'src/app/Models/EmployeeDeduction';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.css']
})
export class CreateEmployeeComponent implements OnInit {

  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faCaretDown = faCaretDown;

  public urlSetting: string;
  public setting: string = 'create';
  public loading: Boolean = false;
  public modalMessage: String = '';
  public error: Boolean = false;

  submittedForms: Array<string> = [];

  employeeRef: String = '';
  company: Company = Defaults.CompanyDefaults();
  public employee: Employee = Defaults.EmployeeDefault();
  address: Address = Defaults.AddressDefaults();
  ach: Ach = Defaults.achDefault();

  public employeeLoan: EmployeeLoan = Defaults.EmployeeLoanDefault();
  public employeeDeduction: EmployeeDeduction = Defaults.EmployeeDeductionDefault();

  bankingCodes: Array<BankCode> = [];

  employeeId: number = null;
  companyId: string;

  departmentCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  healthCode: HealthCode = Defaults.HealthCodeDefault();
  occupationCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  transactionCode: TransactionCode = Defaults.TransactionCodeDefault();
  deductionCode: DeductionCode = Defaults.DeductionCodeDefault();
  loanCode: LoanCode = Defaults.LoanCodeDefault();
  bankCode: BankCode = Defaults.BankCodeDefault();
  pensionCode: PensionCode = Defaults.PensionCodeDefault();

  public loanEnabledInput: boolean = false;
  public deductionEnabledInput: boolean = false;

  public loanContinuous: boolean = false;
  public deductionContinuous: boolean = false;

  public companyCodesDepartment: Array<DepartmentOccupationCode> = [];
  public companyCodesOccupation: Array<DepartmentOccupationCode> = [];
  public companyCodesHealth: Array<HealthCode> = [];
  public companyCodesLoan: Array<LoanCode> = [];
  public companyCodesDeduction: Array<DeductionCode> = [];
  public companyCodesTransaction: Array<TransactionCode> = [];
  public companyCodesPension: Array<PensionCode> = [];

  public createPersonalSubmitted: Boolean = false;

  chartOfAccounts: ChartOfAccounts = Defaults.ChartOfAccountsDefault();
  chartsOfAccounts: Array<ChartOfAccounts> = [];

  chartsOfAccountsExpenses: Array<ChartOfAccounts> = [];
  chartsOfAccountsLiabilities: Array<ChartOfAccounts> = [];
  chartsOfAccountsAssets: Array<ChartOfAccounts> = [];

  public addDeduction: boolean = false;
  public addLoan: boolean = false;
  public specialAddType: string = '';

  public specialEdit: Boolean = false;
  public specialDelete: Boolean = false;
  public specialIndex: number = undefined;

  public ytdRetrieved: Boolean = false;
  public mtdRetrieved: Boolean = false;
  public ytdArgs: any = {
    type: 'ytd',
    period: 'weekly',
  };
  public selectedEmployeeYtd: Array<any> = [];
  public selectedEmployeeMtd: Array<any> = [];
  hideTerminationDate: boolean = false;


  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {

  }

  ngOnInit(){
    $('.actionsBox').toggle();

    const routeParams = this.route.snapshot.paramMap;
    this.companyId = this.route.snapshot.paramMap.get('id');
    this.urlSetting = this.route.snapshot.url[4].toString();


    let codeTypes = ['department','occupation','transaction','deduction','loan','pension','health'];
    for (const type of codeTypes) {
      this.getCode(type)
    }


    if (this.urlSetting === 'create') {
      this.setting = 'create';

      // $("#employment-tab").removeClass("disabled");
      // $("#banking-tab").removeClass("disabled");
      // $("#ytd-tab").removeClass("disabled");

    }

    if (this.urlSetting === 'edit') {

      this.setting = 'edit';
      if (this.route.snapshot.url[5]) {
        this.employeeRef = this.route.snapshot.url[5].toString();
      }

      $("#employment-tab").removeClass("disabled");
      $("#banking-tab").removeClass("disabled");
      $("#ytd-tab").removeClass("disabled");
      $("#loan-tab").removeClass("disabled");
      $("#deductions-tab").removeClass("disabled");
      $("#pension-tab").removeClass("disabled");


      this.api.getEmployees(this.companyId).subscribe(employees => {

        let emp = employees.find( ({id}) => id === this.employeeRef );

        this.employee = emp;
        // console.log('emp',emp);

        for (const loan of this.employee.loans) {
          loan.numPayments = parseInt((loan.initialAmount/loan.repaymentAmount).toFixed(2));
        }

        if(this.employee.employment.startDate != null){
          this.employee.employment.startDate = this.employee.employment.startDate.split("T")[0]

        }
        if(this.employee.employment.terminationDate != null){
          this.employee.employment.terminationDate = this.employee.employment.terminationDate.split("T")[0]

        }


        // if (this.employee.loans[0]) {
        //   this.employeeLoan = this.employee.loans[0];
        // }
        // if (this.employee.deductions[0]) {
        //   this.employeeDeduction = this.employee.deductions[0];
        // }
        //
        // if (!this.employee.loans[0]) {
        //   this.employee.loans.push(this.employeeLoan)
        // }
        // if (!this.employee.deductions[0]) {
        //   this.employee.deductions.push(this.employeeDeduction)
        // }


        // no of payments = initial amount/repayment amt
        // on create, outstanding = initial

        if (this.employee.dob) {
          this.employee.dob = emp.dob.slice(0,10);
        }

        if (this.employee.employment.startDate) {
          this.employee.employment.startDate = emp.employment.startDate.slice(0,10);
          this.employee.parentId = emp.secondaryID;
        }


        if (this.employee.employment.status === 'terminated') {
          this.hideTerminationDate = false;
        }

        if (this.employee.employment.status !== 'terminated') {
          this.hideTerminationDate = true;
        }


      },
      error => {
        this.loading = false;
        // console.log('api request errorx',error);
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error!"
        }
        $("#errorModal").modal("show");
      })

    }

    // $("#employment-tab").removeClass("disabled");
    // $("#banking-tab").removeClass("disabled");
    // $("#ytd-tab").removeClass("disabled");
    // $("#loan-tab").removeClass("disabled");
    // $("#deductions-tab").removeClass("disabled");


    this.getChartsOfAccounts();
    this.getBankingCodes()

    window.addEventListener('click', (e) => this.handleClick(e.target));

  }

  getCode(type) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCode(this.companyId,type).subscribe(codes => {

      // console.log('getCode',codes);

      // this[type+'Codes'] = codes;

      switch(type) {
        case 'department' :
         this.companyCodesDepartment = codes;
        break;
        case 'occupation' :
         this.companyCodesOccupation = codes;
        break;
        case 'transaction' :
         this.companyCodesTransaction = codes;
        break;
        case 'deduction' :
         this.companyCodesDeduction = codes;
        break;
        case 'loan' :
         this.companyCodesLoan = codes;
        break;
        case 'pension' :
         this.companyCodesPension = codes;
        break;
        case 'health' :
         this.companyCodesHealth = codes;
        break;
      }

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCode"+type+"!"
      }

      $("#errorModal").modal("show");
    })

  }

  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
        // $('.actionsBox2').hide();
        // $('.s01Action').hide();
      }

    }

  }

  pauseEnableToggle() {

    if (this.employeeLoan.paused === true) {
      this.loanEnabledInput = true;
    } else {
      this.loanEnabledInput = false;
    }
    if (this.employeeDeduction.paused === true) {
      this.deductionEnabledInput = true;
    } else {
      this.deductionEnabledInput = false;
    }

  };

  getChartsOfAccounts () {

      this.loading = true;
      $("#errorModal").modal("show");
      this.api.getChartOfAccounts(this.companyId).subscribe(next => {

        this.chartsOfAccounts = next.coas;

        if (next.coas && next.coas[0]) {
          for (const chart of next.coas) {
            switch(chart.type) {
              case 'expenses' :
               this.chartsOfAccountsExpenses.push(chart)
              break;
              case 'liabilities' :
               this.chartsOfAccountsLiabilities.push(chart)
              break;
              case 'assets' :
               this.chartsOfAccountsAssets.push(chart)
              break;
            }
          }
        } else {
          console.log('No Charts of Accounts for this company');
        }



        this.loading = false;
        $("#errorModal").modal("hide");

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getChartOfAccounts!"
        }
        $("#errorModal").modal("show");
      })

    }

  onSubmit (formType) {

    // console.log('this.employee',this.employee);


      this.loading = true;
      $("#errorModal").modal("show");

      if (this.setting === 'create') {

        this.employee.parentId = this.companyId;

        if (formType === 'personal') {


          this.api.createEmployee(JSON.stringify(this.employee)).subscribe(next =>{
            console.log('createEmployee',next);


            this.loading = false;
            this.modalMessage = "Create Success!"
            $("#errorModal").modal("show");
            this.submittedForms.push(formType);
            this.createPersonalSubmitted = true;

            this.employeeId = next.employee.employeeId;

            $("#employment-tab").removeClass("disabled");
            $("#banking-tab").removeClass("disabled");
            $("#ytd-tab").removeClass("disabled");
            $("#loan-tab").removeClass("disabled");
            $("#deductions-tab").removeClass("disabled");
            $("#pension-tab").removeClass("disabled");

            if (next.status === "Employee added.") {
              // this.router.navigate(['pages/company', this.companyId, 'details']);
              this.setting = 'edit';
              this.employee = next.employee;

              if(this.employee.employment.startDate != null){
                this.employee.employment.startDate = this.employee.employment.startDate.split("T")[0]

              }
              if(this.employee.employment.terminationDate != null){
                this.employee.employment.terminationDate = this.employee.employment.terminationDate.split("T")[0]

              }

            }

            setTimeout(()=>{
              $("#errorModal").modal("hide");
            }, 3000);

          },
          error => {

            this.loading = false;
            if (error.error) {
              this.modalMessage = error.error.status
            } else {
              this.modalMessage = "Error: createEmployee!"
            }
            $("#errorModal").modal("show");
          }
          );

        }

        else if (this.submittedForms.includes('personal') !== true) {

          this.modalMessage = "Add personal inforamtion 1st!"
          $("#errorModal").modal("show");
        }

        else if (this.submittedForms.includes('personal') === true) {

          this.employee.employeeId = this.employeeId;


          this.employeeLoan.outstanding = this.employeeLoan.initialAmount;

          for (const loan of this.employee.loans) {
            loan.numPayments = parseInt((loan.initialAmount/loan.repaymentAmount).toFixed(2));
          }

          if (this.employee.employment.status === "terminated" && !this.employee.employment.terminationDate || this.employee.employment.terminationDate === null || this.employee.employment.terminationDate.charAt(0) === "0" ) {
            this.employee.employment.terminationDate = new Date().toISOString();
          }

          if (this.employee.employment.status !== "terminated") {
            this.employee.employment.terminationDate = null;
          }

          this.api.updateEmployee(this.employee).subscribe(next =>{

            this.loading = false;
            this.modalMessage = "Update Success!"
            this.submittedForms.push(formType);
            this.employee = next.employee;

            if (this.employee.employment.status === "terminated") {
              this.hideTerminationDate = false;
            }

            setTimeout(()=>{
              $("#errorModal").modal("hide");
            }, 3000);

          },
          error => {
            this.loading = false;
            if (error.error) {
              this.modalMessage = error.error.status
            } else {
              this.modalMessage = "Error: updateEmployee!"
            }
            $("#errorModal").modal("show");
          });

        }

      }

      if (this.setting === 'edit') {

        this.employee.parentId = this.companyId;
        // this.employee.id = this.employeeId;

        this.employee.pension.employeeId = this.employee.employeeId;

        if (formType === 'employment') {


          let fullDeptCode = this.companyCodesDepartment.find(x=>x.code === this.employee.employment.department.code)

          // console.log('xxx',this.employee.employment);
          // console.log('yyy',fullDeptCode,this.companyCodesDepartment);


          if (fullDeptCode) {
            this.employee.employment.department.abbreviation = fullDeptCode.abbreviation;
            this.employee.employment.department.code = fullDeptCode.code;
            this.employee.employment.department.companyID = fullDeptCode.companyID;

            // this.employee.employment.department.defaultCompanyAmount = fullDeptCode.defaultCompanyAmount;
            // this.employee.employment.department.defaultEmployeeAmount = fullDeptCode.defaultEmployeeAmount;

            this.employee.employment.department.description = fullDeptCode.description;
            this.employee.employment.department.rate = fullDeptCode.rate;
            this.employee.employment.department.id = fullDeptCode.id;
            this.employee.employment.department.type = fullDeptCode.type;
          }


          let fullOccupCode = this.companyCodesOccupation.find(x=>x.code === this.employee.employment.occupation.code)

          if (fullOccupCode) {
            this.employee.employment.occupation.abbreviation = fullOccupCode.abbreviation;
            this.employee.employment.occupation.code = fullOccupCode.code;
            this.employee.employment.occupation.companyID = fullOccupCode.companyID;
            // this.employee.employment.occupation.defaultCompanyAmount = fullOccupCode.defaultCompanyAmount;
            // this.employee.employment.occupation.defaultEmployeeAmount = fullOccupCode.defaultEmployeeAmount;
            this.employee.employment.occupation.description = fullOccupCode.description;
            this.employee.employment.occupation.rate = fullOccupCode.rate;
            this.employee.employment.occupation.id = fullOccupCode.id;
            this.employee.employment.occupation.type = fullOccupCode.type;
          }


        }


        if (this.specialEdit === true) {
          if (this.addLoan === true) {
            this.employee.loans[this.specialIndex] = this.employeeLoan;
          }
          if (this.addDeduction === true) {
            this.employee.deductions[this.specialIndex] = this.employeeDeduction;
          }
        }

        if (this.specialDelete === true) {

        }

        if (
          this.specialEdit !== true &&
          this.specialDelete !== true
        ) {
          if (this.addLoan === true) {
            if (this.employeeLoan.endDate === "") {
              this.employeeLoan.endDate = this.employeeLoan.startDate;
            }
            this.employee.loans.push(this.employeeLoan);
          }
          if (this.addDeduction === true) {
            if (this.employeeDeduction.endDate === "") {
              this.employeeDeduction.endDate = this.employeeDeduction.startDate;
            }
            this.employee.deductions.push(this.employeeDeduction);
          }
        }


        this.employeeLoan = Defaults.EmployeeLoanDefault();
        this.employeeDeduction = Defaults.EmployeeDeductionDefault();
        this.addLoan = false;
        this.addDeduction = false;

        for (const loan of this.employee.loans) {
          loan.numPayments = parseInt((loan.initialAmount/loan.repaymentAmount).toFixed(2));
        }
        // console.log('termdate',this.employee.employment.terminationDate,this.employee.employment);

        // if (this.employee.employment.status === "terminated" && !this.employee.employment.terminationDate || this.employee.employment.terminationDate === null || this.employee.employment.terminationDate.charAt(0) === "0" ) {
        if (this.employee.employment.status === "terminated" && !this.employee.employment.terminationDate || this.employee.employment.terminationDate === null) {
          this.employee.employment.terminationDate = new Date().toISOString();
        }
        if (this.employee.employment.status !== "terminated") {
          this.employee.employment.terminationDate = null;
        }

        this.loading = true;
        $("#errorModal").modal("show");

        this.api.updateEmployee(this.employee).subscribe(next =>{

          this.loading = false;
          this.modalMessage = "Update Success!"
          // $("#errorModal").modal("hide");
          this.submittedForms.push(formType);
          // console.log('updateEmployee',next);



          this.employee = next.employee;

          if (this.employee.employment.status === "terminated") {
            this.hideTerminationDate = false;
          }

          if(this.employee.employment.startDate != null){
            this.employee.employment.startDate = this.employee.employment.startDate.split("T")[0]

          }
          if(this.employee.employment.terminationDate != null){
            this.employee.employment.terminationDate = this.employee.employment.terminationDate.split("T")[0]

          }

          setTimeout(()=>{
            $("#errorModal").modal("hide");
          }, 3000);



        },
        error => {
          this.loading = false;
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: updateEmployee!"
          }
          $("#errorModal").modal("show");
        })
      }


    }

  getBankingCodes() {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getBankingCodes().subscribe(next => {

      this.bankingCodes = next;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getBankingCodes!"
      }
      $("#errorModal").modal("show");
    })

  }

  checkBoxHandler (value,label) {
    // console.log('this.employee.trnPayable',this.employee.trnPayable);
  }

  toggleSpecialAdd(args) {

    this.specialEdit = false;
    this.specialDelete = false;

    if (args === 'deduction') {
      this.addDeduction = !this.addDeduction;
      this.employeeDeduction = Defaults.EmployeeDeductionDefault();
    }
    if (args === 'loan') {
      this.addLoan = !this.addLoan;
      this.employeeLoan = Defaults.EmployeeLoanDefault();
    }

  }

  actionsBoxSpecial (index,type,event,item) {

    this.specialIndex = index;
    if (type === 'loan') {

      this.employeeLoan = item;
      this.specialAddType = 'loan';
      this.loanContinuous = item.continuous;
    }
    if (type === 'deduction') {

      this.employeeDeduction = item;
      this.specialAddType = 'deduction';
      this.deductionContinuous = item.continuous;
    }

    item.startDate = item.startDate.slice(0,10);
    item.endDate = item.endDate.slice(0,10);


    $('.actionsBox').css({'top':event.pageY-100,'left':event.pageX-100})
    $('.actionsBox').toggle();
  }

  handleLoanAmount (event) {
    if (this.specialEdit != true) {
      this.employeeLoan.outstanding = parseInt(event.target.value);
    }

    this.employeeLoan.numPayments = 0;
    this.employeeLoan.repaymentAmount = 0;
  }

  handleLoanPaymentCalc(event) {

    let repaymentNumber = this.employeeLoan.numPayments;
    let repaymentAmount = this.employeeLoan.repaymentAmount;
    let initialAmount = this.employeeLoan.initialAmount;

    if (repaymentNumber && repaymentNumber !== 0 && initialAmount && initialAmount !== 0)  {
      this.employeeLoan.repaymentAmount = parseInt((initialAmount/repaymentNumber).toFixed(2))
    }
    if (repaymentAmount && repaymentAmount !== 0 && initialAmount && initialAmount !== 0)  {
      this.employeeLoan.numPayments = parseInt((initialAmount/repaymentAmount).toFixed(2))
    }
    if (repaymentNumber && repaymentNumber !== 0 && repaymentAmount && repaymentAmount !== 0 && initialAmount === 0)  {
      this.employeeLoan.initialAmount = parseInt((initialAmount/repaymentAmount).toFixed(2))
    }

  }

  employeeAction(args) {

    if (args === 'edit') {
      this.specialEdit = true;
      this.specialDelete = false;

      if (this.specialAddType === 'loan') {
        this.addLoan = true;
      }
      if (this.specialAddType === 'deduction') {
        this.addDeduction = true;
      }

    }
    if (args === 'delete') {
      this.specialEdit = false;
      this.specialDelete = true;

      if (this.specialAddType === 'loan') {
        // console.log('delete loan',this.employee.loans[this.specialIndex]);

        // this.employee.loans.filter(x=>x !== this.employee.loans[this.specialIndex])
        this.employee.loans.splice(this.specialIndex,1)
        this.onSubmit('loan');
      }
      if (this.specialAddType === 'deduction') {
        // console.log('delete deduction',this.employee.deductions[this.specialIndex]);

        // this.employee.deductions.filter(x=>x !== this.employee.deductions[this.specialIndex])
        this.employee.deductions.splice(this.specialIndex,1)
        this.onSubmit('deduction');
      }

    }
    $('.actionsBox').hide();

  }

  toggleBox() {
    $('.actionsBox').hide();
  }

  handlePreEndDate(type,event) {

    if (type === 'loan') {
      this.loanContinuous = event.target.checked;

    }
    if (type === 'deduction') {
      this.deductionContinuous = event.target.checked;
    }

  }

  resetYtd() {

    this.ytdArgs = {
      type: 'ytd',
      period: 'weekly',
    }
    this.ytdRetrieved = false;
    this.mtdRetrieved = false;
    this.selectedEmployeeYtd = [];
    this.selectedEmployeeMtd = [];

  }

  getYtd() {

    let empId = this.employee.employeeId;
    let companyId = this.companyId;
    let report = this.ytdArgs.type;
    let cycle = this.employee.employment.payCycle;

    this.loading = true;
    $("#errorModal").modal("show");

    if (report === "ytd") {

      this.api.getEmployeeYtdCurrent(companyId,empId,cycle).subscribe(next => {

        if (next.status) {

          if (next.status.substring(0,10) === 'No payroll') {
            this.loading = false;
            this.modalMessage = next.status;
            $("#errorModal").modal("show");
          }
        }
        else {
          this.ytdRetrieved = true;
          // this.selectedEmployeeYtd = next;

          for(const [key, value] of Object.entries(next)) {

            let item: any = undefined;
            let val: any = value;


            if (val) {
              switch(key) {
                case 'deductions' :
                for (const elem of val) {

                }
                break;
                case 'loans' :
                for (const elem of val) {

                }
                break;
                case 'pay' :
                for (const elem of val) {
                  item = {
                    key: elem.description,
                    value: elem.amount,
                  }
                  this.selectedEmployeeYtd.push(item);
                }
                break;
                case 'statutory' :
                for (const elem of val) {
                  item = {
                    key: elem.tax,
                    value: elem.employeeAmount
                  }
                  this.selectedEmployeeYtd.push(item);
                }
                break;
                default:
                if (key !== 'employee' && key !== 'employeeId' && key !== 'isTerminated') {
                  if (typeof val == 'number') {
                    item = {
                      key: key,
                      value: val.toFixed(2)
                    }
                  }
                  else {
                    item = {
                      key: key,
                      value: val
                    }
                  }

                  this.selectedEmployeeYtd.push(item);
                }

              }
            }

          }

          this.loading = false;
          $("#errorModal").modal("hide");

        }


      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeeYtd!"
        }
        $("#errorModal").modal("hide");
      })

    }

    if (report === "mtd") {

      this.api.getEmployeeMtdCurrent(companyId,empId,cycle).subscribe(next => {

        if (next.status) {

          if (next.status.substring(0,10) === 'No payroll') {
            this.loading = false;
            this.modalMessage = next.status;
            $("#errorModal").modal("show");
          }
        }
        else {
          this.mtdRetrieved = true;
          this.ytdRetrieved = true;
          // this.selectedEmployeeYtd = next;

          for(const [key, value] of Object.entries(next)) {

            let item: any = undefined;
            let val: any = value;


            if (val) {
              switch(key) {
                case 'deductions' :
                for (const elem of val) {

                }
                break;
                case 'loans' :
                for (const elem of val) {

                }
                break;
                case 'pay' :
                for (const elem of val) {
                  item = {
                    key: elem.description,
                    value: elem.amount.toFixed(2),
                  }
                  this.selectedEmployeeMtd.push(item);
                }
                break;
                case 'statutory' :
                for (const elem of val) {
                  item = {
                    key: elem.tax,
                    value: elem.employeeAmount.toFixed(2)
                  }
                  this.selectedEmployeeMtd.push(item);
                }
                break;
                default:
                if (key !== 'employee' && key !== 'employeeId' && key !== 'isTerminated') {
                  if (typeof val == 'number') {
                    item = {
                      key: key,
                      value: val.toFixed(2)
                    }
                  }
                  else {
                    item = {
                      key: key,
                      value: val
                    }
                  }

                  this.selectedEmployeeMtd.push(item);
                }

              }
            }

          }

          this.loading = false;
          $("#errorModal").modal("hide");

        }


      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeeMtd!"
        }
        $("#errorModal").modal("hide");
      })

    }


  }


  handleStatusChange(args) {

    if (args.value === 'terminated') {
      this.hideTerminationDate = false;
    } else {
      this.hideTerminationDate = true;
    }



  }

  resetHealth() {

    this.employee.health.code = ""
    this.employee.health.type = ""

    this.onSubmit('employment')
  }


}
