import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Defaults } from 'src/app/Models/Defaults';
import { Tax } from 'src/app/Models/Tax';
import { TaxTierEmployee } from 'src/app/Models/TaxTierEmployee';
import { TaxTierEmployer } from 'src/app/Models/TaxTierEmployer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

declare const $: any;


import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-taxes-deductions',
  templateUrl: './taxes-deductions.component.html',
  styleUrls: ['./taxes-deductions.component.css']
})
export class TaxesDeductionsComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;

  public url: string;
  url2 : string;
  url3 : string;
  url4 : string;
  url5 : string;
  url6 : string;

  public id: string = '';

  public loading: Boolean = false;
  public modalMessage: string = '';
  public setting: string = 'List';

  tax: Tax = Defaults.TaxDefault();
  taxTierEmployee: TaxTierEmployee = Defaults.TaxTierEmployeeDefault();
  taxTierEmployer: TaxTierEmployer = Defaults.TaxTierEmployerDefault();

  taxes: Array<Tax> = [];
  payeTaxes: Array<Tax> = [];
  edTaxes: Array<Tax> = [];
  nhtTaxes: Array<Tax> = [];
  heartTaxes: Array<Tax> = [];
  nisTaxes: Array<Tax> = [];

  employeeThresholds: Array<TaxTierEmployee> = [];
  employerThresholds: Array<TaxTierEmployer> = [];

  public selectedEmployeeThreshold: number = null;
  public selectedEmployerThreshold: number = null;


  constructor(
    private api: ApiService,
    private location:Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');


    $('.actionsBox').toggle();

    this.getTaxes();

  }

  getTaxes () {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getTaxHeaders().subscribe(next => {

      console.log('getTaxHeaders',next);


      this.taxes = next.taxheaders;
      for (const tax of next.taxheaders) {

        switch(tax.type) {
          case 'PAYE' :
            this.payeTaxes.push(tax)
          break;
          case 'Ed. Tax' :
            this.edTaxes.push(tax)
          break;
          case 'NHT' :
            this.nhtTaxes.push(tax)
          break;
          case 'HEART' :
            this.heartTaxes.push(tax)
          break;
          case 'NIS' :
            this.nisTaxes.push(tax)
          break;
        }
      }



      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      console.log('api request errorx',error);
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getTaxes!"
      }
      $("#errorModal").modal("show");
    })

  }

  actionsBox (event) {


    $('.actionsBox').css({'top':event.pageY-50,'left':event.pageX+10})
    $('.actionsBox').toggle();
  }

  newTax(){
    this.setting = 'Add';
  }
  showTax(){
    this.setting = 'List';
  }

  onSubmit () {

    this.tax.taxTierEmployee = this.employeeThresholds;
    this.tax.taxTierEmployer = this.employerThresholds;

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.createTaxHeader(this.tax).subscribe(next => {



      this.loading = false;
      this.modalMessage = "Tax Header Created"


      setTimeout(()=>{
        $("#errorModal").modal("hide");
        this.getTaxes();
      }, 3000);

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: createTaxHeader!"
      }
      $("#errorModal").modal("show");
    })

  }

  addThresholdInput (args) {

    let threshold1: TaxTierEmployee = Defaults.TaxTierEmployeeDefault();
    let threshold2: TaxTierEmployer = Defaults.TaxTierEmployerDefault();

    if (args === 'employee') {
      this.employeeThresholds.push(threshold1);
      console.log('employeeThresholds',this.employeeThresholds);
    }
    if (args === 'employer') {
      this.employerThresholds.push(threshold2);
      console.log('employerThresholds',this.employerThresholds);
    }

  }

  removeThresholdInput (args) {
    if (args === 'employee') {
      this.employeeThresholds.splice(this.employeeThresholds.length-1,1)
    }
    if (args === 'employer') {
      this.employerThresholds.splice(this.employerThresholds.length-1,1)
    }
  }

  selectThreshold (type,index) {
    if (type === 'employee') {
      if (this.selectedEmployeeThreshold === index) {
        this.selectedEmployeeThreshold = null;
      }
      else if (this.selectedEmployeeThreshold !== index) {
        this.selectedEmployeeThreshold = index;
      }
    }
    if (type === 'employer') {
      if (this.selectedEmployerThreshold === index) {
        this.selectedEmployerThreshold = null;
      }
      else if (this.selectedEmployerThreshold !== index) {
        this.selectedEmployerThreshold = index;
      }
    }
  }

}
