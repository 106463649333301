import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/Models/Company';

declare const $: any;

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;

  public loading: Boolean = false;
  rows = [];
  public srch = [];
  public modalMessage: string = '';


  // company: Company = Defaults.CompanyDefaults();
  customers: Array<Company> = [];
  customerSrch: Array<Company> = [];
  // public customers: any[] = [
  // ]
  public companyEmpCounts: Array<any> = [];


  public totalEmployeeCount: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];

  currentUser: any = {};

  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {

    this.getCompanies()

    localStorage.setItem('companySelected', 'false')
    this.currentUser = JSON.parse(localStorage.getItem("admin"))


  }

  getCompanies () {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCompanies().subscribe(
      next => {
        // console.log('this.customers',next);

        this.customers = next;
        this.customerSrch = next;

        this.loading = false;
        $("#errorModal").modal("hide");


        // for (const company of this.customers) {
        //   let index = this.customers.indexOf(company)
        //     this.api.getEmployeesByPage(0,1,company.id).subscribe(
        //       next => {
        //
        //         if (index === this.customers.length-1) {
        //           this.loading = false;
        //           $("#errorModal").modal("hide");
        //         }
        //
        //         let index2 = this.customerSrch.indexOf(company);
        //         // console.log('company',company.companyName,'dashboard emplyees',next.length, next);
        //
        //         this.companyEmpCounts[index2] = next.count;
        //       },
        //       error => {
        //         this.loading = false;
        //         if (error.error) {
        //           this.modalMessage = error.error.status
        //           if (error.error.status !== 'No Employees registered.') {
        //             $("#errorModal2").modal("hide");
        //           }
        //         } else {
        //           this.modalMessage = "Error: getEmployeesByPage!";
        //           $("#errorModal2").modal("show");
        //         }
        //
        //
        //         let index3 = this.customerSrch.indexOf(company);
        //         // console.log('2 company',company.companyName,'dashboard emplyees',next.length, next);
        //
        //         this.companyEmpCounts[index3] = 0;
        //       }
        //     )
        // }

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getCompanies!"
        }
        $("#errorModal").modal("show");
      }
    )

  }

  editCompany (company) {

    // this.router.navigate(['pages/company/details'], { queryParams: { 'id': company.id } });
    this.router.navigate(['pages/company/create'], { queryParams: { 'id': company.id } });

  }

  selectCompany(id){
    this.router.navigate(['pages/company',id, 'details']);
    // this.router.navigate(['pages/company/details'], { queryParams: { 'id': id } });
  }

  searchCompany(val) {
    // this.rows.splice(0, this.rows.length);

    let temp = this.customers.filter(function(d) {
      val = val.toLowerCase();
      return d.companyName.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.customerSrch = [...temp];

    // this.rows.push(...temp);
  }


}
