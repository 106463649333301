<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Company Codes</h3>
      </div>


      <div class="row input">

        <div class=" btnCol">
          <button (click)="newCode()" type="button" class="btn createBtn"><fa-icon [icon]="faPlus" class=" btnIcon"></fa-icon><p class="btnText ">Add</p></button>
        </div>
        <div class=" btnCol">
          <button (click)="uploadCode()" type="button" class="btn createBtn"><fa-icon [icon]="faUpload" class=" btnIcon"></fa-icon><p class="btnText ">Upload</p></button>
        </div>
        <div class=" btnCol">
          <button (click)="showCode()" type="button" class="btn showBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Show</p></button>
        </div>

      </div>
      <div class="row input">

        <!-- <div class=" btnCol col-md-3"> -->
          <div class=" form-group " style="margin-left:1rem;">
            <input *ngIf="searchField != 'isEnable'" type="text" [(ngModel)]="codeSearchQuery" (input)="searchCodes($event.target.value)" id="inputSearch" class="form-control floating">
            <select *ngIf="searchField == 'isEnable'" class="form-control" aria-label="Default select example" (change)="searchCodes($event.target.value)" id="inputTypeSelectBool"  name="typeSelect">
              <option [value]="true" >True</option>
              <option [value]="false" >False</option>
            </select>
          </div>
        <!-- </div> -->

        <!-- <div class=" btnCol col-md-3"> -->
          <div class="formInput form-group">

              <select class="form-control" aria-label="Default select example" (change)="selectSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                <option *ngFor="let field of fieldList" [value]="field" >{{field}}</option>
              </select>
          </div>
        <!-- </div> -->

        <!-- <div  class=" btnCol col-md-3"> -->
        <div *ngIf="searchField == 'isEnable'" class="formInput form-group" >
          <button type="button" class="btn createBtn" (click)="searchCodes('boolsearch')">
            <p class="btnText ">Search</p></button>
        </div>
        <!-- </div> -->

        <!-- <div class=" btnCol col-md-3"> -->
        <div class="formInput form-group">
          <button type="button" class="btn createBtn" (click)="clearSearch()">
            <p class="btnText ">Clear</p></button>
        </div>
        <!-- </div> -->

        <!-- <div class=" btnCol col-md-3"> -->
        <div class="form-group" >

            <select class="form-control" aria-label="Default select example" (change)="selectSortDirection($event.target.value)" id="inputTypeSelect"  name="typeSelect">
              <option value="Ascending">Ascending</option>
              <option value="Descending">Descending</option>
            </select>

        </div>
        <!-- </div> -->

        <!-- <div class=" btnCol col-md-2"> -->
        <div class="form-group">
          <button type="button" class="btn createBtn" (click)="sortCodes()">
            <p class="btnText ">Sort</p>
          </button>
        </div>
        <!-- </div> -->



      </div>

    </div>

    <div class="subcontainerLower">

      <div class="row lowerTable">


        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button (click)="getCode('department')" class="nav-link active" id="department-tab" data-toggle="tab" href="#department" type="button" role="tab" aria-controls="department" aria-selected="true">Department</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="getCode('occupation')" class="nav-link" id="occupation-tab" data-toggle="tab" href="#occupation" type="button" role="tab" aria-controls="occupation" aria-selected="false">Occupation</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="getCode('transaction')" class="nav-link" id="transaction-tab" data-toggle="tab" href="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="false">Transaction</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="getCode('deduction')" class="nav-link" id="deduction-tab" data-toggle="tab" href="#deduction" type="button" role="tab" aria-controls="deduction" aria-selected="false">Deduction</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="getCode('loan')" class="nav-link" id="loan-tab" data-toggle="tab" href="#loan" type="button" role="tab" aria-controls="loan" aria-selected="false">Loan</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="getCode('pension')" class="nav-link" id="pension-tab" data-toggle="tab" href="#pension" type="button" role="tab" aria-controls="pension" aria-selected="false">Pension</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="getCode('health')" class="nav-link" id="health-tab" data-toggle="tab" href="#health" type="button" role="tab" aria-controls="health" aria-selected="false">Health</button>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="department" role="tabpanel" aria-labelledby="department-tab">
            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of departmentCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.abbreviation}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'department')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>


            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('department')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Department</h4>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="departmentCode.code" id="inputOccupationCodeCode" name="occupationCodeCode" >
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="departmentCode.description" id="inputOccupationCodeDescription" name="occupationCodeDescription" >
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputOccupationCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="departmentCode.abbreviation" id="inputOccupationCodeAbbreviation" name="occupationCodeAbbreviation" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputOccupationCodeRate">Rate</label>
                    <input type="hidden" name="type" [value]="department" [(ngModel)]="departmentCode.type" />
                    <input type="number" class="form-control" [(ngModel)]="departmentCode.rate" id="inputOccupationCodeRate" name="occupationCodeRate">
                  </div>
                </div>

                <!-- <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeType">Type</label>
                    <input type="text" class="form-control" [(ngModel)]="departmentCode.type" id="inputOccupationCodeType" name="occupationCodeType" >
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeCompanyID">CompanyID</label>
                    <input type="text" class="form-control" [(ngModel)]="departmentCode.companyID" id="inputOccupationCodeCompanyID" name="occupationCodeCompanyID" >
                  </div>

                </div> -->

                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>
              </form>
            </div>


            <div class="codeAdd" *ngIf="setting == 'Upload'">

              <div class="formHead">
                <h4 class="formHeading">{{setting}} Department Codes</h4>
              </div>

              <div class="dragDropBox">

                <div class="form-group col-md-12 fileDropZone">
                  <ngx-file-drop id="employeeUploadDragDrop"
                  contentClassName="dragDropText" dropZoneClassName="dropZone" dropZoneLabel="Drop files here"
                  (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
                  style="margin:0 auto;width:100%;height: 10rem;border-radius: 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color:#ffffff;">
                      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <p class="dragDroptext">Drag & Drop OR
                          <input type="file" (change)="selectFile($event.target.files)" id="select">
                        </p>
                      </ng-template>
                  </ngx-file-drop>

                </div>
              </div>


              <div class="interRow">
                <button type="button" (click)="uploadCodes()" class="btn createBtn"><p class="btnText">upload</p></button>
              </div>


              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Rate</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of codesToUpload">
                  <td class="tableCels">{{code.code}}</td>
                  <td class="tableCels">{{code.description}}</td>
                  <td class="tableCels">{{code.abbreviation}}</td>
                  <td class="tableCels">{{code.rate}}</td>
                  <!-- <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,item)" ><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td> -->
                </tr>

              </table>


            </div>



          </div>

          <div class="tab-pane fade" id="occupation" role="tabpanel" aria-labelledby="occupation-tab">
            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Rate</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of occupationCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.abbreviation}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.rate}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'occupation')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('occupation')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Occupation</h4>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="occupationCode.code" id="inputOccupationCodeCode" name="occupationCodeCode" >
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="occupationCode.description" id="inputOccupationCodeDescription" name="occupationCodeDescription" >
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputOccupationCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="occupationCode.abbreviation" id="inputOccupationCodeAbbreviation" name="occupationCodeAbbreviation" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputOccupationCodeRate">Rate</label>
                    <input type="number" class="form-control" [(ngModel)]="occupationCode.rate" id="inputOccupationCodeRate" name="occupationCodeRate">
                  </div>
                </div>

                <!-- <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeType">Type</label>
                    <input type="text" class="form-control" [(ngModel)]="occupationCode.type" id="inputOccupationCodeType" name="occupationCodeType" >
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputOccupationCodeCompanyID">CompanyID</label>
                    <input type="text" class="form-control" [(ngModel)]="occupationCode.companyID" id="inputOccupationCodeCompanyID" name="occupationCodeCompanyID" >
                  </div>

                </div> -->

                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>
              </form>
            </div>
          </div>

          <div class="tab-pane fade" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Gl Account</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Type</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <!-- <th class="tableHeadings">
                    <p class="headText">Rate</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th> -->
                  <th class="tableHeadings">
                    <p class="headText">Rate Multiplier</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of transactionCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.abbreviation}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.glAccount}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.type}}</p></td>
                  <!-- <td class="tableCels"><p class="cellText">{{code.rate}}</p></td> -->
                  <td class="tableCels"><p class="cellText">{{code.rateMultiplier}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'transaction')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('transaction')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Transaction</h4>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label for="inputTransactionCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="transactionCode.code" id="inputTransactionCodeCode" name="transactionCodeCode" >
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputTransactionCodeName">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="transactionCode.name" id="inputTransactionCodeName" name="transactionCodeName" >
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputTransactionCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="transactionCode.description" id="inputTransactionCodeDescription" name="transactionCodeDescription" >
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputTransactionCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="transactionCode.abbreviation" id="inputTransactionCodeAbbreviation" name="transactionCodeAbbreviation" >
                  </div>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label for="inputTransactionType">Type</label>
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="transactionCode.type" id="inputTransactionType" name="transactionType">
                      <option value="Payment of Amount">Payment of Amount</option>
                      <option value="Payment of Hours">Payment of Hours</option>
                      <option value="Deduction of Amount">Deduction of Amount</option>
                      <option value="Deduction of Hours">Deduction of Hours</option>
                      <option value="Piece Work Deduction">Piece Work Deduction</option>
                    </select>
                  </div>

                  <!-- <div class="form-group col-md-3">
                    <label for="inputTransactionCodeType">Type</label>
                    <input type="text" class="form-control" [(ngModel)]="transactionCode.type" id="inputTransactionCodeType" name="transactionCodeType" placeholder="xxx-xxxx-xxxx">
                  </div> -->
                  <!-- <div class="form-group col-md-3">
                    <label for="inputTransactionCodeCategory">Category</label>
                    <input type="text" class="form-control" [(ngModel)]="transactionCode.category" id="inputTransactionCodeCategory" name="transactionCodeCategory" placeholder="xxx-xxxx-xxxx">
                  </div> -->
                  <div class="form-group col-md-4">
                    <label for="inputTransactionCodeTaxable">Taxable</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="transactionCode.isTaxable"  id="transactionIsTaxable" name="transaction.IsTaxable">
                  </div>
                  <!-- <div class="form-group col-md-3">
                    <label for="inputTransactionCodeStatutoryDeduction">StatutoryDeduction</label>
                    <input class="form-control" type="checkbox" [checked]="transactionCode.statutoryDeduction"  id="transactionIsStatutoryDeduction">
                  </div> -->
                  <!-- <div class="form-group col-md-3">
                    <label for="inputTransactionCodeRate">Rate</label>
                    <input type="number" class="form-control" [(ngModel)]="transactionCode.rate" id="inputTransactionCodeRate" name="transactionCodeRate">
                  </div> -->

                  <div class="form-group col-md-4">
                    <label for="inputTransactionCodeRateMultiplier">Rate Multiplier</label>
                    <input type="number" class="form-control" [(ngModel)]="transactionCode.rateMultiplier" id="inputTransactionCodeRateMultiplier" name="transactionCodeRateMultiplier">
                  </div>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label for="inputGlAccountTransaction">Account Gl</label>
                    <select class="form-control" aria-label="Default select example"  id="inputGlAccountTransaction" [(ngModel)]="transactionCode.glAccount" name="glAccount" >
                      <!-- <option *ngIf="setting == 'Edit'" selected [ngValue]="transactionCode.glAccount">{{transactionCode.glAccount}}</option> -->
                      <option *ngFor="let coa of chartsOfAccounts; let i = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                    </select>
                  </div>
                  <!-- <div class="form-group col-md-3">
                    <label for="inputTransactionCodeIsTaxable">Is Taxable</label>
                    <input class="form-control" type="checkbox" [checked]="transactionCode.isTaxable" [(ngModel)]="transactionCode.isTaxable" id="inputTransactionCodeIsTaxable">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputTransactionCodeStatutoryDeduction">Statutory Deduction</label>
                    <input type="number" class="form-control" [(ngModel)]="transactionCode.statutoryDeduction" id="inputTransactionCodeStatutoryDeduction" name="transactionCodeStatutoryDeduction" placeholder="xxx-xxxx-xxxx">
                  </div> -->
                </div>

                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>
              </form>
            </div>

          </div>

          <div class="tab-pane fade" id="deduction" role="tabpanel" aria-labelledby="deduction-tab">
            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of deductionCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.abbreviation}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'deduction')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('deduction')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Deduction</h4>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputDeductionCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="deductionCode.code" id="inputDeductionCodeCode" name="deductionCodeCode">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputDeductionCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="deductionCode.description" id="inputDeductionCodeDescription" name="deductionCodeDescription">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputDeductionCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="deductionCode.abbreviation" id="inputDeductionCodeAbbreviation" name="deductionCodeAbbreviation">
                  </div>
                </div>

                <div class="form-row">
                  <!-- <div class="form-group col-md-3">
                    <label for="inputDeductionCodePolicyNumber">Policy Number</label>
                    <input type="text" class="form-control" [(ngModel)]="deductionCode.policyNumber" id="inputDeductionCodePolicyNumber" name="deductionCodePolicyNumber">
                  </div> -->
                  <div class="form-group col-md-3">
                    <label for="inputDeductionCodeRateType">Rate Type</label>
                    <!-- <input type="text" class="form-control" [(ngModel)]="deductionCode.description" id="inputDeductionCodeDescription" name="deductionCodeDescription" placeholder="xxx-xxxx-xxxx"> -->
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="deductionCode.rateType" id="inputDeductionCodeRateType"  name="rateTypeSelect">
                      <option>Percent</option>
                      <option>Flat</option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputDeductionCodeTaxType">Tax Type</label>
                    <!-- <input type="text" class="form-control" [(ngModel)]="deductionCode.description" id="inputDeductionCodeDescription" name="deductionCodeDescription" placeholder="xxx-xxxx-xxxx"> -->
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="deductionCode.taxType" id="inputDeductionCodeTaxType"  name="taxTypeSelect">
                      <option>Before</option>
                      <option>After</option>
                    </select>
                  </div>

                  <!-- <div class="form-group col-md-3">
                    <label for="inputDeductionCodePension">Pension</label>
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="deductionCode.pensionType" id="inputDeductionCodePension"  name="pensionSelect">
                      <option>Before</option>
                      <option>After</option>
                    </select>
                  </div> -->

                  <!-- <div class="form-group col-md-3">
                    <label for="inputDeductionCodePensionType">Pension Type</label>
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="deductionCode.pensionType" id="inputDeductionCodePensionType"  name="rateTypeSelect">
                      <option>Before</option>
                      <option>After</option>
                    </select>
                  </div> -->

                </div>

                <div class="form-row">
                  <!-- <div class="form-group col-md-4">
                    <label for="inputDeductionCodeStatutoryDeduction">Statutory Deduction</label>
                    <input class="form-control" type="checkbox" [checked]="deductionCode.statutoryDeduction"  id="inputDeductionCodeStatutoryDeduction">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputDeductionCodeCustomFormula">Custom Formula</label>
                    <input class="form-control" type="checkbox" [checked]="deductionCode.customFormula" id="inputDeductionCodeCustomFormula">
                  </div> -->

                  <div class="form-group col-md-4">
                    <label for="inputDeductionCodeAccumulate">Accumulate</label>
                    <input class="form-control" type="checkbox" [checked]="deductionCode.accumulate" id="inputDeductionCodeAccumulate">
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputDeductionCodeSeparateCheque">Separate Cheque</label>
                    <input class="form-control" type="checkbox"  [checked]="deductionCode.separateCheque" id="inputDeductionCodeSeparateCheque">

                  </div>

                  <!-- <div class="form-group col-md-8">
                    <label for="inputDeductionCodePayableTo">Payable To</label>
                    <input type="text" class="form-control" [(ngModel)]="deductionCode.payableTo" id="inputDeductionCodePayableTo" name="deductionCodeDescription">
                  </div> -->

                </div>

                <!-- <div class="form-row">

                  <div class="form-group col-md-3">
                    <label for="inputDeductionCodeDefaultEmployeeAmount">Default Employee Amount</label>
                    <input type="number" step=".01" class="form-control" [(ngModel)]="deductionCode.defaultEmployeeAmount" id="inputDeductionCodeDefaultEmployeeAmount" name="deductionCodeDefaultEmployeeAmount">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputDeductionCodeDefaultCompanyAmount">Default Company Amount</label>
                    <input type="number" step=".01" class="form-control" [(ngModel)]="deductionCode.defaultCompanyAmount" id="inputDeductionCodeDefaultCompanyAmount" name="inputDeductionCodeDefaultCompanyAmount">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputDeductionCodePeriodDeductionDue">Period Deduction is Due</label>
                    <input type="text" class="form-control" [(ngModel)]="deductionCode.dueDate" id="inputDeductionCodePeriodDeductionDue" name="deductionCodePeriodDeductionDue">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputDeductionCodePeriodDeductionDue">Loan Term</label>
                    <input type="number" step=".01" class="form-control" [(ngModel)]="deductionCode.loanTerm" id="inputDeductionCodePeriodDeductionDue" name="deductionCodePeriodDeductionDue">
                  </div>

                </div> -->

                <div class="form-row">
                  <div class="form-group col-md-8">
                    <label for="inputDeductionCodeEmployeeGlAccount">G/L Account</label>
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="deductionCode.glAccount" id="inputDeductionCodeEmployeeGlAccount"  name="deductionCodeEmployeeGlAccount">
                      <!-- <option *ngIf="setting == 'Edit'" selected [ngValue]="deductionCode.glAccount">{{deductionCode.glAccount}}</option> -->
                      <option *ngFor="let coa of chartsOfAccounts; let f = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                    </select>
                  </div>
                </div>


                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>
              </form>
            </div>

          </div>

          <div class="tab-pane fade" id="loan" role="tabpanel" aria-labelledby="loan-tab">

            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <!-- <th class="tableHeadings">
                    <p class="headText">Payable To</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Term</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th> -->
                  <th class="tableHeadings">
                    <p class="headText">Gl Account</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of loanCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.abbreviation}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.glAccount}}</p></td>
                  <!-- <td class="tableCels"><p class="cellText">{{code.payableTo}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.amount}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.term}}</p></td> -->
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'loan')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('loan')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Loan</h4>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.code" id="inputLoanCodeCode" name="loanCodeCode">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeName">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.name" id="inputLoanCodeName" name="loanCodeName">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.description" id="inputLoanCodeDescription" name="loanCodeDescription">
                  </div>

                </div>

                <div class="form-row">

                  <!-- <div class="form-group col-md-3">
                    <label for="inputLoanCodePayable">Payable To</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.payableTo" id="inputLoanCodePayableTo" name="loanCodePayableTo">
                  </div> -->
                  <!-- <div class="form-group col-md-3">
                    <label for="inputLoanCodeLoanAmount">Loan Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.loanAmount" id="inputLoanCodeLoanAmount" name="loanCodeLoanAmount">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeLoanTerm">Loan Term</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.loanTerm" id="inputLoanCodeLoanTerm" name="loanCodeLoanTerm">
                  </div> -->
                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.abbreviation" id="inputLoanCodeAbbreviation" name="loanCodeAbbreviation">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputGlAccountLoan">Account Gl</label>
                    <select class="form-control" aria-label="Default select example"  id="inputGlAccountLoan" [(ngModel)]="loanCode.glAccount" name="glAccount" >
                      <!-- <option *ngIf="setting == 'Edit'" selected [ngValue]="loanCode.glAccount">{{loanCode.glAccount}}</option> -->
                      <option *ngFor="let coa of chartsOfAccounts; let i = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                    </select>
                  </div>
                </div>


                <!-- <div class="formHead">
                  <h4 class="formHeading">Banking</h4>
                </div>

                <div class="form-row" >

                  <div class="form-group col-md-3">
                    <label for="inputBankBank">Bank</label>
                    <select class="form-control" aria-label="Default select example" id="inputBankBank" name="bankBank" [(ngModel)]="loanCode.banking.bank" >
                      <option *ngFor="let bankCode of bankCodes; let x = index" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
                    </select>
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputBankType">Type</label>
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="loanCode.banking.type" id="inputBankType" name="bankType">
                      <option value="Savings" >savings</option>
                      <option value="Chequing">chequing</option>
                    </select>
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputMethod">Method</label>
                    <select class="form-control" aria-label="Default select example" [(ngModel)]="loanCode.banking.method" id="inputMethod" name="bankMethod">
                      <option value="Cash">Cash</option>
                      <option value="Direct Deposit">Direct Deposit</option>
                    </select>
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputAccountNumber">Account Number</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.banking.account" id="inputAccountNumber" name="bankAccountNumber" placeholder="xxx-xxxx-xxxx">
                  </div>
                </div> -->


                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>

              </form>
            </div>

          </div>

          <div class="tab-pane fade" id="pension" role="tabpanel" aria-labelledby="pension-tab">

            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Pension %</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Pension Amt</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <!-- <th class="tableHeadings">
                    <p class="headText">Payable To</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Term</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th> -->
                  <th class="tableHeadings">
                    <p class="headText">Employer %</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Employer Amt</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of pensionCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.employeePercent}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.employeeAmount}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.employerPercent}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.employerAmount}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'pension')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('pension')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Pension</h4>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-6">
                    <label for="inputPensionCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="pensionCode.code" id="inputPensionCodeCode" name="pensionCodeCode">
                  </div>

                  <!-- <div class="form-group col-md-3">
                    <label for="inputPensionCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="pensionCode.description" id="inputPensionCodeDescription" name="pensionCodeDescription">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="pensionCode.abbreviation" id="inputPensionCodeAbbreviation" name="pensionCodeAbbreviation">
                  </div> -->


                </div>

                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeEnable">Enable</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="pensionCode.isEnable" name="pensionCodeEnable">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodePensionPercent">Pension %</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.employeePercent" id="inputPensionCodePensionPercent" name="pensionCodePensionPercent">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodePensionAmount">Pension Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.employeeAmount" id="inputPensionCodePensionAmount" name="pensionCodePensionAmount">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeEmployerPercent">Employer %</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.employerPercent" id="inputPensionCodeEmployerPercent" name="pensionCodeEmployerPercent">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeEmployerAmount">Employer Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.employerAmount" id="inputPensionCodeEmployerAmount" name="pensionCodeEmployerAmount">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeEmployerAmount">Max Payable</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.maxPayable" id="inputPensionCodeEmployerAmount" name="pensionCodeEmployerAmount">
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputGlAccountPension">Account Gl</label>
                    <select class="form-control" aria-label="Default select example"  id="inputGlAccountPension" [(ngModel)]="pensionCode.glAccount" name="glAccount" >
                      <option *ngFor="let coa of chartsOfAccounts; let i = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                    </select>
                  </div>

                </div>

                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodePensionPercent">Pension %</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.pensionPercent" id="inputPensionCodePensionPercent" name="pensionCodePensionPercent">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodePensionAmount">Pension Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.pensionAmount" id="inputPensionCodePensionAmount" name="pensionCodePensionAmount">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeEmployerPercent">Employer %</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.employerPercent" id="inputPensionCodeEmployerPercent" name="pensionCodeEmployerPercent">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionCodeEmployerAmount">Employer Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.employerAmount" id="inputPensionCodeEmployerAmount" name="pensionCodeEmployerAmount">
                  </div>

                </div>

                <div class="form-row">

                  <!-- <div class="form-group col-md-3">
                    <label for="inputLoanCodePayable">Payable To</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.payableTo" id="inputLoanCodePayableTo" name="loanCodePayableTo">
                  </div> -->
                  <!-- <div class="form-group col-md-3">
                    <label for="inputLoanCodeLoanAmount">Loan Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.loanAmount" id="inputLoanCodeLoanAmount" name="loanCodeLoanAmount">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeLoanTerm">Loan Term</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.loanTerm" id="inputLoanCodeLoanTerm" name="loanCodeLoanTerm">
                  </div> -->


                  <!-- <div class="form-group col-md-3">
                    <label for="inputPensionEmployeeAmount">Employee Amount</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="pensionCode.defaultEmployeeAmount" id="inputPensionEmployeeAmount" name="PensionEmployeeAmount" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionEmployeePercent">Employee %</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="pensionCode.defaultEmployeePercent" id="inputPensionEmployeePercent" name="PensionEmployeePercent" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionEmployerAmount">Employer Amount</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="pensionCode.defaultEmployerAmount" id="inputPensionEmployerAmount" name="PensionEmployerAmount" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputPensionEmployerPercent">Employer %</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="pensionCode.defaultEmployerPercent" id="inputPensionEmployerPercent" name="PensionEmployerPercent" >
                  </div> -->

                  <!-- <div class="form-group col-md-8">
                    <label for="inputPensionCodeAccount">Account #</label>
                    <input type="number" class="form-control" [(ngModel)]="pensionCode.account" id="inputPensionCodeAccount" name="pensionCodeAccount">
                  </div> -->



                </div>


                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>

              </form>
            </div>

          </div>

          <div class="tab-pane fade" id="health" role="tabpanel" aria-labelledby="health-tab">

            <div class="codeList" *ngIf="setting == 'list'">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Enable</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Employee Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Employer Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Account # </p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of healthCodeSearch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.isEnable.toString()}}</p></td>
                  <td class="tableCels">
                    <p class="cellText">Single: {{code.employeeAmount.single}}</p>
                    <p class="cellText">Multiple: {{code.employeeAmount.multiple}}</p>
                    <p class="cellText">Family: {{code.employeeAmount.family}}</p>
                  </td>
                  <td class="tableCels">
                    <p class="cellText">Single: {{code.employerAmount.single}}</p>
                    <p class="cellText">Multiple: {{code.employerAmount.multiple}}</p>
                    <p class="cellText">Family: {{code.employerAmount.family}}</p>
                  </td>
                  <td class="tableCels"><p class="cellText">{{code.account}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code,'health')"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">
              <form (ngSubmit)="onSubmit('health')">

                <div class="formHead">
                  <h4 class="formHeading">{{setting}} Health</h4>
                </div>

                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label for="inputHealthCodeCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="healthCode.code" id="inputHealthCodeCode" name="healthCodeCode">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthCodeDescription">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="healthCode.description" id="inputHealthCodeDescription" name="healthCodeDescription">
                  </div>

                  <!-- <div class="form-group col-md-3">
                    <label for="inputPensionCodeAbbreviation">Abbreviation</label>
                    <input type="text" class="form-control" [(ngModel)]="pensionCode.abbreviation" id="inputPensionCodeAbbreviation" name="pensionCodeAbbreviation">
                  </div> -->

                  <div class="form-group col-md-3">
                    <label for="inputHealthCodeEnable">Enable</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="healthCode.isEnable" name="healthCodeEnable" id="healthCodeEnable">
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthCodeAccount">Account {{healthCode.account}}</label>
                    <input type="text" class="form-control" [(ngModel)]="healthCode.account" id="inputHealthCodeAccount" name="healthCodeAccount">
                  </div>
                </div>

                <div class="form-row">

                  <!-- <div class="form-group col-md-3">
                    <label for="inputLoanCodePayable">Payable To</label>
                    <input type="text" class="form-control" [(ngModel)]="loanCode.payableTo" id="inputLoanCodePayableTo" name="loanCodePayableTo">
                  </div> -->
                  <!-- <div class="form-group col-md-3">
                    <label for="inputLoanCodeLoanAmount">Loan Amount</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.loanAmount" id="inputLoanCodeLoanAmount" name="loanCodeLoanAmount">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputLoanCodeLoanTerm">Loan Term</label>
                    <input type="number" class="form-control" [(ngModel)]="loanCode.loanTerm" id="inputLoanCodeLoanTerm" name="loanCodeLoanTerm">
                  </div> -->


                  <!-- <div class="form-group col-md-3">
                    <label for="inputHealthPensionAmount">Pension Amount</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.defaultPensionAmount" id="inputHealthPensionAmount" name="HealthPensionAmount" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthPensionPercent">Pension %</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.defaultPensionPercent" id="inputHealthPensionPercent" name="HealthPensionPercent" >
                  </div> -->

                  <div class="form-group col-md-3">
                    <label for="inputHealthEmployeeAmount">Employee Amount (single)</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.employeeAmount.single" id="inputHealthEmployeeAmountSingle" name="HealthEmployeeAmountSingle" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthEmployeeAmount">Employee Amount (multiple)</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.employeeAmount.multiple" id="inputHealthEmployeeAmountMultiple" name="HealthEmployeeAmountMultiple" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthEmployeeAmount">Employee Amount (family)</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.employeeAmount.family" id="inputHealthEmployeeAmountFamily" name="HealthEmployeeAmountFamily" >
                  </div>


                  <div class="form-group col-md-3">
                    <label for="inputHealthEmployerAmount">Employer Amount (single)</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.employerAmount.single" id="inputHealthEmployerAmountSingle" name="HealthEmployerAmountSingle" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthEmployerAmount">Employer Amount (multiple)</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.employerAmount.multiple" id="inputHealthEmployerAmountMultiple" name="HealthEmployerAmountMultiple" >
                  </div>

                  <div class="form-group col-md-3">
                    <label for="inputHealthEmployerAmount">Employer Amount (family)</label>
                    <input type="number" step="0.01" class="form-control" [(ngModel)]="healthCode.employerAmount.family" id="inputHealthEmployerAmountFamily" name="HealthEmployerAmountFamily" >
                  </div>

                  <!-- <div class="form-group col-md-6">
                    <label for="inputGlAccountPension">Account Gl</label>
                    <select class="form-control" aria-label="Default select example"  id="inputGlAccountPension" [(ngModel)]="pensionCode.glAccount" name="glAccount" >
                      <option *ngFor="let coa of chartsOfAccounts; let i = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                    </select>
                  </div> -->

                </div>


                <button *ngIf="setting == 'Edit'" type="submit" class="btn btn-primary">Update</button>
                <button *ngIf="setting == 'Add'" type="submit" class="btn btn-primary">Create</button>

              </form>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>

</div>



<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)=" codeAction('edit')">
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Edit</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="codeAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>

<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
