import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Defaults } from 'src/app/Models/Defaults';
import { Location } from '@angular/common';
import { Company } from 'src/app/Models/Company';
import { Code } from 'src/app/Models/Code';

import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-payroll-history',
  templateUrl: './payroll-history.component.html',
  styleUrls: ['./payroll-history.component.css']
})
export class PayrollHistoryComponent implements OnInit {


  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;


  public id: string = '';
  public setting: string = 'List';
  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';

  company: Company = Defaults.CompanyDefaults();
  public companyLogoBase64: any;


  constructor(
    private api: ApiService,
    private location:Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {

    $('.actionsBox').toggle();

    this.id = this.activatedRoute.snapshot.paramMap.get('id');

  }


  getCompany () {
    this.api.getCompany(this.id).subscribe(companyData =>{

      this.company = companyData;
      this.api.getCompanyBaseImage(companyData.id,companyData.type).subscribe(companyLogo =>{
        this.companyLogoBase64 = companyLogo;
      });
      //
      // this.api.getEmployees(companyData.id).subscribe(
      //   next => {
      //     this.employees = next;
      //     // this.loading = false;
      //     // $("#errorModal").modal("hide");
      //
      //     // console.log(' emplyees',next);
      //   },
      //   error => {
      //     // console.log('api request error',error);
      //   }
      // )

    });
  }

}
