import { Injectable } from '@angular/core';
import {Observable} from "rxjs/index";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  // public route: Observable<any> = '';

  constructor() {

  }


}
