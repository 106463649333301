import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Admin } from 'src/app/Models/Admin';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Defaults } from 'src/app/Models/Defaults';
import { formatDate } from '@angular/common';

import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;
  faFileAlt = faFileAlt;
  faCalendarAlt = faCalendarAlt;

  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';
  public setting: string = 'List';
  public id: string = '';

  currentUser: any = {};
  public selectedUser: Admin = Defaults.UserDefault();
  public users: Array<Admin> = [];
  public userSrch: Array<Admin> = [];
  public searchField: any = 'username';
  public permissionOptions: Array<any> = [
    "create company","close payroll","remove user"
  ]
  public filterVal: any = "";
  public permissionsChosen: Array<string> = ['','',''];
  public permStates: Array<boolean> = [false,false,false];

  public showResetPassword: boolean = false;
  email: string = '';
  oldPassword: string = '';
  newPassword: string = '';

  passwordInput: any = undefined;
  togglePasswordButton: any = undefined;
  passwordInputNew: any = undefined;
  togglePasswordButtonNew: any = undefined;


  constructor(
    private api: ApiService,
    private location:Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {

    $('.actionsBox').toggle();
    // this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.currentUser = JSON.parse(localStorage.getItem("admin"))
    this.getUsers();

    window.addEventListener('click', (e) => this.handleClick(e.target));


  }

  togglePasswordCurrent() {

    if (this.passwordInput.type === 'password') {
      this.passwordInput.type = 'text';
      this.togglePasswordButton.textContent = 'Hide password';
      this.togglePasswordButton.setAttribute('aria-label',
        'Hide password.');
    } else {
      this.passwordInput.type = 'password';
      this.togglePasswordButton.textContent = 'Show password';
      this.togglePasswordButton.setAttribute('aria-label',
        'Show password as plain text. ' +
        'Warning: this will display your password on the screen.');
    }
  }
  togglePasswordNew() {

    if (this.passwordInputNew.type === 'password') {
      this.passwordInputNew.type = 'text';
      this.togglePasswordButtonNew.textContent = 'Hide password';
      this.togglePasswordButtonNew.setAttribute('aria-label',
        'Hide password.');
    } else {
      this.passwordInputNew.type = 'password';
      this.togglePasswordButtonNew.textContent = 'Show password';
      this.togglePasswordButtonNew.setAttribute('aria-label',
        'Show password as plain text. ' +
        'Warning: this will display your password on the screen.');
    }
  }
  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
        // $('.actionsBox2').hide();
        // $('.s01Action').hide();
      }

    }

  }

  getUsers() {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getAdmin(this.currentUser.id).subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");
        // console.log('getAdmin',next);

        this.users = next;
        this.userSrch = next;
        this.setting = 'List';

        this.filterVal = "";

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
          console.log('error',error.error.status);

        } else {
          this.modalMessage = "Error: getAdmin!"
        }
        $("#errorModal").modal("show");
      }
    )

  }
  searchUsers(val) {

    this.filterVal = val;
    if (this.searchField !== 'permissions') {
      $('.actionsBox').hide();
      // this.rows.splice(0, this.rows.length);
      let field = this.searchField;
      let temp = this.users.filter(function(d) {
        val = val.toLowerCase();
        return d[field].toLowerCase().indexOf(val) !== -1 || !val;
      });

      this.userSrch = [...temp];
    }
    else {
      $('.actionsBox').hide();

      let field = this.searchField;
      let temp = [];
      for (const usr of this.users) {
        for (const perm of usr.permissions) {
          if (perm.includes(val) === true) {
            temp.push(usr)
          }
        }
      }

      this.userSrch = [...temp];
    }

  }
  selectSearchField(args) {
    this.searchField = args;
    this.searchUsers(this.filterVal)
  }

  actionsBox(event,user) {

    this.selectedUser = user;
    for (let index = 0; index < this.selectedUser.permissions.length; index++) {

      let element = this.selectedUser.permissions[index];
      let stateIndex = this.permissionOptions.indexOf(element);
      this.permStates[stateIndex] = true;
      this.permissionsChosen[stateIndex] = this.permissionOptions[stateIndex];
    }


    $('.actionsBox').css({'top':event.pageY-130,'left':event.pageX-120})
    $('.actionsBox').toggle();

  }
  adminAction(args) {

    $('.actionsBox').toggle();

    if (args === 'Edit') {
      this.setting = args;

      for (const elem of this.permissionOptions) {
        let indx = this.permissionOptions.indexOf(elem);
        if (this.selectedUser.permissions.includes(elem)) {
          this.permStates[indx] = true;
        } else {
          this.permStates[indx] = false;
        }
      }
      // if (this.selectedUser.permissions) {
      //
      // }
    } else {
      this.setting = 'List';
    }

    if (args === 'Delete') {

      this.removeAdmin()
    }
    if (args === 'resetPassword') {

      this.showResetPassword = true;

      setTimeout(()=>{

        this.passwordInput = document.getElementById('current-password')
        this.togglePasswordButton = document.getElementById('toggle-password')
        this.passwordInputNew = document.getElementById('new-password')
        this.togglePasswordButtonNew = document.getElementById('toggle-passwordNew')

        document.getElementById('toggle-password').addEventListener('click', (e) => this.togglePasswordCurrent());
        document.getElementById('toggle-passwordNew').addEventListener('click', (e) => this.togglePasswordNew());

      },2000);

    }

  }

  cancelForm() {
    this.setting = 'List'
  }
  createUser() {
    this.setting = "Create"
  }
  onSubmit(args) {


    if (args === 'Create') {

      this.selectedUser.permissions = this.permissionsChosen;
      for (const perm of this.selectedUser.permissions) {
        let indx = this.selectedUser.permissions.indexOf(perm)
        if (perm === '' || !perm) {
          this.selectedUser.permissions.splice(indx,1)
        }
      }
      this.selectedUser.parentID = this.currentUser.id;
      this.selectedUser.password = btoa(this.selectedUser.password);

      // console.log('new admin args',JSON.stringify(this.selectedUser));

      this.api.createAdmin(JSON.stringify(this.selectedUser)).subscribe(
        next => {
          this.loading = false;
          // $("#errorModal").modal("hide");
          // console.log('new admin',next);

          // this.getUsers();
          // this.setting = 'list';
          this.modalMessage = "Admin Created"

          setTimeout(()=>{
            this.getUsers();
            this.setting = 'list';
            $("#errorModal").modal("hide");
          }, 3000);

        },
        error => {
          this.loading = false;
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: createAdmin!"
          }
          $("#errorModal").modal("show");
        }
      )

    }
    if (args === 'Edit') {

      this.selectedUser.permissions = this.permissionsChosen;
      for (const perm of this.selectedUser.permissions) {
        let indx = this.selectedUser.permissions.indexOf(perm)
        if (perm === '' || !perm) {
          this.selectedUser.permissions.splice(indx,1)
        }
      }

      this.api.updateAdmin(JSON.stringify(this.selectedUser)).subscribe(
        next => {
          this.loading = false;
          this.modalMessage = 'Admin Updated'
          // $("#errorModal").modal("hide");
          // console.log('updateAdmin',next);

          setTimeout(()=>{
            this.getUsers();
            this.setting = 'list';
            $("#errorModal").modal("hide");
          }, 3000);

        },
        error => {
          this.loading = false;
          console.log('api request error',error);
          this.modalMessage = "Error";
          $("#errorModal").modal("show");
        }
      )

    }

  }

  removeAdmin() {

    this.api.removeAdmin(this.selectedUser.id).subscribe(
      next => {
        this.loading = false;
        this.modalMessage = 'Admin Removed'
        // $("#errorModal").modal("hide");
        // console.log('remove admin',next);

        // this.getUsers();
        // this.setting = 'list';
        setTimeout(()=>{
          this.getUsers();
          this.setting = 'list';
          $("#errorModal").modal("hide");
        }, 3000);

      },
      error => {
        this.loading = false;
        console.log('api request error',error);
        this.modalMessage = "Error";
        $("#errorModal").modal("show");
      }
    )

  }
  choosePermission(args,indx) {

    if (this.permStates[indx] === true) {
      this.permissionsChosen[indx] = args;
    }
    else {
      this.permissionsChosen[indx] = '';
    }

  }

  cancelPasswordReset () {

    this.showResetPassword = false;
    this.oldPassword = '';
    this.newPassword = '';
    this.email = '';

    document.getElementById('toggle-password').removeEventListener('click', (e) => {});
    document.getElementById('toggle-passwordNew').removeEventListener('click', (e) => {});

  }
  resetPassword() {


    this.api.resetAdminPassword(encodeURIComponent(btoa(this.oldPassword)),encodeURIComponent(btoa(this.newPassword)),encodeURIComponent(this.email),this.currentUser.id).subscribe(
      next => {

        this.loading = false;
        this.modalMessage = "Password Reset";

        setTimeout(()=>{
          $("#errorModal").modal("hide");
          this.modalMessage = '';

        },2000);

        this.showResetPassword = false;
        this.oldPassword = '';
        this.newPassword = '';
        this.email = '';

        this.getUsers();
        this.setting = 'list';

      },
      error => {
        this.loading = false;
        this.modalMessage = "Error: resetAdminPassword!";
        $("#errorModal").modal("show");
      }
    )

  }

}
