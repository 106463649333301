import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/Models/Company';
import { ChartOfAccounts } from 'src/app/Models/ChartOfAccounts';
import { Defaults } from 'src/app/Models/Defaults';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

declare const $: any;


import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-charts-of-accounts',
  templateUrl: './charts-of-accounts.component.html',
  styleUrls: ['./charts-of-accounts.component.css']
})
export class ChartsOfAccountsComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;

  public url: string;
  url2 : string;
  url3 : string;
  url4 : string;
  url5 : string;
  url6 : string;

  public id: string = '';


  public setting: string = 'List';
  public loading: Boolean = false;
  public modalMessage: string = '';

  chartOfAccounts: ChartOfAccounts = Defaults.ChartOfAccountsDefault();
  chartsOfAccounts: Array<ChartOfAccounts> = [];

  chartsOfAccountsExpenses: Array<ChartOfAccounts> = [];
  chartsOfAccountsLiabilities: Array<ChartOfAccounts> = [];
  chartsOfAccountsAssets: Array<ChartOfAccounts> = [];

  constructor(
    private api: ApiService,
    private location:Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {

      }

      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')[1];
        this.url2 = event.url.split('/')[2];
        this.url3 = event.url.split('/')[3];
        this.url4 = event.url.split('/')[4];
        this.url5 = event.url.split('/')[5];
        this.url6 = event.url.split('/')[6];

        this.id = this.url3;

      }

      if (event instanceof NavigationError) {

      }

    })
  }

  ngOnInit(): void {


    this.getChartsOfAccounts();

    window.addEventListener('click', (e) => this.handleClick(e.target));

  }

  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
        // $('.actionsBox2').hide();
        // $('.s01Action').hide();
      }

    }

  }

  getChartsOfAccounts () {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getChartOfAccounts(this.id).subscribe(next => {

      this.chartsOfAccounts = next.coas;

      for (const chart of next.coas) {
        switch(chart.type) {
          case 'credit' :
           this.chartsOfAccountsExpenses.push(chart)
          break;
          case 'debit' :
           this.chartsOfAccountsLiabilities.push(chart)
          break;
          // case 'assets' :
          //  this.chartsOfAccountsAssets.push(chart)
          // break;
        }
      }


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getChartOfAccounts!"
      }
      $("#errorModal").modal("show");
    })

  }

  onSubmit () {

    this.chartOfAccounts.companyID = this.id;
    this.loading = true;
    $("#errorModal").modal("show");
    this.api.createChartOfAccounts(this.chartOfAccounts).subscribe(next => {
      this.loading = false;
      $("#errorModal").modal("hide");
      this.getChartsOfAccounts();

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: createChartOfAccounts!"
      }
      $("#errorModal").modal("show");
    })

  }


  actionsBox (event,code,type) {

    $('.actionsBox').css({'top':event.pageY-50,'left':event.pageX+10})
    $('.actionsBox').toggle();
  }

  action (args) {

  }

  newChart () {
    this.setting = 'Add';
  }

  showChart () {
    this.setting = 'list';
  }

  toggleBox() {
    $('.actionsBox').hide();
    this.setting = 'list';
  }

}
