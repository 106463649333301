<div class="pageContainerSidebar">

  <div *ngIf="loaded" class="companyDataHeadBox">
    <h2 class="companyDataHeading">Company Data</h2>
  </div>

  <div *ngIf="loaded" class="companyDataContainer">


      <form (ngSubmit)="onSubmit()">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputCompanyCode">Company Code</label>
            <input type="text" class="form-control" [(ngModel)]="company.code" id="inputCompanyCode" name="code" placeholder="xxx-xxx">
          </div>
          <div class="form-group col-md-3">
            <label for="inputCompanyName">Company name</label>
            <input type="text" class="form-control" [(ngModel)]="company.companyName" id="inputCompanyName" name="companyName" placeholder="High tech">
          </div>
          <div class="form-group col-md-3">
            <label for="inputLegalName">Legal name</label>
            <input type="text" class="form-control" [(ngModel)]="company.legalName" id="inputLegalName" name="LegalName" placeholder="High tech">
          </div>
          <div class="form-group col-md-3">
            <label for="inputPhoneNumber">Phone number</label>
            <input type="text" class="form-control" [(ngModel)]="company.contact" id="inputPhoneNumber" name="phone" placeholder="xxx-xxxx-xxxx">
          </div>
        </div>

        <div class="form-row">

          <div class="form-group col-md-4">
            <label for="inputAddress">Address</label>
            <input type="text" class="form-control" [(ngModel)]="address.street" id="inputAddress" name="street" placeholder="...">
          </div>
          <div class="form-group col-md-4">
            <label for="inputCity">City</label>
            <input type="text" class="form-control" [(ngModel)]="address.city" id="inputCity" name="city" placeholder="...">
          </div>
          <div class="form-group col-md-4">
            <label for="inputParish">Parish</label>
            <select class="form-control" aria-label="Default select example" id="inputParish" [(ngModel)]="address.parish" name="parish">
              <option value="Hanover">Hanover</option>
              <option value="Saint Elizabeth">Saint Elizabeth</option>
              <option value="Saint James">Saint James</option>
              <option value="Trelawny">Trelawny</option>
              <option value="Westmoreland">Westmoreland</option>
              <option value="Clarendon">Clarendon</option>
              <option value="Manchester">Manchester</option>
              <option value="Saint Ann">Saint Ann</option>
              <option value="Saint Catherine">Saint Catherine</option>
              <option value="Saint Mary">Saint Mary</option>
              <option value="Kingston">Kingston</option>
              <option value="Portland">Portland</option>
              <option value="Saint Andrew">Saint Andrew</option>
              <option value="Saint Thomas">Saint Thomas</option>
            </select>
          </div>
        </div>


        <div class="form-row">
          <div class="col-md-6">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputNisRef">NIS Ref</label>
                <!-- allow up to 11 digits -->
                <input type="text" class="form-control" [(ngModel)]="company.nis" id="inputNisRef" name="NIS" >
              </div>
              <div class="form-group col-md-6">
                <label for="inputTaxRef">TAX Ref</label>
                <input type="text" class="form-control" [(ngModel)]="company.tax" id="inputTaxRef" name="tax" >
              </div>

            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail">Email</label>
                <input type="email" class="form-control" [(ngModel)]="company.email" id="inputEmail" name="email" placeholder="company@email.com">
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword">Password</label>
                <input type="password" class="form-control" [(ngModel)]="company.password" name="password" id="inputPassword" placeholder="...">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">

              <div class="form-group col-md-12 fileDropZone">
                <label for="companyLogoDragDrop">Company Logo (1280 pixels by 720 pixels preferred)</label>
                <ngx-file-drop id="companyLogoDragDrop"
                contentClassName="dragDropText" dropZoneClassName="dropZone" dropZoneLabel="Drop files here"
                (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
                style="height: 8rem;border-radius: 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color:#ffffff;">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                      <p class="dragDroptext">Drag & Drop OR
                        <input type="file" (change)="selectFile($event.target.files)" id="select">
                      </p>
                      <!-- <button type="button" (click)="openFileSelector()">Browse Files</button> -->
                    </ng-template>
                </ngx-file-drop>
                <div *ngIf="filePresent" class="clearFile">
                  <p class="droppedFileText">{{fileName}} </p><a class="droppedFileTextClear" (click)="clearFiles()"><fa-icon [icon]="faWindowClose" class="fa-sm sidebarIcon"></fa-icon></a>
                </div>

                <!-- <label for="inputCompanyLogo">Company Logo</label>
                <textarea class="form-control" [(ngModel)]="data.companylogo" id="exampleFormControlTextarea1" rows="5"></textarea> -->
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">

          <div class="form-group col-md-4">
            <label for="inputUsdRate">USD Rate</label>
            <input class="form-control" type="number"  [(ngModel)]="company.usdRate" name="company.usdRate"  id="usdRate">
          </div>
          <div class="form-group col-md-6">
            <label for="inputUsdEffectiveDate">USD Effective Date</label>
            <input class="form-control" type="date"  [(ngModel)]="company.usdEffectiveDate" name="company.usdEffectiveDate"  id="usdEffectiveDate">
          </div>

        </div>


        <!-- <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputCompanyPayrollStartDates">Payroll Start Dates</label>
            <input type="date" class="form-control" [(ngModel)]="company.payrollStartDates" id="inputCompanyPayrollStartDate" name="payrollStartDate" placeholder="company.payrollStartDate">
          </div>
          <div class="form-group col-md-3">
            <label for="inputCompanyPayDayOffset">Payday Offsets</label>
            <input type="number" class="form-control" [(ngModel)]="company.payDayOffsets" id="inputCompanyPayDayOffset" name="companyPayDayOffset">
          </div>
        </div> -->


        <div class="formHead">
          <h4 class="formHeading">Banking</h4>

            <button *ngIf="canAddNewBanking == true" type="button" class="btn createBtn" (click)="startAddNewBanking()">
              <p class="btnText ">Banking +</p>
            </button>
        </div>

        <div *ngIf="addNewBanking == true" class="form-row">

          <div class="form-group col-md-3">
            <label for="inputBankBank">Bank</label>
            <select class="form-control" aria-label="Default select example" id="inputBankBank" name="bankBank" [(ngModel)]="newBanking.bank" >
              <option *ngFor="let bankCode of bankingCodes" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
            </select>
          </div>

          <div class="form-group col-md-3">
            <label for="inputBankType">Type</label>
            <select class="form-control" aria-label="Default select example" [(ngModel)]="newBanking.type" id="inputBankType" name="bankType">
              <option value="Savings">Savings</option>
              <option value="Chequing">Chequing</option>
            </select>
          </div>

          <div class="form-group col-md-3">
            <label for="inputMethod">Method</label>
            <select class="form-control" aria-label="Default select example" [(ngModel)]="newBanking.method" id="inputBankMethod" name="bankMethod">
              <option value="directDeposit">Direct Deposit</option>
              <option value="cash">Cash</option>
              <option value="cheque">Cheque</option>
            </select>
          </div>

          <div class="form-group col-md-3">
            <label for="inputAccountNumber">Account Number</label>
            <input type="number" class="form-control" [(ngModel)]="newBanking.account" id="inputAccountNumber" name="bankAccountNumber">
          </div>

          <div class="form-group col-md-3">
            <label for="inputAchId">ACH Id</label>
            <input type="text" class="form-control" [(ngModel)]="newBanking.achId" id="inputAchId" name="bankAchId" >
          </div>

          <div class="form-group col-md-3">
            <label for="inputAchName">ACH Name</label>
            <input type="text" class="form-control" [(ngModel)]="newBanking.achName" id="inputAchName" name="bankAchName" >
          </div>

        </div>

        <div *ngIf="addNewBanking == true" class="form-row">

          <div class="form-group col-md-3">
            <button type="button" class="btn showBtn" (click)="cancelAddNewBanking()">
              <p class="btnText ">Cancel</p>
            </button>
          </div>

        </div>


        <div class="companyBanking" *ngFor="let bankingItem of company.banking; let v = index">
          <div  class="form-row">

              <div class="form-group col-md-4">
                <label for="{{'bankBank'+v}}">Bank</label>
                <select class="form-control" aria-label="Default select example"  name="{{'bankBank'+v}}" [(ngModel)]="company.banking[v].bank" >
                  <option *ngFor="let bankCode of bankingCodes; let x = index" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
                </select>
              </div>

            <div class="form-group col-md-4">
              <label for="{{'bankType'+v}}">Type</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="company.banking[v].type"  name="{{'bankType'+v}}">
                <option value="Savings">Savings</option>
                <option value="Chequing">Chequing</option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label for="{{'inputMethod'+v}}">Method</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="company.banking[v].method"  name="{{'inputMethod'+v}}">
                <option value="cash">Cash</option>
                <option value="directDeposit">Direct Deposit</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>


          </div>

          <div  class="form-row">
            <div class="form-group col-md-3">
              <label for="{{'bankAccountNumber'+v}}">Account Number</label>
              <input type="number" class="form-control" [(ngModel)]="company.banking[v].account"  name="{{'bankAccountNumber'+v}}" >
            </div>

            <div class="form-group col-md-3">
              <label for="{{'bankCurrency'+v}}">Currency</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="company.banking[v].currency"  name="{{'inputCurrency'+v}}">
                <option value="JMD">JMD</option>
                <option value="USD">USD</option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <label for="{{'bankAchId'+v}}">Ach Id</label>
              <input type="text" class="form-control" [(ngModel)]="company.banking[v].achId" id="inputAchId2" name="{{'bankAchId'+v}}" >
            </div>

            <div class="form-group col-md-3">
              <label for="{{'bankAchName'+v}}">Ach Name</label>
              <input type="text" class="form-control" [(ngModel)]="company.banking[v].achName" id="inputAchName2" name="{{'bankAchName'+v}}" >
            </div>


          </div>
        </div>


        <div class="formHead">
          <h4 class="formHeading">Fortnightly Setup</h4>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputCompanyPayrollStartDates">Payroll Start Dates</label>
            <input type="date" class="form-control" [(ngModel)]="company.payrollStartDates" id="inputCompanyPayrollStartDate" name="payrollStartDate" placeholder="company.payrollStartDate">
          </div>
          <div class="form-group col-md-3">
            <label for="inputCompanyPayDayOffset">Payday Offsets</label>
            <input type="number" class="form-control" [(ngModel)]="company.payDayOffsets" id="inputCompanyPayDayOffset" name="companyPayDayOffset">
          </div>
        </div>




        <div class="formHead">
          <h4 class="formHeading">Monthly Setup</h4>
        </div>

        <div class="form-row" *ngIf="company.monthly">
          <div class="form-group col-md-6">
            <label for="inputPayday">Payday</label>
            <input type="date" class="form-control" [(ngModel)]="company.monthly.payDay" id="inputPayday" name="payday" >
          </div>
        </div>

        <hr>

        <div class="formHead">
          <h4 class="formHeading">Bi-Monthly Setup</h4>
        </div>

        <div class="form-row" *ngIf="company.biMonthly">
          <div class="form-group col-md-6">
            <label for="inputPayday1">1st Payday</label>
            <input type="date" class="form-control" [(ngModel)]="company.biMonthly.firstPayDay" id="inputPayday1" name="payday1" >
          </div>

          <div class="form-group col-md-6" *ngIf="company.biMonthly">
            <label for="inputPayday2">2nd Payday</label>
            <input type="date" class="form-control" [(ngModel)]="company.biMonthly.secondPayDay" id="inputPayday2" name="payday2" >
          </div>
        </div>

        <!-- <div class="form-row">
          <div class="form-group col-md-4">
            <label for="inputBank">Bank</label>
            <input type="text" class="form-control" [(ngModel)]="ach.bank" name="bank" id="inputBank">
          </div>
          <div class="form-group col-md-4">
            <label for="inputAchCompanyDetails">ACH Name</label>
            <input type="text" class="form-control" [(ngModel)]="ach.achName" name="achName" id="inputAchCompanyDetails">
          </div>
          <div class="form-group col-md-4">
            <label for="inputAchId">ACH Id</label>
            <input type="text" class="form-control" [(ngModel)]="ach.achId" name="achId" id="inputAchId">
          </div>
        </div> -->

        <!-- <div class="form-row">
          <div class="form-group col-md-4">
            <label for="inputAbb">ABA</label>
            <input type="text" class="form-control" [(ngModel)]="ach.abb" name="abb" id="inputAbb">
          </div>
          <div class="form-group col-md-4">
            <label for="inputAccount">Account</label>
            <input type="text" class="form-control" [(ngModel)]="ach.account" name="account" id="inputAccount">
          </div>
        </div> -->

        <button type="submit" class="btn btn-primary">Done</button>
      </form>

  </div>

</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="imgPreview == true">
          <img  class="previewImg" src="#" alt="...">
        </div>

        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true || imgPreview == true" >{{modalMessage}}</h5>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
