

<div class="mainWrapper" role="main">

  <router-outlet>
    <app-user-control></app-user-control>
    <app-sidebar></app-sidebar>
  </router-outlet>

</div>
