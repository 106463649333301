<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Charts of Accounts</h3>
      </div>

      <div class="row input">
        <div class=" btnCol">
          <button (click)="newChart()" type="button" class="btn createBtn"><fa-icon [icon]="faPlus" class=" btnIcon"></fa-icon><p class="btnText ">Add</p></button>
        </div>
        <div class=" btnCol">
          <button (click)="showChart()" type="button" class="btn showBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Show</p></button>
        </div>
      </div>

    </div>

    <div class="subcontainerLower">

      <div class="row lowerTable">

        <div class="cont" *ngIf="setting == 'List'">

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button (click)="toggleBox()" class="nav-link active" id="expenses-tab" data-toggle="tab" href="#expenses" type="button" role="tab" aria-controls="expenses" aria-selected="true">Credit</button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="toggleBox()" class="nav-link" id="liabilities-tab" data-toggle="tab" href="#liabilities" type="button" role="tab" aria-controls="liabilities" aria-selected="false">Debit</button>
            </li>
            <!-- <li class="nav-item" role="presentation">
              <button class="nav-link" id="assets-tab" data-toggle="tab" href="#assets" type="button" role="tab" aria-controls="assets" aria-selected="false">Assets</button>
            </li> -->
          </ul>

          <div class="tab-content" id="myTabContent">

            <div class="tab-pane fade show active" id="expenses" role="tabpanel" aria-labelledby="expenses-tab">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Account</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Type</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let chart of chartsOfAccountsExpenses" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{chart.account}}</p></td>
                  <td class="tableCels"><p class="cellText">{{chart.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{chart.type}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,chart)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="tab-pane fade show" id="liabilities" role="tabpanel" aria-labelledby="liabilities-tab">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Account</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Type</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let chart2 of chartsOfAccountsLiabilities" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{chart2.account}}</p></td>
                  <td class="tableCels"><p class="cellText">{{chart2.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{chart2.type}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,chart)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div class="tab-pane fade show" id="assets" role="tabpanel" aria-labelledby="assets-tab">
              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Account</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Description</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Type</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let chart3 of chartsOfAccountsAssets" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{chart3.account}}</p></td>
                  <td class="tableCels"><p class="cellText">{{chart3.description}}</p></td>
                  <td class="tableCels"><p class="cellText">{{chart3.type}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,chart)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

          </div>

        </div>

        <div class="cont" *ngIf="setting == 'Add' || setting == 'Edit'">
          <form (ngSubmit)="onSubmit()">

            <div class="formHead">
              <h4 class="formHeading">Add</h4>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputChartOfAccountsAccount">Account</label>
                <input type="text" class="form-control" [(ngModel)]="chartOfAccounts.account" id="inputChartOfAccountsAccount" name="chartOfAccountsAccount" placeholder="xxx-xxxx-xxxx">
              </div>
              <div class="form-group col-md-4">
                <label for="inputChartOfAccountsDescription">Description</label>
                <input type="text" class="form-control" [(ngModel)]="chartOfAccounts.description" id="inputChartOfAccountsDescription" name="chartOfAccountsDescription" placeholder="xxx-xxxx-xxxx">
              </div>
              <div class="form-group col-md-4">
                <label for="inputChartOfAccountsType">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="chartOfAccounts.type" id="inputChartOfAccountsType" name="chartOfAccountsType">
                  <option>credit</option>
                  <option>debit</option>
                </select>
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Create</button>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>




<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">
        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>
      </div>
    </div>
  </div>
</div>
