import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Defaults } from 'src/app/Models/Defaults';
import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { CompanyPaySummary } from 'src/app/Models/CompanyPaySummary';
import { Code } from 'src/app/Models/Code';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';

import { Employee } from 'src/app/Models/Employee';
import { Payroll } from 'src/app/Models/Payroll';

import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from '@angular/common';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;
  faFileAlt = faFileAlt;
  faCalendarAlt = faCalendarAlt;

  public id: string = '';
  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';
  public currentUser: any = {};

  public setting: string = 'List';
  public listType: string = 'open';
  public payrollId: string = '';
  public openPayrolls: Array<Payroll> = [];
  public closedPayrolls: Array<Payroll> = [];
  public allPayrolls: Array<Payroll> = [];
  public selectedPayroll: Payroll = Defaults.PayrollDefault();

  public paySummary: Array<any> = [];
  public paySummarySrch: Array<any> = [];
  public paySummaryLength: number;
  public selectedPayItem: any = {};
  public s01PaySummary: Array<any> = [];
  public s01PaySummaryLength: number;
  public companyPaySummary: CompanyPaySummary = Defaults.CompanyPaySummaryDefault();

  company: Company = Defaults.CompanyDefaults();
  public companyLogoBase64: any;
  public payslipLogo: boolean = true;
  public addLogo: boolean = false;
  public uploadLogo: boolean = false;
  public filePresent: boolean = false;

  public reports: any = {
    ytd: [
      {
        name: 'Pension Listing',
        description: "Pension for all employees starting from the start of the financial year to today's date",
      },
      // {
      //   name: 'Payslip',
      //   description: "Payslip for one or all employees starting from the start of the financial year to today's date",
      // },
      {
        name: 'Vacation Leave',
        description: "Vacation leave information for user",
      },
      {
        name: 'Payroll Register',
        description: "Earnings and deduction summaries for all employees",
      },
      {
        name: 'Employee Register',
        description: "Earnings and deduction summaries for each",
      },
      {
        name: 'Lodgment Listing',
        description: "Monies being paid out by the client",
      },
      {
        name: 'General Ledger',
        description: "...",
      },
      {
        name: 'Deduction Listing',
        description: "Total deductions",
      },
      {
        name: 'Termination Report',
        description: "...",
      },

    ],
    current: [
      {
        name: 'Pension Listing',
        description: "Pension for all employees starting from the start of the financial year to today's date",
      },
      {
        name: 'Payslip',
        description: "Payslip for one or all employees starting from the start of the financial year to today's date",
      },
      {
        name: 'Vacation Leave',
        description: "Vacation leave information for user",
      },
      {
        name: 'Payroll Register',
        description: "Earnings and deduction summaries for all employees",
      },
      {
        name: 'Employee Register',
        description: "Earnings and deduction summaries for each",
      },
      {
        name: 'Lodgment Listing',
        description: "Monies being paid out by the client",
      },
      {
        name: 'General Ledger',
        description: "...",
      },
      {
        name: 'Deduction Listing',
        description: "Total deductions",
      },
      {
        name: 'Termination Report',
        description: "...",
      },
    ],
    previous: [],
  }

  employee: Employee = Defaults.EmployeeDefault();
  public employeeSrch: Array<Employee> = [];
  public payitemEmployee: Employee = Defaults.EmployeeDefault();
  public payslipEmployee: Employee = Defaults.EmployeeDefault();
  public employees: Array<Employee> = [];
  public employeeYtd: any = {};
  public selectedPayrollYtd: Array<any> = [];
  public selectedYtdData: any = {};

  public totalEmployeeCount: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];
  public toggleSearch: boolean = false;

  allCodes: Array<any> = [];
  departmentCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  healthCode: HealthCode = Defaults.HealthCodeDefault();
  occupationCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  transactionCode: TransactionCode = Defaults.TransactionCodeDefault();
  deductionCode: DeductionCode = Defaults.DeductionCodeDefault();
  loanCode: LoanCode = Defaults.LoanCodeDefault();
  bankCode: BankCode = Defaults.BankCodeDefault();
  pensionCode: PensionCode = Defaults.PensionCodeDefault();

  departmentCodes: Array<DepartmentOccupationCode> = [];
  healthCodes: Array<HealthCode> = [];
  occupationCodes: Array<DepartmentOccupationCode> = [];
  transactionCodes: Array<TransactionCode> = [];
  deductionCodes: Array<DeductionCode> = [];
  loanCodes: Array<LoanCode> = [];
  pensionCodes: Array<PensionCode> = [];
  bankCodes: Array<BankCode> = [];
  bankingCodes: Array<BankCode> = [];

  docGenType: string = "";
  docGenReport: any = {};
  docGenDocType: string = "";


  finalFilterData: any = {};

  selectedReportFilterFields: Array<string> = [];

  filterInputs: Array<any> = [];
  filterProperties1: Array<any> = [

  ];
  filterProperties2: Array<string> = [
    'First Name',
    'Last Name',
    // 'Employee ID',
    'Occupation Code',
    'Department Code',
  ];
  filterConditions : any = {
    string: [
      'Equal to',
      'Contains',
      'Not Equal to',
    ],
    number: [
      'Greater than',
      'Less than',
      'Equal to',
      'Greater than or Equal to',
      'Less than or Equal to',
    ],
  }

  payslipReport: string = "";
  payslipReportChooseFilter: boolean = false;
  ytdArray: Array<any> = [];

  filterPropPathRef: any = {
    First_Name: 'Employee/FirstName',
    Last_Name: 'Employee/LastName',
    Occupation_Code: 'Employee/Employment/Occupation/Code',
    Department_Code: 'Employee/Employment/Department/Code',
  }


  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
    $('.actionsBox').toggle();

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.currentUser = JSON.parse(localStorage.getItem("admin"))

    window.addEventListener('click', (e) => this.handleClick(e.target));


    this.getCompany()
    this.getBankingCodes();
    let codeTypes = ['department','occupation','transaction','deduction','loan','pension','health'];
    for (const type of codeTypes) {
      this.getCode(type)
    }
    this.getEmployeesByPage(true,'');
    this.getOpenPayrolls();

  }

  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBoxList','actionsBoxListItem','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
      }

    }

  }

  getEmployeesBySearch(query) {


    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getEmployeesBySearch(query,this.id).subscribe(next =>{

      console.log('getEmployeesBySearch',next);

      for (const elem of next) {
        this.employeeSrch.push(elem.data)
      }

      // this.loading = false;
      $("#errorModal").modal("hide");


    },
    error => {
      this.loading = false;
      this.modalMessage = "Error: getEmployeesBySearch!";
      $("#errorModal").modal("show");
    })

  }
  getEmployeesByPage(init,direction) {

    this.loading = true;
    $("#errorModal").modal("show");

    let start = 0;
    let count = 20;
    if (direction === 'next') {
      this.currentPage++
    }
    if (direction === 'previous') {
      this.currentPage--
    }

    let customCurrentPage = 1;
    if (direction !== 'next' && direction !== 'previous') {
      customCurrentPage = this.currentPage;
      this.currentPage = direction;
    }
    start = (this.currentPage-1)*20;
    if (init === true) {
      start = 0;
    }




    this.api.getEmployeesByPage(start,count,this.id).subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");


        if (init === true) {
          this.currentPage = 1;
          this.totalPages = Math.ceil(next.count/20);
          this.pagesArray = [];
          for (let index = 0; index < this.totalPages; index++) {
            this.pagesArray.push("")
          }
        } else {

          if (next.count !== this.totalEmployeeCount) {
            this.totalPages = Math.ceil(next.count/20);
            console.log('emp count has changed');
            this.pagesArray = [];
            for (let index = 0; index < this.totalPages; index++) {
              this.pagesArray.push("")
            }
          }

        }

        this.employeeSrch = next.data;
        this.employees = next.data;
        this.totalEmployeeCount = next.count;

        this.loading = false;

        $("#errorModal").modal("hide");


      },
      error => {

        if (direction === 'next') {
          this.currentPage--
        }
        if (direction === 'previous') {
          this.currentPage++
        }
        if (direction !== 'next' && direction !== 'previous') {
          this.currentPage = customCurrentPage;
        }

        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeesByPage!";
        }
        $("#errorModal").modal("show");
      }
    )

  }

  getCode(type) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCode(this.id,type).subscribe(codes => {;

      this[type+'Codes'] = codes;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCode "+type+""
      }

      $("#errorModal").modal("show");
    })

  }
  getBankingCodes() {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getBankingCodes().subscribe(next => {

      this.bankingCodes = next;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getBankingCodes!"
      }
      $("#errorModal").modal("show");
    })

  }

  getCompany () {
    this.api.getCompany(this.id).subscribe(companyData =>{

      this.company = companyData;
      this.api.getCompanyBaseImage(companyData.id,companyData.type).subscribe(companyLogo =>{
        this.companyLogoBase64 = companyLogo;
      });

      this.api.getEmployees(companyData.id).subscribe(
        next => {
          this.employees = next;
          // this.loading = false;
          // $("#errorModal").modal("hide");
        },
        error => {
          this.loading = false;
          this.error = true;
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: getBankingCodes!"
          }
          $("#errorModal").modal("show");
        }
      )

    });




  }
  getOpenPayrolls () {

    $('.actionsBox').hide();
    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");
    this.listType = 'Open';

    this.api.getOpenPayrolls(this.id).subscribe(next => {


      for (const proll of next) {

        var stateDateYear = new Date(proll.payCycle.startDate).getFullYear();
        var endDateYear = new Date(proll.payCycle.startDate).getFullYear();
        var payDateYear = new Date(proll.payCycle.startDate).getFullYear();

        proll.payCycle.startDate = proll.payCycle.startDate.substr(0,10)
        proll.payCycle.endDate = proll.payCycle.endDate.substr(0,10)
        proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substr(0,10)

      }
      this.openPayrolls = next;
      this.setting = "List"

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getOpenPayrolls!"
      }
      $("#errorModal").modal("show");
    })



  }
  getClosedPayrolls() {
    $('.actionsBox').hide();
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getClosedPayrolls(this.id).subscribe(next => {

      for (const proll of next) {
        proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
        proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
        proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)
      }
      this.closedPayrolls = next;

      this.loading = false;
      $("#errorModal").modal("hide");
      this.setting = 'List';
      this.listType = 'Closed';
    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getClosedPayrolls!"
      }
      $("#errorModal").modal("show");
    });

  }


  getCompanyPaySummary() {

      // this.loading = true;
      // this.error = false;
      // $("#errorModal").modal("show");

      this.api.getCompanyPaySummary(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

        this.companyPaySummary = next;
        this.companyPaySummary.payrollStartDate = this.companyPaySummary.payrollStartDate.substr(0,10);
        this.companyPaySummary.payrollEndDate = this.companyPaySummary.payrollEndDate.substr(0,10);

        if (next.statutories.length > 0) {
          this.companyPaySummary.statNisEmployee = next.statutories[0].employeeAmount;
          this.companyPaySummary.statNisEmployer = next.statutories[0].employerAmount;
          this.companyPaySummary.statNhtEmployee = next.statutories[1].employeeAmount;
          this.companyPaySummary.statNhtEmployer = next.statutories[1].employerAmount;
          this.companyPaySummary.statEduEmployee = next.statutories[2].employeeAmount;
          this.companyPaySummary.statEduEmployer = next.statutories[2].employerAmount;
          this.companyPaySummary.statHeartEmployee = next.statutories[3].employeeAmount;
          this.companyPaySummary.statHeartEmployer = next.statutories[3].employerAmount;
          this.companyPaySummary.statPayeEmployee = next.statutories[4].employeeAmount;
          this.companyPaySummary.statPayeEmployer = next.statutories[4].employerAmount;
        }

        // this.loading = false;
        // $("#errorModal").modal("hide");

      },
      error => {
        console.log('error',error);

        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getCompanyPaySummary!"
        }
        $("#errorModal").modal("show");
      })

    }

  async getS01PaySummary() {

    this.loading = true;
    // this.error = false;
    $("#errorModal").modal("show");

    try {
      let next = await this.api.getSO1ScheduleReport([{payrollId:this.selectedPayroll.id,type:this.selectedPayroll.type}]).toPromise();
    } catch (error) {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getS01PaySummary!"
      }
      $("#errorModal").modal("show");
    }

    // this.api.getSO1ScheduleReport([{payrollId:this.selectedPayroll.id,type:this.selectedPayroll.type}]).subscribe(next => {

    //   this.s01PaySummary = next;
    //   this.s01PaySummaryLength = next.length;

    //   // this.loading = false;
    //   // $("#errorModal").modal("hide");

    // },
    // error => {
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error: getS01PaySummary!"
    //   }
    //   $("#errorModal").modal("show");
    // })

  }
  async getPaySummary() {

    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");

    // console.log('getPaySummary args',this.selectedPayroll.id,this.selectedPayroll.type);

    try {
      let next = await this.api.getPaySummary(this.selectedPayroll.id,this.selectedPayroll.type).toPromise();

      this.paySummary = next;
      this.paySummarySrch = next;

      // this.loading = false;
      // $("#errorModal").modal("hide");

    } catch (error) {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getPaySummary!"
      }
    }


  }
  async getYtdData() {

    this.loading = true;
    // this.error = false;
    $("#errorModal").modal("show");
    console.log('selectedPayroll getYtdData',this.company);

    try {
      let next = await this.api.getPayrollYtd(this.company.id, this.selectedPayroll.type, this.selectedPayroll.payCycle.endDate).toPromise();
      this.selectedPayrollYtd = next;

      this.loading = false;
      $("#errorModal").modal("hide");
      this.setting = 'Report';

    } catch (error) {
      console.log('api request errorx',error);
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getYtdData!"
      }
      $("#errorModal").modal("show");
    }

    // this.api.getPayrollYtd(this.company.id, this.selectedPayroll.type, this.selectedPayroll.payCycle.endDate).subscribe(next => {

    //   if (next[0]) {
    //     this.selectedPayrollYtd = next;
    //     // console.log('getYtdData',next);

    //   }

    // },
    // error => {
    //   // this.loading = false;

    // })

  }

  toggleListType(type) {

    this.listType = type;
    this['get'+type+'Payrolls']();
  }
  toggleSetting(args) {
    this.setting = args;
  }
  async selectPayroll(payroll) {

    this.selectedPayroll = payroll;
    // this.setting = 'Report';

    await this.getPaySummary()
    await this.getS01PaySummary()
    await this.getYtdData()

  }

  reportHandler(type,report,docType,filter) {

    this.docGenType = type;
    this.docGenReport = report;
    this.docGenDocType = docType;

    if (filter === true) {

      this.filterInputs.push(
        {
          inputType: "string",
          data: {
            Property: "",
            Condition: "",
            Value: "",
            Type: "",
          }
        }
      )

      this.filterProperties1.push(this.filterProperties2)

      $("#reportFilterModal").modal("show");

    } else {

      this.docPreGen()

    }
  }

  docPreGen() {
    this.payslipReportChooseFilter = false;

    if (this.docGenType === 'current') {

      switch (this.docGenReport.name) {
        case 'Pension Listing':
          if (this.docGenDocType === 'pdf') {
            this.prePensionListingPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.prePensionListingXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Vacation Leave':

        break;
        case 'Payroll Register':
          if (this.docGenDocType === 'pdf') {
            this.prePayrollRegisterPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.prePayrollRegisterXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Employee Register':
          if (this.docGenDocType === 'pdf') {
            this.preEmployeeRegisterPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.preEmployeeRegisterXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Lodgment Listing':
          if (this.docGenDocType === 'pdf') {
            this.preLodgementListingPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.preLodgementListingXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'General Ledger':

        break;
        case 'Deduction Listing':
          if (this.docGenDocType === 'pdf') {
            this.preDeductionListingGenPdf(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.preDeductionListingGenXls(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Audit Report':

        break;
        case 'Payslip':
          this.preAllPayslipsGenPdf()
        break;
        case 'Termination Report':
          if (this.docGenDocType === 'pdf') {
            this.preTerminationReportPdf(this.docGenDocType)
          }
          if (this.docGenDocType === 'xls') {
            this.preTerminationReportXls(this.docGenDocType)
          }
        break;
        default:
        break;
      }

    }

    if (this.docGenType === 'ytd') {

    }

  }

  setReportFilter () {

    // this.reportFilterFinalData[index].field
    // this.reportFilterFinalData[index].value
    // console.log('this.filterInputs',this.filterInputs);
    let finalFilterData: any = this.filterInputs.map(x=>x.data)
    // console.log('finalFilterData',finalFilterData);

    for (const elem of finalFilterData) {

      let refProp = ""+elem.Property.split(" ")[0]+"_"+elem.Property.split(" ")[1]+"";
      // console.log('refProp',refProp);
      elem.Property = this.filterPropPathRef[refProp]
      // console.log('elem.Property');

    }

    finalFilterData = JSON.stringify(finalFilterData);
    this.finalFilterData = finalFilterData;

    console.log('finalFilterData',finalFilterData);

    this.payslipReportChooseFilter = false;

    if (this.docGenType === 'current') {

      switch (this.docGenReport.name) {
        case 'Pension Listing':
          if (this.docGenDocType === 'pdf') {
            this.prePensionListingPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.prePensionListingXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Vacation Leave':

        break;
        case 'Payroll Register':
          if (this.docGenDocType === 'pdf') {
            this.prePayrollRegisterPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.prePayrollRegisterXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Employee Register':
          if (this.docGenDocType === 'pdf') {
            this.preEmployeeRegisterPdfGenFilter(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.preEmployeeRegisterXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Lodgment Listing':
          if (this.docGenDocType === 'pdf') {
            this.preLodgementListingPdfGen(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.preLodgementListingXlsGen(this.docGenType,this.docGenDocType)
          }
        break;
        case 'General Ledger':

        break;
        case 'Deduction Listing':
          if (this.docGenDocType === 'pdf') {
            this.preDeductionListingGenPdf(this.docGenDocType);
          }
          if (this.docGenDocType === 'xls') {
            this.preDeductionListingGenXls(this.docGenType,this.docGenDocType)
          }
        break;
        case 'Termination Report':

        break;
        case 'Payslip':
         this.preGetAllPayslipsFilter()
        break;
        default:
        break;
      }

    }

    if (this.docGenType === 'ytd') {

    }

    this.filterInputs = [];
    this.filterProperties1 = [];
    $("#reportFilterModal").modal("hide");
  }
  closeReportFiltering() {
    $("#reportFilterModal").modal("hide");
    this.filterInputs = [];
    this.filterProperties1 = [];
  }


  prePayrollRegisterPdfGen (docType) {

    this.loading = true;
    $("#errorModal").modal("show");

    // console.log('this.selectedPayroll.payCycle',this.selectedPayroll.payCycle);
    this.selectedPayroll.payCycle.startDate = new Date(this.selectedPayroll.payCycle.startDate).toISOString()
    this.selectedPayroll.payCycle.endDate = new Date(this.selectedPayroll.payCycle.endDate).toISOString()
    this.selectedPayroll.payCycle.payDateOffset = new Date(this.selectedPayroll.payCycle.payDateOffset).toISOString()


    this.api.setPayrollRegister(JSON.stringify(this.selectedPayroll)).subscribe(next => {

      // console.log('setPayrollRegister',next);

      this.payrollRegisterPdf(next)


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: setPayrollRegister!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  prePayrollRegisterXlsGen (type,docType) {


    this.loading = true;
    $("#errorModal").modal("show");
    let current = false;
    let year = 0;
    if (type === 'current') {
      current = true;
      year = 0;

    }
    if (type === 'ytd') {
      year = 0;
    }



    this.api.getPayrollRegisterExcel(this.id,this.selectedPayroll.type,current,year).subscribe(next => {

      this.payrollRegisterXls(next)


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getPayrollRegisterExcel!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  payrollRegisterPdf (args) {
    $('.actionsBox').hide();
    // console.log('payrollRegisterGen',args);

    let company = this.company;

    let empNo = args.ytdEmployees.length;

    let mainTable = [];
    let summaryTotal = 0;
    let earningsTotal = 0;
    let statutoryEmployeeTotal = 0;
    let statutoryEmployerTotal = 0;
    let hrsTotal = 0;
    let nonStatTotal = 0;

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Summary',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ]
    )
    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Description',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Current Period',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Year-to-Date',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
      [
        {border: [false, false, false, false], text: 'GROSS PAY',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.gross.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.generalGross.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
      [
        {border: [false, false, false, false], text: 'TAXABLE GROSS PAY',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.taxable.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.gross.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],

      [
        {border: [false, false, false, false], text: 'TOTAL DEDUCTION',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.deductions.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.totalDeductions.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
      [
        {border: [false, false, false, false], text: 'TOTAL NETPAY',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.net.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.net.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
      [
        {border: [false, false, false, false], text: 'EMPLOYEE COUNT',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: ''+args.total.employeeCount+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
    )

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Earnings',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Unit/Hours',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Amount',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )
    for (const earning of args.earnings) {
      mainTable.push(
        [
          {border: [false, false, false, false], text: ''+earning.description+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: ''+earning.hours+'',fontSize: 8},
          {border: [false, false, false, false], text: '$ '+earning.currentAmount.toFixed(2)+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
        ]
      )

      earningsTotal += earning.currentAmount;
      hrsTotal += earning.hours;
    }

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'TOTAL',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '$ '+earningsTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Deduction Statutory',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'EMPLOYEE',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'EMPLOYER',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )

    for (const stat of args.statutory) {

      mainTable.push(
        [
          {text: ''+stat.tax+'',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '$ '+stat.employeeAmount.toFixed(2)+'',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '$ '+stat.employerAmount.toFixed(2)+'',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
        ]
      )

      statutoryEmployeeTotal += stat.employeeAmount;
      statutoryEmployerTotal += stat.employerAmount;

    }

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'TOTAL',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '$ '+statutoryEmployeeTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '$ '+statutoryEmployerTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Deduction Non-Statutory',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ]
    )
    for (const deduct of args.deductions) {
      // console.log('deduct',deduct);

      if (!deduct.description) {
        // console.log('no desc',deduct);
      }
      mainTable.push(
        [
          {border: [false, false, false, false], text: ''+deduct.description+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '$ '+deduct.amount.toFixed(2)+'',fontSize: 8},
          {border: [false, false, false, false], text: '$ '+deduct.employerAmount.toFixed(2)+'',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
        ]
      )

      nonStatTotal += deduct.amount;

    }

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'TOTAL',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '$ '+nonStatTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )


    let runDate = new Date();

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let user = this.currentUser;

    const def = {

      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'Run-Date: '+formatDate(runDate, 'longDate', 'en')+'', alignment: 'left', margin: 20, fontSize: 9},
          ],
        };
      },


      content:
      [

        // PAGE THREE
        {
          text: 'Payroll Summary',
          fontSize: 20,
          alignment: 'center',
          bold: true,
          margin:3
        },
        {
          text: '  ',
          margin:0,
        },
        {
          text: this.company.legalName,
          fontSize: 15,
          alignment: 'center',
          margin:3,
        },

        {
          text: 'Period Ending Date: '+ formatDate(this.selectedPayroll.payCycle.endDate, 'longDate', 'en')+' || Period Number: '+this.selectedPayroll.payCycle.period+' || Payroll Cycle: ' +this.selectedPayroll.type,
          fontSize: 10,
          bold: true,
          alignment: 'center',
          margin:3
        },

        {
    			style: 'tableExample',
          margin: [0,0, 0, -1],
    			table: {
    				headerRows: 1,
            widths: ['100%'],
    				body: [
              [
                {text: 'Employer NIS: '+this.company.nis+' -  Employer TRN: '+this.company.tax+'', fontSize: 9, alignment: 'center'},
              ],
    				]
    			},
    			layout: 'noBorders',
    		},
        {
          text: '  ',
          margin:0,
        },


        {
    			style: 'tableExample',
          margin: [0,0, 0, -1],
    			table: {
            widths: ['32%','16%','18%','18%','16%'],
    				headerRows: 1,
    				body: mainTable
    			},
          // layout: 'noBorders',
    		},
        {
          text: 'Employee count by Status : Total Active - '+args.total.active+', Inactive - '+args.total.inactive+', Total Employee Count - '+args.total.employeeCount+'',
          fontSize: 11,
          alignment: 'left',
          margin: [0, 15]
        },

      ],


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }
  payrollRegisterXls(args) {
    // console.log('payrollRegisterXls',args);


    $('.actionsBox').hide();
    this.loading = true;
    $("#errorModal").modal("show");


    let XL_row_object;

    var workbook = XLSX.utils.book_new();
    var ws_name = "Sheet1";
    let row1 = [];


    // let ws_data2 = [];
    // for (let index = 0; index < 21; index++) {
    //   ws_data2.push("")
    //   row1.push(undefined)
    // }

    var ws_data1 = [
      [
        "rowcount",
        "period",
        "payDate",
        "payAbbr",
        'hours',
        'payrate',
        'payAmount',
        'payNotational',
        'deducAbbr',
        'deductAmount',
        'deducEmployerAmount',
        'loanBalance',
        'multiSlips',
      ]
    ];
    var ws = XLSX.utils.aoa_to_sheet(ws_data1);
    var wscols = [];
    for (let index = 0; index < 13; index++) {
      wscols.push({wch:15})
    }
    ws['!cols'] = wscols;


    XLSX.utils.book_append_sheet(workbook, ws, ws_name);
    XLSX.utils.sheet_add_aoa(ws, [row1], {origin: -1});

    let count2 = 0;
    for (const item of args) {
      console.log('item',item);


      let row2 = [];

      for (let index = 0; index < 13; index++) {
        row2.push(undefined)
      }


      row2[0] = count2+1;

      for (const [key, value] of Object.entries(item)) {
        // console.log('payroll register key',key,'value',value,'count',count2,'match indx',ws_data1[0].indexOf(key));

        let val: any = value;

        if (
          key === 'payRate' ||
          key === 'payAmount' ||
          key === 'deductAmount' ||
          key === 'deducEmployerAmount' ||
          key === 'loanBalance'
        ) {
          val = val.toFixed(2);
        }
        if (key === 'payDate') {
          val = val.substr(0,10);
        }
        if (
          key === 'payNotational' ||
          key === 'multiSlips'
        ) {
            val = val.toString();
        }
        if (val === null || val === undefined || val === "") {
          val = "..."
        }

        row2[ws_data1[0].indexOf(key)] = val;
        // count2++;
      }
      count2++;


      XLSX.utils.sheet_add_aoa(ws, [row2], {origin: -1});
    }


    function ec(r, c){
      return XLSX.utils.encode_cell({r:r,c:c});
    }
    function delete_row(wsh, row_index){
      var variable = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < variable.e.r; ++R){
        for(var C = variable.s.c; C <= variable.e.c; ++C){
          wsh[ec(R,C)] = wsh[ec(R+1,C)];
        }
      }
      variable.e.r--
      wsh['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
    }
    delete_row(ws, 1)


    XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    XLSX.writeFile(workbook, 'payrollRegister.xlsx');

    this.loading = false;
    $("#errorModal").modal("hide");


  }

  preEmployeeRegisterPdfGen (docType) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.setEmployeeRegister(this.selectedPayroll.id).subscribe(next => {

      this.employeeRegisterPdf(next);

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      console.log('error',error);

      this.loading = false;
      $("#errorModal").modal("hide");
    })

  }
  preEmployeeRegisterXlsGen (type,docType) {

    this.loading = true;
    $("#errorModal").modal("show");
    let current = false;
    let year = 0;
    if (type === 'current') {
      current = true;
      year = 0;

    }
    if (type === 'ytd') {
      year = 0;
    }

    this.api.getEmployeeRegisterExcel(this.selectedPayroll.id).subscribe(next => {

      this.employeeRegisterXls(next)


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getEmployeeRegisterExcel!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  employeeRegisterPdf (args) {
      $('.actionsBox').hide();

      // console.log('employeeRegisterGen',args);

      let company = this.company;
      let payroll = this.selectedPayroll;
      let payItem = this.selectedPayItem;

      let body: Array<any> = [
        {
          text: this.company.legalName,
          fontSize: 20,
          alignment: 'center',
          bold: true,
          margin:3,
          decoration: 'underline',
        },
        {
          text: 'Employee Register',
          fontSize: 15,
          alignment: 'center',
          margin:3,
          decoration: 'underline',
        },

        {
          text: '',
          fontSize: 10,
          alignment: 'center',
          margin:3
        },

        {
          style: 'tableExample1',
          margin: [0,0, 0, -2],
          table: {
            headerRows: 1,
            widths: ['25%','25%','25%','25%'],
            body: [
              [
                {text: 'Period Number: '+this.selectedPayroll.payCycle.period+'', fontSize: 9, alignment: 'center'},
                {text: 'Period Ending Date: '+this.selectedPayroll.payCycle.endDate+'', fontSize: 9, alignment: 'center'},
                {text: 'Period/Payroll Cycle:'+this.selectedPayroll.type+'', fontSize: 9, alignment: 'center'},
                {text: 'Run Date :'+new Date().toISOString().slice(0,10)+"", fontSize: 9, alignment: 'center'},
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          text: '',
          fontSize: 9,
          alignment: 'center',
          margin:3
        },

      ];

      for (const emp of args) {
        let indx = args.indexOf(emp);
        let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeID === emp.employeeId).employee;

        body.push(
          // {
          //   style: 'tableExample',
          //   margin: [0,3,0,1],
          //   table: {
          //     headerRows: 1,
          //     widths: ['100%'],
          //     body: [
          //       [
          //         {text: 'Department: '+employee.employment.department.code+' - '+employee.employment.department.abbreviation+'', fontSize: 10, alignment: 'left'},
          //       ],
          //     ]
          //   },
          //   layout: 'noBorders',
          // },

          {
            style: 'tableExample',
            margin: [0,1],
            table: {
              headerRows: 1,
              widths: ['20%','20%','20%','20%','20%'],
              body: [
                [
                  {border: [true, true , true, true], text: 'Department', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: 'EMPLOYEE # ', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: 'JOB TITLE: ', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: 'TRN: ', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: 'NIS: ', fontSize: 9, alignment: 'left'},
                ],
                [
                  {border: [true, true , true, true], text: ''+employee.employment.department.code+' _ '+employee.employment.department.abbreviation+'', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: ''+emp.employeeID+' - '+emp.employee+'', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: ''+employee.employment.department.description+'', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: ''+employee.trn+'', fontSize: 9, alignment: 'left'},
                  {border: [true, true , true, true], text: ''+employee.nis+'', fontSize: 9, alignment: 'left'},
                ],
              ]
            },
            layout: 'noBorders',
          },
        )

        let summaryData = [
          [
            {text: 'Summary', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {text: '', fontSize: 9, alignment: 'left'},
            {text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          ]
        ];

        let earningsData = [
          [
            {border: [true, true, false, false], text: 'Description', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, true, false, false], text: 'Hours', fontSize: 9, alignment: 'left'},
            {border: [false, true, false, false], text: 'Current', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, true, false, false], text: 'Previous', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, true, true, false], text: 'YTD', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          ]
        ];
        let deductionData = [];
        let deductionData2 = [];

        let totalHours = 0;
        let totalCurrent = 0;
        let totalPrevious = 0;
        let totalYtd = 0;
        for (const earning of emp.earnings) {
          earningsData.push(
            [
              {border: [true, false, false, false], text: ''+earning.description+'', fontSize: 9, alignment: 'left'},
              {border: [false, false, false, false], text: ''+earning.hours+'', fontSize: 9, alignment: 'left'},
              {border: [false, false, false, false], text: '$ '+earning.currentAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
              {border: [false, false, false, false], text: '$ '+earning.previousAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
              {border: [false, false, true, false], text: '$ '+earning.ytd.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            ]
          )
          totalHours += earning.hours;
          totalCurrent += earning.currentAmount;
          totalPrevious += earning.previousAmount;
          totalYtd += earning.ytd;
        }
        earningsData.push(
          [
            {border: [true, false, false, false], text: 'Totals', fontSize: 10, alignment: 'left'},
            {border: [false, false, false, false], text: ''+totalHours.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, false, false], text: '$ '+totalCurrent.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, false, false], text: '$ '+totalPrevious.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '$ '+totalYtd.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          ]
        )

        deductionData.push(
          [
            {border: [true, true, false, true], text: 'Statutory', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, true, true, true], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, true, true, true], text: 'Employee Amt', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [true, true, true, true], text: 'Employer Amt', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          ],
        )

        let totalEmployee = 0;
        let totalEmployer = 0;
        for (const stat of emp.statutory) {
          deductionData.push(
            [
              {border: [true, false, false, false], text: ''+stat.tax+'', fontSize: 9, alignment: 'left'},
              {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
              {border: [true, false, true, false], text: '$ '+stat.employeeAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
              {border: [true, false, true, false], text: '$ '+stat.employerAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            ]
          )
          totalEmployee += stat.employeeAmount;
          totalEmployer += stat.employerAmount;
        }

        deductionData2.push(
          [
            {border: [true, false, false, false], text: 'Non-Statutory', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [true, false, true, false], text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          ],
        )

        let totalEmployee2 = 0;
        let totalEmployer2 = 0;
        // console.log("EmpDeduction4040", emp.deductions);
        for (const nonStat of emp.deductions) {
          // console.log('nonStat',nonStat);

          deductionData2.push(
            [
              {border: [true, false, false, false], text: ''+nonStat.code+'', fontSize: 9, alignment: 'left'},
              {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
              {border: [true, false, true, false], text: '$ '+nonStat.amount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
              {border: [true, false, true, false], text: '$ '+nonStat.employerAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            ],
          )
          totalEmployee2 += nonStat.amount;
        }

        deductionData2.push(
          [
            {border: [true, false, false, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          ],
          [
            {border: [true, false, false, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          ],
          [
            {border: [true, false, false, false], text: 'Total Deduction', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '$ '+(totalEmployee+totalEmployee2).toFixed(2)+'', fontSize: 9, alignment: 'left',decoration: 'underline'},
            {border: [true, false, true, false], text: '$ '+(totalEmployer+totalEmployer2).toFixed(2)+'', fontSize: 9, alignment: 'left',decoration: 'underline'},
          ],
          [
            {border: [true, false, false, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          ],
          [
            {border: [true, false, false, true], text: 'NET PAY', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
            {border: [false, false, true, true], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, true], text: '$ '+emp.total.net.toFixed(2)+'', fontSize: 9, alignment: 'left',decoration: 'underline'},
            {border: [true, false, true, true], text: '', fontSize: 9, alignment: 'left'},
          ],
        );

        let summaryTable = {
          style: 'tableExample',
          margin: [0,1],
          // margin: [0,2, 0, -1],
          table: {
            headerRows: 1,

            widths: ['40%','14%','23%','23%'],
            body: summaryData
          },
          layout: 'noBorders',
        };

        let earningsTable = {
          style: 'tableExample',
          margin: [0,0,0,-1],
          table: {
            headerRows: 1,

            widths: ['28%','18%','18%','18%','18%'],
            body: earningsData
          },
          // layout: 'noBorders',
        };

        let deductionTable = {
          style: 'tableExample',
          // margin: [0,2, 0, -1],
          margin: [0,0,0,-1],
          table: {
            headerRows: 1,

            widths: ['40%','14%','23%','23%'],
            body: deductionData
          },
          // layout: 'noBorders',
        };

        let breakPg = '';
        if (indx%2 !== 0) {
          breakPg = 'after'
        } else {
          breakPg = '';
        }

        let deductionTable2 = {
          style: 'tableExample',
          // margin: [0,2, 0, -1],
          margin: [0,0,0,-1],
          table: {
            headerRows: 1,

            widths: ['40%','14%','23%','23%'],
            body: deductionData2,
          },
          // layout: 'noBorders',
          pageBreak: breakPg
        };

        body.push(summaryTable);
        body.push(earningsTable);
        body.push(deductionTable);
        body.push(deductionTable2);
        body.push(
          //{canvas: [ { type: 'line', x1: 10, y1: 10, x2: 500, y2: 10, lineWidth: 3, color: 'black', alignment:'center'} ]},
        );

      }

      let user = this.currentUser;

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const def = {
        content: body,

        footer: function (currentPage, pageCount, pageSize) {

          return {
            columns: [
              { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
              { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
            ],
          };
        },

        styles: {
          topHeader: {
            margin: [0, 0, 0, 10],
          },
          header: {
            fontSize: 8,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 10, 0, 10]
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black'
          },
          innerHeading: {
              fontSize: 8
          },
          innerHeading2: {
              fontSize: 8,
              bold: true
          },
          content: {
              fontSize: 10
          }
        },
      };

      pdfMake.createPdf(def).open();

    }
  employeeRegisterXls (args) {
    // console.log('employeeRegisterXls',args);


    $('.actionsBox').hide();
    this.loading = true;
    $("#errorModal").modal("show");


    let XL_row_object;

    var workbook = XLSX.utils.book_new();
    var ws_name = "Sheet1";
    let row1 = [];


    // let ws_data2 = [];
    // for (let index = 0; index < 21; index++) {
    //   ws_data2.push("")
    //   row1.push(undefined)
    // }

    var ws_data1 = [
      [
        'rowCount',
        'pageNumber',
        'firstname',
        'lastname',
        'employeeNo',
        'grade',
        'occupationCode',
        'occupationDescription',
        'departmentCode',
        'departmentDescription',
        'badgeNo',
        'taxRef',
        'nisNo',
        'dedAbbr',
        'payJob',
        'payNotional',
        'payAbbr',
        'ytdColAbbr',
        'ytdColAmount',
        'ytdColHours',
        'multiSlips',
        'payHours',
        'payRate',
        'payAmount',
        'dedAmount',
        'dedEmployerAmount',
        'loanBalance',
      ]
    ];
    var ws = XLSX.utils.aoa_to_sheet(ws_data1);
    var wscols = [];
    for (let index = 0; index < 27; index++) {
      wscols.push({wch:15})
    }
    ws['!cols'] = wscols;


    XLSX.utils.book_append_sheet(workbook, ws, ws_name);
    XLSX.utils.sheet_add_aoa(ws, [row1], {origin: -1});

    let count2 = 0;
    for (const item of args) {

      let row2 = [];

      for (let index = 0; index < 27; index++) {
        row2.push(undefined)
      }

      // row2[0] = count2+1;


      // let count2 = 0;
      for (const [key, value] of Object.entries(item)) {

        // console.log('Employee register key',key,'value',value,'count',count2,'match indx',ws_data1[0].indexOf(key));

        let val: any = value;

        if (
          key === 'ytdColAmount' ||
          key === 'payRate' ||
          key === 'payAmount' ||
          key === 'dedAmount' ||
          key === 'dedEmployerAmount' ||
          key === 'loanBalance'
        ) {
          val = val.toFixed(2);
        }
        // if (key === 'payDate') {
        //   val = val.substr(0,10);
        // }
        if (
          key === 'payNotional' ||
          key === 'multiSlips'
        ) {
            val = val.toString();
        }
        if (val === null || val === undefined || val === "") {
          val = "..."
        }

        row2[ws_data1[0].indexOf(key)] = val;

      }

      count2++;
      XLSX.utils.sheet_add_aoa(ws, [row2], {origin: -1});
    }


    function ec(r, c){
      return XLSX.utils.encode_cell({r:r,c:c});
    }
    function delete_row(wsh, row_index){
      var variable = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < variable.e.r; ++R){
        for(var C = variable.s.c; C <= variable.e.c; ++C){
          wsh[ec(R,C)] = wsh[ec(R+1,C)];
        }
      }
      variable.e.r--
      wsh['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
    }
    // delete_row(ws, 1)


    XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    XLSX.writeFile(workbook, 'employeeRegister.xlsx');

    this.loading = false;
    $("#errorModal").modal("hide");


  }

  preEmployeeRegisterPdfGenFilter (args) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.setEmployeeRegisterFilter(this.selectedPayroll.id,this.finalFilterData).subscribe(next => {

      console.log('setEmployeeRegisterFilter',next);

      // this.employeeRegisterPdf(next);

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      console.log('error',error);

      this.loading = false;
      $("#errorModal").modal("hide");
    })

  }

  preDeductionListingGenPdf (docType) {

      this.loading = true;
      $("#errorModal").modal("show");

      this.api.getDeductionListingReport(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

        this.deductionListingPdf(next);

        this.loading = false;
        $("#errorModal").modal("hide");

      },
      error => {
        this.error = true;
        this.modalMessage = 'Error: getDeductionListingReportPdf!'
        this.loading = false;
        $("#errorModal").modal("show");
      })

  }
  preDeductionListingGenXls (type,docType) {

      this.loading = true;
      $("#errorModal").modal("show");

      this.api.getDeductionListingReportExcel(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

        this.deductionListingXls(next);

        this.loading = false;
        $("#errorModal").modal("hide");

      },
      error => {
        this.error = true;
        this.modalMessage = 'Error: getDeductionListingReportXls!'
        this.loading = false;
        $("#errorModal").modal("show");
      })

  }
  deductionListingPdf(args) {
      $('.actionsBox').hide();


      // let employee: Employee = Defaults.EmployeeDefault();

      let company = this.company;
      let payroll = this.selectedPayroll;
      let payItem = this.selectedPayItem;

      let deductionBody: Array<any> = [
        {
          text: 'Deduction Listing',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          // margin: [0, 5]
        },
        {
          text: ''+company.companyName+'',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          margin: [0, 5]
        },

        {
          text: 'DEDUCTION LISTING FOR PERIOD '+formatDate(payroll.payCycle.startDate, 'longDate', 'en')+' TO '+formatDate(payroll.payCycle.endDate, 'longDate', 'en')+'',
          fontSize: 9,
          alignment: 'center',
        },

        {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

        {
          text: 'FILTER CRITERIA: ALL RECORDS',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 5]
        },
      ];

      for (const elem of args) {

        let thisCode: any = '';
        if (this.deductionCodes.length > 0) {
          for (const code of this.deductionCodes) {
            if (code.abbreviation === elem[0].code) {
              thisCode = code;
            }
          }
        }

        let deductionListing = [];
        let deductionHead = [];
        let totalDeduction = 0;
        let totalBalance = 0;
        let deductionCount = 0;
        let employeeCount = 0;
        let deductionRow1 = {
          style: 'tableExample',
          table: {
            widths: ['50%','10%','10%','10%','10%','10%'],
            body: deductionHead
          },
          // layout: 'noBorders'
        };


        deductionHead.push(
          [
            // {text: 'DEDUCTION ABBR: '+elem.code.substr(0,6)+'', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'DEDUCTION ABBR: '+elem[0].code+'', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
          ]
        )

        let desc = '';

        if (elem[0].description) {
          desc = elem[0].description;
        }
        deductionHead.push(
          [
            // {text: 'DEDUCTION Desc: '+elem.code.substr(6,elem.code.length)+'', fontSize: 9, border: [false, false, false, true], bold: true},
            {text: 'DEDUCTION Desc: '+desc+'', fontSize: 9, border: [false, false, false, true], bold: true},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
            {text: '', border: [false, false, false, false]},
          ],
        )

        let deductionRow2 = {
          style: 'tableExample',
          table: {
            widths: ['8%','8%','11%','26%','12%','15%','12%','20%'],
            body: deductionListing
          },
        };


        deductionListing.push(
          [
            {text: 'EMP. #',fontSize: 9, border: [false, false, false, true]},
            {text: 'DEPT CODE',fontSize: 9, border: [false, false, false, true]},
            {text: 'POLICY',fontSize: 9, border: [false, false, false, true]},
            {text: 'EMPLOYEE NAME',fontSize: 9, border: [false, false, false, true]},
            {text: 'EMPLOYEE AMT',fontSize: 9, border: [false, false, false, true]},
            {text: 'EMPLOYER AMT',fontSize: 9, border: [false, false, false, true]},
            {text: 'TOTAL AMT',fontSize: 9, border: [false, false, false, true]},
            {text: 'BALANCE',fontSize: 9, border: [false, false, false, true]},
          ]
        )

        let employeeTotal = 0;
        let employerTotal = 0;

        for (const elem2 of elem) {

          // employee = employee = this.employees.filter( emp => emp.employeeId === elem2.employeeId)[0];
          let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === elem2.deductions[0].employeeId).employee;

          let deductionPreTotal = elem2.deductions[0].employeeAmount + elem2.deductions[0].employerAmount;

          let row = [
            {text: ''+elem2.deductions[0].employeeId+'', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: ''+employee.employment.department.code+'', fontSize: 9, border: [false, false, false, false]},
            {text: ''+elem2.deductions[0].policy+'', fontSize: 9, border: [false, false, false, false]},
            {text: ''+elem2.deductions[0].employee+'', fontSize: 9, border: [false, false, false, false]},
            {text: '$ '+elem2.deductions[0].employeeAmount.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
            {text: '$ '+elem2.deductions[0].employerAmount.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
            {text: '$ '+deductionPreTotal.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
            {text: '$ '+elem2.deductions[0].balance.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
          ]

          employeeTotal += elem2.deductions[0].employeeAmount;
          employerTotal += elem2.deductions[0].employerAmount;
          totalBalance += elem2.deductions[0].balance;
          totalDeduction += deductionPreTotal;
          deductionCount ++;
          employeeCount ++;


          deductionListing.push(row)
        }

        let deductionRow3 = {
          style: 'tableExample',
          table: {
            widths: ['30%','10%','15%','15%','15%','15%'],
            margin: [0, -5],
            body: [
              [
                {text: '** TOTALS: ', fontSize: 9, bold: true, border: [false, false, false, true]},
                {text: '', fontSize: 9, border: [false, false, false, false]},
                {text: 'Employee Amt', fontSize: 9, border: [false, true, false, true]},
                {text: 'Employer Amt', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
                {text: 'Grand Total', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
                {text: 'Total Balance', fontSize: 9, border: [false, true, false, true]},
              ],
              [
                {text: ''+employeeCount+' EMPLOYEE(S) LISTED W/ '+deductionCount+' DEDUCTIONS(S)', fontSize: 9, bold: true, border: [false, false, false, true]},
                {text: '', fontSize: 9, border: [false, false, false, false]},
                {text: '$ '+employeeTotal.toFixed(2)+'', fontSize: 9, border: [false, true, false, true]},
                {text: '$ '+employerTotal.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
                {text: '$ '+totalDeduction.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
                {text: '$'+totalBalance.toFixed(2)+'', fontSize: 9, border: [false, true, false, true]},
              ]
            ]
          },
          // layout: 'noBorders'
          pageBreak: "after"
        };

        deductionBody.push(deductionRow1)
        deductionBody.push(deductionRow2)
        deductionBody.push(deductionRow3)

      }


      let user = this.currentUser;
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const def = {


        header: function(currentPage, pageCount, pageSize) {
          return {
            columns: [
              { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
              { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
            ],
          };
        },


        content: deductionBody,


        footer: function (currentPage, pageCount, pageSize) {

          return {
            columns: [
              { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
              { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
            ],
          };
        },

        styles: {
          topHeader: {
            margin: [0, 0, 0, 10],
          },
          header: {
            fontSize: 8,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 10, 0, 10]
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black'
          },
          innerHeading: {
              fontSize: 8
          },
          innerHeading2: {
              fontSize: 8,
              bold: true
          },
          content: {
              fontSize: 10
          }
        },
      };

      pdfMake.createPdf(def).open();


    }
  deductionListingXls (args) {
      // console.log('deductionListingXls',args);

      $('.actionsBox').hide();
      this.loading = true;
      $("#errorModal").modal("show");


      let XL_row_object;

      var workbook = XLSX.utils.book_new();
      var ws_name = "Sheet1";
      let row1 = [];


      // let ws_data2 = [];
      // for (let index = 0; index < 21; index++) {
      //   ws_data2.push("")
      //   row1.push(undefined)
      // }

      var ws_data1 = [
        [
          'rowCount',
          'policy',
          'firstname',
          'lastname',
          'employeeNo',
          'departmentCode',
          'initial',
          'grade',
          'payType',
          'occupationCode',
          'cpayDate',
          'startDate',
          'endDate',
          'deductionDescription',
          'deductionTxt',
          'code',
          'abbr',
          'statutoryDeduction',
          'deductionCount',
          'employeesCount',
          'employeeAmount',
          'employerAmount',
        ]
      ];
      var ws = XLSX.utils.aoa_to_sheet(ws_data1);
      var wscols = [];
      for (let index = 0; index < 22; index++) {
        wscols.push({wch:20})
      }
      ws['!cols'] = wscols;


      XLSX.utils.book_append_sheet(workbook, ws, ws_name);
      XLSX.utils.sheet_add_aoa(ws, [row1], {origin: -1});

      let count2 = 0;
      for (const item of args) {

        let row2 = [];
        for (let index = 0; index < 22; index++) {
          row2.push(undefined)
        }

        row2[0] = count2+1;

        // let count2 = 0;
        for (const [key, value] of Object.entries(item)) {
          console.log('deduction listing key',key,'value',value,'count',count2,'match indx',ws_data1[0].indexOf(key));

          let val: any = value;
          // if (
          //   typeof val === 'number' &&
          //   key !== 'period' &&
          //   key !== 'rowCount'
          // ) {
          //   row2[count2] = val.toFixed(2);
          // }
          // if (key === 'payDate') {
          //   row2[count2] = val.substr(0,10);
          // }
          // else {
          //   row2[count2] = val;
          // }
          // row2[count2] = val;
          // count2++;

          if (
            key === 'employeeAmount' ||
            key === 'employerAmount'
          ) {
            val = val.toFixed(2);
          }
          if (
            key === 'cpayDate' ||
            key === 'startDate' ||
            key === 'endDate'
          ) {
            if (val !== null && val !== undefined) {
              val = val.substr(0,10);
            }

          }
          if (
            key === 'statutoryDeduction'
          ) {
              val = val.toString();
          }
          if (val === null || val === undefined || val === "") {
            val = "..."
          }

          row2[ws_data1[0].indexOf(key)] = val;

        }

        count2++;
        XLSX.utils.sheet_add_aoa(ws, [row2], {origin: -1});
      }


      function ec(r, c){
        return XLSX.utils.encode_cell({r:r,c:c});
      }
      function delete_row(wsh, row_index){
        var variable = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < variable.e.r; ++R){
          for(var C = variable.s.c; C <= variable.e.c; ++C){
            wsh[ec(R,C)] = wsh[ec(R+1,C)];
          }
        }
        variable.e.r--
        wsh['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
      }
      // delete_row(ws, 1)


      XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

      XLSX.writeFile(workbook, 'deductionListing.xlsx');

      this.loading = false;
      $("#errorModal").modal("hide");


}

  preLodgementListingPdfGen (docType) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getLodgementListingReport(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      // console.log('getLodgementListingReport',next);

      this.lodgementListingPdf(next);

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getLodgementListingReport!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  preLodgementListingXlsGen (type,docType) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getLodgementListingReportExcel(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

        this.lodgementListingXls(next);

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getLodgementListingReportExcel!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  lodgementListingPdf(args) {
      $('.actionsBox').hide();

      let employee: Employee = Defaults.EmployeeDefault();

      // if (this.employees.length > 0) {
      //   employee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
      // }

      let company = this.company;
      let payroll = this.selectedPayroll;
      let payItem = this.selectedPayItem;


      let totalAmount = 0;
      let totalUsdAmount = 0;
      let employeeCount = 0;

      let lodgementListing = [];
      let lodgementBody = [];

      let startDate = payroll.payCycle.startDate;
      let endDate = payroll.payCycle.endDate;
      let usdRateCol: boolean = false;
      for (const elem2 of args) {
        if (elem2.bank.currency === "USD") {
          usdRateCol = true;
        }
      }


      if (usdRateCol === true) {

        lodgementBody = [
          {
            text: 'Lodgement Listing',
            fontSize: 12,
            alignment: 'center',
            bold: true,
            // margin: [0, 5]
          },
          {
            text: ''+company.companyName+'',
            fontSize: 12,
            alignment: 'center',
            bold: true,
            margin: [0, 5]
          },

          {
            text: 'LODGEMENT LISTING FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
            fontSize: 9,
            alignment: 'center',
          },

          {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

          {
            text: 'FILTER CRITERIA: ALL RECORDS',
            fontSize: 9,
            alignment: 'center',
            margin: [0, 5]
          },


          {
            style: 'tableExample',
            table: {
              widths: ['7%','6%','20%','8%','22%','11%','8%','9%','9%'],
              body: lodgementListing
            },
          },


        ];


        lodgementListing.push(
          [
            {text: 'EMP. #',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'DEPT CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'PAYEE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'BANK CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'BANK NAME',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'ACCOUNT #',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'TYPE',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'AMOUNT',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'USD AMT',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          ]
        )

        for (const elem of args) {



          let bank = this.bankingCodes.filter(x=>x.id === elem.bank.bank)[0];
          let usdAmount = (elem.amount / company.usdRate);

          lodgementListing.push(
            [
              {text: ''+elem.employeeId+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+elem.department+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+elem.employee+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+bank.code+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+bank.name+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: ''+elem.bank.account+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: ''+elem.bank.type+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: '$ '+elem.amount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: '$ '+usdAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            ]
          )


          totalAmount += elem.amount;
          totalUsdAmount += usdAmount;
          employeeCount ++;

        }

        lodgementBody.push({
          style: 'tableExample',
          table: {
            widths: ['25%','50%','25%'],
            body: [
              [
                {text: '* * TOTAL EMPLOYEES: '+employeeCount+'', fontSize: 9, bold: true, border: [false, false, false, false]},
                {text: '* * GRAND TOTAL AMOUNT FOR ALL BANKS', fontSize: 9, bold: true, border: [false, false, false, false]},
                {text: '$ '+totalAmount.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0], alignment: 'right'},
              ]
            ]
          },
          // layout: 'noBorders'
        })


      }
      else {

        lodgementBody = [
          {
            text: 'Lodgement Listing',
            fontSize: 12,
            alignment: 'center',
            bold: true,
            // margin: [0, 5]
          },
          {
            text: ''+company.companyName+'',
            fontSize: 12,
            alignment: 'center',
            bold: true,
            margin: [0, 5]
          },

          {
            text: 'LODGEMENT LISTING FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
            fontSize: 9,
            alignment: 'center',
          },

          {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

          {
            text: 'FILTER CRITERIA: ALL RECORDS',
            fontSize: 9,
            alignment: 'center',
            margin: [0, 5]
          },


          {
            style: 'tableExample',
            table: {
              widths: ['7%','6%','20%','8%','28%','11%','8%','12%'],
              body: lodgementListing
            },
          },


        ];


        lodgementListing.push(
          [
            {text: 'EMP. #',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'DEPT CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'PAYEE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'BANK CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
            {text: 'BANK NAME',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'ACCOUNT #',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'TYPE',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
            {text: 'AMOUNT',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          ]
        )

        for (const elem of args) {



          let bank = this.bankingCodes.filter(x=>x.id === elem.bank.bank)[0];

          lodgementListing.push(
            [
              {text: ''+elem.employeeId+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+elem.department+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+elem.employee+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+bank.code+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
              {text: ''+bank.name+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: ''+elem.bank.account+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: ''+elem.bank.type+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
              {text: '$ '+elem.amount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            ]
          )


          totalAmount += elem.amount;
          employeeCount ++;

        }


        lodgementBody.push({
          style: 'tableExample',
          table: {
            widths: ['25%','50%','25%'],
            body: [
              [
                {text: '* * TOTAL EMPLOYEES: '+employeeCount+'', fontSize: 9, bold: true, border: [false, false, false, false]},
                {text: '* * GRAND TOTAL AMOUNT FOR ALL BANKS', fontSize: 9, bold: true, border: [false, false, false, false]},
                {text: 'USD $ '+totalUsdAmount.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0], alignment: 'right'},
              ]
            ]
          },
          // layout: 'noBorders'
        })


      }


      let user = this.currentUser;

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const def = {


        header: function(currentPage, pageCount, pageSize) {
          return {
            columns: [
              { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
              { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
            ],
          };
        },


        content: lodgementBody,

        pageMargins: [ 15, 15, 15, 15 ],

        footer: function (currentPage, pageCount, pageSize) {

          return {
            columns: [
              { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
              { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
            ],
          };
        },


        styles: {
          topHeader: {
            margin: [0, 0, 0, 10],
          },
          header: {
            fontSize: 8,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 10, 0, 10]
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black'
          },
          innerHeading: {
              fontSize: 8
          },
          innerHeading2: {
              fontSize: 8,
              bold: true
          },
          content: {
              fontSize: 10
          }
        },
      };

      pdfMake.createPdf(def).open();


    }
  lodgementListingXls (args) {
      // console.log('lodgementListingXls',args);

      $('.actionsBox').hide();
      this.loading = true;
      $("#errorModal").modal("show");


      let XL_row_object;

      var workbook = XLSX.utils.book_new();
      var ws_name = "Sheet1";
      let row1 = [];

      let usdRateCol: boolean = false;
      for (const elem2 of args) {
        if (elem2.usdRate > 0) {
          usdRateCol = true;
        }
      }


      // let ws_data2 = [];
      // for (let index = 0; index < 21; index++) {
      //   ws_data2.push("")
      //   row1.push(undefined)
      // }
      var ws
      var wscols
      var ws_data1


      if (usdRateCol === true) {

        ws_data1 = [
          [
            'rowCount',
            'firstname',
            'lastname',
            'employeeNo',
            'otherId',
            'ddInfo',
            'departmentCode',
            'payType',
            'bankCode',
            'bankName',
            'accountType',
            'accountCode',
            'accountNo',
            'address1',
            'address2',
            'routingNo',
            'transit',
            'abaNum',
            'employee',
            'symbol',
            'amount',
            'usdAmount',
            'otherAmount',
            'jaRate',
            'currencyCD',
          ]
        ];
        ws = XLSX.utils.aoa_to_sheet(ws_data1);
        wscols = [];
        for (let index = 0; index < 25; index++) {
          wscols.push({wch:20})
        }
        ws['!cols'] = wscols;


      }
      else {

        ws_data1 = [
          [
            'rowCount',
            'firstname',
            'lastname',
            'employeeNo',
            'otherId',
            'ddInfo',
            'departmentCode',
            'payType',
            'bankCode',
            'bankName',
            'accountType',
            'accountCode',
            'accountNo',
            'address1',
            'address2',
            'routingNo',
            'transit',
            'abaNum',
            'employee',
            'symbol',
            'amount',
            'otherAmount',
            'jaRate',
            'currencyCD',
          ]
        ];
        ws = XLSX.utils.aoa_to_sheet(ws_data1);
        wscols = [];
        for (let index = 0; index < 24; index++) {
          wscols.push({wch:20})
        }
        ws['!cols'] = wscols;


      }




      XLSX.utils.book_append_sheet(workbook, ws, ws_name);
      XLSX.utils.sheet_add_aoa(ws, [row1], {origin: -1});

      let count2 = 0;

      for (const item of args) {
        console.log('item',item);


        let row2 = [];
        for (let index = 0; index < 24; index++) {
          row2.push(undefined)
        }

        row2[0] = count2+1;

        // let count2 = 0;
        for (const [key, value] of Object.entries(item)) {
          // console.log('lodgement listing key',key,'value',value,'count',count2,'match indx',ws_data1[0].indexOf(key));

          let val: any = value;
          // if (
          //   typeof val === 'number' &&
          //   key !== 'period' &&
          //   key !== 'rowCount'
          // ) {
          //   row2[count2] = val.toFixed(2);
          // }
          // if (key === 'payDate') {
          //   row2[count2] = val.substr(0,10);
          // }
          // else {
          //   row2[count2] = val;
          // }
          // row2[count2] = val;
          // count2++;

          if (
            key === 'amount' ||
            key === 'otherAmount'
          ) {
            val = val.toFixed(2);
          }

          if (usdRateCol === true) {
            if (key === 'usdRate') {

              row2[ws_data1[0].indexOf("usdAmount")] = item.amount / val;

            }
          }


          // if (
          //   key === 'cpayDate' ||
          //   key === 'startDate' ||
          //   key === 'endDate'
          // ) {
          //   if (val !== null && val !== undefined) {
          //     val = val.substr(0,10);
          //   }
          //
          // }
          // if (
          //   key === 'statutoryDeduction'
          // ) {
          //     val = val.toString();
          // }
          if (val === null || val === undefined || val === "") {
            val = "..."
          }

          row2[ws_data1[0].indexOf(key)] = val;

        }

        count2++;
        XLSX.utils.sheet_add_aoa(ws, [row2], {origin: -1});
      }


      function ec(r, c){
        return XLSX.utils.encode_cell({r:r,c:c});
      }
      function delete_row(wsh, row_index){
        var variable = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < variable.e.r; ++R){
          for(var C = variable.s.c; C <= variable.e.c; ++C){
            wsh[ec(R,C)] = wsh[ec(R+1,C)];
          }
        }
        variable.e.r--
        wsh['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
      }
      // delete_row(ws, 1)


      XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

      XLSX.writeFile(workbook, 'lodgementListing.xlsx');

      this.loading = false;
      $("#errorModal").modal("hide");


  }

  prePensionListingPdfGen(docType) {

    this.loading = true;
    $("#errorModal").modal("show");


    this.api.getPensionListingPdf(this.id,this.selectedPayroll.type).subscribe(next => {

      this.pensionListingPdf(next)


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: setPayrollRegister!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  prePensionListingXlsGen(type,docType) {

  }
  pensionListingPdf(args) {

    console.log('pensionListingPdf',args);

    let company = this.company;

    let mainTable = [];
    let totals = [];
    let summaryTotal = 0;


    mainTable.push(
      [
        {border: [false, false, false, false], text: 'EMP#',fontSize: 10,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: 'EMPLOYEE NAME',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: 'ACCOUNT',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: '...',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: '...',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: '...',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: 'EMPLOYEE',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: 'EMPLOYER',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: '...',fontSize: 9,bold:true,decoration:'underline'},
        {border: [false, false, false, false], text: 'NIS',fontSize: 9,bold:true,decoration:'underline'},
      ],
    )

    for (const item of args.pensionInfos) {
      mainTable.push(
        [
          {border: [false, false, false, false], text: ''+item.employeeId+'',fontSize: 8},
          {border: [false, false, false, false], text: ''+item.employee+'',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: ''+item.bankAccount+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '$ '+item.pensionEmployee.toFixed(2)+'',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '$ '+item.pensionEmployer.toFixed(2)+'',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '$ '+item.nis.toFixed(2)+'',fontSize: 8,bold:true},
        ]
      )

    }

    totals.push(
      [
        {border: [false, false, false, false], text: 'TOTALS:',fontSize: 11,bold:true},
        {border: [false, false, false, false], text: ''+args.pensionTotal.totalRecords+' Employees',fontSize: 11,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.pensionTotal.pensionEmployee.toFixed(2)+'',fontSize: 11,bold:true},
        {border: [false, false, false, false], text: '$ '+args.pensionTotal.pensionEmployer.toFixed(2)+'',fontSize: 11,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.pensionTotal.nis.toFixed(2)+'',fontSize: 11,bold:true},
      ]
    )


    let runDate = new Date();
    let user = this.currentUser;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {

      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'Run-Date: '+formatDate(runDate, 'longDate', 'en')+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage+'', alignment: 'right', margin: 20, fontSize: 9},
          ],
        };
      },


      content:
      [

        // PAGE THREE
        {
          text: 'Pension Listing',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          // margin: [0, 5]
        },
        {
          text: ''+company.companyName+'',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          margin: [0, 5]
        },

        {
          text: 'PENSION LISTING FOR PERIOD '+formatDate(this.selectedPayroll.payCycle.startDate, 'longDate', 'en')+' TO '+formatDate(this.selectedPayroll.payCycle.endDate, 'longDate', 'en')+'',
          fontSize: 9,
          alignment: 'center',
        },

        {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

        {
          text: 'FILTER CRITERIA: ALL RECORDS',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 5]
        },


        {
    			style: 'tableExample',
          margin: [0,0, 0, -1],
    			table: {
            widths: ['10%','18%','15%','2%','2%','2%','15%','14%','2%','10%'],
    				headerRows: 1,
    				body: mainTable
    			},
          // layout: 'noBorders',
    		},
        {
    			style: 'tableExample',
          margin: [0,0, 0, -1],
    			table: {
            widths: ['10%','18%','15%','2%','2%','2%','15%','14%','2%','10%'],
    				headerRows: 1,
    				body: totals
    			},
          // layout: 'noBorders',
    		},
        // {
        //   text: 'Employee count by Status : Total Active - '+args.total.active+', Inactive - '+args.total.inactive+', Total Employee Count - '+args.total.employeeCount+'',
        //   fontSize: 11,
        //   alignment: 'left',
        //   margin: [0, 15]
        // },

      ],

      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }
  pensionListingXls(args) {

  }


  preTerminationReportPdf(args) {


    this.loading = true;
    $("#errorModal").modal("show");


    this.api.getTerminationReportPdf(this.selectedPayroll.id).subscribe(next => {

      console.log('getTerminationReportPdf',next);

      this.terminationReportPdf(next)


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getTerminationReportPdf!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  preTerminationReportXls(args) {

  }
  terminationReportPdf(args) {

    $('.actionsBox').hide();

    let employee: Employee = Defaults.EmployeeDefault();

    // if (this.employees.length > 0) {
    //   employee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
    // }

    let company = this.company;
    let payroll = this.selectedPayroll;

    let totalAmount = 0;
    let employeeCount = 0;

    let terminationListing = [];

    let startDate = payroll.payCycle.startDate;
    let endDate = payroll.payCycle.endDate;


    let terminationBody = [
      {
        text: 'Termination Listing',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        // margin: [0, 5]
      },
      {
        text: ''+company.companyName+'',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        margin: [0, 5]
      },

      {
        text: 'TERMINATION LISTING FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
        fontSize: 9,
        alignment: 'center',
      },

      {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

      {
        text: 'FILTER CRITERIA: ALL RECORDS',
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5]
      },


      {
        style: 'tableExample',
        table: {
          widths: ['20%','20%','20%','20%','20%'],
          body: terminationListing
        },
      },


    ];

    terminationListing.push(
      [
        {text: 'FIRST NAME',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'LAST NAME',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EMPLOYEE ID',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'DEPT CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'GROSS AMOUNT',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
      ]
    )


    for (const elem of args) {

      let dept = this.departmentCodes.find(x=>x.code === elem.departmentCode)

      terminationListing.push(
        [
          {text: ''+elem.firstName+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: ''+elem.lastName+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: ''+elem.employeeId+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: ''+elem.departmentCode+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.amount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
        ]
      )

      totalAmount += elem.amount;
      employeeCount ++;

    }

    terminationBody.push({
      style: 'tableExample',
      table: {
        widths: ['25%','50%','25%'],
        body: [
          [
            {text: '* * TOTAL EMPLOYEES: '+employeeCount+'', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '* * TOTAL AMOUNT', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+totalAmount.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0], alignment: 'right'},
          ]
        ]
      },
      // layout: 'noBorders'
    })

    let user = this.currentUser;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      content: terminationBody,


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();



  }


  getEmployeeYtd(empId,index) {


    // let employee;
    // if (this.employees.length > 0) {
    //   employee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
    // }

    let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === empId).employee;


    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");
    this.api.getEmployeeYtd(employee.employeeId,this.selectedPayroll.id, this.selectedPayroll.type).subscribe(next => {

      this.employeeYtd = next;

      // this.loading = false;
      // $("#errorModal").modal("hide");


      this.api.getEmployeesBySearch(employee.employeeId,this.id).subscribe(next => {

        console.log('getEmployeeBySearch',next[0].data);

        this.loading = false;
        $("#errorModal").modal("hide");



        this.payslipGen(index,next[0].data);

      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeesBySearch!"
        }
        $("#errorModal").modal("show");
      })


    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployeeYtd!"
      }
      $("#errorModal").modal("show");
    })

  }

  payslipGen(index,emp) {

    // let employee: Employee = Defaults.EmployeeDefault();
    let employee = emp;
    // console.log('employee',employee);
    // console.log('this.paySummary[index]',this.paySummary[index],'index',index);


    // if (this.employees.length > 0) {
    //   employee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
    // }


    let company = this.company;
    let payroll = this.selectedPayroll;
    let payItem = this.paySummary[index];



    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    var showRate: boolean = false;
    let args = {
      rate: payItem.pay[0].rate,
      payDate: payroll.payCycle.payDateOffset,
      netPay: payItem.net,
      pay: payItem.pay,
      statutory: payItem.statutory,
      // deductions: payItem.statutory,
      deductions: employee.deductions,
      yearToDate: this.employeeYtd,
    }

    console.log("payslipGen: payItem",payItem);
    console.log("payslipGen args",args);


    // this.companyLogoBase64.image = '';
    let logo;
    if (this.payslipLogo === false) {
      logo = {text:'',style: 'content'}
    } else {
      logo = {
        image: 'data:'+this.companyLogoBase64.image,
        width: 200,
        height: 80,
      }
    }

    let payslipPaySummary = this.paySummary.find(paySum => paySum.employeeId === employee.employeeId)


    let payDate: Date = new Date(args.payDate);

    let usdRate = '';
    if (employee.banking.currency === "USD") {
      usdRate = '*Net pay of JA$'+args.netPay+' shown above is paid as USD$'+ (args.netPay / company.usdRate).toFixed(2) +' per agreement using the Friday prior to payroll rate of USD$' + company.usdRate +'';
    }


    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let user = this.currentUser;

    args.payDate = args.payDate.substr(0,10);
    let earningsCol = [
      [
          {text:'Description',style: 'innerHeading2',},
          {text:'Hours',style: 'innerHeading2'},
          {text:'Rate',style: 'innerHeading2'},
          {text:'Amount',style: 'innerHeading2'}
      ],
    ]
    let deductionsCol = [
      [
         {text:'Description',style: 'innerHeading2'},
         {text:'Amount',style: 'innerHeading2'},
      ],
    ]
    let yearToDateCol = [
      [
         {text:'Description',style: 'innerHeading2'},
         {text:'Amount',style: 'innerHeading2'},
      ],
    ]

    let totalPay: number = 0;
    let totalDeduction: number = payslipPaySummary.totalDeductions;

    for(const value of args.pay) {
      if (!value.description) {
        console.log('no desc',value);

      }

      let payRow = [
          {text:''+value.description+'',style: 'content'},
          // {text:''+codeDesc.code.description+'',style: 'content'},
          {text:''+value.hours+'',style: 'content'},
          {text:'$ '+value.rate.toFixed(2)+'',style: 'content'},
          {text:'$ '+value.amount.toFixed(2)+'',style: 'content'},
      ]
      earningsCol.push(payRow);

      totalPay += value.amount;
    }

    for (const value2 of payItem.statutory) {
      if (value2.tax !== "HEART") {
        deductionsCol.push([
          {text:''+value2.tax+'',style: 'content'},
          {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
        ]);
      }

      //totalDeduction += value2.employeeAmount;
    }

    // for (const deduct of args.deductions) {

    //   deductionsCol.push([
    //       {text:''+deduct.code+'',style: 'content'},
    //       {text:'$ '+deduct.deductionAmount.toFixed(2)+'',style: 'content'},
    //   ]);

    //   //totalDeduction += deduct.deductionAmount;

    // }

    for(const [key, value] of Object.entries(payslipPaySummary.deductions)) {
    // for(const value of payslipPaySummary.deductions) {

      let val: any = value;
      // console.log('val 2',val,this.deductionCodes.find(code => code.code === val.code));


      if (val.code !== "HEART") {
        let deductionsRow2 = [
            {text:''+val.code.split('-')[0]+'',style: 'content'},
            {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
        ]
        deductionsCol.push(deductionsRow2);

        //totalDeduction += val.amount;
      }

    }

    if (payItem.pension !== 0) {
      deductionsCol.push([
          {text:'Employee Pension',style: 'content'},
          {text:'$ '+payItem.pension.toFixed(2)+'',style: 'content'},
      ]);
    }

    for(const [key, value] of Object.entries(payslipPaySummary.loans)) {

      let val: any = value;
      // console.log(val.code,this.loanCodes,'code match',this.loanCodes.find(code => code.code === val.code));


      let deductionsRow2 = [
          {text:''+val.description+'',style: 'content'},
          {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
      ]
      deductionsCol.push(deductionsRow2);

      //totalDeduction += val.amount;
    }

    for (const [key, value] of Object.entries(args.yearToDate)) {

      let val: any = value;

      let ytdRow;
      switch(key) {
        case 'pay':
        if (val === []) {
          ytdRow = [
            // {text:''+codeDesc.code.description+'',style: 'content'},
            {text:''+val[0].code+'',style: 'content'},
            // {text:''+val[0].code+'',style: 'content'},
            {text:'$ '+val[0].amount.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        }

        break;
        case 'taxCalc':

          // for (const [key, tax] of Object.entries(val)) {
          //   ytdRow = [
          //     {text:''+tax.tax+'',style: 'content'},
          //     {text:'$ '+tax.employeeAmount.toFixed(2)+'',style: 'content'},
          //   ]
          //   yearToDateCol.push(ytdRow);
          // }
        break;
        case 'generalGross':
          ytdRow = [
            {text:'Gross',style: 'content'},
            {text:'$ '+val.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        break;
        case 'gross':
          ytdRow = [
            {text:'TaxableGross',style: 'content'},
            {text:'$ '+val.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        break;
        case 'net':
          ytdRow = [
            {text:'Net',style: 'content'},
            {text:'$ '+val.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        break;
        case 'statutory':
          for (const value2 of val) {
            if (value2.tax !== "HEART") {
              ytdRow = [
                {text:''+value2.tax+'',style: 'content'},
                {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            }
          }
        break;
      }

    }

    let modDate = "...";

    modDate = formatDate(payroll.payCycle.endDate, 'longDate', 'en')

    let modDate2 = formatDate(payDate, 'longDate', 'en')

    const def = {
      content:
      [
        {
          text: 'PAY ADVICE FOR PERIOD '+payroll.payCycle.period+', ' +modDate+".",
          fontSize: 18,
          styles:'topHeader',
          alignment: 'center',
          bold: true,
        },

        {
          style: 'tableExample',
          table: {
          widths: [ '20%', '40%', '40%'],
            body: [
              [
                  {text:'NPJF',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName + '\('+payroll.type+'\)',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName,fontSize:13,decoration:'underline',bold:true},
              ],
              [
                  {text:'Pay Date',style: 'content'},
                  {text: modDate2 ,style: 'content'},
                  {text: company.address.street + ', ' + company.address.city + ', ' +company.address.parish ,style: 'content',bold:true,fontSize:13},
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          style: 'tableExample',
          table: {
          widths: [ '60%', '40%'],
            body: [
              [
                [
                  {
                    style: 'tableExample',
                    fillColor: '#e5e5e5',
                    margin: [0,20,0,0],
                   table: {
                     body: [
                      [
                       {
                         type: 'none',
                         margin: [50,0,60,0],
                         ul: [
                           {text:''+payItem.employeeId+' : '+payItem.employee+'',bold: true,fontSize:14,decoration:'underline'},
                           {text:''+employee.employment.occupation.code+' : '+employee.employment.occupation.description+'',fontSize:11,bold: true},
                           {text:'Department: '+employee.employment.department.description},
                          //  {text:'Salary Destination:  ... '},
                         ]
                       },
                      ],
                     ]
                   }
                 }
                ],
                [
                  logo
                ],
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          margin: [0,30,0,0],
          table: {
          widths: [ '18%', '20%', '30%', '32%'],
            body: [
              [
                  {border: [false, true, false, false],text:'TRN: '+company.tax+'',fontSize:11},
                  {border: [false, true, false, false],text:'NIS Ref#: '+company.nis+'',fontSize:11},
                  // {border: [false, true, false, false],text:'Vacation Bal: '+args.deductions.vacBal+' Hours',fontSize:11},
                  {border: [false, true, false, false],text:'Company Pension YTD: ',fontSize:11},
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          style: 'tableExample',
          layout: 'noBorders',
          table: {
            widths: ['40%', '30%', '30%'],
            body: [
              [
                  {text:'EARNINGS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'DEDUCTIONS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'YEAR TO DATE',style:'heading',bold: true, decoration: 'underline', fontSize: 9}
               ],
              [
               [
                  {
                    table: {
                      body: earningsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      widths: ['60%', '40%'],
                      body: deductionsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      body: yearToDateCol
                    },
                    layout: 'noBorders',
                  }
                ],
              ]
            ]
          }
        },

        {
            alignment: 'right',
            margin: [0,20,0,10],
            columns: [
            {
              text: '... ',
              style: 'content',
              bold: true
            },
            {
              text: 'RUN DATE: '+args.payDate+' ',

              style: 'content'
            }
          ]
        },

        {
          alignment: 'right',
          layout: 'noBorders',
          margin: [0,5,0,10],
          style: 'totalsTable',
          table: {
              widths: [90, '*', '*', "*", '*', '*'],
            body: [
              [
                  {text:' **Total Earnings'  ,fontSize: 10, bold: true},
                  {text:' $' +payItem.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),fontSize: 10},
                  {text:' **Total Deductions'  ,fontSize: 10, bold: true},
                  {text:'$ '+payItem.totalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ,fontSize: 10,},
                  {text:' ** Net Pay',fontSize: 10,},
                  {text: '$'+args.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),bold:true,decoration:'underline', fillColor: '#e5e5e5' ,borders:[false,true,false,true]},
               ],
            ]
          }
        },

        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: usdRate,
        },

        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: ''
        }

      ],

      footer:  function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };
    pdfMake.createPdf(def).open();

  }
  preAllPayslipsGenPdf() {

    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");

    // let ytdArray = [];
    // let indx = 0;

    for (const item of this.paySummary) {
      let indx = this.paySummary.indexOf(item);
      // console.log('indx',indx);


      this.api.getEmployeeYtd(item.employeeId,this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

        this.ytdArray.push({empId:item.employeeId,ytd: next});
        // console.log('getEmployeeYtd: indx',indx,+item.employeeId+'',next);

        if (this.ytdArray.length === this.paySummary.length) {
        // if (indx === this.paySummary.length-1) {
          this.getAllPayslips()
          // this.getAllPayslips(ytdArray)
        }
      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: preGetAllPayslips!"
        }
        $("#errorModal").modal("show");
        this.ytdArray = [];
      })

    }

  }
  getAllPayslips() {

    // console.log('getAllPayslips',this.ytdArray);

    let body = [];

    for (const employ of this.paySummary) {
      // console.log('employ',employ);


      let empPage = [];
      // let employee: Employee = Defaults.EmployeeDefault();

      // if (this.employees.length > 0) {
      //   employee = this.employees.filter( emp => emp.employeeId === employ.employeeId)[0];
      // }
      let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === employ.employeeId).employee;

      let company = this.company;
      let payroll = this.selectedPayroll;
      let payItem = employ;

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let ytdArrayElem;
      if (this.ytdArray.find(x=>x.empId === employee.employeeId)) {
        ytdArrayElem = this.ytdArray.find(x=>x.empId === employee.employeeId).ytd;
      }

      // console.log('ytdArrayElem',employee.employeeId,ytdArrayElem);

      // console.log('getAllPayslips',payItem.pay);

      var showRate: boolean = false;
      // console.log('error check', payItem);
      let args = {
        rate: payItem.pay[0].rate,
        payDate: payroll.payCycle.payDateOffset,
        netPay: payItem.net,
        pay: payItem.pay,
        statutory: payItem.statutory,
        deductions: employee.deductions,
        yearToDate: ytdArrayElem,
      }


      // console.log('this.companyLogoBase64',this.companyLogoBase64);

      let logo;
      if (this.payslipLogo === false) {
        logo = {text:'',style: 'content'}
      } else {
        logo = {
          image: 'data:'+this.companyLogoBase64.image,
          width: 200,
          height: 80,
        }
      }


      let payslipPaySummary = this.paySummary.find(paySum => paySum.employeeId === employee.employeeId)

      let payDate: Date = new Date(args.payDate);

      let usdRate = '';
      if (employee.banking.currency === "USD") {
        usdRate = '*Net pay of JA$'+args.netPay+' shown above is paid as USD$'+ (args.netPay / company.usdRate).toFixed(2) +' per agreement using the Friday prior to payroll rate of USD$' + company.usdRate +'';
      }

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      args.payDate = args.payDate.substr(0,10);
      let earningsCol = [
        [
            {text:'Description',style: 'innerHeading2',},
            {text:'Hours',style: 'innerHeading2'},
            {text:'Rate',style: 'innerHeading2'},
            {text:'Amount',style: 'innerHeading2'}
        ],
      ]
      let deductionsCol = [
        [
           {text:'Description',style: 'innerHeading2'},
           {text:'Amount',style: 'innerHeading2'},
        ],
      ]
      let yearToDateCol = [
        [
           {text:'Description',style: 'innerHeading2'},
           {text:'Amount',style: 'innerHeading2'},
        ],
      ]

      let totalPay: number = 0;
      let totalDeduction: number = payslipPaySummary.totalDeductions;

      for(const value of args.pay) {

        let payRow = [
            {text:''+value.description+'',style: 'content'},
            // {text:''+codeDesc.code.description+'',style: 'content'},
            {text:''+value.hours+'',style: 'content'},
            {text:'$ '+value.rate.toFixed(2)+'',style: 'content'},
            {text:'$ '+value.amount.toFixed(2)+'',style: 'content'},
        ]
        earningsCol.push(payRow);

        totalPay += value.amount;
      }

      for (const value2 of payItem.statutory) {
        if (value2.tax !== "HEART") {
          deductionsCol.push([
            {text:''+value2.tax+'',style: 'content'},
            {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
          ]);
        }

        //totalDeduction += value2.employeeAmount;
      }

      for (const deduct of args.deductions) {
        if (!deduct.description) {
          // console.log('no desc',deduct);

        }
        deductionsCol.push([
            {text:''+deduct.description+'',style: 'content'},
            {text:'$ '+deduct.deductionAmount.toFixed(2)+'',style: 'content'},
        ]);

        //totalDeduction += deduct.deductionAmount;

      }

      for(const [key, value] of Object.entries(payslipPaySummary.deductions)) {
      // for(const value of payslipPaySummary.deductions) {

        let val: any = value;
        // console.log('val 2',val,this.deductionCodes.find(code => code.code === val.code));


        if (val.code !== "HEART") {
          let deductionsRow2 = [
              {text:''+val.code.split('-')[0]+'',style: 'content'},
              {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
          ]
          deductionsCol.push(deductionsRow2);

          //totalDeduction += val.amount;
        }

      }

      if (payItem.pension !== 0) {
        deductionsCol.push([
            {text:'Employee Pension',style: 'content'},
            {text:'$ '+payItem.pension.toFixed(2)+'',style: 'content'},
        ]);
      }

      for(const [key, value] of Object.entries(payslipPaySummary.loans)) {

        let val: any = value;
        // console.log(val.code,this.loanCodes,'code match',this.loanCodes.find(code => code.code === val.code));


        let deductionsRow2 = [
            {text:''+val.code+'',style: 'content'},
            {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
        ]
        deductionsCol.push(deductionsRow2);

        //totalDeduction += val.amount;
      }

      if (args.yearToDate) {
        for (const [key, value] of Object.entries(args.yearToDate)) {

          let val: any = value;

          let ytdRow;
          switch(key) {
            case 'pay':
              ytdRow = [
                // {text:''+codeDesc.code.description+'',style: 'content'},
                {text:''+val[0].code+'',style: 'content'},
                // {text:''+val[0].code+'',style: 'content'},
                {text:'$ '+val[0].amount.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'taxCalc':

              // for (const [key, tax] of Object.entries(val)) {
              //   ytdRow = [
              //     {text:''+tax.tax+'',style: 'content'},
              //     {text:'$ '+tax.employeeAmount.toFixed(2)+'',style: 'content'},
              //   ]
              //   yearToDateCol.push(ytdRow);
              // }
            break;
            case 'generalGross':
              ytdRow = [
                {text:'Gross',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'gross':
              ytdRow = [
                {text:'TaxableGross',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'net':
              ytdRow = [
                {text:'Net',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'statutory':
              for (const value2 of val) {
                if (value2.tax !== "HEART") {
                  ytdRow = [
                    {text:''+value2.tax+'',style: 'content'},
                    {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
                  ]
                  yearToDateCol.push(ytdRow);
                }
              }
            break;
          }

        }
      }

      let modDate = "...";

      modDate = formatDate(payroll.payCycle.endDate, 'longDate', 'en')

      let modDate2 = formatDate(payroll.payCycle.payDateOffset, 'longDate', 'en')

      empPage.push(
        {
          text: 'PAY ADVICE FOR PERIOD '+payroll.payCycle.period+', ' +modDate+".",
          fontSize: 18,
          styles:'topHeader',
          alignment: 'center',
          bold: true,
        },
        {
          style: 'tableExample',
          table: {
          widths: [ '20%', '40%', '40%'],
            body: [
              [
                  {text:'NPJF',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName + '\('+payroll.type+'\)',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName,fontSize:13,decoration:'underline',bold:true},
              ],
              // [
              //     {text:'423',style: 'content',bold:true},
              //     {text:'Accounts Receivables',style: 'content',bold:true},
              //     {text:'Data Entry Building # 2',style: 'content',bold:true,fontSize:13},
              // ],
              [
                  {text:'Pay Date',style: 'content'},
                  {text: modDate2 ,style: 'content'},
                  {text: company.address.street + ', ' + company.address.city + ', ' +company.address.parish ,style: 'content',bold:true,fontSize:13},
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          style: 'tableExample',
          table: {
          widths: [ '60%', '40%'],
            body: [
              [
                [
                  {
                    style: 'tableExample',
                    fillColor: '#e5e5e5',
                    margin: [0,20,0,0],
                   table: {
                     body: [
                      [
                       {
                         type: 'none',
                         margin: [50,0,60,0],
                         ul: [
                           {text:''+payItem.employeeId+' : '+payItem.employee+'',bold: true,fontSize:14,decoration:'underline'},
                           {text:''+employee.employment.occupation.code+' : '+employee.employment.occupation.description+'',fontSize:11,bold: true},
                           {text:'Department: '+employee.employment.department.description},
                         ]
                       },
                      ],
                     ]
                   }
                 }
                ],
                [
                  logo
                ],
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          margin: [0,30,0,0],
          table: {
          widths: [ '18%', '20%', '30%', '32%'],
            body: [
              [
                  {border: [false, true, false, false],text:'TRN: '+company.tax+'',fontSize:11},
                  {border: [false, true, false, false],text:'NIS Ref#: '+company.nis+'',fontSize:11},
                  // {border: [false, true, false, false],text:'Vacation Bal: '+args.deductions.vacBal+' Hours',fontSize:11},
                  {border: [false, true, false, false],text:'Company Pension YTD: ',fontSize:11},
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          style: 'tableExample',
          layout: 'noBorders',
          table: {
            widths: ['40%', '30%', '30%'],
            body: [
              [
                  {text:'EARNINGS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'DEDUCTIONS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'YEAR TO DATE',style:'heading',bold: true, decoration: 'underline', fontSize: 9}
               ],
              [
               [
                  {
                    table: {
                      body: earningsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      widths: ['60%', '40%'],
                      body: deductionsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      body: yearToDateCol
                    },
                    layout: 'noBorders',
                  }
                ],
              ]
            ]
          }
        },
        {
            alignment: 'right',
            margin: [0,20,0,10],
            columns: [
            {
              text: '... ',
              style: 'content',
              bold: true
            },
            {
              text: 'RUN DATE: '+args.payDate+' ',

              style: 'content'
            }
          ]
        },
        {
          alignment: 'right',
          layout: 'noBorders',
          margin: [0,5,0,10],
          style: 'totalsTable',
          table: {
              widths: [90, '*', '*', "*", '*', '*'],
            body: [
              [
                  {text:' **Total Earnings'  ,fontSize: 10, bold: true},
                  {text:' $' +payItem.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),fontSize: 10},
                  {text:' **Total Deductions'  ,fontSize: 10, bold: true},
                  {text:'$ '+payItem.totalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ,fontSize: 10,},
                  {text:' ** Net Pay',fontSize: 10,},
                  {text: '$'+args.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),bold:true,decoration:'underline', fillColor: '#e5e5e5' ,borders:[false,true,false,true]},
               ],
            ]
          }
        },

        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: usdRate,
        },
        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: ''
        },
        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: '',
          pageBreak: "after",
        }
      )

      body.push(empPage)

    }

    let user = this.currentUser;

    const def = {
      content:
      body,



      footer:  function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };
    pdfMake.createPdf(def).open();

    this.ytdArray = [];
    this.loading = false;
    $("#errorModal").modal("hide");

  }
  preGetAllPayslipsFilter() {

    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");

    console.log('this.finalFilterData',this.finalFilterData);


    this.api.getAllPayslipsFilter(this.selectedPayroll.id,this.finalFilterData).subscribe(next => {

      this.loading = false;
      console.log('getAllPayslipsFilter',next);
      this.finalFilterData = [];

      if (next.length === 0) {
        this.modalMessage = "No Results Found"
        setTimeout(()=>{

          $("#errorModal").modal("hide");
        }, 3000);

      } else {
        this.getAllPayslipsFilter(next)
      }



    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: preGetAllPayslips!"
      }
      $("#errorModal").modal("show");
      this.ytdArray = [];
    })

  }
  getAllPayslipsFilter(employees) {

    let body = [];

    for (const employ of employees) {
      // console.log('employ',employ);


      let empPage = [];
      // let employee: Employee = Defaults.EmployeeDefault();

      // if (this.employees.length > 0) {
      //   employee = this.employees.filter( emp => emp.employeeId === employ.employeeId)[0];
      // }
      let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === employ.employeeId).employee;

      let company = this.company;
      let payroll = this.selectedPayroll;
      let payItem = employ;

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let ytdArrayElem;
      if (this.ytdArray.find(x=>x.empId === employee.employeeId)) {
        ytdArrayElem = this.ytdArray.find(x=>x.empId === employee.employeeId).ytd;
      }

      // console.log('ytdArrayElem',employee.employeeId,ytdArrayElem);

      // console.log('getAllPayslips',payItem.pay);

      var showRate: boolean = false;
      // console.log('error check', payItem);
      let args = {
        rate: payItem.pay[0].rate,
        payDate: payroll.payCycle.payDateOffset,
        netPay: payItem.net,
        pay: payItem.pay,
        statutory: payItem.statutory,
        deductions: employee.deductions,
        yearToDate: ytdArrayElem,
      }


      // console.log('this.companyLogoBase64',this.companyLogoBase64);

      let logo;
      if (this.payslipLogo === false) {
        logo = {text:'',style: 'content'}
      } else {
        logo = {
          image: 'data:'+this.companyLogoBase64.image,
          width: 200,
          height: 80,
        }
      }


      let payslipPaySummary = this.paySummary.find(paySum => paySum.employeeId === employee.employeeId)

      let payDate: Date = new Date(args.payDate);

      let us = '*Net pay of JA$'+args.netPay+' shown above is paid as USD$'+ (args.netPay / args.rate).toFixed(2) +' per agreement using the Friday prior to payroll rate of USD$' + args.rate +'';


      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      args.payDate = args.payDate.substr(0,10);
      let earningsCol = [
        [
            {text:'Description',style: 'innerHeading2',},
            {text:'Hours',style: 'innerHeading2'},
            {text:'Rate',style: 'innerHeading2'},
            {text:'Amount',style: 'innerHeading2'}
        ],
      ]
      let deductionsCol = [
        [
           {text:'Description',style: 'innerHeading2'},
           {text:'Amount',style: 'innerHeading2'},
        ],
      ]
      let yearToDateCol = [
        [
           {text:'Description',style: 'innerHeading2'},
           {text:'Amount',style: 'innerHeading2'},
        ],
      ]

      let totalPay: number = 0;
      let totalDeduction: number = payslipPaySummary.totalDeductions;

      for(const value of args.pay) {

        let payRow = [
            {text:''+value.description+'',style: 'content'},
            // {text:''+codeDesc.code.description+'',style: 'content'},
            {text:''+value.hours+'',style: 'content'},
            {text:'$ '+value.rate.toFixed(2)+'',style: 'content'},
            {text:'$ '+value.amount.toFixed(2)+'',style: 'content'},
        ]
        earningsCol.push(payRow);

        totalPay += value.amount;
      }

      for (const value2 of payItem.statutory) {
        if (value2.tax !== "HEART") {
          deductionsCol.push([
            {text:''+value2.tax+'',style: 'content'},
            {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
          ]);
        }

        //totalDeduction += value2.employeeAmount;
      }

      for (const deduct of args.deductions) {
        if (!deduct.description) {
          // console.log('no desc',deduct);

        }
        deductionsCol.push([
            {text:''+deduct.description+'',style: 'content'},
            {text:'$ '+deduct.deductionAmount.toFixed(2)+'',style: 'content'},
        ]);

        //totalDeduction += deduct.deductionAmount;

      }

      for(const [key, value] of Object.entries(payslipPaySummary.deductions)) {
      // for(const value of payslipPaySummary.deductions) {

        let val: any = value;
        // console.log('val 2',val,this.deductionCodes.find(code => code.code === val.code));


        if (val.code !== "HEART") {
          let deductionsRow2 = [
              {text:''+val.code.split('-')[0]+'',style: 'content'},
              {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
          ]
          deductionsCol.push(deductionsRow2);

          //totalDeduction += val.amount;
        }

      }

      if (payItem.pension !== 0) {
        deductionsCol.push([
            {text:'Employee Pension',style: 'content'},
            {text:'$ '+payItem.pension.toFixed(2)+'',style: 'content'},
        ]);
      }

      for(const [key, value] of Object.entries(payslipPaySummary.loans)) {

        let val: any = value;
        // console.log(val.code,this.loanCodes,'code match',this.loanCodes.find(code => code.code === val.code));


        let deductionsRow2 = [
            {text:''+val.code+'',style: 'content'},
            {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
        ]
        deductionsCol.push(deductionsRow2);

        //totalDeduction += val.amount;
      }

      if (args.yearToDate) {
        for (const [key, value] of Object.entries(args.yearToDate)) {

          let val: any = value;

          let ytdRow;
          switch(key) {
            case 'pay':
              ytdRow = [
                // {text:''+codeDesc.code.description+'',style: 'content'},
                {text:''+val[0].code+'',style: 'content'},
                // {text:''+val[0].code+'',style: 'content'},
                {text:'$ '+val[0].amount.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'taxCalc':

              // for (const [key, tax] of Object.entries(val)) {
              //   ytdRow = [
              //     {text:''+tax.tax+'',style: 'content'},
              //     {text:'$ '+tax.employeeAmount.toFixed(2)+'',style: 'content'},
              //   ]
              //   yearToDateCol.push(ytdRow);
              // }
            break;
            case 'generalGross':
              ytdRow = [
                {text:'Gross',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'gross':
              ytdRow = [
                {text:'TaxableGross',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'net':
              ytdRow = [
                {text:'Net',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'statutory':
              for (const value2 of val) {
                if (value2.tax !== "HEART") {
                  ytdRow = [
                    {text:''+value2.tax+'',style: 'content'},
                    {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
                  ]
                  yearToDateCol.push(ytdRow);
                }
              }
            break;
          }

        }
      }

      let modDate = "...";

      modDate = formatDate(payroll.payCycle.endDate, 'longDate', 'en')

      let modDate2 = formatDate(payroll.payCycle.payDateOffset, 'longDate', 'en')

      empPage.push(
        {
          text: 'PAY ADVICE FOR PERIOD '+payroll.payCycle.period+', ' +modDate+".",
          fontSize: 18,
          styles:'topHeader',
          alignment: 'center',
          bold: true,
        },
        {
          style: 'tableExample',
          table: {
          widths: [ '20%', '40%', '40%'],
            body: [
              [
                  {text:'NPJF',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName + '\('+payroll.type+'\)',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName,fontSize:13,decoration:'underline',bold:true},
              ],
              // [
              //     {text:'423',style: 'content',bold:true},
              //     {text:'Accounts Receivables',style: 'content',bold:true},
              //     {text:'Data Entry Building # 2',style: 'content',bold:true,fontSize:13},
              // ],
              [
                  {text:'Pay Date',style: 'content'},
                  {text: modDate2 ,style: 'content'},
                  {text: company.address.street + ', ' + company.address.city + ', ' +company.address.parish ,style: 'content',bold:true,fontSize:13},
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          style: 'tableExample',
          table: {
          widths: [ '60%', '40%'],
            body: [
              [
                [
                  {
                    style: 'tableExample',
                    fillColor: '#e5e5e5',
                    margin: [0,20,0,0],
                   table: {
                     body: [
                      [
                       {
                         type: 'none',
                         margin: [50,0,60,0],
                         ul: [
                           {text:''+payItem.employeeId+' : '+payItem.employee+'',bold: true,fontSize:14,decoration:'underline'},
                           {text:''+employee.employment.occupation.code+' : '+employee.employment.occupation.description+'',fontSize:11,bold: true},
                           {text:'Department: '+employee.employment.department.description},
                         ]
                       },
                      ],
                     ]
                   }
                 }
                ],
                [
                  logo
                ],
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          margin: [0,30,0,0],
          table: {
          widths: [ '18%', '20%', '30%', '32%'],
            body: [
              [
                  {border: [false, true, false, false],text:'TRN: '+company.tax+'',fontSize:11},
                  {border: [false, true, false, false],text:'NIS Ref#: '+company.nis+'',fontSize:11},
                  // {border: [false, true, false, false],text:'Vacation Bal: '+args.deductions.vacBal+' Hours',fontSize:11},
                  {border: [false, true, false, false],text:'Company Pension YTD: ',fontSize:11},
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          style: 'tableExample',
          layout: 'noBorders',
          table: {
            widths: ['40%', '30%', '30%'],
            body: [
              [
                  {text:'EARNINGS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'DEDUCTIONS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'YEAR TO DATE',style:'heading',bold: true, decoration: 'underline', fontSize: 9}
               ],
              [
               [
                  {
                    table: {
                      body: earningsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      widths: ['60%', '40%'],
                      body: deductionsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      body: yearToDateCol
                    },
                    layout: 'noBorders',
                  }
                ],
              ]
            ]
          }
        },
        {
            alignment: 'right',
            margin: [0,20,0,10],
            columns: [
            {
              text: '... ',
              style: 'content',
              bold: true
            },
            {
              text: 'RUN DATE: '+args.payDate+' ',

              style: 'content'
            }
          ]
        },
        {
          alignment: 'right',
          layout: 'noBorders',
          margin: [0,5,0,10],
          style: 'totalsTable',
          table: {
              widths: [90, '*', '*', "*", '*', '*'],
            body: [
              [
                  {text:' **Total Earnings'  ,fontSize: 10, bold: true},
                  {text:' $' +payItem.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),fontSize: 10},
                  {text:' **Total Deductions'  ,fontSize: 10, bold: true},
                  {text:'$ '+payItem.totalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ,fontSize: 10,},
                  {text:' ** Net Pay',fontSize: 10,},
                  {text: '$'+args.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),bold:true,decoration:'underline', fillColor: '#e5e5e5' ,borders:[false,true,false,true]},
               ],
            ]
          }
        },
        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: '',
          pageBreak: "after",
        }
      )

      body.push(empPage)

    }

    let user = this.currentUser;

    const def = {
      content:
      body,


      footer:  function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };
    pdfMake.createPdf(def).open();

    this.ytdArray = [];
    this.loading = false;
    $("#errorModal").modal("hide");

  }

  handleFilterInputTypeChange(index,value) {
    // console.log('handleFilterInputTypeChange',value);
    if (
      this.filterInputs[index].data.Property === 'First Name' ||
      this.filterInputs[index].data.Property === 'Last Name' ||
      // this.filterInputs[index].data.Property === 'Employee ID' ||
      this.filterInputs[index].data.Property === 'Occupation Code' ||
      this.filterInputs[index].data.Property === 'Department Code'
    ) {
      this.filterInputs[index].inputType = 'string';
    }
    else
    // if (this.filterInputs[index].data.Property === 'Department Code')
    {
      this.filterInputs[index].inputType = 'number';
    }


    let propsInUse = [];
    for (const input of this.filterInputs) {
      if (input.data.Property !== "") {
        let index2 = this.filterInputs.indexOf(input);
        propsInUse.push({index:index2,prop:input.data.Property})
      }
    }
    // console.log('propsInUse',propsInUse,'filterProperties1',this.filterProperties1);

    for (let list of this.filterProperties1) {
      let index3 = this.filterProperties1.indexOf(list);
      let list2 = this.filterProperties2;
      for (const prop of propsInUse) {
        if (prop.index !== index3) {
          // console.log('remove',prop.prop,'from proplist',index3);

          let index4 = list.indexOf(prop.prop)
          list2 = list2.filter(x=>x !== prop.prop)
          this.filterProperties1[index3] = list2
        }
      }
    }

    // console.log('change: filterProperties1',this.filterProperties1);

  }

  addFilterInput(index,type) {

    if (this.filterInputs.length < 6) {
      this.filterInputs[index].data.Type = type;

      let array = [];
      // let array = this.filterProperties2
      // for (const input of this.filterInputs) {
      //   let removeIndex = array.indexOf(input.data.Property)
      //   if (removeIndex && removeIndex > -1) {
      //     array = array.filter(x=>x !== input.data.Property)
      //   }
      // }

      this.filterInputs.push(
        {
          inputType: "string",
          data: {
            Property: "",
            Condition: "",
            Value: "",
            Type: "",
          }
        }
      )

      this.filterProperties1.push(array)


      let propsInUse = [];
      for (const input of this.filterInputs) {
        if (input.data.Property !== "") {
          let index2 = this.filterInputs.indexOf(input);
          propsInUse.push({index:index2,prop:input.data.Property})
        }
      }
      // console.log('propsInUse',propsInUse,'filterProperties1',this.filterProperties1);

      for (let list of this.filterProperties1) {
        let index3 = this.filterProperties1.indexOf(list);
        let list2 = this.filterProperties2;
        for (const prop of propsInUse) {
          if (prop.index !== index3) {
            // console.log('remove',prop.prop,'from proplist',index3);

            let index4 = list.indexOf(prop.prop)
            list2 = list2.filter(x=>x !== prop.prop)
            this.filterProperties1[index3] = list2
          }
        }
      }

    }

    // console.log('add: filterProperties1',this.filterProperties1);

  }

  removeFilterInput(index) {

    let prop = this.filterInputs[index].data.Property;
    let targetInput = this.filterInputs[index];
    let targetPropList = this.filterProperties1[index];


    this.filterInputs = this.filterInputs.filter(x=>x !== targetInput)
    this.filterProperties1 = this.filterProperties1.filter(x=>x !== targetPropList)
    // this.filterInputs.splice(index,1)
    // this.filterProperties1.splice(index,1)


    for (const prop2 of this.filterProperties1) {
      prop2.push(prop)
    }


    let propsInUse = [];
    for (const input of this.filterInputs) {
      if (input.data.Property !== "") {
        let index2 = this.filterInputs.indexOf(input);
        propsInUse.push({index:index2,prop:input.data.Property})
      }
    }
    // console.log('propsInUse',propsInUse,'filterProperties1',this.filterProperties1);

    for (let list of this.filterProperties1) {
      let index3 = this.filterProperties1.indexOf(list);
      let list2 = this.filterProperties2;
      for (const prop of propsInUse) {
        if (prop.index !== index3) {
          // console.log('remove',prop.prop,'from proplist',index3);

          let index4 = list.indexOf(prop.prop)
          list2 = list2.filter(x=>x !== prop.prop)
          this.filterProperties1[index3] = list2
        }
      }
    }

    // console.log('remove: filterProperties1',this.filterProperties1);



  }

  payslipReportHandler(args,type,report) {

    if (args === 'single') {

      $("#selectEmployeeModal").modal("show");
    }
    if (args === 'allFiltered') {
      this.reportHandler(type,report,'pdf',true)
    }
    if (args === 'allUnfiltered') {
      this.reportHandler(type,report,'pdf',false)
    }

  }

  setPayslipEmployee(index,employee) {

    // console.log('setPayslipEmployee',employee,'indx',index);
    this.getEmployeeYtd(employee.employeeId,index)
    $("#selectEmployeeModal").modal("hide");

  }


}
