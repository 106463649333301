import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, last } from 'rxjs/operators';
import { Company } from './Models/Company';
import { environment } from './../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

server = "http://localhost:6662/";


error: any;

constructor(
  private http: HttpClient
) {
  this.server = environment.apiUrl;
}


loginAdmin(email, password) {

  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      // 'access-control-allow-origin': "http://localhost:6662"
    })
  };

  return this.http.get(this.server+"/api/auth/loginAdmin/"+email+"/"+password+"").pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}
createAdmin(adminData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/auth/createAdmin/", adminData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}
getAdmin(id) {

  let token = localStorage.getItem('token');

  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/auth/getAdmins/"+id+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}
updateAdmin(admin) {

  let token = localStorage.getItem('token');

  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      // 'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/auth/updateAdmin/", admin, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}
removeAdmin(userId) {

  let token = localStorage.getItem('token');

  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/auth/removeAdmin/"+userId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}
resetAdminPassword(oldPassword,newPassword,email,requesterId) {

  let token = localStorage.getItem('token');

  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/auth/resetAdminPassword/"+oldPassword+"/"+newPassword+"/"+email+"/"+requesterId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getCompany(companyRef) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  }

  return this.http.get(this.server+"/api/company/getCompany/"+companyRef+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}
getCompanies() {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/js/on;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/company/getCompanies/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}
createCompany(companyData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/company/createCompany", companyData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}
editCompany(companyData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/company/updateCompany", companyData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

upload(file) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      // 'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/company/upload", file, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

adminForgetPassword(email) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/company/adminForgetPassword/"+email+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

resetPassword(newPassword) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/company/resetPassword", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

createEmployee(employeeData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      // 'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/employee/addEmployeeSingle", employeeData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateEmployee(employeeData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/employee/updateEmployee", employeeData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

addEmployeeBatch(employeesJson) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/employee/EmployeeBatchUpload", employeesJson, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

getEmployees(companyId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/employee/getEmployees/"+companyId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

getEmployeesByPage(start,count,companyId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/employee/getEmployees/"+start+"/"+count+"/"+companyId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

getEmployeesBySearch(query,companyId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/employee/search/"+query+"/"+companyId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

deleteEmployee(employeeId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/employee/removeEmployee/"+employeeId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

getCodes(companyId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      // 'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/code/getCodes/"+companyId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getCode(companyId,type) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      // 'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/code/getCode/"+companyId+"/"+type+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/company/createCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateCode(code) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/employee/updateCode", code, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

addDepartmentOccupationCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/createDepartmentOccupationCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateDepartmentOccupationCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/updateDepartmentOccupationCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addTransactionCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/createTransactionCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateTransactionCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/updateTransactionCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addDeductionCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/createDeductionCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateDeductionCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/updateDeductionCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addLoanCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/createLoanCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateLoanCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/updateLoanCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addPensionCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/createPensionCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updatePensionCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/updatePensionCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addHealthCode(codeObject) {

  let token = localStorage.getItem('token');

  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/createHealthCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updateHealthCode(codeObject) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/code/updateHealthCode", codeObject, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getTaxHeaders() {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/tax/getHeaders", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        return response;
      }
    })
  )
}

createTaxHeader(taxObj) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/tax/createTaxHeader", taxObj, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

createChartOfAccounts(chart) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/payroll/createChartOfAccounts", chart, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getChartOfAccounts(companyID) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/getChartOfAccounts/"+companyID+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

startPayroll(payroll) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/payroll/startPayroll/",payroll , httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        // console.log('response',response);
        return response;
      }
    })
  )
}

getOpenPayrolls(companyId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/getOpenPayrolls/"+companyId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {
        // console.log('response',response);
        return response;
      }
    })
  )
}

getOpenPayroll(payrollId,payrollType) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/getOpenPayroll/"+payrollId+"/"+payrollType+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

uploadHours(payData) {

  let token = localStorage.getItem('token');

  const httpOptionsSecure = {

    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    }),
    reportProgress: true,
  };

  return this.http.post(this.server+"/api/payroll/uploadTimesheets", payData, httpOptionsSecure).pipe(
    // map(event => console.log('xx',event)),
    // tap(message => console.log('yy',message)),
    // last(), // return last (completed) message to callers
    map((response: any) => {


      if (response) {

        return response;
      }
    })
  )
}

getPaySummary(payrollId,payCycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/GetPaySummary/"+payrollId+"/"+payCycle+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getPayrollYtd(companyId,type,endDate) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/ytd/"+companyId+"/"+type+"/"+endDate, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getCompanyBaseImage(companyId,type) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/company/getCompanyBaseImage/"+companyId+"/"+type+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getPaySchedule(companyId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/PaySchedule/"+companyId+"/"+cycle+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

commitPayroll(companyId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/commitPayroll/"+companyId+"/"+cycle+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

uncommitPayroll(payrollId,payrollType) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/uncommitPayroll/"+payrollId+"/"+payrollType+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

resetPayroll(payrollId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/resetPayroll/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

closePayroll(payrollId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/closePayroll/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getClosedPayrolls(companyId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/getClosedPayrolls/"+companyId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

setPayrollRegister(payslip) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/report/PayrollRegister/", payslip, httpOptionsSecure).pipe(
  // return this.http.post(this.server+"/api/payroll/PayrollRegister/", payslip, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getEmployeeTransactions(empId,payrollId,type) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/getEmployeeTransactions/"+empId+"/"+payrollId+"/"+type+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

editEmployeeTransaction(payrollID,employeeID,code,amount) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/editEmployeeTransaction/"+payrollID+"/"+employeeID+"/"+code+"/"+amount+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addEmployeeTransaction(payrollID,transaction) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.put(this.server+"/api/payroll/addEmployeeTransactions/"+payrollID+"", transaction, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

addEmployeeTransactions(payrollID,employee) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.put(this.server+"/api/payroll/addEmployeeTransactions/"+payrollID+"/", employee, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

removeEmployeeTransaction(data) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/payroll/removeEmployeeTransaction/", data, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

removeEmployeeInPayroll(payrollID,employeeID) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"​/api/payroll/removeEmployeeInPayroll/"+payrollID+"/"+employeeID+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

setEmployeeRegister(payrollId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/EmployeeRegister/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

setEmployeeRegisterFilter(payrollId,filterData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.put(this.server+"/api/report/EmployeeRegisterFilterable/"+payrollId+"/", filterData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}


modifyPayCycle(updateData) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/payroll/modifyPayCycle/", updateData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getEmployeeYtd(empId,payrollId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/ytdEmployee/"+empId+"/"+payrollId+"/"+cycle+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getEmployeeYtdCurrent(companyId,empId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/ytdEmployeeCurrent/"+companyId+"/"+empId+"/"+cycle+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getEmployeeMtdCurrent(companyId,empId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/mtdEmployeeCurrent/"+companyId+"/"+empId+"/"+cycle+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getSO1Form(payrollId,type) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json;charset=utf8',
      'Content-Type': 'application/pdf',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/SO1Form/"+payrollId+"/"+type+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getSO1ScheduleReport(payrollRefs) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/report/SO1Schedule/", payrollRefs, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getDeductionListingReport(payrollId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/deductionListing2/"+payrollId+"/"+cycle+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getDeductionListingReportExcel(payrollId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/deductionListingExcel/"+payrollId+"/"+cycle+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getLodgementListingReport(payrollId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/lodgementListing/"+payrollId+"/"+cycle+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getLodgementListingReportExcel(payrollId,cycle) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/lodgementListingExcel/"+payrollId+"/"+cycle+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getPayrollRegisterExcel(companyID,type,current,year) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/PayrollRegisterExcel/"+companyID+"/"+type+"/"+current+"/"+year+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getEmployeeRegisterExcel(payrollId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/EmployeeRegisterExcel/"+payrollId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getPensionListingPdf(payrollId,type) {
  // console.log('api get lodgement report');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/pensionListingPDF/"+payrollId+"/"+type+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getTerminationReportPdf(payrollId) {
  // console.log('api get lodgement report');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/termination/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getAuditReportByEmployeePdf(data) {
  // console.log('api get lodgement report');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/report/auditReport/", data, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getAuditReportByMonthPdf(companyId,type) {
  // console.log('api get lodgement report');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/auditReportByMonth/"+companyId+"/"+type+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getDepartmentSummary(id) {
  // console.log('api get department summary');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  // return this.http.get(this.server+"/api/report/departmentSummary/"+id+"/"+year+"", httpOptionsSecure).pipe(
  return this.http.get(this.server+"/api/report/departmentSummary/"+id+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getCompanyPaySummary(payrollId,cycle) {
  // console.log('api get company pay summary');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/GetCompanyPaySummary/"+payrollId+"/"+cycle+"", httpOptionsSecure).pipe(
  // return this.http.get(this.server+"/api/payroll/GetCompanyPaySummary/"+payrollId+"/"+cycle+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getBankFile(payrollId) {

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/FGB/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getBankFileNCB(payrollId) {


  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/NCB/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getBankFileBNS(payrollId) {


  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'text/plain',
      // 'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/BNS/"+payrollId+"/", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getS02ASCII(companyId,year) {


  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'text/plain',
      // 'Content-Type': 'application/json;charset=utf8',
      // 'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/SO2ASCII/"+companyId+"/"+year+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getPayrollTaxHeaders(payrollId) {
  // console.log('api get lodgement report');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/TaxHeaders/"+payrollId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

updatePayrollTaxHeader(payrollId,taxHeader) {
  // console.log('api get lodgement report');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.put(this.server+"/api/payroll/UpdateTaxHeaders/"+payrollId+"", taxHeader, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

batchUploadBankingCodes(bankingCodeData) {
  // console.log('api batch upload banking codes');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.post(this.server+"/api/payroll/batchUploadBanking", bankingCodeData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getBankingCodes() {
  // console.log('api get banking codes');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/payroll/getBanking", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

exportEmployees(companyId) {
  // console.log('api get banking codes');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/employee/exportEmployees/"+companyId+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getAllPayslipsFilter(payrollId,filterData) {
  // console.log('api get banking codes');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.put(this.server+"/api/payroll/getPayslips/"+payrollId+"", filterData, httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

getYearEndSummaryReportPdf(companyId,type) {
  // console.log('api get banking codes');

  let token = localStorage.getItem('token');
  const httpOptionsSecure = {
    headers: new HttpHeaders({
      // 'Content-Type': 'text/plain',
      'Content-Type': 'text/plain; charset=utf-8',
      // 'Content-Type': 'application/json;charset=utf8',
      // 'Content-Type': 'text/html',
      // 'Content-Type': 'application/pdf',
      'Access-control-allow-origin': "http://localhost:6662",
      'Authorization': `Bearer ${token}`
    })
  };

  return this.http.get(this.server+"/api/report/YearEndSummaryReport/"+companyId+"/"+type+"", httpOptionsSecure).pipe(
    map((response: any) => {
      if (response) {

        return response;
      }
    })
  )
}

}
