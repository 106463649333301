import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Defaults } from 'src/app/Models/Defaults';
import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { CompanyPaySummary } from 'src/app/Models/CompanyPaySummary';
import { Code } from 'src/app/Models/Code';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';

import { Employee } from 'src/app/Models/Employee';
import { Payroll } from 'src/app/Models/Payroll';

import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from '@angular/common';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-general-reports',
  templateUrl: './general-reports.component.html',
  styleUrls: ['./general-reports.component.css']
})
export class GeneralReportsComponent implements OnInit {


  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;
  faFileAlt = faFileAlt;
  faCalendarAlt = faCalendarAlt;

  public id: string = '';
  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';
  public currentUser: any = {};

  public setting: string = 'List';
  company: Company = Defaults.CompanyDefaults();
  public companyLogoBase64: any;
  employee: Employee = Defaults.EmployeeDefault();
  public employeeSrch: Array<Employee> = [];
  public employees: Array<Employee> = [];

  public totalEmployeeCount: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];
  public toggleSearch: boolean = false;

  allCodes: Array<any> = [];
  departmentCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  healthCode: HealthCode = Defaults.HealthCodeDefault();
  occupationCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  transactionCode: TransactionCode = Defaults.TransactionCodeDefault();
  deductionCode: DeductionCode = Defaults.DeductionCodeDefault();
  loanCode: LoanCode = Defaults.LoanCodeDefault();
  bankCode: BankCode = Defaults.BankCodeDefault();
  pensionCode: PensionCode = Defaults.PensionCodeDefault();

  departmentCodes: Array<DepartmentOccupationCode> = [];
  healthCodes: Array<HealthCode> = [];
  occupationCodes: Array<DepartmentOccupationCode> = [];
  transactionCodes: Array<TransactionCode> = [];
  deductionCodes: Array<DeductionCode> = [];
  loanCodes: Array<LoanCode> = [];
  pensionCodes: Array<PensionCode> = [];
  bankCodes: Array<BankCode> = [];
  bankingCodes: Array<BankCode> = [];


  public openPayrolls: Array<Payroll> = [];
  public closedPayrolls: Array<Payroll> = [];
  public allPayrolls: Array<Payroll> = [];
  public selectedPayroll: Payroll = Defaults.PayrollDefault();

  public reports: Array<any> =  [
    {
      name: 'SO1 Report',
      description: "...",
    },
    {
      name: 'SO2 Report',
      description: "...",
    },
    {
      name: 'Audit Report by Employee',
      description: "...",
    },
    {
      name: 'Audit Report by Month',
      description: "...",
    },
    {
      name: 'Year End Summary Report',
      description: "...",
    }
  ]
  docGenReport: any = {};
  finalFilterData: any = {};

  filterInputs: Array<any> = [];
  filterProperties1: Array<any> = [

  ];
  filterProperties2: Array<string> = [
    'First Name',
    'Last Name',
    // 'Employee ID',
    'Occupation Code',
    'Department Code',
  ];
  filterConditions : any = {
    string: [
      'Equal to',
      'Contains',
      'Not Equal to',
    ],
    number: [
      'Greater than',
      'Less than',
      'Equal to',
      'Greater than or Equal to',
      'Less than or Equal to',
    ],
  }
  filterPropPathRef: any = {
    First_Name: 'Employee/FirstName',
    Last_Name: 'Employee/LastName',
    Occupation_Code: 'Employee/Employment/Occupation/Code',
    Department_Code: 'Employee/Employment/Department/Code',
  }

  payslipReport: string = "";
  payslipReportChooseFilter: boolean = false;

  textFile: any = undefined;
  link: any = undefined;
  S02ASCIILink: string = "";
  showS02Link: boolean = false;
  selectedS01: Array<any> = [];
  s01Selected: Boolean = false;
  public s01Select: Boolean = false;
  selectedAuditEmployee: Array<any> = [];
  public auditSelect: Boolean = false;
  public auditSelect2: Boolean = false;
  public yearEndSelect: Boolean = false;
  public filterAudit: Boolean = false;

  public s01PaySummary: Array<any> = [];
  public s01PaySummaryLength: number;

  public auditPayrollType: string = "";
  public auditBPayrollType: string = "";
  public yearEndPayrollType: string = "";

  showYearEndLink: boolean = false;
  YearEndLink: string = "";
  yearEndTypeSelection: Array<any> = [];


  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private location:Location,
  ) { }

  ngOnInit() {


    $('.actionsBox').toggle();

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.currentUser = JSON.parse(localStorage.getItem("admin"))

    window.addEventListener('click', (e) => this.handleClick(e.target));


    this.getCompany()
    this.getBankingCodes();
    let codeTypes = ['department','occupation','transaction','deduction','loan','pension','health'];
    for (const type of codeTypes) {
      this.getCode(type)
    }
    this.getEmployeesByPage(true,'');

    this.getPayrolls();


  }


  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBoxList','actionsBoxListItem','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();


      }

    }



  }

  getEmployeesBySearch(query) {


    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getEmployeesBySearch(query,this.id).subscribe(next =>{

      console.log('getEmployeesBySearch',next);

      for (const elem of next) {
        this.employeeSrch.push(elem.data)
      }

      // this.loading = false;
      $("#errorModal").modal("hide");


    },
    error => {
      this.loading = false;
      this.modalMessage = "Error: getEmployeesBySearch!";
      $("#errorModal").modal("show");
    })

  }
  getEmployeesByPage(init,direction) {

    this.loading = true;
    $("#errorModal").modal("show");

    let start = 0;
    let count = 20;
    if (direction === 'next') {
      this.currentPage++
    }
    if (direction === 'previous') {
      this.currentPage--
    }

    let customCurrentPage = 1;
    if (direction !== 'next' && direction !== 'previous') {
      customCurrentPage = this.currentPage;
      this.currentPage = direction;
    }
    start = (this.currentPage-1)*20;
    if (init === true) {
      start = 0;
    }




    this.api.getEmployeesByPage(start,count,this.id).subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");


        if (init === true) {
          this.currentPage = 1;
          this.totalPages = Math.ceil(next.count/20);
          this.pagesArray = [];
          for (let index = 0; index < this.totalPages; index++) {
            this.pagesArray.push("")
          }
        } else {

          if (next.count !== this.totalEmployeeCount) {
            this.totalPages = Math.ceil(next.count/20);
            console.log('emp count has changed');
            this.pagesArray = [];
            for (let index = 0; index < this.totalPages; index++) {
              this.pagesArray.push("")
            }
          }

        }

        this.employeeSrch = next.data;
        this.employees = next.data;
        this.totalEmployeeCount = next.count;

        this.loading = false;

        $("#errorModal").modal("hide");


      },
      error => {

        if (direction === 'next') {
          this.currentPage--
        }
        if (direction === 'previous') {
          this.currentPage++
        }
        if (direction !== 'next' && direction !== 'previous') {
          this.currentPage = customCurrentPage;
        }

        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeesByPage!";
        }
        $("#errorModal").modal("show");
      }
    )

  }

  getCode(type) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCode(this.id,type).subscribe(codes => {;

      this[type+'Codes'] = codes;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCode "+type+""
      }

      $("#errorModal").modal("show");
    })

  }
  getBankingCodes() {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getBankingCodes().subscribe(next => {

      this.bankingCodes = next;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getBankingCodes!"
      }
      $("#errorModal").modal("show");
    })

  }
  getCompany () {
    this.api.getCompany(this.id).subscribe(companyData =>{

      this.company = companyData;
      this.api.getCompanyBaseImage(companyData.id,companyData.type).subscribe(companyLogo =>{
        this.companyLogoBase64 = companyLogo;
      });

      this.api.getEmployees(companyData.id).subscribe(
        next => {
          this.employees = next;
          // this.loading = false;
          // $("#errorModal").modal("hide");
        },
        error => {
          this.loading = false;
          this.error = true;
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: getBankingCodes!"
          }
          $("#errorModal").modal("show");
        }
      )

    });

  }

  getPayrolls() {

    let getOpen = false;

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getOpenPayrolls(this.id).subscribe(next => {

      // console.log('s01 payroll select getOpenPayrolls',next);

      for (const proll of next) {
        proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
        proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
        proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)

        this.allPayrolls.push(proll)

      }
      getOpen = true;

      let selection: Array<any> = this.allPayrolls;
      selection = selection.map(x=> x.type);
      this.yearEndTypeSelection = [...new Set(selection)]
      console.log('this.yearEndTypeSelection',this.yearEndTypeSelection);


    },
    error => {
      this.loading = false;
      console.log('api request error',error);

      this.modalMessage = 'Cannot get Open Payrolls'
      setTimeout(()=>{
        $("#errorModal").modal("hide");
      }, 3000)
    })


    this.api.getClosedPayrolls(this.id).subscribe(next => {

      // console.log('s01 payroll select getClosedPayrolls',next);


      for (const proll of next) {
        proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
        proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
        proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)

        this.allPayrolls.push(proll)
      }

      // if (getOpen === true) {
      //
      //   let selection: Array<any> = this.allPayrolls;
      //   selection = selection.map(x=> x.type);
      //   this.yearEndTypeSelection = [...new Set(selection)]
      //   console.log('this.yearEndTypeSelection',this.yearEndTypeSelection);
      //
      // }

      let selection: Array<any> = this.allPayrolls;
      selection = selection.map(x=> x.type);
      this.yearEndTypeSelection = [...new Set(selection)]
      console.log('this.yearEndTypeSelection',this.yearEndTypeSelection);

      $("#errorModal").modal("hide");
      this.loading = false;

      // this.allPayrolls = this.allPayrolls.filter(elem => elem.type === this.selectedPayroll.type)

      // $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      console.log('api request error',error);
      this.modalMessage = 'Cannot get Closed Payrolls'
      setTimeout(()=>{
        $("#errorModal").modal("hide");
      }, 3000)
    });

  }



  docPreGen() {

    this.payslipReportChooseFilter = false;

    switch (this.docGenReport.name) {
      case 'SO1 Report':
        this.preSO1Report(false)
      break;
      case 'SO2 Report':
        this.preSO2Report(false)
      break;
      case 'Audit Report by Employee':
        this.preAuditReport(false)
      break;
      case 'Audit Report by Month':
        this.preAuditReportB(false)
      break;
      case 'Year End Summary Report':
        this.preYearEndSummary(false)
      break;
      default:
      break;
    }

    // this.auditSelect = false;
    // this.auditSelect2 = false;

  }
  setReportFilter () {
    let finalFilterData: any = this.filterInputs.map(x=>x.data)
    for (const elem of finalFilterData) {
      let refProp = ""+elem.Property.split(" ")[0]+"_"+elem.Property.split(" ")[0]+""
      elem.Property = this.filterPropPathRef[refProp]
    }
    finalFilterData = JSON.stringify(finalFilterData);
    this.finalFilterData = finalFilterData;

    // console.log('finalFilterData',finalFilterData);

    this.payslipReportChooseFilter = false;


    switch (this.docGenReport.name) {
      case 'SO1 Report':
        this.preSO1Report(true)
      break;
      case 'SO2 Report':
        this.preSO2Report(true)
      break;
      case 'Audit Report by Employee':
        this.preAuditReport(true)
      break;
      case 'Audit Report by Month':
        this.preAuditReportB(false)
      break;
      case 'Year End Summary Report':
        this.preYearEndSummary(false)
      break;
      default:
      break;
    }
    $("#reportFilterModal").modal("hide");

    this.filterInputs = [];
    this.filterProperties1 = [];
    // this.auditSelect = false;
    // this.auditSelect2 = false;


  }

  reportHandler(report,filter) {

    // console.log('reportHandler', report);

    this.docGenReport = report;

    if (filter === true) {

      this.filterInputs.push(
        {
          inputType: "string",
          data: {
            Property: "",
            Condition: "",
            Value: "",
            Type: "",
          }
        }
      )

      this.filterProperties1.push(this.filterProperties2)

      $("#reportFilterModal").modal("show");

    } else {

      this.docPreGen()

    }

  }
  closeReportFiltering() {
    $("#reportFilterModal").modal("hide");
    this.filterInputs = [];
    this.filterProperties1 = [];
  }
  handleFilterInputTypeChange(index,value) {
    if (
      this.filterInputs[index].data.Property === 'First Name' ||
      this.filterInputs[index].data.Property === 'Last Name' ||
      // this.filterInputs[index].data.Property === 'Employee ID' ||
      this.filterInputs[index].data.Property === 'Occupation Code' ||
      this.filterInputs[index].data.Property === 'Department Code'
    ) {
      this.filterInputs[index].inputType = 'string';
    }
    else
    {
      this.filterInputs[index].inputType = 'number';
    }


    let propsInUse = [];
    for (const input of this.filterInputs) {
      if (input.data.Property !== "") {
        let index2 = this.filterInputs.indexOf(input);
        propsInUse.push({index:index2,prop:input.data.Property})
      }
    }

    for (let list of this.filterProperties1) {
      let index3 = this.filterProperties1.indexOf(list);
      let list2 = this.filterProperties2;
      for (const prop of propsInUse) {
        if (prop.index !== index3) {

          let index4 = list.indexOf(prop.prop)
          list2 = list2.filter(x=>x !== prop.prop)
          this.filterProperties1[index3] = list2
        }
      }
    }

    // console.log('change: filterProperties1',this.filterProperties1);

  }
  addFilterInput(index,type) {

    if (this.filterInputs.length < 6) {
      this.filterInputs[index].data.Type = type;

      let array = [];
      // let array = this.filterProperties2
      // for (const input of this.filterInputs) {
      //   let removeIndex = array.indexOf(input.data.Property)
      //   if (removeIndex && removeIndex > -1) {
      //     array = array.filter(x=>x !== input.data.Property)
      //   }
      // }

      this.filterInputs.push(
        {
          inputType: "string",
          data: {
            Property: "",
            Condition: "",
            Value: "",
            Type: "",
          }
        }
      )

      this.filterProperties1.push(array)


      let propsInUse = [];
      for (const input of this.filterInputs) {
        if (input.data.Property !== "") {
          let index2 = this.filterInputs.indexOf(input);
          propsInUse.push({index:index2,prop:input.data.Property})
        }
      }
      // console.log('propsInUse',propsInUse,'filterProperties1',this.filterProperties1);

      for (let list of this.filterProperties1) {
        let index3 = this.filterProperties1.indexOf(list);
        let list2 = this.filterProperties2;
        for (const prop of propsInUse) {
          if (prop.index !== index3) {
            // console.log('remove',prop.prop,'from proplist',index3);

            let index4 = list.indexOf(prop.prop)
            list2 = list2.filter(x=>x !== prop.prop)
            this.filterProperties1[index3] = list2
          }
        }
      }

    }

    // console.log('add: filterProperties1',this.filterProperties1);

  }
  removeFilterInput(index) {

    let prop = this.filterInputs[index].data.Property;
    let targetInput = this.filterInputs[index];
    let targetPropList = this.filterProperties1[index];


    this.filterInputs = this.filterInputs.filter(x=>x !== targetInput)
    this.filterProperties1 = this.filterProperties1.filter(x=>x !== targetPropList)
    // this.filterInputs.splice(index,1)
    // this.filterProperties1.splice(index,1)


    for (const prop2 of this.filterProperties1) {
      prop2.push(prop)
    }


    let propsInUse = [];
    for (const input of this.filterInputs) {
      if (input.data.Property !== "") {
        let index2 = this.filterInputs.indexOf(input);
        propsInUse.push({index:index2,prop:input.data.Property})
      }
    }
    // console.log('propsInUse',propsInUse,'filterProperties1',this.filterProperties1);

    for (let list of this.filterProperties1) {
      let index3 = this.filterProperties1.indexOf(list);
      let list2 = this.filterProperties2;
      for (const prop of propsInUse) {
        if (prop.index !== index3) {
          // console.log('remove',prop.prop,'from proplist',index3);

          let index4 = list.indexOf(prop.prop)
          list2 = list2.filter(x=>x !== prop.prop)
          this.filterProperties1[index3] = list2
        }
      }
    }

    // console.log('remove: filterProperties1',this.filterProperties1);



  }


  preSO1Report(filter) {

    if (filter === true) {

    }
    else {

      // this.loading = true;
      $("#errorModal").modal("show");
      this.s01Select = true;

        // this.api.getOpenPayrolls(this.id).subscribe(next => {
        //
        //   // console.log('s01 payroll select getOpenPayrolls',next);
        //
        //   for (const proll of next) {
        //     proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
        //     proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
        //     proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)
        //
        //     this.allPayrolls.push(proll)
        //
        //   }
        //
        //
        // },
        // error => {
        //   this.loading = false;
        //   console.log('api request error',error);
        // })
        //
        //
        // this.api.getClosedPayrolls(this.id).subscribe(next => {
        //
        //   // console.log('s01 payroll select getClosedPayrolls',next);
        //
        //
        //   for (const proll of next) {
        //     proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
        //     proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
        //     proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)
        //
        //     this.allPayrolls.push(proll)
        //   }
        //
        //   this.loading = false;
        //
        //   // this.allPayrolls = this.allPayrolls.filter(elem => elem.type === this.selectedPayroll.type)
        //
        //   // $("#errorModal").modal("hide");
        //
        // },
        // error => {
        //   this.loading = false;
        //   console.log('api request error',error);
        // });

    }


  }
  selectMultipleS01(args) {
    this.selectedS01.push(args)
  }

  selectSO1 () {

    this.s01Select = false;
    this.loading = true;
    $("#errorModal").modal("show");

    let requestData = this.selectedS01.map(elem=> elem = {payrollId:elem.id,type:elem.type})

    this.api.getSO1ScheduleReport(JSON.stringify(requestData)).subscribe(next => {

      this.s01PaySummary = next;
      this.s01PaySummaryLength = next.length;
      this.loading = false;
      $("#errorModal").modal("hide");
      this.startGenerateSO1()

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: selectSO1!"
      }
      $("#errorModal").modal("show");
    })


  }
  startGenerateSO1() {
    $('.actionsBox').hide();
    // this.loading = true;
    // $("#errorModal").modal("show");

    let newPaySummary = [];
    let paySum = this.s01PaySummary;

    for (const elem of paySum) {
      let newPaySumItem;
      for (const elem2 of this.employees) {
        if (elem.employeeId === elem2.employeeId) {
          newPaySumItem = Object.assign({}, elem, elem2);
        }
      }
      newPaySummary.push(newPaySumItem)
    }

    let XL_row_object;

    var workbook = XLSX.utils.book_new();
    var ws_name = "Sheet1";

    var ws_data1 = [
      [
        "Surname",
        "Firstname",
        "Middle Initials",
        "Employee TRN",
        "Employee NIS",
        "Gross Emoluments Received in Cash \n Salaries, Wages, Fees, Bonuses, Overtime pay, Commissions, etc...",
        "Gross Emoluments Received in Kind",
        "Superannuation / Pension, Agreed Expenses, Employees Share Ownership Plan",
        "Number of weekly NIS and NHT Contributions for the month",
        "NIS \n (Employee's Rate + Employer's Rate) x (Total Gross Emoluments)",
        "NHT \n (Employee's Rate + Employer's Rate) x (Total Gross Emoluments)",
        "Education Tax \n (Employee's Rate + Employer's Rate) x (Total Gross Emoluments after Deductions and NIS)",
        "PAYE Income Tax / (Refunds) \n (Rate) x (Total Gross Emoluments after Deductions, NIS and Nil-Rate (NR))",
      ],
    ];
    var ws = XLSX.utils.aoa_to_sheet(ws_data1);
    XLSX.utils.book_append_sheet(workbook, ws, ws_name);

    for (const [key, value] of Object.entries(paySum)) {
      let empRow = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined];
      for (const [key2, value2] of Object.entries(value)) {

        let xlRowDataRef = [
          'lastName',
          'firstName',
          'initials',
          'trn',
          'nis',
          'gross',
          'grossKind',
          'pension',
          'weeklys',
          'nisTax',
          'nhtTax',
          'edTax',
          'payeTax',
        ];
        let propKey = key2
        let propVal: any = value2
        if (key2 === 'sO1Calc') {
          for (const [key3, value3] of Object.entries(value2)) {
            propKey = key3;
            if (propKey === 'weeklyNIS') {
              propVal = value3;
            }

            else {
              propVal = value3.toFixed(2);
            }

            if (propKey === 'nis') {
              propKey = 'nis2';
            }

            if (xlRowDataRef.includes(propKey)) {
              let indx2 = xlRowDataRef.indexOf(propKey);
              empRow[indx2] = propVal;
            }
          }
        }

        else {
          if (xlRowDataRef.includes(propKey)) {
            let indx = xlRowDataRef.indexOf(propKey);
            if (
              propKey === 'gross' ||
              propKey === 'net' ||
              propKey === 'nisTax' ||
              propKey === 'nhtTax' ||
              propKey === 'edTax' ||
              propKey === 'payeTax' ||
              propKey === 'pension'
            ) {
              empRow[indx] = propVal.toFixed(2);
            } else {
              empRow[indx] = propVal;
            }
          }
        }

      }
      // console.log('----------final emp row-----------',empRow);
      XLSX.utils.sheet_add_aoa(ws, [empRow], {origin: -1});
    }

    // XLSX.utils.sheet_add_aoa(ws, [[4,5,6,7,8]], {origin: -1});
    XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    // console.log('XL_row_object',XL_row_object);

    XLSX.writeFile(workbook, 'SO1Schedule.xlsx');



  }

  preSO2Report(filter) {

    if (filter === true) {

    }
    else {

      let year = new Date().toISOString().split("-")[0]
      this.S02ASCIILink = `https://payrollapidev.caribbeanhrsolutions.com/api/report/SO2ASCII/${this.id}/${year}`
      this.showS02Link = true;

      $("#specialModal").modal("show");

    }


    // this.loading = true;
    // $("#errorModal").modal("show");
    //
    // let year = new Date().toISOString().split("-")[0]
    //
    // this.api.getS02ASCII(this.id,year).subscribe(next => {
    //
    //   this.loading = false;
    //   $("#errorModal").modal("hide");
    //   console.log('getS02ASCII',next);
    //
    //
    //   this.generateS02ASCII(next)
    //
    // },
    // error => {
    //
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error!"
    //   }
    //   $("#errorModal").modal("show");
    // })

  }
  toggleS02() {

    this.showS02Link = false;
    $("#specialModal").modal("hide");

  }

  selectMultipleAuditEmployee(args) {
    this.selectedAuditEmployee.push(args)
  }
  preAuditReport(filter) {
    // this.auditSelect = false;
    this.auditSelect2 = false;
    this.yearEndSelect = false;

    this.filterAudit = filter;
    // this.loading = true;
    $("#errorModal").modal("show");
    this.auditSelect = true;

  }
  selectAuditPayrollType() {

    // console.log('selectAuditPayroll',payroll);

    this.auditSelect = false;
    let type = this.auditPayrollType;


    if (this.filterAudit === true) {

    }
    else {

      this.loading = true;
      // $("#errorModal").modal("show");


      let requestData = this.selectedAuditEmployee.map(elem=> elem = {PayrollId:elem.id,Type:elem.type})
      this.selectedAuditEmployee = []
      this.api.getAuditReportByEmployeePdf(JSON.stringify(requestData)).subscribe(next => {

        console.log('getAuditByEmployeeReportPdf',next);

        this.loading = false;

        if (next.status) {
          this.modalMessage = next.status;
          $("#errorModal").modal("show");
        }
        else {
          this.auditReportByEmployeePdf(next)
          $("#errorModal").modal("hide");
        }


      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getAuditReportByEmployeePdf"
        }
        $("#errorModal").modal("show");
      })

    }

    this.auditPayrollType = "";
    this.filterAudit = false;


  }
  auditReportByEmployeePdf(args) {



    let company = this.company;
    let totalGross = 0;
    let totalTaxable = 0;
    let totalPayeEmployee = 0;
    let totalNisEmployee = 0;
    let totalNisEmployer = 0;
    let totalNhtEmployee = 0;
    let totalNhtEmployer = 0;
    let totalEduEmployee = 0;
    let totalEduEmployer = 0;
    let totalHeartEmployer = 0;
    let totalPensionEmployee = 0;
    let totalPensionEmployer = 0;
    let totalNetPay = 0;
    let totalOtherDeductions = 0;
    let employeeCount = 0;

    let auditReport = [];

    let startDate = "...";
    let endDate = "...";
    // let startDate = payroll.payCycle.startDate;
    // let endDate = payroll.payCycle.endDate;


    let auditBody = [
      {
        text: 'Audit Report',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        // margin: [0, 5]
      },
      {
        text: ''+company.companyName+'',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        margin: [0, 5]
      },

      {
        text: 'AUDIT REPORT FOR PERIOD '+startDate+' TO '+endDate+'',
        // text: 'AUDIT REPORT FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
        fontSize: 9,
        alignment: 'center',
      },

      {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 750, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

      {
        text: 'FILTER CRITERIA: ALL RECORDS',
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5]
      },


      {
        style: 'tableExample',
        // margin: [0, -5],
        table: {
          widths: ['6%','10%','6%','6%','6%','6%','6%','6%','6%','6%','6%','6%','6%','6%','6%','6%'],
          body: auditReport
        },
      },


    ];

    auditReport.push(
      [
        {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EMPLOYEE STATUTORY', colSpan: 4, fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EMPLOYER STATUTORY', colSpan: 4, fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
      ],
      [
        {text: 'Pay Date',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Employee',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Gross',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Taxable Gross',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'PAYE', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NIS', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NHT', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EDU', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Pension',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Other Deduc',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Net Pay',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NIS', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NHT', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EDU', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Heart', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Pension',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
      ]
    )


    for (const elem of args) {



      for (const audit of elem.audits) {


        let otherDeducTotal = 0;
        if (audit.employeeAudit.deductions[0]) {
          for (const deduc of audit.employeeAudit.deductions) {
            otherDeducTotal += deduc.amount;
          }
        }

        let netPay = audit.employeeAudit.net;
        let paye = audit.employeeAudit.statutory.find(x=>x.tax === 'PAYE')
        let nis = audit.employeeAudit.statutory.find(x=>x.tax === 'NIS')
        let nht = audit.employeeAudit.statutory.find(x=>x.tax === 'NHT')
        let edu = audit.employeeAudit.statutory.find(x=>x.tax === 'Ed. Tax')
        let heart = audit.employeeAudit.statutory.find(x=>x.tax === 'HEART')
        let totalPay = audit.employeeAudit.totalGross;
        // for (const pay of elem.pays) {
        //   totalPay += pay.amount;
        // }


        auditReport.push(
          [
            {text: ''+audit.payDate.split('T')[0]+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+audit.employeeAudit.employeeId+' '+audit.employeeAudit.employee+' '+elem.lastName+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+audit.employeeAudit.generalGross.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+audit.employeeAudit.taxableGross.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+paye.employeeAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+nis.employeeAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+nht.employeeAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+edu.employeeAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+audit.employeeAudit.pensionEmployee.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+otherDeducTotal.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+netPay.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+nis.employerAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+nht.employerAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+edu.employeeAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+heart.employerAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: '$ '+audit.employeeAudit.pensionEmployer.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          ]
        )

        totalGross += audit.employeeAudit.generalGross;
        totalPayeEmployee += paye.employeeAmount;
        totalNisEmployee += nis.employeeAmount;
        totalNhtEmployee += nht.employeeAmount;
        totalEduEmployee += edu.employeeAmount;
        totalPensionEmployee += audit.employeeAudit.pensionEmployee;


        totalTaxable += audit.employeeAudit.taxableGross;
        totalNisEmployer += nis.employeeAmount;
        totalNhtEmployer += nht.employeeAmount;
        totalEduEmployer += edu.employeeAmount;
        totalHeartEmployer += heart.employerAmount;
        totalPensionEmployer += audit.employeeAudit.pensionEmployer;


        totalNetPay += netPay;
        totalOtherDeductions += otherDeducTotal;
        employeeCount ++;

      }

    }

    auditReport.push(
      [
        {text: 'GRAND TOTAL',fontSize: 9, border: [false, true, false, true], alignment: 'left', bold: true},
        {text: '...',fontSize: 9, border: [false, true, false, true], alignment: 'left', bold: true},
        {text: ''+totalGross.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalTaxable.toFixed(2)+'', fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalPayeEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNisEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNhtEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalEduEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalPensionEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalOtherDeductions.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNetPay.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNisEmployer.toFixed(2)+'', fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNhtEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalEduEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalHeartEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalPensionEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
      ]
    )

    // auditReport.push(
    //   [
    //     {text: '...',fontSize: 9, border: [false, false, false, true], alignment: 'left'},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //   ]
    // )


    auditBody.push({
      style: 'tableExample',
      table: {
        widths: ['10%','20%','10%','10%','50%'],
        body: [
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL EMPLOYEES:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: ''+employeeCount+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL HEART:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+totalHeartEmployer.toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL NIS:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalNisEmployer+totalNisEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL NHT:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalNhtEmployer+totalNhtEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL EDUC TAX:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalEduEmployer+totalEduEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL PAYE:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+totalPayeEmployee.toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL PENSION:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalPensionEmployee+totalPensionEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
        ]
      },
      // layout: 'noBorders'
    })

    let user = this.currentUser;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {

      pageOrientation: 'landscape',


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      pageMargins: [ 10, 10, 10, 10 ],


      content: auditBody,


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }

  preAuditReportB(filter) {

    this.auditSelect = false;
    this.yearEndSelect = false;
    // this.auditSelect2 = false;

    this.filterAudit = filter;
    // this.loading = true;
    $("#errorModal").modal("show");
    this.auditSelect2 = true;

  }
  selectAuditBPayrollType() {

    // console.log('selectAuditPayroll',payroll);

    this.auditSelect2 = false;
    let type = this.auditBPayrollType;


    if (this.filterAudit === true) {

    }
    else {

      this.loading = true;
      // $("#errorModal").modal("show");

      this.api.getAuditReportByMonthPdf(this.company.id,type).subscribe(next => {

        console.log('getAuditReportByMonthPdf',next);

        this.loading = false;


        if (next.status) {
          this.modalMessage = next.status;
          $("#errorModal").modal("show");
        }
        else {
          this.auditReportByMonthPdf(next)
          $("#errorModal").modal("hide");
        }



      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getAuditReportByMonthPdf"
        }
        $("#errorModal").modal("show");
      })

    }

    this.auditBPayrollType = "";
    this.filterAudit = false;


  }
  auditReportByMonthPdf(args) {


    let company = this.company;
    let totalGross = 0;
    let totalTaxable = 0;
    let totalPayeEmployee = 0;
    let totalNisEmployee = 0;
    let totalNisEmployer = 0;
    let totalNhtEmployee = 0;
    let totalNhtEmployer = 0;
    let totalEduEmployee = 0;
    let totalEduEmployer = 0;
    let totalHeartEmployer = 0;
    let totalPensionEmployee = 0;
    let totalPensionEmployer = 0;
    let totalNetPay = 0;
    let totalOtherDeductions = 0;
    let employeeCount = 0;

    let auditReport = [];

    let startDate = "...";
    let endDate = "...";
    // let startDate = payroll.payCycle.startDate;
    // let endDate = payroll.payCycle.endDate;


    let auditBody = [
      {
        text: 'Audit Report',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        // margin: [0, 5]
      },
      {
        text: ''+company.companyName+'',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        margin: [0, 5]
      },

      {
        text: 'AUDIT REPORT FOR PERIOD '+startDate+' TO '+endDate+'',
        // text: 'AUDIT REPORT FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
        fontSize: 9,
        alignment: 'center',
      },

      {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 750, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

      {
        text: 'FILTER CRITERIA: ALL RECORDS',
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5]
      },


      {
        style: 'tableExample',
        table: {
          widths: ['6%','7%','7%','7%','7%','7%','7%','7%','7%','7%','7%','6%','6%','6%','6%'],
          body: auditReport
        },
      },


    ];

    auditReport.push(
      [
        {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        // {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EMPLOYEE STATUTORY', colSpan: 4, fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EMPLOYER STATUTORY', colSpan: 4, fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
      ],
      [
        {text: 'Month',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        // {text: 'Employee',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Gross',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Taxable Gross',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'PAYE', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NIS', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NHT', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EDU', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Pension',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Other Deduc',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Net Pay',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NIS', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'NHT', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'EDU', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Heart', fillColor: '#dddddd', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
        {text: 'Pension',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
      ]
    )



    for (const elem of args) {

      // if (elem.deductions[0]) {
      //   for (const deduc of elem.deductions) {
      //     otherDeducTotal += deduc.amount;
      //   }
      // }
      // let netPay = elem.totalEarnings - elem.totalDeductions;
      // let paye = elem.statutory.find(x=>x.tax === 'PAYE')
      // let nis = elem.statutory.find(x=>x.tax === 'NIS')
      // let nht = elem.statutory.find(x=>x.tax === 'NHT')
      // let edu = elem.statutory.find(x=>x.tax === 'Ed. Tax')
      // let heart = elem.statutory.find(x=>x.tax === 'HEART')

      // for (const pay of elem.pays) {
      //   totalPay += pay.amount;
      // }


      auditReport.push(
        [
          {text: ''+elem.month+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          // {text: ''+elem.firstName+' '+elem.lastName+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalGeneralGross.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalGross.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalPAYETax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalNISTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalNHTTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalEdTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalPension.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalNonStatDeductions.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employeeTotals.totalNet.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employerTotals.totalNISTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employerTotals.totalNHTTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employerTotals.totalEdTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employerTotals.totalHEARTTax.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
          {text: '$ '+elem.employerTotals.totalPension.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
        ]
      )

      totalGross += elem.employeeTotals.totalGeneralGross;
      totalPayeEmployee += elem.employeeTotals.totalPAYETax;
      totalNisEmployee += elem.employeeTotals.totalNISTax;
      totalNhtEmployee += elem.employeeTotals.totalNHTTax;
      totalEduEmployee += elem.employeeTotals.totalEdTax;
      totalPensionEmployee += elem.employeeTotals.totalPension;


      totalTaxable += elem.employeeTotals.totalGross;
      totalNisEmployer += elem.employerTotals.totalNISTax;
      totalNhtEmployer += elem.employerTotals.totalNHTTax;
      totalEduEmployer += elem.employerTotals.totalEdTax;
      totalHeartEmployer += elem.employerTotals.totalHEARTTax;
      totalPensionEmployer += elem.employerTotals.totalPension;


      totalNetPay += elem.employeeTotals.totalNet;
      totalOtherDeductions += elem.employeeTotals.totalNonStatDeductions;
      employeeCount ++;

    }


    auditReport.push(
      [
        {text: 'GRAND TOTAL',fontSize: 9, border: [false, true, false, true], alignment: 'left', bold: true},
        {text: ''+totalGross.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalTaxable.toFixed(2)+'', fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalPayeEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNisEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNhtEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalEduEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalPensionEmployee.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalOtherDeductions.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNetPay.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNisEmployer.toFixed(2)+'', fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalNhtEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalEduEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalHeartEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
        {text: ''+totalPensionEmployer.toFixed(2)+'',fontSize: 9, alignment: 'left',border: [false, true, false, true], bold: true},
      ]
    )

    // auditReport.push(
    //   [
    //     {text: '...',fontSize: 9, border: [false, false, false, true], alignment: 'left'},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '', fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //     {text: '',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
    //   ]
    // )


    auditBody.push({
      style: 'tableExample',
      table: {
        widths: ['5%','15%','15%','10%','45%'],
        body: [
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL EMPLOYEES:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: ''+employeeCount+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL HEART:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+totalHeartEmployer.toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL NIS:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalNisEmployer+totalNisEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL NHT:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalNhtEmployer+totalNhtEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL EDUC TAX:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalEduEmployer+totalEduEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL PAYE:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+totalPayeEmployee.toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
          [
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: 'TOTAL PENSION:', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '$ '+(totalPensionEmployee+totalPensionEmployee).toFixed(2)+'', fontSize: 9, border: [false, false, false, false], margin: [1,0], alignment: 'right'},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
            {text: '', fontSize: 9, bold: true, border: [false, false, false, false]},
          ],
        ]
      },
      // layout: 'noBorders'
    })

    let user = this.currentUser;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {

      pageOrientation: 'landscape',


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      pageMargins: [ 10, 10, 10, 10 ],


      content: auditBody,


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();



  }


  preYearEndSummary(filter) {

    this.auditSelect = false;
    // this.yearEndSelect = false;
    this.auditSelect2 = false;

    this.filterAudit = filter;
    // this.loading = true;
    $("#errorModal").modal("show");
    this.yearEndSelect = true;

  }
  async selectYearEndPayrollType() {

    // console.log('selectAuditPayroll',payroll);

    this.yearEndSelect = false;
    let type = this.yearEndPayrollType;


    if (this.filterAudit === true) {

    }
    else {

      this.loading = true;
      // $("#errorModal").modal("show");


      try {

        let result = await this.api.getYearEndSummaryReportPdf(this.company.id,type).toPromise();

        window.location.href = result.file;

        this.loading = false;
        $("#errorModal").modal("hide");



        // this.YearEndLink = result.file;
        // $("#specialModal").modal("show");



      }
      catch (error) {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getYearEndSummaryReportPdf!"
        }
        $("#errorModal").modal("show");
      }


      // this.api.getYearEndSummaryReportPdf(this.company.id,type).subscribe(next => {
      //
      //   console.log('getYearEndSummaryReportPdf',next);
      //
      //   this.loading = false;
      //
      //
      //   if (next.status) {
      //     this.modalMessage = next.status;
      //     $("#errorModal").modal("show");
      //   }
      //   else {
      //     this.yearEndSummaryPdf(next)
      //     $("#errorModal").modal("hide");
      //   }
      //
      //
      //
      // },
      // error => {
      //   this.loading = false;
      //   this.error = true;
      //   if (error.error) {
      //     this.modalMessage = error.error.status
      //   } else {
      //     this.modalMessage = "Error: getYearEndSummaryReportPdf"
      //   }
      //   $("#errorModal").modal("show");
      // })

    }

    this.yearEndPayrollType = "";
    this.filterAudit = false;


  }

  yearEndSummaryPdf(args) {

  }



  closeModal () {

    this.filterAudit = false;
    this.s01Select = false;
    this.auditSelect = false;
    this.auditSelect2 = false;
    this.yearEndSelect = false;
    this.selectedS01 = [];
    this.selectedAuditEmployee = []
  }

  // toggleYearEnd() {
  //   $("#specialModal").modal("hide");
  //   this.showYearEndLink = false;
  // }




}
