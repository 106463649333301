import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/Models/Company';
import { Employee } from 'src/app/Models/Employee';
import { EmployeeDepartment } from 'src/app/Models/EmployeeDepartment';
import { EmployeeOccupation } from 'src/app/Models/EmployeeOccupation';
import { EmployeeHealth } from 'src/app/Models/EmployeeHealth';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { Defaults } from 'src/app/Models/Defaults';

declare const $: any;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-details-company',
  templateUrl: './details-company.component.html',
  styleUrls: ['./details-company.component.css']
})
export class DetailsCompanyComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;


  public loading: Boolean = false;
  public modalMessage: string = '';
  public error: boolean = false;;

  company: Company = Defaults.CompanyDefaults();
  employee: Employee = Defaults.EmployeeDefault();
  employees: Array<Employee> = [];
  employeeSrch: Array<Employee> = [];
  address: Address = Defaults.AddressDefaults();
  ach: Ach = Defaults.achDefault();
  ach1: Ach = {} as any;


  public id: string = '';
  // employeeSrch: Array<any> = [];
  departmentSrch: Array<any> = [];
  rows = [];
  public searchField: string = 'firstName';
  public employeeSearchQuery: string = '';
  public toggleSearch: boolean = false;

  public totalEmployeeCount: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];
  public showPagination: boolean = true;

  public sortDirection; string = "Ascending";

  actionsBoxEmployee: string = '';

  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute
  ) {

  }


  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');

    localStorage.setItem('companySelected', 'true')

    $('.actionsBox').toggle();

    // const routeParams = this.route.snapshot.paramMap;
    // this.id = routeParams.get('id').split('=')[1];

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getCompany(this.id).subscribe(companyData => {

      // console.log('companyData',companyData);

      this.company = companyData;
      this.address = companyData.address;
      this.ach = companyData.ach;
      // this.ach1 = companyData.ach[0];

      // this.api.getCompanyBaseImage(companyData.id,companyData.type).subscribe(companyLogo =>{
      //   this.companyLogoBase64 = companyLogo;
      // });

      this.loading = false;
      $("#errorModal").modal("hide");
      $(".logo").attr("src", companyData.logo);
      // console.log('companyData.logo',companyData.logo);

      // this.getEmployees();
      this.getEmployeesByPage(true,'');

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCompany!"
      }
      $("#errorModal").modal("show");
    })


  }


  getEmployeesByPage(init,direction) {

    this.loading = true;
    $("#errorModal").modal("show");

    let start = 0;
    let count = 20;
    if (direction === 'next') {
      this.currentPage++
    }
    if (direction === 'previous') {
      this.currentPage--
    }
    start = (this.currentPage-1)*20;
    if (init === true) {
      start = 0;
    }
    this.showPagination = true;

    this.api.getEmployeesByPage(start,count,this.id).subscribe(


      next => {
        this.loading = false;
        $("#errorModal").modal("hide");


        if (init === true) {
          this.currentPage = 1;
          this.totalPages = Math.ceil(next.count/20);
          this.pagesArray = [];
          for (let index = 0; index < this.totalPages; index++) {
            this.pagesArray.push("")
          }
        } else {



          if (next.count !== this.totalEmployeeCount) {
            this.totalPages = Math.ceil(next.count/20);
            console.log('emp count has changed');
            this.pagesArray = [];
            for (let index = 0; index < this.totalPages; index++) {
              this.pagesArray.push("")
            }
          }

        }
        console.log('getEmployeesByPage',next);

        this.employeeSrch = next.data;
        this.employees = next.data;
        this.totalEmployeeCount = next.count;

        this.loading = false;
        $("#errorModal").modal("hide");


      },
      error => {
        if (direction === 'next') {
          this.currentPage--
        }
        if (direction === 'previous') {
          this.currentPage++
        }
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeesByPage!";
        }
        $("#errorModal").modal("show");
      }
    )

  }

  stepPage(args) {

    if (
      args === 'previous' &&
      this.currentPage > 1
    ) {
      this.getEmployeesByPage(false,'previous');
    }

    if (args === 'next' && this.currentPage < this.totalPages) {
      this.getEmployeesByPage(false,'next');
    }
  }

  filterEmployee(val) {

    this.toggleSearch = true;

    // $('.actionsBox').hide();
    // // this.rows.splice(0, this.rows.length);
    // let field = this.searchField;
    //
    // let temp = this.employees.filter(function(d) {
    //   val = val.toLowerCase();
    //   return d[field].toLowerCase().indexOf(val) !== -1 || !val;
    // });
    //
    // this.employeeSrch = [...temp];

    this.employeeSearchQuery = val;

    this.getEmployeesBySearch(val)

  }

  getEmployeesBySearch(query) {


    if (query.length >= 3) {

      this.showPagination = false;

      // this.loading = true;
      // $("#errorModal").modal("show");

      this.api.getEmployeesBySearch(query,this.id).subscribe(next =>{

        this.employeeSrch = [];
        for (const elem of next) {
          this.employeeSrch.push(elem.data)
        }
        this.employeeSrch = next.map(x=>x = x.data);

        // console.log('getEmployeesBySearch',next,this.employeeSrch);

        // this.loading = false;
        // $("#errorModal").modal("hide");


      },
      error => {
        this.loading = false;
        this.modalMessage = "Error: getEmployeesBySearch!";
        $("#errorModal").modal("show");
      })

    }

    if (query.length === 0) {
      this.clearSearch()
    }

  }

  clearSearch() {
    this.toggleSearch = false;
    this.getEmployeesByPage(true,'');
  }

  selectSearchField(args) {
    this.searchField = args
  }

  filterDepartment(val) {

    // this.rows.splice(0, this.rows.length);

    let temp = this.departmentSrch.filter(function(d) {
      val = val.toLowerCase();
      return d.userName.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows.push(...temp);
  }

  preSortEmployees() {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getEmployees(this.id).subscribe(next => {

      this.employees = next;
      this.employeeSrch = next;

      // this.loading = false;
      // $("#errorModal").modal("hide");
      this.sortEmployees()
    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployees!"
      }
      $("#errorModal").modal("show");
    })

  }

  sortEmployees() {

    let direction = this.sortDirection;

    console.log('num3',this.employeeSrch.sort((a, b) => (a[this.searchField] > b[this.searchField]) ? 1 : -1));
    console.log('num4',this.employeeSrch.sort((a, b) => a[this.searchField] - b[this.searchField]));
    console.log('num5',this.employeeSrch.sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0)));

    if (this.searchField.split(".")[2]) {

      if (direction === 'Ascending') {
        this.employeeSrch = this.employees.sort((a,b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] > b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? 1 : ((b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] > a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? -1 : 0))
      }
      if (direction === 'Descending') {
        this.employeeSrch = this.employees.sort((a,b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] < b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? 1 : ((b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] > a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? -1 : 0))
      }
      // if (direction === 'Ascending') {
      //   this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] > b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? 1 : -1);
      // }
      // if (direction === 'Descending') {
      //   this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] < b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? 1 : -1);
      // }

    } else {
      if (direction === 'Ascending') {
        this.employeeSrch = this.employees.sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0))
      }
      if (direction === 'Descending') {
        this.employeeSrch = this.employees.sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] < a[this.searchField]) ? -1 : 0))
      }
      // if (direction === 'Ascending') {
      //   this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField] > b[this.searchField]) ? 1 : -1);
      // }
      // if (direction === 'Descending') {
      //   this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField] < b[this.searchField]) ? 1 : -1);
      // }
    }


    this.loading = false;
    $("#errorModal").modal("hide");

  }
  selectSortDirection(args) {
    this.sortDirection = args;
  }

  actionsBox (event,employee) {

    this.actionsBoxEmployee = employee;


    $('.actionsBox').css({'top':event.pageY-100,'left':event.pageX-100})
    $('.actionsBox').toggle();
  }

  createEmployee () {
    this.router.navigate(['/pages/company', this.id, 'employee/upload']);
  }

  employeeAction (action) {
    if (action === 'edit') {
      this.router.navigate(['/pages/company', this.id, 'employee/edit', this.actionsBoxEmployee]);
    }
    if (action === 'delete') {
      this.deleteEmployee()
    }

  }

  goToEdit() {

    document.location.href = `/pages/company/create?id=${this.id}`

  }

  deleteEmployee() {


    let emp = this.employeeSrch.find(x=>x.id === this.actionsBoxEmployee)
    // console.log('deleteEmployee', emp);


    this.loading = true;
    $("#errorModal").modal("show");

    this.api.deleteEmployee(emp.employeeId).subscribe(next =>{

      console.log('deleteEmployee',next);

      this.loading = false;
      this.modalMessage = 'Employee Deleted';
      // $("#errorModal").modal("hide");

      setTimeout(()=>{
        this.getEmployeesByPage(true,'');
      }, 3000);


    },
    error => {
      this.loading = false;
      this.modalMessage = "Error: deleteEmployee!";
      $("#errorModal").modal("show");
    })

  }


}
