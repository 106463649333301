import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppService } from 'src/app/app.service';
import { Company } from 'src/app/Models/Company';
import { Code } from 'src/app/Models/Code';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';
import { Defaults } from 'src/app/Models/Defaults';
import { ChartOfAccounts } from 'src/app/Models/ChartOfAccounts';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.css']
})
export class CodesComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;

  public url: string;

  public id: string = '';

  public loading: Boolean = false;
  public modalMessage: string = '';

  public codeType: string = "";
  public codeType2: string = "";
  public setting: string = 'list';
  public selectedTab: string = 'department';

  allCodes: Array<any> = [];
  codesToUpload: Array<any> = [];

  departmentCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  healthCode: HealthCode = Defaults.HealthCodeDefault();
  occupationCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  transactionCode: TransactionCode = Defaults.TransactionCodeDefault();
  deductionCode: DeductionCode = Defaults.DeductionCodeDefault();
  loanCode: LoanCode = Defaults.LoanCodeDefault();
  bankCode: BankCode = Defaults.BankCodeDefault();
  pensionCode: PensionCode = Defaults.PensionCodeDefault();

  departmentCodes: Array<DepartmentOccupationCode> = [];
  departmentCodeSearch: Array<DepartmentOccupationCode> = [];
  healthCodes: Array<HealthCode> = [];
  healthCodeSearch: Array<HealthCode> = [];
  occupationCodes: Array<DepartmentOccupationCode> = [];
  occupationCodeSearch: Array<DepartmentOccupationCode> = [];
  transactionCodes: Array<TransactionCode> = [];
  transactionCodeSearch: Array<TransactionCode> = [];
  deductionCodes: Array<DeductionCode> = [];
  deductionCodeSearch: Array<DeductionCode> = [];
  loanCodes: Array<LoanCode> = [];
  loanCodeSearch: Array<LoanCode> = [];
  pensionCodeSearch: Array<PensionCode> = [];
  bankCodes: Array<BankCode> = [];
  bankCodeSearch: Array<BankCode> = [];


  chartOfAccounts: ChartOfAccounts = Defaults.ChartOfAccountsDefault();
  chartsOfAccounts: Array<ChartOfAccounts> = [];

  chartsOfAccountsExpenses: Array<ChartOfAccounts> = [];
  chartsOfAccountsLiabilities: Array<ChartOfAccounts> = [];
  chartsOfAccountsAssets: Array<ChartOfAccounts> = [];

  actionsBoxState: Boolean = false;

  public files: NgxFileDropEntry[] = [];
  uploading: Boolean = false;

  public searchField: any = "";
  public codeSearchQuery: string = "";
  public fieldList: Array<string> = [];
  public codeFieldLists = {
    department: [
      'code',
      'description',
      'abbreviation',
    ],
    occupation: [
      'code',
      'description',
      'abbreviation',
      'rate',
    ],
    transaction: [
      'code',
      'description',
      'abbreviation',
      'glAccount',
      'type',
      'rateMultiplier',
    ],
    deduction: [
      'code',
      'description',
      'abbreviation',
    ],
    loan: [
      'code',
      'description',
      'abbreviation',
      'glAccount',
    ],
    pension: [
      'code',
      'employeePercent',
      'employeeAmount',
      'employerPercent',
      'employerAmount',
    ],
    health: [
      'code',
      'description',
      'isEnable',
      'employeeAmount.single',
      'employeeAmount.multiple',
      'employeeAmount.family',
      'employerAmount.single',
      'employerAmount.multiple',
      'employerAmount.family',
      'account',
    ]
  };
  public sortDirection: string = "Ascending";

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log('file!!',droppedFile.relativePath, file, droppedFile, file.type);

          const formData = new FormData()
          formData.append(file.name, file)

          this.startUpload(file)

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event){

  }

  public fileLeave(event){

  }


  constructor(
    private api: ApiService,
    private appService: AppService,
    private location:Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit() {

    $('.actionsBox').toggle();

    this.id = this.route.snapshot.paramMap.get('id');

    this.getCode('department')
    this.selectedTab = 'department'
    this.fieldList = this.codeFieldLists['department'];
    this.searchField = this.codeFieldLists['department'][0]
    window.addEventListener('click', (e) => this.handleClick(e.target));

  }

  // sort by field

  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
      }

    }

  }

  selectSearchField(field) {
    this.searchField = field;
  }

  searchCodes(val) {

    // this.toggleSearch = true;
    let  selected = this.selectedTab

    $('.actionsBox').hide();
    $('.actionsBox2').hide();


    if (val.length === 0) {
      this[this.selectedTab+"CodeSearch"] = this[this.selectedTab+"Codes"];
      // this.getCode(this.selectedTab)
    }
    else if (val.length > 0) {

      if (
        this.searchField === 'rate' ||
        this.searchField === 'rateMultiplier' ||
        this.searchField === 'employeePercent' ||
        this.searchField === 'employeeAmount' ||
        this.searchField === 'employerPercent' ||
        this.searchField === 'employerAmount' ||
        this.searchField === 'employeeAmount.single' ||
        this.searchField === 'employeeAmount.multiple' ||
        this.searchField === 'employeeAmount.family' ||
        this.searchField === 'employerAmount.single' ||
        this.searchField === 'employerAmount.multiple' ||
        this.searchField === 'employerAmount.family'
      ) {
        val = parseInt(val);
      }

      if (val === 'boolsearch') {
        val = $('#inputTypeSelectBool').val();
        if (val === 'true') {
          val = true;
        }
        if (val === 'false') {
          val = false;
        }
      }

      // this.rows.splice(0, this.rows.length);
      let field = this.searchField;
      let temp = this[this.selectedTab+"Codes"].filter(d => {


        if (typeof val === 'boolean') {
          let indx = this[this.selectedTab+"Codes"].findIndex(x=>x[field] === val)
          return this[this.selectedTab+"Codes"][indx];
          // return d[field].indexOf(val) !== -1 || !val;
        }
        if (typeof val === 'string') {
          val = val.toLowerCase();
          return d[field].toLowerCase().indexOf(val) !== -1 || !val;
        }
        if (typeof val === 'number' && !field.split(".")[0]) {
          val = val;
          return d[field].indexOf(val) !== -1 || !val;
        }
        if (typeof val === 'number' && field.split(".")[0]) {

          let prop1 = field.split(".")[0]
          let prop2 = field.split(".")[1]

          return this[this.selectedTab+"Codes"].filter(x=>x[prop1][prop2] === val)[0];

          // return d[prop1][prop2].indexOf(val) !== -1 || !val;
          // return d[field.split(".")[0]][field.split(".")[1]].indexOf(val) !== -1 || !val;
        }

      });


      this.codeSearchQuery = val;

      this[this.selectedTab+"CodeSearch"] = [...temp];


    }

  }

  clearSearch() {

    this.getCode(this.selectedTab)

  }

  sortCodes() {



    let direction = this.sortDirection;

    if (this.searchField.split(".")[1]) {
      if (direction === 'Ascending') {
        this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a,b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]] > b[this.searchField.split(".")[0]][this.searchField.split(".")[1]]) ? 1 : ((b[this.searchField.split(".")[0]][this.searchField.split(".")[1]] > a[this.searchField.split(".")[0]][this.searchField.split(".")[1]]) ? -1 : 0))
      }
      if (direction === 'Descending') {
        this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a,b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]] > b[this.searchField.split(".")[0]][this.searchField.split(".")[1]]) ? 1 : ((b[this.searchField.split(".")[0]][this.searchField.split(".")[1]] < a[this.searchField.split(".")[0]][this.searchField.split(".")[1]]) ? -1 : 0))
      }

      // if (direction === 'Ascending') {
      //   this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a, b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]] > b[this.searchField.split(".")[0]][this.searchField.split(".")[1]]) ? 1 : -1);
      // }
      // if (direction === 'Descending') {
      //   this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a, b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]] < b[this.searchField.split(".")[0]][this.searchField.split(".")[1]]) ? 1 : -1);
      // }
    } else {
      if (direction === 'Ascending') {
        this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0))
      }
      if (direction === 'Descending') {
        this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a,b) => (a[this.searchField] < b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0))
      }
      // if (direction === 'Ascending') {
      //   this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a, b) => (a[this.searchField] > b[this.searchField]) ? 1 : -1);
      // }
      // if (direction === 'Descending') {
      //   this[this.selectedTab+'CodeSearch'] = this[this.selectedTab+"Codes"].sort((a, b) => (a[this.searchField] < b[this.searchField]) ? 1 : -1);
      // }

    }

  }


  selectSortDirection(args) {
    this.sortDirection = args;
  }

  getCode(type) {
    this.selectedTab = type;
    // console.log('this.selectedTab',this.selectedTab);


    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCode(this.id,type).subscribe(codes => {

      // console.log('getCode',codes,type,this.id);

      this[type+'Codes'] = codes;
      this[type+'CodeSearch'] = codes;
      // console.log('get codes',codes);


      this.fieldList = this.codeFieldLists[type];

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCode"+type+"!"
      }

      $("#errorModal").modal("show");
    })

  }

  getChartsOfAccounts () {

    // this.loading = true;
    // $("#errorModal").modal("show");
    this.api.getChartOfAccounts(this.id).subscribe(next => {

      try {
        this.chartsOfAccounts = next.coas;

        for (const chart of next.coas) {
          switch(chart.type) {
            case 'expenses' :
            this.chartsOfAccountsExpenses.push(chart)
            break;
            case 'liabilities' :
            this.chartsOfAccountsLiabilities.push(chart)
            break;
            case 'assets' :
            this.chartsOfAccountsAssets.push(chart)
            break;
          }
        }
      } catch (error) {

      }




      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getChartOfAccounts!"
      }
      $("#errorModal").modal("show");
    })

  }

  actionsBox (event,code,type) {

    console.log('code',code,'type',type);

    this.codeType = type;
    switch(type) {
      case 'department' :
        this.departmentCode = code;
      break;
      case 'occupation' :
       this.occupationCode = code;
      break;
      case 'transaction' :
       this.transactionCode = code;
      break;
      case 'deduction' :
       this.deductionCode = code;
      break;
      case 'loan' :
       this.loanCode = code;
      break;
      case 'pension' :
       this.pensionCode = code;
      break;
      case 'health' :
       this.healthCode = code;
      break;
    }

    $('.actionsBox').css({'top':event.pageY-90,'left':event.pageX-50})
    $('.actionsBox').toggle();
    this.actionsBoxState = !this.actionsBoxState
  }

  toggleBox() {
    $('.actionsBox').hide();
    this.setting = 'list';

  }

  newCode () {
    this.setting = 'Add';

    this.getChartsOfAccounts();

    // this.selectGlAccount();
    $('.actionsBox').hide();
  }

  uploadCode () {
    this.setting = 'Upload';

    this.getChartsOfAccounts();

    // this.selectGlAccount();
    $('.actionsBox').hide();
  }

  showCode () {
    this.setting = 'list';
  }

  codeAction (args) {
    if (args = 'edit') {
      this.setting = 'Edit';

    }
    $('.actionsBox').hide();
  }

  onSubmit (type) {

    let code;
    let apiType;

    switch(type) {
      case 'department' :
        this.departmentCode.type = "department";
        code = this.departmentCode;
        if (this.setting === 'Edit') {
          apiType = 'updateDepartmentOccupationCode';
        } else if (this.setting === 'Add') {
          apiType = 'addDepartmentOccupationCode';
        }
        code.companyID = this.id;

      break;
      case 'occupation' :
        this.occupationCode.type = "occupation";
        code = this.occupationCode;
        if (this.setting === 'Edit') {
          apiType = 'updateDepartmentOccupationCode';
        } else if (this.setting === 'Add') {
          apiType = 'addDepartmentOccupationCode';
        }
        code.companyID = this.id;

      break;
      case 'transaction' :
        code = this.transactionCode;
        if (this.setting === 'Edit') {
          apiType = 'updateTransactionCode';
        } else if (this.setting === 'Add') {
          apiType = 'addTransactionCode';
        }

        code.companyID = this.id;

      break;
      case 'deduction' :
        code = this.deductionCode;
        if (this.setting === 'Edit') {
          apiType = 'updateDeductionCode';
        } else if (this.setting === 'Add') {
          apiType = 'addDeductionCode';
        }

        code.companyID = this.id;

      break;
      case 'loan' :
        code = this.loanCode;
        if (this.setting === 'Edit') {
          apiType = 'updateLoanCode';
        } else if (this.setting === 'Add') {
          apiType = 'addLoanCode';
        }

        code.companyID = this.id;

      break;
      case 'bank' :
        code = this.bankCode;
        if (this.setting === 'Edit') {
          apiType = 'updateBankCode';
        } else if (this.setting === 'Add') {
          apiType = 'addBankCode';
        }
      break;
      case 'pension' :
        code = this.pensionCode;
        if (this.setting === 'Edit') {
          apiType = 'updatePensionCode';
        } else if (this.setting === 'Add') {
          apiType = 'addPensionCode';
        }
        code.companyID = this.id;
      break;
      case 'health' :
        code = this.healthCode;
        if (this.setting === 'Edit') {
          apiType = 'updateHealthCode';
        } else if (this.setting === 'Add') {
          apiType = 'addHealthCode';
        }
        code.companyID = this.id;
      break;
    }

    this.departmentCode = Defaults.DepartmentOccupationCodeDefault();
    this.departmentCode.type = "department";
    this.healthCode = Defaults.HealthCodeDefault();
    this.occupationCode = Defaults.DepartmentOccupationCodeDefault();
    this.occupationCode.type = 'occupation';
    this.transactionCode = Defaults.TransactionCodeDefault();
    this.deductionCode = Defaults.DeductionCodeDefault();
    this.loanCode = Defaults.LoanCodeDefault();
    this.bankCode = Defaults.BankCodeDefault();
    this.pensionCode = Defaults.PensionCodeDefault();

    // console.log('code',code,JSON.stringify(code));

    if (this.setting === 'Add') {

      this.loading = true;
      $("#errorModal").modal("show");

      this.api[apiType](code).subscribe(next => {
        // location.reload();

        this.modalMessage = next.status;

        this.loading = false;

        setTimeout(()=>{
          $("#errorModal").modal("hide");
          this.getCode(this.selectedTab);
          this.setting = 'list';
        }, 3000);

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: "+apiType+"!"
        }
        $("#errorModal").modal("show");
      })


    }
    if (this.setting === 'Edit') {

      this.loading = true;
      $("#errorModal").modal("show");


      this.api[apiType](code).subscribe(next => {
        // location.reload();
        this.loading = false;
        // $("#errorModal").modal("hide");

        setTimeout(()=>{
          $("#errorModal").modal("hide");
          this.getCode(this.selectedTab);
          this.setting = 'list';
        }, 3000);

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: "+apiType+"!"
        }
        $("#errorModal").modal("show");
      })

    }

  }

  startUpload(file) {

    const id = this.id;

    const reader: FileReader = new FileReader();
    let codes = [];
    let workbook;
    let XL_row_object;
    let json_object;

    let codeCheck = false;
    reader.readAsBinaryString(file);
    new Promise((resolve, reject) => {
      reader.onload = () => {

        let data = reader.result;
         workbook = XLSX.read(data,{type: 'binary'});

         workbook.SheetNames.forEach((sheetName) => {
          // Here is your object

           XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

           XL_row_object.shift();

           for(const code of XL_row_object) {

             let codeRow: Code = Defaults.CodeDefault();

             for(const [key, value] of Object.entries(code)) {

               codeRow[key] = value;

             }


             codes.push(codeRow);

           }
           this.codesToUpload = codes;

           json_object = codes;


           resolve(json_object);

        });

      };

    })
    .then((json_object: any) => {

      this.codesToUpload = json_object;

    });

  }

  uploadCodes() {
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.addEmployeeBatch(JSON.stringify(this.codesToUpload)).subscribe(next =>{

      this.loading = false;
      this.modalMessage = "Success!"
      // $("#errorModal").modal("show");

      setTimeout(()=>{
        $("#errorModal").modal("hide");
      }, 3000);

      // $("#errorModal").modal("hide");
      // this.router.navigate(['pages/company', this.companyId, 'details']);
    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: addBatchCodes!"
      }
      $("#errorModal").modal("show");

    });

  }

  selectFile(files) {
    let file = files[0];
    const formData = new FormData()
    formData.append(file.name, file)

    this.startUpload(file)
  }


}
