
<div class="loginFormContainer">

  <div class="loginLeftSide">
    <img class="leftLoginPane" src="../../assets/img/welcome_image.png" alt="...">
  </div>

  <div class="loginRightSide">

    <!-- <div class="formLogo">
      <p>logo</p>
    </div> -->

    <img class="formLogo" src="../../assets/img/logo.png" alt="...">

    <div class="loginFormSubcontainer">
      <h2 class="formHeading">Admin Login</h2>

      <form (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input type="email" name="email" [(ngModel)]="data.email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
        </div>
        <div class="form-group">
          <label for="current-password">Password</label>

          <input type="password" name="password" [(ngModel)]="data.password" class="form-control" id="current-password" autocomplete="current-password" aria-label="current-password" aria-describedby="current-password">
          <button id="toggle-password" type="button" aria-label="Show password as plain text. Warning: this will display your password on the screen.">Show</button>
        </div>
        <!-- <div class="form-check">
          <input type="checkbox" class="form-check-input" id="exampleCheck1">
          <label class="form-check-label" for="exampleCheck1">Check me out</label>
        </div> -->
        <div class="forgetPassBox">
          <div class="col-9 x">
            <a href=".." routerLink="/forgotpassword" class="forgetPassword"><p>Forgot Password?</p></a>
          </div>
          <div class="col-3 y">
            <button type="submit" class="btn btn-primary submitBtn">Submit</button>
          </div>
        </div>

      </form>

    </div>

  </div>


</div>

<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
