import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { Defaults } from 'src/app/Models/Defaults';
import { Location } from '@angular/common';

import { EmployeeLoan } from 'src/app/Models/EmployeeLoan';
import { EmployeeDeduction } from 'src/app/Models/EmployeeDeduction';

import { Employee } from 'src/app/Models/Employee';

import { Code } from 'src/app/Models/Code';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';
import { ChartOfAccounts } from 'src/app/Models/ChartOfAccounts';

import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from '@angular/common';

declare const $: any;


import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-datamanagement-company',
  templateUrl: './datamanagement-company.component.html',
  styleUrls: ['./datamanagement-company.component.css']
})
export class DatamanagementCompanyComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;

  company: Company = Defaults.CompanyDefaults();
  address: Address = Defaults.AddressDefaults();
  ach: Ach = Defaults.achDefault();

  public totalEmployeeCount: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];

  public selectedEmployee: Employee = Defaults.EmployeeDefault();
  public employees: Array<Employee> = [];
  public allEmployees: Array<Employee> = [];
  public employeeSrch: Array<Employee> = [];
  public searchField: any = 'firstName';
  public toggleSearch: boolean = false;
  public employeeSearchQuery: string = '';
  public sortDirection: string = "Ascending";

  public selectedEmployeeYtd: Array<any> = [];
  public selectedEmployeeMtd: Array<any> = [];
  public employeeLoan: EmployeeLoan = Defaults.EmployeeLoanDefault();
  public employeeDeduction: EmployeeDeduction = Defaults.EmployeeDeductionDefault();

  public update: Boolean = false;

  departmentCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  healthCode: HealthCode = Defaults.HealthCodeDefault();
  occupationCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  transactionCode: TransactionCode = Defaults.TransactionCodeDefault();
  deductionCode: DeductionCode = Defaults.DeductionCodeDefault();
  loanCode: LoanCode = Defaults.LoanCodeDefault();
  bankCode: BankCode = Defaults.BankCodeDefault();
  pensionCode: PensionCode = Defaults.PensionCodeDefault();

  public loanEnabledInput: boolean = false;
  public deductionEnabledInput: boolean = false;

  public loanContinuous: boolean = false;
  public deductionContinuous: boolean = false;

  bankingCodes: Array<BankCode> = [];

  public companyCodesDepartment: Array<DepartmentOccupationCode> = [];
  public companyCodesOccupation: Array<DepartmentOccupationCode> = [];
  public companyCodesHealth: Array<HealthCode> = [];
  public companyCodesLoan: Array<LoanCode> = [];
  public companyCodesDeduction: Array<DeductionCode> = [];
  public companyCodesTransaction: Array<TransactionCode> = [];
  public companyCodesPension: Array<PensionCode> = [];
  chartOfAccounts: ChartOfAccounts = Defaults.ChartOfAccountsDefault();
  chartsOfAccounts: Array<ChartOfAccounts> = [];

  chartsOfAccountsExpenses: Array<ChartOfAccounts> = [];
  chartsOfAccountsLiabilities: Array<ChartOfAccounts> = [];
  chartsOfAccountsAssets: Array<ChartOfAccounts> = [];

  data: any = {};
  public id: string = '';

  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';

  public addDeduction: boolean = false;
  public addLoan: boolean = false;
  public specialAddType: string = '';

  public specialEdit: Boolean = false;
  public specialDelete: Boolean = false;
  public specialIndex: number = undefined;

  public ytdRetrieved: Boolean = false;
  public mtdRetrieved: Boolean = false;
  public ytdArgs: any = {
    type: 'ytd',
    period: 'weekly',
  };
  previousEmployment: any = {
    gross: 0,
    nis: 0,
  }

  public currentUser: any = {};

  hideTerminationDate: boolean = false;


  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit() {
    $('.actionsBox').toggle();
    $('.actionsBox2').toggle();

    this.id = this.route.snapshot.paramMap.get('id');
    this.currentUser = JSON.parse(localStorage.getItem("admin"));
    if (this.currentUser.permissions.includes('close payroll') === true) {
      // this.canClose = false;
    }

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCompany(this.id).subscribe(companyData =>{

      this.loading = false;
      $("#errorModal").modal("hide");

      this.company = companyData;
      this.address = companyData.address;
      this.ach = companyData.ach;
      this.getChartsOfAccountsCodes();
      this.getBankingCodes();
      this.getEmployeesByPage(true,'');


    },
    error => {
      this.loading = false;
      this.modalMessage = "Error: getCompany!";
      $("#errorModal").modal("show");
    }

  );

    window.addEventListener('click', (e) => this.handleClick(e.target));

    $("#dropdown1").on("click", ".dropdown-toggle", (e) => {this.handleDropdownClick(e.target)});

  }

  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
        $('.actionsBox2').hide();
      }

    }

  }
  handleDropdownClick(eventTarget) {

    $(".dropdown").parent().addClass("show");
    $(".dropdown").attr("aria-expanded", "true");
    $(".dropdown").next().addClass("show");

  }

  stepPage(args) {


    if (
      args === 'previous' &&
      this.currentPage > 1
    ) {
      this.getEmployeesByPage(false,'previous');
    }

    if (args === 'next' && this.currentPage < this.totalPages) {
      this.getEmployeesByPage(false,'next');
    }
  }
  getEmployeesByPage(init,direction) {

    this.loading = true;
    $("#errorModal").modal("show");

    let start = 0;
    let count = 20;
    if (direction === 'next') {
      this.currentPage++
    }
    if (direction === 'previous') {
      this.currentPage--
    }

    let customCurrentPage = 1;
    if (direction !== 'next' && direction !== 'previous') {
      customCurrentPage = this.currentPage;
      this.currentPage = direction;
    }
    start = (this.currentPage-1)*20;
    if (init === true) {
      start = 0;
    }




    this.api.getEmployeesByPage(start,count,this.id).subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");


        if (init === true) {
          this.currentPage = 1;
          this.totalPages = Math.ceil(next.count/20);
          this.pagesArray = [];
          for (let index = 0; index < this.totalPages; index++) {
            this.pagesArray.push("")
          }
        } else {

          if (next.count !== this.totalEmployeeCount) {
            this.totalPages = Math.ceil(next.count/20);
            console.log('emp count has changed');
            this.pagesArray = [];
            for (let index = 0; index < this.totalPages; index++) {
              this.pagesArray.push("")
            }
          }

        }


        this.update = false;
        this.employeeSrch = next.data;
        this.employees = next.data;
        this.totalEmployeeCount = next.count;

        // console.log('this.employeeSrch',this.employeeSrch);

        this.loading = false;
        $("#errorModal").modal("hide");


      },
      error => {

        if (direction === 'next') {
          this.currentPage--
        }
        if (direction === 'previous') {
          this.currentPage++
        }
        if (direction !== 'next' && direction !== 'previous') {
          this.currentPage = customCurrentPage;
        }

        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeesByPage!";
        }
        $("#errorModal").modal("show");
      }
    )

  }
  clearSearch() {
    this.toggleSearch = false;
    this.getEmployeesByPage(true,'');
  }
  getEmployees() {

    this.loading = true;
    // $("#errorModal").modal("show");

    this.api.getEmployees(this.id).subscribe(next => {

      this.allEmployees = next;
      // console.log('this.allEmployees',this.allEmployees);
      this.loading = false;
    },
    error => {
      this.loading = false;
      console.log('api request errorx',error);
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployees!"
      }
      $("#errorModal").modal("show");
    })

  }
  preSortEmployees() {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getEmployees(this.id).subscribe(next => {

      this.employees = next;
      this.employeeSrch = next;

      // this.loading = false;
      // $("#errorModal").modal("hide");
      this.sortEmployees()
    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployees!"
      }
      $("#errorModal").modal("show");
    })

  }
  sortEmployees() {

    let direction = this.sortDirection;

    // console.log('num3',this.employeeSrch.sort((a, b) => (a[this.searchField] > b[this.searchField]) ? 1 : -1));
    // console.log('num4',this.employeeSrch.sort((a, b) => a[this.searchField] - b[this.searchField]));
    // console.log('num5',this.employeeSrch.sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0)));

    if (direction === 'Ascending') {
      this.employeeSrch = this.employees.sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0))
    }
    if (direction === 'Descending') {
      this.employeeSrch = this.employees.sort((a,b) => (a[this.searchField] > b[this.searchField]) ? 1 : ((b[this.searchField] > a[this.searchField]) ? -1 : 0))
    }
    // if (direction === 'Ascending') {
    //   this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField] > b[this.searchField]) ? 1 : -1);
    // }
    // if (direction === 'Descending') {
    //   this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField] < b[this.searchField]) ? 1 : -1);
    // }

    this.loading = false;
    $("#errorModal").modal("hide");

  }
  selectSortDirection(args) {
    this.sortDirection = args;
  }

  pauseEnableToggle() {

    if (this.employeeLoan.paused === true) {
      this.loanEnabledInput = true;
    } else {
      this.loanEnabledInput = false;
    }
    if (this.employeeDeduction.paused === true) {
      this.deductionEnabledInput = true;
    } else {
      this.deductionEnabledInput = false;
    }
  };

  getCode(type) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCode(this.id,type).subscribe(codes => {

      // console.log('getCode',codes,type);

      // this[type+'Codes'] = codes;

      switch(type) {
        case 'department' :
         this.companyCodesDepartment = codes;
        break;
        case 'occupation' :
         this.companyCodesOccupation = codes;
        break;
        case 'transaction' :
         this.companyCodesTransaction = codes;
        break;
        case 'deduction' :
         this.companyCodesDeduction = codes;
        break;
        case 'loan' :
         this.companyCodesLoan = codes;
        break;
        case 'pension' :
         this.companyCodesPension = codes;
        break;
        case 'health' :
         this.companyCodesHealth = codes;
        break;
      }

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCode"+type+"!"
      }

      $("#errorModal").modal("show");
    })

  }
  getChartsOfAccountsCodes () {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getChartOfAccounts(this.company.id).subscribe(next => {

      this.chartsOfAccounts = next.coas;
      if (this.chartsOfAccounts) {
        for (const chart of next.coas) {
          switch(chart.type) {
            case 'expenses' :
             this.chartsOfAccountsExpenses.push(chart)
            break;
            case 'liabilities' :
             this.chartsOfAccountsLiabilities.push(chart)
            break;
            case 'assets' :
             this.chartsOfAccountsAssets.push(chart)
            break;
          }
        }
      }


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getChartOfAccounts!"
      }
      $("#errorModal").modal("show");
    })


    let codeTypes = ['department','occupation','transaction','deduction','loan','pension','health'];
    for (const type of codeTypes) {
      this.getCode(type)
    }

  }
  onSubmit (args) {

    this.loading = true;
    $("#errorModal").modal("show");


    this.selectedEmployee.pension.employeeId = this.selectedEmployee.employeeId;

    if (args === 'employment') {


      if (this.selectedEmployee.employment.status === "terminated" && !this.selectedEmployee.employment.terminationDate || this.selectedEmployee.employment.terminationDate === null ) {
        this.selectedEmployee.employment.terminationDate = new Date().toISOString();
      }


      let fullDeptCode = this.companyCodesDepartment.find(x=>x.code === this.selectedEmployee.employment.department.code)
      let fullOccupCode = this.companyCodesOccupation.find(y=>y.code === this.selectedEmployee.employment.occupation.code)

      if (fullDeptCode) {
        this.selectedEmployee.employment.department.abbreviation = fullDeptCode.abbreviation;
        this.selectedEmployee.employment.department.code = fullDeptCode.code;
        this.selectedEmployee.employment.department.companyID = fullDeptCode.companyID;
        // this.selectedEmployee.employment.department.defaultCompanyAmount = fullDeptCode.defaultCompanyAmount;
        // this.selectedEmployee.employment.department.defaultEmployeeAmount = fullDeptCode.defaultEmployeeAmount;
        this.selectedEmployee.employment.department.description = fullDeptCode.description;
        this.selectedEmployee.employment.department.rate = fullDeptCode.rate;
        this.selectedEmployee.employment.department.id = fullDeptCode.id;
        this.selectedEmployee.employment.department.type = fullDeptCode.type;
      }

      if (fullOccupCode) {
        this.selectedEmployee.employment.occupation.abbreviation = fullOccupCode.abbreviation;
        this.selectedEmployee.employment.occupation.code = fullOccupCode.code;
        this.selectedEmployee.employment.occupation.companyID = fullOccupCode.companyID;
        // this.selectedEmployee.employment.occupation.defaultCompanyAmount = fullOccupCode.defaultCompanyAmount;
        // this.selectedEmployee.employment.occupation.defaultEmployeeAmount = fullOccupCode.defaultEmployeeAmount;
        this.selectedEmployee.employment.occupation.description = fullOccupCode.description;
        this.selectedEmployee.employment.occupation.rate = fullOccupCode.rate;
        this.selectedEmployee.employment.occupation.id = fullOccupCode.id;
        this.selectedEmployee.employment.occupation.type = fullOccupCode.type;
      }

    }

    // this.selectedEmployee.loans[0] = this.employeeLoan;
    // this.selectedEmployee.deductions[0] = this.employeeDeduction;

    if (this.specialEdit === true) {
      if (this.addLoan === true) {
        this.selectedEmployee.loans[this.specialIndex] = this.employeeLoan;
      }
      if (this.addDeduction === true) {
        this.selectedEmployee.deductions[this.specialIndex] = this.employeeDeduction;
      }
    }

    if (this.specialDelete === true) {

    }

    if (this.selectedEmployee.employment.status === "terminated" && !this.selectedEmployee.employment.terminationDate || this.selectedEmployee.employment.terminationDate === null) {
      this.selectedEmployee.employment.terminationDate = new Date().toISOString();
    }
    if (this.selectedEmployee.employment.status !== "terminated") {
      this.selectedEmployee.employment.terminationDate = null;
    }


    if (
      this.specialEdit !== true &&
      this.specialDelete !== true
    ) {
      if (this.addLoan === true) {
        if (this.employeeLoan.endDate === "") {
          this.employeeLoan.endDate = this.employeeLoan.startDate;
        }
        this.selectedEmployee.loans.push(this.employeeLoan);
      }
      if (this.addDeduction === true) {
        if (this.employeeDeduction.endDate === "") {
          this.employeeDeduction.endDate = this.employeeDeduction.startDate;
        }
        this.selectedEmployee.deductions.push(this.employeeDeduction);
      }
    }

    this.employeeLoan = Defaults.EmployeeLoanDefault();
    this.employeeDeduction = Defaults.EmployeeDeductionDefault();
    this.addLoan = false;
    this.addDeduction = false;

    for (const loan of this.selectedEmployee.loans) {
      loan.numPayments = parseInt((loan.initialAmount/loan.repaymentAmount).toFixed(2));
      if(typeof(loan.outstanding) != 'number'){
        loan.outstanding = parseFloat(loan.outstanding)
      }
    }

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.updateEmployee(this.selectedEmployee).subscribe(next =>{

      // this.loading = true;
      // // this.modalMessage = "Success!"
      // $("#errorModal").modal("hide");

      this.selectedEmployee = next.employee;
      if(this.selectedEmployee.employment.startDate != null){
        this.selectedEmployee.employment.startDate = this.selectedEmployee.employment.startDate.split("T")[0]
      }
      if(this.selectedEmployee.employment.terminationDate != null){
        this.selectedEmployee.employment.terminationDate = this.selectedEmployee.employment.terminationDate.split("T")[0]
      }

      this.modalMessage = "Employee Updated"
      this.loading = false;
      $("#errorModal").modal("show");

      if (this.selectedEmployee.employment.status !== 'terminated') {
        this.hideTerminationDate = true;
      }

      setTimeout(()=>{
        $("#errorModal").modal("hide");
      }, 3000);


    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: updateEmployee!"
      }
      $("#errorModal").modal("show");
    }
    );

  }

  searchEmployees(val) {

    this.toggleSearch = true;

    // $('.actionsBox').hide();
    // $('.actionsBox2').hide();
    // // this.rows.splice(0, this.rows.length);
    // let field = this.searchField;
    // let temp = this.employees.filter(function(d) {
    //   val = val.toLowerCase();
    //   return d[field].toLowerCase().indexOf(val) !== -1 || !val;
    // });
    //
    // this.employeeSrch = [...temp];

    this.getEmployeesBySearch(val)

  }

  getEmployeesBySearch(query) {


    if (query.length >= 3) {

      this.loading = true;
      //$("#errorModal").modal("show");

      this.api.getEmployeesBySearch(query,this.id).subscribe(next =>{

        this.employeeSrch = [];
        for (const elem of next) {
          this.employeeSrch.push(elem.data)
        }
        // this.employeeSrch = next;
        // console.log('getEmployeesBySearch',next,this.employeeSrch);

        this.loading = false;
        //$("#errorModal").modal("hide");


      },
      error => {
        this.loading = false;
        this.modalMessage = "Error: getEmployeesBySearch!";
        $("#errorModal").modal("show");
      })

    }

    if (query.length === 0) {
      this.clearSearch()
    }

  }

  selectSearchField(args) {
    this.searchField = args;
  }

  dataManagementAction(args) {

    $('.actionsBox').toggle();

    if (args === 'update') {

      this.update = true;
    }

    if (args === 'delete') {
      this.deleteEmployee()
    }
  }

  actionsBox(event,employee) {
    // console.log('actionsBox', employee);

    if (employee.dob) {
      employee.dob = employee.dob.slice(0,10);

    }

    this.selectedEmployee = employee;

    if(this.selectedEmployee.employment.startDate != null){
      this.selectedEmployee.employment.startDate = this.selectedEmployee.employment.startDate.split("T")[0]

    }

    if(this.selectedEmployee.employment.terminationDate != null){
      this.selectedEmployee.employment.terminationDate = this.selectedEmployee.employment.terminationDate.split("T")[0]

    }


    // if (this.selectedEmployee.loans[0]) {
    //   this.employeeLoan = this.selectedEmployee.loans[0];
    // }
    // if (this.selectedEmployee.deductions[0]) {
    //   this.employeeDeduction = this.selectedEmployee.deductions[0];
    // }
    //
    // if (!this.selectedEmployee.loans[0]) {
    //   this.selectedEmployee.loans.push(this.employeeLoan)
    // }
    // if (!this.selectedEmployee.deductions[0]) {
    //   this.selectedEmployee.deductions.push(this.employeeDeduction)
    // }

    $('.actionsBox').css({'top':event.pageY-100,'left':event.pageX-150})
    $('.actionsBox').toggle();
    // console.log('this.selectedEmployee',this.selectedEmployee);


    for (const loan of this.selectedEmployee.loans) {
      loan.numPayments = parseInt((loan.initialAmount/loan.repaymentAmount).toFixed(2));
    }

    if (this.selectedEmployee.employment.status === 'terminated') {
      this.hideTerminationDate = false;
    }
    if (this.selectedEmployee.employment.status !== 'terminated') {
      this.hideTerminationDate = true;
    }

  }

  getBankingCodes() {

    this.loading = true;
    $("#errorModal").modal("show");
    this.api.getBankingCodes().subscribe(next => {

      this.bankingCodes = next;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getBankingCodes!"
      }
      $("#errorModal").modal("hide");
    })

  }

  listEmployees() {

    this.update = false;
  }

  toggleSpecialAdd(args) {


    this.specialEdit = false;
    this.specialDelete = false;

    if (args === 'deduction') {
      this.addDeduction = !this.addDeduction;
      this.employeeDeduction = Defaults.EmployeeDeductionDefault();
    }
    if (args === 'loan') {
      this.addLoan = !this.addLoan;
      this.employeeLoan = Defaults.EmployeeLoanDefault();

    }

  }

  actionsBoxSpecial (index,type,event,item) {

    this.specialIndex = index;
    if (type === 'loan') {

      this.employeeLoan = item;
      this.specialAddType = 'loan';
      this.loanContinuous = item.continuous;

    }
    if (type === 'deduction') {

      this.employeeDeduction = item;
      this.specialAddType = 'deduction';
      this.deductionContinuous = item.continuous;
    }

    item.startDate = item.startDate.slice(0,10);
    item.endDate = item.endDate.slice(0,10);

    $('.actionsBox2').css({'top':event.pageY-100,'left':event.pageX-250})
    // $('.actionsBox2').css({'top':event.pageY-50,'left':event.pageX+10})
    $('.actionsBox2').toggle();

  }

  handleLoanAmount (event) {
    if (this.specialEdit != true) {
      this.employeeLoan.outstanding = event.target.value;
    }
    this.employeeLoan.numPayments = 0;
    this.employeeLoan.repaymentAmount = 0;
  }

  handleLoanPaymentCalc(event) {

    let repaymentNumber = this.employeeLoan.numPayments;
    let repaymentAmount = this.employeeLoan.repaymentAmount;
    let initialAmount = this.employeeLoan.initialAmount;

    if (repaymentNumber && repaymentNumber !== 0 && initialAmount && initialAmount !== 0)  {
      this.employeeLoan.repaymentAmount = parseInt((initialAmount/repaymentNumber).toFixed(2))
    }
    if (repaymentAmount && repaymentAmount !== 0 && initialAmount && initialAmount !== 0)  {
      this.employeeLoan.numPayments = parseInt((initialAmount/repaymentAmount).toFixed(2))
    }
    if (repaymentNumber && repaymentNumber !== 0 && repaymentAmount && repaymentAmount !== 0 && initialAmount === 0)  {
      this.employeeLoan.initialAmount = parseInt((initialAmount/repaymentAmount).toFixed(2))
    }

  }

  employeeAction(args) {

    if (args === 'edit') {
      this.specialEdit = true;
      this.specialDelete = false;

      if (this.specialAddType === 'loan') {
        this.addLoan = true;
      }
      if (this.specialAddType === 'deduction') {
        this.addDeduction = true;
      }

    }
    if (args === 'delete') {
      this.specialEdit = false;
      this.specialDelete = true;

      if (this.specialAddType === 'loan') {
        // console.log('delete loan',this.selectedEmployee.loans[this.specialIndex]);

        // this.employee.loans.filter(x=>x !== this.employee.loans[this.specialIndex])
        this.selectedEmployee.loans.splice(this.specialIndex,1)
        this.onSubmit('loan');
      }
      if (this.specialAddType === 'deduction') {
        // console.log('delete deduction',this.selectedEmployee.deductions[this.specialIndex]);

        // this.employee.deductions.filter(x=>x !== this.employee.deductions[this.specialIndex])
        this.selectedEmployee.deductions.splice(this.specialIndex,1)
        this.onSubmit('deduction');
      }
    }
    $('.actionsBox2').hide();

  }

  handlePreEndDate(type,event) {

    if (type === 'loan') {
      this.loanContinuous = event.target.checked;

    }
    if (type === 'deduction') {
      this.deductionContinuous = event.target.checked;
    }

  }

  resetYtd() {

    this.ytdArgs = {
      type: 'ytd',
      period: 'weekly',
    }
    this.ytdRetrieved = false;
    this.mtdRetrieved = false;
    this.selectedEmployeeYtd = [];
    this.selectedEmployeeMtd = [];

  }

  getYtd() {


    let empId = this.selectedEmployee.employeeId;
    let companyId = this.id;
    let report = this.ytdArgs.type;
    let cycle = this.selectedEmployee.employment.payCycle;

    if (report === 'ytd') {

      this.loading = true;
      $("#errorModal").modal("show");

      // console.log(companyId,empId,cycle);

      this.api.getEmployeeYtdCurrent(companyId,empId,cycle).subscribe(next => {

        console.log('getEmployeeYtdCurrent',next);

        if (next.status) {

          if (next.status.substring(0,10) === 'No payroll') {
            this.loading = false;
            this.modalMessage = next.status;
            $("#errorModal").modal("show");
          }
        }
        else {
          this.ytdRetrieved = true;
          // this.selectedEmployeeYtd = next;

          for(const [key, value] of Object.entries(next)) {

            let item: any = undefined;
            let val: any = value;

            if (val) {
              switch(key) {
                case 'deductions' :
                for (const elem of val) {

                }
                break;
                case 'loans' :
                for (const elem of val) {

                }
                break;
                case 'pay' :
                for (const elem of val) {
                  item = {
                    key: elem.description,
                    value: elem.amount.toFixed(2),
                  }
                  this.selectedEmployeeYtd.push(item);
                }
                break;
                case 'statutory' :
                for (const elem of val) {
                  item = {
                    key: elem.tax,
                    value: elem.employeeAmount.toFixed(2)
                  }
                  this.selectedEmployeeYtd.push(item);
                }
                break;
                default:
                if (key !== 'employee' && key !== 'employeeId' && key !== 'isTerminated') {
                  if (typeof val == 'number') {
                    item = {
                      key: key,
                      value: val.toFixed(2)
                    }
                  }
                  else {
                    item = {
                      key: key,
                      value: val
                    }
                  }

                  this.selectedEmployeeYtd.push(item);
                }

              }
            }

          }

          // console.log('this.selectedEmployeeYtd',this.selectedEmployeeYtd);

          this.loading = false;
          $("#errorModal").modal("hide");

        }

      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeeYtd!"
        }
        $("#errorModal").modal("hide");
      })

    }

    if (report === "mtd") {

      this.loading = true;
      // this.modalMessage = next.status;
      $("#errorModal").modal("show");

      this.api.getEmployeeMtdCurrent(companyId,empId,cycle).subscribe(next => {

        console.log('getEmployeeMtdCurrent',next);


        if (next.status) {

          if (next.status.substring(0,10) === 'No payroll') {
            this.loading = false;
            this.modalMessage = next.status;
            $("#errorModal").modal("show");
          }
        }
        else {
          this.mtdRetrieved = true;
          this.ytdRetrieved = true;
          // this.selectedEmployeeYtd = next;

          for(const [key, value] of Object.entries(next)) {

            let item: any = undefined;
            let val: any = value;

            if (val) {
              switch(key) {
                case 'deductions' :
                for (const elem of val) {

                }
                break;
                case 'loans' :
                for (const elem of val) {

                }
                break;
                case 'pay' :
                for (const elem of val) {
                  item = {
                    key: elem.description,
                    value: elem.amount.toFixed(2),
                  }
                  this.selectedEmployeeMtd.push(item);
                }
                break;
                case 'statutory' :
                for (const elem of val) {
                  item = {
                    key: elem.tax,
                    value: elem.employeeAmount.toFixed(2)
                  }
                  this.selectedEmployeeMtd.push(item);
                }
                break;
                default:
                if (key !== 'employee' && key !== 'employeeId' && key !== 'isTerminated') {
                  if (typeof val == 'number') {
                    item = {
                      key: key,
                      value: val.toFixed(2)
                    }
                  }
                  else {
                    item = {
                      key: key,
                      value: val
                    }
                  }

                  this.selectedEmployeeMtd.push(item);
                }

              }
            }
            // if (val) {
            //   switch(key) {
            //     case 'deductions' :
            //     for (const elem of val) {
            //
            //     }
            //     break;
            //     case 'loans' :
            //     for (const elem of val) {
            //
            //     }
            //     break;
            //     case 'pay' :
            //     for (const elem of val) {
            //       item = {
            //         key: elem.description,
            //         value: elem.amount,
            //       }
            //       this.selectedEmployeeMtd.push(item);
            //     }
            //     break;
            //     case 'statutory' :
            //     for (const elem of val) {
            //       item = {
            //         key: elem.tax,
            //         value: elem.employeeAmount
            //       }
            //       this.selectedEmployeeMtd.push(item);
            //     }
            //     break;
            //     default:
            //     if (key !== 'employee' && key !== 'employeeId') {
            //       item = {
            //         key: key,
            //         value: val
            //       }
            //       this.selectedEmployeeMtd.push(item);
            //     }
            //
            //   }
            // }

          }

          this.loading = false;
          $("#errorModal").modal("hide");

        }


      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeeMtd!"
        }
        $("#errorModal").modal("hide");
      })

    }

  }

  preExportEmployees(fileType) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getEmployees(this.id).subscribe(next => {

      this.allEmployees = next;
      console.log('this.allEmployees',this.allEmployees);
      this.loading = false;

      if (fileType === 'PDF') {
        this.empExportPdf()
      }
      if (fileType === 'CSV' || fileType === 'XLS') {
        this.empExportXlsCsv(fileType)
      }

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployees!"
      }
      $("#errorModal").modal("show");
    })

  }
  empExportPdf() {


    // let employee: Employee = Defaults.EmployeeDefault();

    let company = this.company;

    let body: Array<any> = [
      {
        text: 'Employee Export',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        // margin: [0, 5]
      },
      {
        text: ''+company.companyName+'',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        margin: [0, 5]
      },


      {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

      {
        text: 'FILTER CRITERIA: ALL RECORDS',
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5]
      },
    ];


      for (const employee of this.allEmployees) {

        if (employee.employment.status !== 'terminated') {
          employee.employment.terminationDate = "..."
        }

        // console.log('employee',employee);
        // console.log('banking codes',this.bankingCodes);
        // console.log('departmentCodes',this.companyCodesDepartment);
        // console.log('occupationCodes',this.companyCodesOccupation);
        // console.log('employee.banking.id',employee.banking.id);



        let nhtPayable = "...";
        if (employee.nhtPayable) {
          nhtPayable = employee.nhtPayable.toString();
        }
        let eduPayable = "...";
        // if (employee.educationPayable) {
        //   eduPayable = employee.educationPayable.toString();
        // }
        let eType = "...";
        // if (employee.employment.eType) {
        //   eType = employee.employment.eType;
        // }
        let dob = '...';
        if (employee.dob) {
          dob = employee.dob.toString().substr(0,10);
        }
        let effectiveDate = '...'
        // if (employee.employment.effectiveDate) {
        //   effectiveDate = employee.employment.effectiveDate.substr(0,10)
        // }
        let employementDuration = '...'
        // if (employee.employement.duration) {
        //   employementDuration = employee.employement.duration;
        // }
        let phone = "...";
        if (employee.phone) {
          phone = employee.phone;
        }
        let grade = '...';
        if (employee.grade) {
          grade = employee.grade;
        }
        let usdRate = "...";
        if (employee.usdRate) {
          usdRate = employee.usdRate.toString();
        }


        // console.log('employee.employeeId',employee.employeeId);

        let bankCode = {
          code: '...',
          name: '...',
        }
        if (this.bankingCodes.find(x=> x.id === employee.banking.bank)) {
          bankCode = this.bankingCodes.find(x=> x.id === employee.banking.bank);
        }
        let pensionBankCode = {
          code: '...',
          name: '...',
        }
        if (this.bankingCodes.find(x=> x.id === employee.pension.bank.bank)) {
          pensionBankCode = this.bankingCodes.find(x=> x.id === employee.pension.bank.bank);
          // console.log('pensionBankCode',pensionBankCode);

        }
        let companyName = this.company.companyName;

        let startDate = '...'
        if (employee.employment.startDate) {
          startDate = employee.employment.startDate.toString().substr(0,10);
        }

        let terminationDate = '...'
        if (employee.employment.status === 'terminated') {
          terminationDate = employee.employment.terminationDate.toString().substr(0,10);
        }

        // let departmentCode = this.companyCodesDepartment.find(x=> x.id === employee.employment.department.id)


        let employeeBasicData = [];
        let employeeBasicTable = {
          style: 'tableExample',
          table: {
            widths: ['16.5%','16.5%','16.5%','16.5%','16.5%','16.5%'],
            margin: [0, -5],
            body:
              employeeBasicData
          },
          // layout: 'noBorders'
          // pageBreak: "after"
        };

        employeeBasicData.push(
          [
            {text: 'Demographics:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'First Name:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.firstName+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Middle Name:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.middleName+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Last Name:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.lastName+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'TRN:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.trn+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'NIS:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.nis+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Email:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.email+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Phone:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+phone+'', fontSize: 9, border: [true, true, true, true]},
            // {text: '...', fontSize: 9, border: [true, true, true, true]},
            // {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: 'Employee Id:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.employeeId+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},

            {text: 'Grade:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+grade+'', fontSize: 9, border: [true, true, true, true]},
          ],
          [
            // {text: 'Grade:', fontSize: 9, bold: true, border: [true, true, true, true]},
            // {text: ''+grade+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Gender:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.gender+'', fontSize: 9, border: [true, true, true, true]},
            // {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            // {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: 'NIS Payable:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.nisPayable.toString()+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'NHT Payable:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+nhtPayable+'', fontSize: 9, border: [true, true, true, true]},
          ],
          [
            // {text: 'NIS Payable:', fontSize: 9, bold: true, border: [true, true, true, true]},
            // {text: ''+employee.nisPayable.toString()+'', fontSize: 9, border: [true, true, true, true]},
            // {text: 'NHT Payable:', fontSize: 9, border: [true, true, true, true]},
            // {text: ''+nhtPayable+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'TRN Payable:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.trnPayable.toString()+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: 'EDU Payable:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+eduPayable+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'USD Rate:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+usdRate+'', fontSize: 9, border: [true, true, true, true]},
          ],
          [
            // {text: 'EDU Payable:', fontSize: 9, bold: true, border: [true, true, true, true]},
            // {text: ''+eduPayable+'', fontSize: 9, border: [true, true, true, true]},
            // {text: 'USD Rate:', fontSize: 9, border: [true, true, true, true]},
            // {text: ''+employee.usdRate.toString()+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.type+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: 'DoB:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+dob+'', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
          ],
          // [
          //   // {text: 'DoB:', fontSize: 9, bold: true, border: [true, true, true, true]},
          //   // {text: ''+dob+'', fontSize: 9, border: [true, true, true, true]},
          //   // {text: '...', fontSize: 9, border: [true, true, true, true]},
          //   // {text: '...', fontSize: 9, border: [true, true, true, true]},
          //   {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          //   {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          // ],
          [
            {text: 'Previous Employment:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Id:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.previousEmployment.id+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Gross:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.previousEmployment.gross+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'NIS:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.previousEmployment.nis+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Health:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Id:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.health.id+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.health.code+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.health.type+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Banking:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            // {text: '...', fontSize: 9, bold: true, border: [true, true, true, true]},
            // {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+bankCode.code+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Bank:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+bankCode.name+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.banking.type+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},

          ],
          [
            {text: 'Currency:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.banking.currency+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Method:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.banking.method+'', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            // {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            // {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'ACH Id:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.banking.achId+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'ACH Name:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.banking.achName+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Account:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.banking.account+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Employment:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [

            {text: 'Rate', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.employment.rate+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Pay Cycle', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.employment.payCycle+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '...', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
          ],
          [
            {text: 'Status:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.employment.status+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Base Pay', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.employment.rate+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'E Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+eType+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Effective Date:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+effectiveDate+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Start Date', fontSize: 9, border: [true, true, true, true]},
            {text: ''+startDate+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Duration', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            // {text: ''+employementDuration+'', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
          ],
          [
            {text: 'Termination Date:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+terminationDate+'', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Department:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [

            {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.employment.department.code+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.employment.department.type+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '...', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
          ],
          [
            {text: 'Company:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+companyName+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Description:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.employment.department.description+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Abbreviation:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.employment.department.abbreviation+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Rate:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.employment.department.rate+'', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Occupation:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [

            {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.employment.occupation.code+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.employment.occupation.type+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '...', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
          ],
          [
            {text: '...', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: 'Description:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.employment.occupation.description+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Abbreviation:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.employment.occupation.abbreviation+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Rate:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.employment.occupation.rate+'', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Pension:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: '...', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true]},
            {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.pension.code+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Employee Id:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.pension.employeeId+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Employee %:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.pension.employeePercent+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Employee Amt:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.pension.employeeAmount+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Employer %:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.pension.employerPercent+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Employer Amt:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.pension.employerAmount+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Enable', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.pension.enable+'', fontSize: 9, border: [true, true, true, true]},
            {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '...', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Pension Banking:', colSpan: 4, fontSize: 10, bold: true, border: [true, true, true, true], margin: [3,3]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            // {text: 'Id:', fontSize: 9, bold: true, border: [true, true, true, true]},
            // {text: ''+employee.pension.bank.id+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+pensionBankCode.code+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Bank:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+pensionBankCode.name+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Type:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.pension.bank.type+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            // {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            // {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'Currency:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.pension.bank.currency+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Method:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.pension.bank.method+'', fontSize: 9, border: [true, true, true, true]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
          [
            {text: 'ACH Id:', fontSize: 9, bold: true, border: [true, true, true, true]},
            {text: ''+employee.pension.bank.achId+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'ACH Name:', fontSize: 9, border: [true, true, true, true]},
            {text: ''+employee.pension.bank.achName+'', fontSize: 9, border: [true, true, true, true]},
            {text: 'Account:', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            {text: ''+employee.pension.bank.account+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
          ],
        )


          let employeeDeductionData = [];
          let employeeDeductionTable = {
            style: 'tableExample',
            table: {
              widths: ['20%','16%','16%','16%','16%','16%'],
              margin: [0, -5],
              body:
                employeeDeductionData
            },
            // layout: 'noBorders'
            // pageBreak: "after"
          };

          employeeDeductionData.push(
            [
              {text: 'Deductions', fontSize: 12, bold: true, border: [true, true, true, true], margin:[3,3]},
              {text: '', fontSize: 9, border: [true, true, true, true]},
              {text: '', fontSize: 9, border: [true, true, true, true]},
              {text: '', fontSize: 9, border: [true, true, true, true]},
              {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
              {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            ]
          )


          if (employee.deductions[0]) {

            for (const deduction of employee.deductions) {
              // console.log('deduction',deduction)
              for (let [key04, value04] of Object.entries(deduction)) {
                // console.log('deductions',key04,value04);

                if (key04 === 'banking') {

                  for (const [key05, value05] of Object.entries(value04)) {


                    if (key05 === 'bank') {

                      let deductionBankCode;
                      if (value05 && this.bankingCodes.find(x=> x.id === value05)) {
                        deductionBankCode = this.bankingCodes.find(x=> x.id === value05);
                      }
                      else {
                        deductionBankCode = {
                          name: '...',
                          code: '...',
                        }
                      }

                      employeeDeductionData.push(
                        [
                          {text: 'Deduction Banking:', fontSize: 9, bold: true, border: [true, true, true, true]},
                          {text: ''+key05+': ', fontSize: 9, border: [true, true, true, true]},
                          // {text: '', fontSize: 9, border: [true, true, true, true]},
                          {text: ''+deductionBankCode.name+'', fontSize: 9, border: [true, true, true, true]},
                          {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
                          // {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                          {text: ''+deductionBankCode.code+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                          {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        ]
                      )
                    } else {
                      employeeDeductionData.push(
                        [
                          {text: 'Deduction Banking:', fontSize: 9, bold: true, border: [true, true, true, true]},
                          {text: ''+key05+': ', fontSize: 9, border: [true, true, true, true]},
                          {text: '', fontSize: 9, border: [true, true, true, true]},
                          {text: ''+value05+'', fontSize: 9, border: [true, true, true, true]},
                          {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                          {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        ]
                      )
                    }


                  }
                } else {
                  // console.log('deduction',key04,value04);

                  if (value04 && key04 === 'startDate') {
                    value04 = value04.toString().substr(0,10)
                  }
                  if (value04 && key04 === 'endDate') {
                    value04 = value04.toString().substr(0,10)
                  }
                  if (value04 && key04 === 'code') {

                    let code = this.companyCodesDeduction.find(x=>x.code === value04);

                    if (!code) {
                      code.code = '...'
                      code.description = '...'
                    }

                    employeeDeductionData.push(
                      [
                        {text: 'Deduction:', fontSize: 9, bold: true, border: [true, true, true, true]},
                        {text: ''+key04+'', fontSize: 9, border: [true, true, true, true]},
                        {text: ''+code.code+'', fontSize: 9, border: [true, true, true, true]},
                        {text: 'Description', fontSize: 9, border: [true, true, true, true]},
                        {text: ''+code.description+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                      ]
                    )

                  } else {
                    employeeDeductionData.push(
                      [
                        {text: 'Deduction:', fontSize: 9, bold: true, border: [true, true, true, true]},
                        {text: ''+key04+'', fontSize: 9, border: [true, true, true, true]},
                        {text: '', fontSize: 9, border: [true, true, true, true]},
                        {text: ''+value04+'', fontSize: 9, border: [true, true, true, true]},
                        {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                      ]
                    )
                  }



                }
              }

            }

          } else {
            console.log('employee has no deductions');

          }


          let employeeLoanData = [];
          let employeeLoanTable = {
            style: 'tableExample',
            table: {
              widths: ['20%','16%','16%','16%','16%','16%'],
              margin: [0, -5],
              body:
                employeeLoanData
            },
            // layout: 'noBorders'
            // pageBreak: "after"
          };

          employeeLoanData.push(
            [
              {text: 'Loans: ', fontSize: 12, bold: true, border: [true, true, true, true], margin: [3,3]},
              {text: '', fontSize: 9, border: [true, true, true, true]},
              {text: '', fontSize: 9, border: [true, true, true, true]},
              {text: '', fontSize: 9, border: [true, true, true, true]},
              {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
              {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
            ]
          )


          if (employee.loans[0]) {

            for (const loan of employee.loans) {
              // console.log('full loan',loan)
              for (let [key06, value06] of Object.entries(loan)) {
                if (key06 === 'banking') {
                  for (const [key07, value07] of Object.entries(value06)) {

                    if (key07 === 'bank') {

                      let loanBankCode;
                      if (value07 && this.bankingCodes.find(x=> x.id === value07)) {
                        loanBankCode = this.bankingCodes.find(x=> x.id === value07);
                      }
                      else {
                        loanBankCode = {
                          name: '...',
                          code: '...',
                        }
                      }

                      employeeLoanData.push(
                        [
                          {text: 'Loan Banking:', fontSize: 9, bold: true, border: [true, true, true, true]},
                          {text: ''+key07+': ', fontSize: 9, border: [true, true, true, true]},
                          // {text: '', fontSize: 9, border: [true, true, true, true]},
                          {text: ''+loanBankCode.name+'', fontSize: 9, border: [true, true, true, true]},
                          {text: 'Code:', fontSize: 9, border: [true, true, true, true]},
                          // {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                          {text: ''+loanBankCode.code+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                          {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        ]
                      )
                    } else {
                      employeeLoanData.push(
                        [
                          {text: 'Loan Banking:', fontSize: 9, bold: true, border: [true, true, true, true]},
                          {text: ''+key07+'', fontSize: 9, border: [true, true, true, true]},

                          {text: ''+value07+'', fontSize: 9, border: [true, true, true, true]},
                          {text: '', fontSize: 9, border: [true, true, true, true]},
                          {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                          {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},

                        ]
                      )
                    }

                  }

                } else {

                  // console.log('loan',key06,value06);

                  if (value06 && key06 === 'startDate') {
                    value06 = value06.toString().substr(0,10)
                  }
                  if (value06 && key06 === 'endDate') {
                    value06 = value06.toString().substr(0,10)
                  }
                  if (value06 && key06 === 'code') {


                    let code = this.companyCodesLoan.find(x=>x.code === value06)

                    if (!code) {
                      code.code = '...'
                      code.description = '...'
                    }

                    employeeLoanData.push(
                      [
                        {text: 'Loan:', fontSize: 9, bold: true, border: [true, true, true, true]},
                        {text: ''+key06+'', fontSize: 9, border: [true, true, true, true]},
                        {text: ''+code.code+'', fontSize: 9, border: [true, true, true, true]},
                        {text: 'Description', fontSize: 9, border: [true, true, true, true]},
                        {text: ''+code.description+'', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                      ]
                    )

                  } else {
                    employeeLoanData.push(
                      [
                        {text: 'Loan:', fontSize: 9, bold: true, border: [true, true, true, true]},
                        {text: ''+key06+'', fontSize: 9, border: [true, true, true, true]},

                        {text: ''+value06+'', fontSize: 9, border: [true, true, true, true]},
                        {text: '', fontSize: 9, border: [true, true, true, true]},
                        {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                        {text: '', fontSize: 9, border: [true, true, true, true], margin: [1,0]},
                      ]
                    )
                  }


                }
              }
            }


          } else {
            console.log('employee has no loans');

          }

        body.push(
          {
            text: 'Bio/Demo Heading',
            fontSize: 9,
            alignment: 'center',
            margin: [0, 5]
          }
        )
        body.push(employeeBasicTable)
        body.push(
          {
            text: 'Deduction Heading',
            fontSize: 9,
            alignment: 'center',
            margin: [0, 5]
          }
        )
        body.push(employeeDeductionTable)
        body.push(
          {
            text: 'Loan Heading',
            fontSize: 9,
            alignment: 'center',
            margin: [0, 5]
          }
        )
        body.push(employeeLoanTable)
      }



    let user = this.currentUser;
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      content: body,

      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }
  empExportXlsCsv(fileType) {
    // console.log('empExportXlsCsv',this.allEmployees.length,this.allEmployees);
    // console.log('this.bankingCodes',this.bankingCodes);


    this.loading = true;
    $("#errorModal").modal("show");


    let XL_row_object;
    let XL_row_object2;
    let XL_row_object3;

    var workbook = XLSX.utils.book_new();

    // BIO/DEMOGRAPHIC/EMPLOYMENT STATS
    // console.log('---***parsing bio/demo/employment data***---');

    var ws_name = "Bio-Demographics";
    let row1 = [];
    var ws_data1 = [
      [
        // 'id',
        // 'parentId',
        'firstName',
        'middleName',
        'lastName',
        'trn',
        'nis',
        'email',
        'phone',
        'status',
        'employeeId',
        'gender',
        'nisPayable',
        'nhtPayable',
        'trnPayable',
        'educationPayable',
        'usdrate',
        'type',
        'grade',
        'dob',
        'previousEmployment_id',
        'previousEmployment_gross',
        'previousEmployment_nis',
        'health_id',
        'health_code',
        'health_type',
        'employment_id',
        // 'employment_department_id',
        'employment_department_code',
        'employment_department_type',
        // 'employment_department_companyid',
        'employment_department_description',
        'employment_department_abbreviation',
        'employment_department_rate',
        // 'employment_occupation_id',
        'employment_occupation_code',
        'employment_occupation_type',
        // 'employment_occupation_companyid',
        'employment_occupation_description',
        'employment_occupation_abbreviation',
        'employment_occupation_rate',
        'employment_rate',
        'employment_payCycle',
        'employment_status',
        'employment_terminationDate',
        'employment_basePay',
        'employment_eType',
        'employment_effectiveDate',
        'employment_startDate',
        'employment_duration',
        // 'banking_id',
        'banking_code',
        'banking_bank',
        'banking_type',
        'banking_currency',
        'banking_method',
        'banking_account',
        'pension_id',
        'pension_code',
        'pension_employeeId',
        'pension_employeePercent',
        'pension_employeeAmount',
        'pension_employerPercent',
        'pension_employerAmount',
        // 'pension_bank_id',
        'pension_bank_code',
        'pension_bank_bank',
        'pension_bank_type',
        'pension_bank_currency',
        'pension_bank_method',
        'pension_bank_account',
        'pension_enable',
      ]
    ];
    var ws = XLSX.utils.aoa_to_sheet(ws_data1);
    var wscols = [];
    for (let index = 0; index < 65; index++) {
      wscols.push({wch:25})
    }
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workbook, ws, ws_name);
    XLSX.utils.sheet_add_aoa(ws, [row1], {origin: -1});

    for (const employee of this.allEmployees) {

      if (employee.employment.status !== 'terminated') {
        employee.employment.terminationDate = "..."
      }

      // console.log('---loop 1 employee',employee.employeeId,'-',employee.firstName,'-',employee.lastName,'---',employee);

      let row2 = [];
      for (let index = 0; index < 65; index++) {
        row2.push(undefined)
      }

      let count1 = 0;
      for (const [key, value] of Object.entries(employee)) {

        if (key !== 'id' && key !== 'parentId') {


          let val: any = value;
          let modKey: string = '';

          if (key === 'previousEmployment' || key === 'health' || key === 'banking') {
            for (const [key01, value01] of Object.entries(value)) {


              modKey = key+'_'+key01;
              val = value01;

              if (modKey === 'banking_bank' && val) {

                val = this.bankingCodes.find(x=>x.id === value01).name

                row2[ws_data1[0].indexOf('banking_code')] = this.bankingCodes.find(x=>x.id === value01).code
              }

              if (val === "" || val === undefined || val === null) {
                val = "..."
              }
              row2[ws_data1[0].indexOf(modKey)] = val;
              count1++
              // console.log('employee ',key,': count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

            }
          }

          if (key === 'pension') {
            for (const [key02, value02] of Object.entries(value)) {
              if (key02 === 'bank') {
                for (const [key03, value03] of Object.entries(value02)) {
                  modKey = key+'_'+key02+'_'+key03;
                  val = value03;
                  // console.log('pension',modKey, val);

                  if (modKey === 'pension_bank_bank' && value03) {

                    val = this.bankingCodes.find(x=>x.id === value03).name

                    row2[ws_data1[0].indexOf('pension_bank_code')] = this.bankingCodes.find(x=>x.id === value03).code;
                  }


                  if (val === "" || val === undefined || val === null) {
                    val = "..."
                  }

                  row2[ws_data1[0].indexOf(modKey)] = val;
                  count1++
                  // console.log('employee.pension.banking: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

                }
              }
              else {
                modKey = key+'_'+key02;
                val = value02;
                if (val === "" || val === undefined || val === null) {
                  val = "..."
                }
                row2[ws_data1[0].indexOf(modKey)] = val;
                count1++
                // console.log('employee.pension: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

              }
            }
          }

          if (key === 'employment') {
            for (const [key04, value04] of Object.entries(value)) {
              if (key04 === 'department') {
                for (const [key05, value05] of Object.entries(value04)) {

                  if (key05 !== 'id' && key05 !== 'companyID') {
                    modKey = key+'_'+key04+'_'+key05;
                    val = value05;
                    if (val === "" || val === undefined || val === null) {
                      val = "..."
                    }
                    row2[ws_data1[0].indexOf(modKey)] = val;
                    count1++
                    // console.log('employee.employment.department: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

                  }
                  // console.log('employee.employment.department: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])
//
                }
              }
              if (key04 === 'occupation') {
                for (const [key06, value06] of Object.entries(value04)) {

                  if (key06 !== 'id' && key06 !== 'companyID') {
                    modKey = key+'_'+key04+'_'+key06;
                    val = value06;
                    if (val === "" || val === undefined || val === null) {
                      val = "..."
                    }
                    row2[ws_data1[0].indexOf(modKey)] = val;
                    count1++
                    // console.log('employee.employment.occupation: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

                  }
                  // console.log('employee.employment.occupation: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

                }
              }
              else {
                modKey = key+'_'+key04;
                val = value04;

                if (val === "" || val === undefined || val === null) {
                  val = "..."
                } else if (key04 === 'terminationDate' || key04 === 'effectiveDate' || key04 === 'startDate') {
                  val = val.substr(0,10)
                }

                row2[ws_data1[0].indexOf(modKey)] = val;
                count1++
                // console.log('employee.employment: count',count1,'key',modKey,'ws_data_indx',ws_data1[0].indexOf(modKey),'row2 _val',row2[ws_data1[0].indexOf(modKey)])

              }
            }

          }

          if (
            key !== 'previousEmployment' &&
            key !== 'health' &&
            key !== 'employment' &&
            key !== 'banking' &&
            key !== 'pension' &&
            key !== 'loans' &&
            key !== 'deductions'
          ) {

            if (key === 'dob') {
              if (val) {
                val = val.substr(0,10)
              } else {
                val = '...'
              }

            }
            if (key === "status" && typeof value !== 'string') {
              val = val.toString()
            }
            if (val === "" || val === undefined || val === null) {
              val = "..."
            }

            row2[ws_data1[0].indexOf(key)] = val;
            count1++;
            // console.log('bio/demo employee: count',count1,'key',key,'ws_data_indx',ws_data1[0].indexOf(key),'row2 _val',row2[ws_data1[0].indexOf(key)])


          }

          if (key === 'deductions' || key === 'loans') {
            // console.log('deduct & loan count',count1);
            // count1++;
          }

          }

      }

      XLSX.utils.sheet_add_aoa(ws, [row2], {origin: -1});

    }


    // delete_row(ws, 1)

    XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);


    // DEDUCTIONS
    console.log('---***parsing deductions***---');

    var ws_name2 = "Deductions";
    let row2 = [];
    var ws_data2 = [
      [
        // 'id',
        // 'parentId',
        'firstName',
        'middleName',
        'lastName',
        'trn',
        'nis',
        'email',
        'phone',
        'status',
        'employeeId',
        'grade',
        'gender',
        // 'id',
        'code',
        'description',
        'deductionAmount',
        'policy',
        'startDate',
        'endDate',
        'employerAmount',
        'beforeTax',
        'paused',
        'enabled',
        'continuous',
        'glAccount',
        'directDeposit',
        // 'banking_id',
        'banking_code',
        'banking_bank',
        'banking_type',
        'banking_currency',
        'banking_method',
        'banking_account',
      ]
    ];
    var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);
    var wscols2 = [];
    for (let index = 0; index < 31; index++) {
      wscols2.push({wch:25})
    }
    ws2['!cols'] = wscols2;


    XLSX.utils.book_append_sheet(workbook, ws2, ws_name2);
    XLSX.utils.sheet_add_aoa(ws2, [row2], {origin: -1});

    for (const employee of this.allEmployees) {


      if (employee.deductions[0]) {

        for (const deduction of employee.deductions) {
          // console.log('deduction',deduction);

          let row3 = [];
          for (let index = 0; index < 31; index++) {
            row3.push(undefined)
          }
          let count3 = 0;
          let modKey: string = '';

          for (let index2 = 0; index2 < 11; index2++) {

            if (ws_data2[0][index2] !== 'id' && ws_data2[0][index2] !== 'parentId') {

              if (ws_data2[0][index2] === "status" && typeof employee[ws_data2[0][index2]] !== 'string') {
                employee[ws_data2[0][index2]] = employee[ws_data2[0][index2]].toString()
              }

              row3[index2] = employee[ws_data2[0][index2]]
              count3++;
            }

            // row3[index2] = employee[ws_data2[0][index2]];
            // count3++;
            // console.log('deduction employee: count',count3,'key',ws_data2[0][index2],'row2_val',employee[ws_data1[0][index2]]);

          }

          for (const [key, value] of Object.entries(deduction)) {



            let val: any = value;

            if (key === 'banking') {
              for (const [key01, value01] of Object.entries(value)) {

                modKey = key+'_'+key01;
                // console.log('deduction banking',modKey,value01);
                val = value01;
                // modKey = 'deduction_'+key+'_'+key01;
                if (modKey === 'banking_bank' && value01) {

                  val = this.bankingCodes.find(x=>x.id === value01).name;

                  row3[ws_data2[0].indexOf('banking_code')] = this.bankingCodes.find(x=>x.id === value01).code
                }


                if (val === "" || val === undefined || val === null) {
                  val = "..."
                }
                row3[ws_data2[0].indexOf(modKey)] = val;

                count3++;
                // console.log('deduct banking val',val);

                // console.log('deduction.banking: count',count3,'key',modKey,'ws_data_indx',ws_data2[0].indexOf(modKey),'row3 _val',row3[ws_data2[0].indexOf(modKey)])
              }
            }

            else if (key !== 'banking') {


              val = value;
              modKey = key
              // modKey = 'deduction_'+key
              // console.log('deductions',modKey,val);

              if (modKey === 'code') {
                row3[ws_data2[0].indexOf('description')] = this.companyCodesDeduction.find(x=>x.code === val).description
              }



              if (
                typeof val === 'number'
              ) {
                row3[ws_data2[0].indexOf(modKey)] = val.toFixed(2);
                // row3[count3] = val.toFixed(2);
              }
              if (
                key === 'startDate' ||
                key === 'endDate'
              ) {
                row3[ws_data2[0].indexOf(modKey)] = val.substr(0,10);
                // row3[count3] = val.substr(0,10);
              }

              else {
                if (val === "") {
                  val = "..."
                }
                row3[ws_data2[0].indexOf(modKey)] = val
                // row3[count3] = val;
              }

              count3++;
              // console.log('deduct val',val);

              // console.log('deduction: count',count3,'key',modKey,'ws_data_indx',ws_data2[0].indexOf(modKey),'row2 _val',row3[ws_data2[0].indexOf(modKey)])
            }

          }

          XLSX.utils.sheet_add_aoa(ws2, [row3], {origin: -1});

        }

      }
      else {

        let row3 = [];
        for (let index = 0; index < 29; index++) {
          row3.push(undefined)
        }
        let count3 = 0;
        let modKey: string = '';


        for (let index2 = 0; index2 < 11; index2++) {

          if (ws_data2[0][index2] !== 'id' && ws_data2[0][index2] !== 'parentId') {

            if (ws_data2[0][index2] === "status" && typeof employee[ws_data2[0][index2]] !== 'string') {
              employee[ws_data2[0][index2]] = employee[ws_data2[0][index2]].toString()
            }

            row3[index2] = employee[ws_data2[0][index2]]
            count3++;
          }

          // row3[index2] = employee[ws_data2[0][index2]]
          // count3++;
          // console.log('deduction employee: count',count3,'key',ws_data2[0][index2],'row3_val',employee[ws_data1[0][index2]]);

        }


        console.log('employee has no deductions');
        row3[14] = 'employee has no deductions...';

        XLSX.utils.sheet_add_aoa(ws2, [row3], {origin: -1});

      }

      // XLSX.utils.sheet_add_aoa(ws2, [row3], {origin: -1});

    }

    // delete_row(ws2, 1)

    XL_row_object2 = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[1]]);



    // LOANS
    console.log('---*** parsing loans ***---');

    var ws_name3 = "Loans";
    let row3 = [];
    var ws_data3 = [
      [
        // 'id',
        // 'parent_id',
        'firstName',
        'middleName',
        'lastName',
        'trn',
        'nis',
        'email',
        'phone',
        'status',
        'employeeId',
        'grade',
        'gender',
        // 'id',
        'code',
        'description',
        'initialAmount',
        'policy',
        'repaymentAmount',
        'numPayments',
        'startDate',
        'endDate',
        'continuous',
        'outstanding',
        'beforeTax',
        'paused',
        'enabled',
        'glAccount',
        'myProperty',
        // 'banking_id',
        'banking_code',
        'banking_bank',
        'banking_type',
        'banking_currency',
        'banking_method',
        'banking_account',
      ]
    ];
    var ws3 = XLSX.utils.aoa_to_sheet(ws_data3);
    var wscols3 = [];
    for (let index = 0; index < 32; index++) {
      wscols2.push({wch:25})
    }
    ws3['!cols'] = wscols3;

    XLSX.utils.book_append_sheet(workbook, ws3, ws_name3);
    XLSX.utils.sheet_add_aoa(ws3, [row3], {origin: -1});


    for (const employee of this.allEmployees) {

      if (employee.loans[0]) {

        for (const loan of employee.loans) {

          let row4 = [];
          for (let index = 0; index < 32; index++) {
            row4.push(undefined)
          }

          let count4 = 0;
          let modKey: string = '';

          for (let index = 0; index < 11; index++) {

            if (ws_data3[0][index] !== 'id' && ws_data3[0][index] !== 'parentId') {

              if (ws_data3[0][index] === "status" && typeof employee[ws_data3[0][index]] !== 'string') {
                employee[ws_data3[0][index]] = employee[ws_data3[0][index]].toString()
              }

              row4[index] = employee[ws_data3[0][index]]
              count4++;
            }

            // row4[index] = employee[ws_data3[0][index]]
            // count4++;
            // console.log('loan employee: count',count4,'key',ws_data3[0][index],'row4_val',employee[ws_data3[0][index]]);

          }

          for (const [key, value] of Object.entries(loan)) {




            let val: any = value;

            if (key === 'banking') {
              for (const [key01, value01] of Object.entries(value)) {

                val = value01;
                modKey = key+'_'+key01;
                // console.log('loan banking',modKey,value01);
                // modKey = 'deduction_'+key+'_'+key01;

                if (modKey === 'banking_bank' && value01) {

                  val = this.bankingCodes.find(x=>x.id === value01).name;

                  row4[ws_data3[0].indexOf('banking_code')] = this.bankingCodes.find(x=>x.id === value01).code;
                }

                if (val === "") {
                  val = "..."
                }
                row4[ws_data3[0].indexOf(modKey)] = val;

                count4++;

                // console.log('deduction.banking: count',count4,'key',modKey,'ws_data_indx',ws_data3[0].indexOf(modKey),'row4 _val',row4[ws_data3[0].indexOf(modKey)])

              }
            }

            else if (key !== 'banking') {

              console.log('loan object keys',key,value);

              val = value;
              modKey = key;

              if (modKey === 'code') {
                row4[ws_data3[0].indexOf('description')] = this.companyCodesLoan.find(x=>x.code === val).description
              }

              if (
                typeof val === 'number' &&
                key === 'initialAmount' ||
                key === 'repaymentAmount'
              ) {
                row4[ws_data3[0].indexOf(modKey)] = val.toFixed(2);
                // row3[count3] = val.toFixed(2);
              }
              if (
                key === 'startDate' ||
                key === 'endDate'
              ) {
                row4[ws_data3[0].indexOf(modKey)] = val.substr(0,10);
                // row4[count4] = val.substr(0,10);
              }

              else {

                if (val === "") {
                  val = "..."
                }

                row4[ws_data3[0].indexOf(modKey)] = val
              }

              // console.log('deduction: count',count4,'key',modKey,'ws_data_indx',ws_data3[0].indexOf(modKey),'row4 _val',row4[ws_data3[0].indexOf(modKey)])
              count4++;

            }

          }

          XLSX.utils.sheet_add_aoa(ws3, [row4], {origin: -1});

        }



      }
      else {

        let row4 = [];
        for (let index = 0; index < 29; index++) {
          row4.push(undefined)
        }

        let count4 = 0;
        let modKey: string = '';

        for (let index = 0; index < 11; index++) {

          if (ws_data3[0][index] !== 'id' && ws_data3[0][index] !== 'parentId') {

            if (ws_data3[0][index] === "status" && typeof employee[ws_data3[0][index]] !== 'string') {
              employee[ws_data3[0][index]] = employee[ws_data3[0][index]].toString()
            }

            row4[index] = employee[ws_data3[0][index]]
            count4++;
          }

          // row4[index] = employee[ws_data3[0][index]]
          // count4++;
          // console.log('loan employee: count',count4,'key',ws_data3[0][index],'row4_val',employee[ws_data3[0][index]]);

        }

        console.log('employee has no loans');
        row4[14] = 'employee has no loans...'

        XLSX.utils.sheet_add_aoa(ws3, [row4], {origin: -1});

      }



      // XLSX.utils.sheet_add_aoa(ws3, [row4], {origin: -1});
    }

    // delete_row3(ws3, 1)

    XL_row_object3 = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[2]]);


    if (fileType === 'CSV') {
      XLSX.writeFile(workbook, 'employee_export.csv');
    }
    if (fileType === 'XLS') {
      XLSX.writeFile(workbook, 'employee_export.xlsx');
    }



    this.loading = false;
    $("#errorModal").modal("hide");


  }

  handleStatusChange(args) {

    if (args.value === 'terminated') {
      this.hideTerminationDate = false;
    } else {
      this.hideTerminationDate = true;
    }


  }

  deleteEmployee() {


    this.loading = true;
    $("#errorModal").modal("show");

    this.api.deleteEmployee(this.selectedEmployee.employeeId).subscribe(next =>{

      console.log('deleteEmployee',next);

      this.loading = false;
      this.modalMessage = 'Employee Deleted';
      // $("#errorModal").modal("hide");

      setTimeout(()=>{
        this.getEmployeesByPage(true,'');
      }, 3000);


    },
    error => {
      this.loading = false;
      this.modalMessage = "Error: deleteEmployee!";
      $("#errorModal").modal("show");
    })

  }

  resetHealth() {

    this.selectedEmployee.health.code = ""
    this.selectedEmployee.health.type = "";
    this.onSubmit('employment')
  }

}
