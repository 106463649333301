<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">General Reports</h3>
      </div>

      <div class="row input">
        <!-- <div class=" btnCol">
          <button type="button" class="btn createBtn" ><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Open Payrolls</p></button>
        </div>
        <div class=" btnCol">
          <button type="button" class="btn showBtn" ><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Closed Payrolls</p></button>
        </div> -->

      </div>

    </div>

    <div class="subcontainerLower">

      <div class="row lowerTable">

        <!-- <div class="row upperHeading">
        <h4 class="lowerHeader">Current Payroll</h4>
        </div> -->

        <ul class="dashboardClientList">
          <li class="dashboardClientListItem" *ngFor="let report of reports">
            <div class="payrollCard">

              <table class="customerCardTable">
                <tr class="customerCardTableRowTop">
                  <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                    <h5 class="customerCardHeading">{{report.name}}</h5>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <p class="customerCardText">{{report.description}}</p>
                  </td>
                </tr>
                <tr class="editBtnRow">

                  <!-- <div *ngIf="showS02Link != true" class="dropdown" id="drop3"> -->
                  <div class="dropdown" id="drop3">
                    <button class="btn btn-secondary dropdown-toggle xlsBtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">Generate</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler(report,false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler(report,true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>

                  <!-- <button type="button" (click)="docPreGen('ytd',report,'xls')" class="btn xlsBtn"><p>XLS</p></button>
                  <button type="button" (click)="docPreGen('ytd',report,'pdf')" class="btn pdfBtn"><p>PDF</p></button> -->
                </tr>
              </table>

            </div>
          </li>
        </ul>

      </div>

    </div>
  </div>

</div>





<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Generate Payslip</p>
      </a>
    </li>
  </ul>
</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true && s01Select != true" >{{modalMessage}}</h5>

        <!-- <div *ngIf="auditSelect == true">

          <form (ngSubmit)="selectAuditPayrollType()">

          <div class="form-row">
            <div class="form-group col-md-6" style="margin: auto;">
              <label for="auditPayrollType">Type</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="auditPayrollType"  id="auditPayrollType" name="auditPayrollType">
                <option *ngFor="let type of yearEndTypeSelection; let i = index">{{type}}</option>

              </select>
            </div>
          </div>

            <button type="submit" class="btn btn-secondary" >Submit</button>

            </form>

        </div> -->


        <div *ngIf="auditSelect2 == true">

          <form (ngSubmit)="selectAuditBPayrollType()">

          <div class="form-row">
            <div class="form-group col-md-6" style="margin: auto;">
              <label for="auditPayrollType">Type</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="auditBPayrollType"  id="auditBPayrollType" name="auditBPayrollType">
                <option *ngFor="let type of yearEndTypeSelection; let i = index">{{type}}</option>
                <!-- <option value="weekly">Weekly</option>
                <option value="fortnightly">Fortnightly</option>
                <option value="bimonthly">Bi-Monthly</option>
                <option value="monthly">Monthly</option> -->
              </select>
            </div>
          </div>

            <button type="submit" class="btn btn-secondary" >Submit</button>

            </form>

        </div>

        <div *ngIf="yearEndSelect == true">

          <form (ngSubmit)="selectYearEndPayrollType()">

          <div class="form-row">
            <div class="form-group col-md-6" style="margin: auto;">
              <label for="auditPayrollType">Type</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="yearEndPayrollType"  id="auditBPayrollType" name="auditBPayrollType">
                <option *ngFor="let type of yearEndTypeSelection; let i = index">{{type}}</option>
                <!-- <option value="weekly">Weekly</option>
                <option value="fortnightly">Fortnightly</option>
                <option value="bimonthly">Bi-Monthly</option>
                <option value="monthly">Monthly</option> -->
              </select>
            </div>
          </div>

            <button type="submit" class="btn btn-secondary" >Submit</button>

            </form>

        </div>


        <div *ngIf="s01Select == true || auditSelect == true">

          <p>Select a Payroll</p>
          <table class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">...</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Type</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Start Date</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">End Date</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Pay Date</p>
              </th>
            </tr>
              <!-- <tr *ngFor="let item of allPayrolls; let j = index" class="tableRow s01Select" attr.id="{{j}}" (click)="selectSO1(item)" (mouseenter)="highlightS01($event)" (mouseleave)="unhighlightS01($event)"> -->
              <tr *ngFor="let item of allPayrolls; let j = index" class="tableRow s01Select" attr.id="{{j}}" >
                <td class="tableCels">
                  <input *ngIf="s01Select == true || auditSelect != true"  class="form-control" type="checkbox" (change)="selectMultipleS01(item)" name="s01" >
                  <input *ngIf="s01Select != true || auditSelect == true"  class="form-control" type="checkbox" (change)="selectMultipleAuditEmployee(item)" name="audit" >
                  <!-- <input *ngIf="s01Select != true || auditSelect == true"  class="form-control" type="checkbox" (change)="selectAuditPayrollType(item)" name="audit" > -->

                </td>
                <td class="tableCels"><p class="cellText">{{item.type}}</p></td>
                <td class="tableCels"><p class="cellText">{{item.payCycle.startDate | date:'mediumDate'}}</p></td>
                <td class="tableCels"><p class="cellText">{{item.payCycle.endDate | date:'mediumDate'}}</p></td>
                <td class="tableCels"><p class="cellText">{{item.payCycle.payDateOffset | date:'mediumDate'}}</p></td>
              </tr>
          </table>

          <button *ngIf="s01Select == true || auditSelect != true" type="button" class="btn btn-secondary" (click)="selectSO1()">Submit</button>
          <button *ngIf="s01Select != true || auditSelect == true" type="button" class="btn btn-secondary" (click)="selectAuditPayrollType()">Submit</button>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg" id="reportFilterModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="max-width:fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <img *ngIf="loading == true" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5> -->

        <div>

          <form *ngIf="filterInputs[0]" (ngSubmit)="setReportFilter()">

            <div class="formHead">
              <h4 class="formHeading">Choose Report Filters</h4>
            </div>


            <div  *ngFor="let input of filterInputs; let i = index;" class="form-row">


              <div class="form-group col-md-3">
                <label for="filterInput{{i}}Property">Property</label>
                <select  class="form-control" aria-label="Default select example" [(ngModel)]="filterInputs[i].data.Property"  id="filterInput{{i}}Property"  name="filterInput{{i}}Property" (change)="handleFilterInputTypeChange(i,$event.target.value)">
                  <option *ngFor="let property of filterProperties1[i];let j = index;">{{property}}</option>
                </select>
              </div>

              <div class="form-group col-md-2">
                <label for="filterInput{{i}}Condition">Condition</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="filterInputs[i].data.Condition" id="filterInput{{i}}Condition"  name="filterInput{{i}}Condition">
                  <option *ngFor="let condition of filterConditions[filterInputs[i].inputType];let index = k;">{{condition}}</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="filterInput{{i}}Value">Value</label>
                <input type="text" class="form-control" [(ngModel)]="filterInputs[i].data.Value" id="filterInput{{i}}Value" name="filterInput{{i}}Value" >
              </div>

              <div *ngIf="filterInputs[i].data.Property !== ''" class="form-group col-md-1">
                <label for="filterInput{{i}}AndOr">.</label>
                <input disabled type="text" class="form-control" [(ngModel)]="filterInputs[i].data.Type" id="filterInput{{i}}AndOr" name="filterInput{{i}}AndOr" >
              </div>

              <!-- <div *ngIf="filterInputs[i].data.Property !== ''" class="form-group col-md-3" style="display: inline-flex;margin-top: auto;"> -->
              <div class="form-group col-md-3" style="display: inline-flex;margin-top: auto;">
                <button *ngIf="filterInputs[i].data.Property !== ''" type="button" class="btn btn-primary" style="margin-right:.2rem;" (click)="addFilterInput(i,'AND')">AND</button>
                <button disabled *ngIf="filterInputs[i].data.Property !== ''" type="button" class="btn btn-primary" style="margin-right:.2rem;" (click)="addFilterInput(i,'OR')">OR</button>
                <!-- <button *ngIf="i > 0" type="button" class="btn btn-danger" (click)="removeFilterInput(i)">Clear</button> -->
                <button *ngIf="i > 0 && i == filterInputs.length-1" type="button" class="btn btn-danger" (click)="removeFilterInput(i)">Clear</button>
              </div>

            </div>

            <button type="submit" class="btn btn-primary m-4">Filter</button>

            <button type="button" class="btn btn-danger" (click)="closeReportFiltering()">Cancel</button>
          </form>

        </div>


      </div>
    </div>
  </div>
</div>

<div class="modal fade " id="specialModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <!-- <div> -->
          <!-- <a  download="NCBBankFile.txt" *ngIf="showNCB == true" id="downloadlink" href="javascript:">
            <button id="create" class="createBtn"><p style="color: #ffffff;">Download NCB Bank File</p></button>
          </a> -->

          <a *ngIf="showS02Link == true" id="downloadlink2" [href]="S02ASCIILink" target="_blank" (click)="toggleS02()">
            <button id="create" class="createBtn btn btn-secondary"><p style="color: #ffffff;">Download S02ASCII</p></button>
          </a>
          <!-- <a *ngIf="showYearEndLink == true" id="downloadlink3" [href]="YearEndLink" target="_blank" (click)="toggleYearEnd()">
            <button id="create" class="createBtn btn btn-secondary"><p style="color: #ffffff;">Download Year End Summary</p></button>
          </a> -->
          <!-- <a download="BNS.ach" *ngIf="showBNSLink == true" id="downloadlink3" href="javascript:">
            <button id="create" class="createBtn"><p style="color: #ffffff;">Download BNS</p></button>
          </a> -->
        <!-- </div> -->


      </div>
      <!-- <div class="modal-footer" *ngIf="s01Select == true">
        <button type="button" class="btn btn-secondary" (click)="selectSO1()" >Submit</button>
      </div> -->
    </div>
  </div>
</div>
