<div class="userControlContainer" *ngIf="controlsShow == true">

  <button [disabled]="createCompany" *ngIf="setting == 'dashboard'" routerLink="/pages/company/create" type="button" class="btn createBtn createCompany">Company +</button>

  <button *ngIf="setting != 'dashboard'" [routerLink]="['/pages/company',id,'employee','create']" type="button" class="btn createBtn">Employee +</button>

  <a *ngIf="setting == 'company' && companyLogo != ''" href="javascript:" [routerLink]="['/pages/company',companyId,'details']">
    <img *ngIf="setting == 'company'" id="userControlImage" src='{{companyLogo}}' alt="logo">
  </a>

  <a *ngIf="setting == 'company' && companyLogo == ''" href="javascript:" (click)="editCompany()">
    <fa-icon [icon]="faFileUpload" class="fa-lg createModalIcon"></fa-icon>
  </a>

  <img *ngIf="setting == 'dashboard'" class="userControlImage" src="../../assets/img/miniLogo.png" alt="...">
  <h6 *ngIf="setting == 'dashboard'" class="userControlText">Caribbean HR</h6>

  <a *ngIf="setting == 'company'" href="javascript:" [routerLink]="['/pages/company',companyId,'details']">
    <h6 *ngIf="setting == 'company'" class="userControlText">{{companyName}}</h6>
  </a>

  <div class="dropdown">

    <button style="margin-left:1rem;" class="btn btn-primary  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <fa-icon [icon]="faBell" class="fa-lg createModalIcon"></fa-icon>
    </button>
    <div class="dropdown-menu dmenu" aria-labelledby="dropdownMenuButton">
      <a *ngIf="currentUser" class="dropdown-item" href="javascript:">{{currentUser.username}}</a>
      <a *ngIf="currentUser" class="dropdown-item" href="javascript:">{{currentUser.type}}</a>
      <a class="dropdown-item" href="javascript:">S01</a>
      <a class="dropdown-item" href="javascript:">S02</a>
      <a class="dropdown-item" href="javascript:">S03</a>
    </div>
  </div>

  <!-- <a href=".."><fa-icon [icon]="faChevronDown" class="fa-lg createModalIcon"></fa-icon></a> -->
</div>
