<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Taxes & Deduction</h3>
      </div>

      <div class="row input">
        <div class=" btnCol">
          <button (click)="newTax()" type="button" class="btn createBtn"><fa-icon [icon]="faPlus" class=" btnIcon"></fa-icon><p class="btnText ">Add</p></button>
        </div>
        <div class=" btnCol">
          <button  type="button" (click)="showTax()" class="btn showBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Show</p></button>
        </div>
      </div>

      <!-- <div class="row input">
        <div class="col-md-4">
          <div class="form-group">
            <label for="inputGender">Code Type</label>

            <select class="form-control" aria-label="Default select example" (change)="selectCodeType($event.target.value)" id="inputTypeSelect"  name="typeSelect">
              <option>department</option>
              <option>occupation</option>
              <option>transaction</option>
              <option>deduction</option>
            </select>
          </div>
        </div>
      </div> -->

    </div>

    <div class="subcontainerLower">

      <div *ngIf="setting == 'Add'" class="row lowerTable">

        <div class="col tabSubcontainer">
          <form (ngSubmit)="onSubmit()">

            <div class="formHead">
              <h4 class="formHeading">Add a Tax</h4>
            </div>

            <div class="form-row">

              <div class="form-group col-md-4">
                <label for="inputTaxRegion">Region</label>
                <input type="text" class="form-control" [(ngModel)]="tax.region" id="inputTaxRegion" name="taxRegion" placeholder="xxx-xxxx-xxxx">
              </div>
              <div class="form-group col-md-4">
                <label for="inputTaxType">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="tax.type" id="inputTaxType"  name="taxType">
                  <option>PAYE</option>
                  <option>Ed. Tax</option>
                  <option>NHT</option>
                  <option>HEART</option>
                  <option>NIS</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="inputTaxCode">Tax Code</label>
                <input type="text" class="form-control" [(ngModel)]="tax.taxCode" id="inputTaxCode" name="taxCode" placeholder="xxx-xxxx-xxxx">
              </div>
            </div>


            <div class="form-row">
              <div class="form-group col-md-6">
                <button (click)="addThresholdInput('employee')" type="button" class="btn addThreshBtn"><p class="btnText "> + Employee Tier</p></button>
                <button (click)="removeThresholdInput('employee')" type="button" class="btn removeThreshBtn"><p class="btnText "> - Employee Tier</p></button>
              </div>
              <div class="form-group col-md-6">
                <button (click)="addThresholdInput('employer')" type="button" class="btn addThreshBtn"><p class="btnText "> + Employer Tier</p></button>
                <button (click)="removeThresholdInput('employer')" type="button" class="btn removeThreshBtn"><p class="btnText "> - Employer Tier</p></button>
              </div>
            </div>


            <div class="taxTierFormGroup">
              <h6 class="formHeading" *ngIf="employeeThresholds.length > 0">Employee Tax Tier</h6>
              <div class="form-row" *ngFor="let employeeTier of employeeThresholds; let i = index">
                <div class="form-group col-md-6" >
                  <label for="inputTaxThreshold">Threshold</label>
                  <input type="number" class="form-control" [(ngModel)]="employeeThresholds[i].threshold" id="inputTaxThreshold" name="taxThreshold" placeholder="xxx-xxxx-xxxx">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputTaxRate">Rate</label>
                  <input type="number" class="form-control" [(ngModel)]="employeeThresholds[i].rate" id="inputTaxRate" name="taxRate" placeholder="xxx-xxxx-xxxx">
                </div>
              </div>
            </div>


            <div class="taxTierFormGroup">
              <h6 class="formHeading" *ngIf="employerThresholds.length > 0">Employer Tax Tier</h6>
              <div class="form-row" *ngFor="let employerTier of employerThresholds; let j = index">
                <div class="form-group col-md-6" >
                  <label for="inputTaxThreshold2">Threshold</label>
                  <input type="number" class="form-control" [(ngModel)]="employerThresholds[j].threshold" id="inputTaxThreshold2" name="taxThreshold2" placeholder="xxx-xxxx-xxxx">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputTaxRate2">Rate</label>
                  <input type="number" class="form-control" [(ngModel)]="employerThresholds[j].rate" id="inputTaxRate2" name="taxRate2" placeholder="xxx-xxxx-xxxx">
                </div>
              </div>
            </div>


            <button type="submit" class="btn btn-primary">Create</button>
          </form>
        </div>

      </div>

      <div *ngIf="setting == 'List'" class="row lowerTable">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="paye-tab" data-toggle="tab" href="#paye" type="button" role="tab" aria-controls="paye" aria-selected="true">PAYE</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="edTax-tab" data-toggle="tab" href="#edTax" type="button" role="tab" aria-controls="edTax" aria-selected="false">Ed. Tax</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="nht-tab" data-toggle="tab" href="#nht" type="button" role="tab" aria-controls="nht" aria-selected="false">NHT</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="heart-tab" data-toggle="tab" href="#heart" type="button" role="tab" aria-controls="heart" aria-selected="false">HEART</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="nis-tab" data-toggle="tab" href="#nis" type="button" role="tab" aria-controls="nis" aria-selected="false">NIS</button>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="paye" role="tabpanel" aria-labelledby="paye-tab">

            <div class="col tabSubcontainer">
              <ul class="taxTypeListTop">
                <li class="taxTypeListTopItem" *ngFor="let tax of payeTaxes; let x = index">

                  <ul class="taxTypeList">
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Region:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.region}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Type:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.type}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Tax Code:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.taxCode}}
                      </p>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a role="button" aria-expanded="false" aria-controls="collapseItem1"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employee:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem1" *ngFor="let threshold of tax.taxTierEmployee; let k = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseA'+k" aria-expanded="false" aria-controls="collapseA" (click)="selectThreshold('employee',k)"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>

                            <!-- </a> -->
                            <!-- <fa-icon *ngIf="selectedEmployeeThreshold != k" [icon]="faChevronRight" class=" expandIcon"></fa-icon>
                            <fa-icon *ngIf="selectedEmployeeThreshold == k" [icon]="faChevronDown" class=" expandIcon"></fa-icon> -->

                            <div [attr.id]="'collapseA'+k" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a href="#collapseItem2" role="button" aria-expanded="false" aria-controls="collapseItem2"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employer:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem2" *ngFor="let threshold of tax.taxTierEmployer; let n = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseB'+n" aria-expanded="false" aria-controls="collapseA" (click)="selectThreshold('employer',n)"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <!-- <fa-icon *ngIf="selectedEmployerThreshold != n" [icon]="faChevronRight" class=" expandIcon"></fa-icon>
                            <fa-icon *ngIf="selectedEmployerThreshold == n" [icon]="faChevronDown" class=" expandIcon"></fa-icon> -->

                            <div [attr.id]="'collapseB'+n" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </li>

                  </ul>

                </li>
              </ul>
            </div>

          </div>

          <div class="tab-pane fade" id="edTax" role="tabpanel" aria-labelledby="edTax-tab">

            <div class="col tabSubcontainer">

              <ul class="taxTypeListTop">
                <li class="taxTypeListTopItem" *ngFor="let tax of edTaxes">

                  <ul class="taxTypeList">
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Region:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.region}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Type:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.type}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Tax Code:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.taxCode}}
                      </p>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a role="button" aria-expanded="false" aria-controls="collapseItem1"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employee:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem1" *ngFor="let threshold of tax.taxTierEmployee; let k = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" [attr.data-target]="'#collapseA'+k" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseA'+k" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a role="button" aria-expanded="false" aria-controls="collapseItem2"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employer:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem2" *ngFor="let threshold of tax.taxTierEmployer; let n = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" [attr.data-target]="'#collapseB'+n" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseB'+n" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </li>

                  </ul>

                </li>
              </ul>

            </div>

          </div>

          <div class="tab-pane fade" id="nht" role="tabpanel" aria-labelledby="nht-tab">
            <div class="col tabSubcontainer">

              <ul class="taxTypeListTop">
                <li class="taxTypeListTopItem" *ngFor="let tax of nhtTaxes">

                  <ul class="taxTypeList">
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Region:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.region}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Type:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.type}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Tax Code:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.taxCode}}
                      </p>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a data-toggle="collapse" href="#collapseItem1" role="button" aria-expanded="false" aria-controls="collapseItem1"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employee:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem1" *ngFor="let threshold of tax.taxTierEmployee; let k = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseA'+k" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseA'+k" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a data-toggle="collapse" href="#collapseItem2" role="button" aria-expanded="false" aria-controls="collapseItem2"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employer:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem2" *ngFor="let threshold of tax.taxTierEmployer; let n = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseB'+n" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseB'+n" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </li>

                  </ul>

                </li>
              </ul>

            </div>
          </div>

          <div class="tab-pane fade" id="heart" role="tabpanel" aria-labelledby="heart-tab">
            <div class="col tabSubcontainer">

              <ul class="taxTypeListTop">
                <li class="taxTypeListTopItem" *ngFor="let tax of heartTaxes">

                  <ul class="taxTypeList">
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Region:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.region}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Type:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.type}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Tax Code:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.taxCode}}
                      </p>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a data-toggle="collapse" href="#collapseItem1" role="button" aria-expanded="false" aria-controls="collapseItem1"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employee:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem1" *ngFor="let threshold of tax.taxTierEmployee; let k = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseA'+k" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseA'+k" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a data-toggle="collapse" href="#collapseItem2" role="button" aria-expanded="false" aria-controls="collapseItem2"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employer:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem2" *ngFor="let threshold of tax.taxTierEmployer; let n = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseB'+n" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseB'+n" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </li>

                  </ul>

                </li>
              </ul>

            </div>
          </div>

          <div class="tab-pane fade" id="nis" role="tabpanel" aria-labelledby="nis-tab">
            <div class="col tabSubcontainer">

              <ul class="taxTypeListTop">
                <li class="taxTypeListTopItem" *ngFor="let tax of nisTaxes">

                  <ul class="taxTypeList">
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Region:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.region}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Type:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.type}}
                      </p>
                    </li>
                    <li class="taxTypeListItem ilb">
                      <h6 class="taxTypeListItemHead">
                        Tax Code:
                      </h6>
                      <p class="taxTypeListItemTopText">
                        {{tax.taxCode}}
                      </p>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a data-toggle="collapse" href="#collapseItem1" role="button" aria-expanded="false" aria-controls="collapseItem1"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employee:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem1" *ngFor="let threshold of tax.taxTierEmployee; let k = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseA'+k" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseA'+k" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="taxTypeListItem">
                      <!-- <a data-toggle="collapse" href="#collapseItem2" role="button" aria-expanded="false" aria-controls="collapseItem2"> -->
                      <h6 class="taxTypeListItemHead">
                        Tax Tier Employer:
                      </h6>
                      <!-- </a> -->

                      <div class="threshholdAccordion" id="collapseItem2" *ngFor="let threshold of tax.taxTierEmployer; let n = index">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <!-- <a href="javascript:" class="accordion-button collapsed" type="button" data-toggle="collapse" [attr.data-target]="'#collapseB'+n" aria-expanded="false" aria-controls="collapseA"> -->
                              <h6 class="accordion-header" id="headingOne">
                              Threshold
                              </h6>
                            <!-- </a> -->

                            <div [attr.id]="'collapseB'+n" class="accordion-collapse" aria-labelledby="headingA" data-parent="#accordionExample">
                              <div class="accordion-body">

                                <ul class="threshholdList">
                                  <li class="threshholdListItem">
                                    <strong>Threshold</strong>$ {{threshold.threshold.toFixed(2)}}
                                  </li>
                                  <li class="threshholdListItem">
                                    <strong>Rate</strong> {{threshold.rate.toFixed(2)}}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </li>

                  </ul>

                </li>
              </ul>

            </div>
          </div>

        </div>

      </div>

    </div>
  </div>

</div>



<!-- <div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)=" codeAction('edit')">
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Edit</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="codeAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div> -->

<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
