
<div class="pageContainerSidebar">


    <div class="companyDetailsContainer">
      <div class="subcontainerUpper row">

        <div class="upperUpper">
          <h2 class="lowerHeader">Upload Wizard</h2>
        </div>

        <div class="dragDropBox">

          <div class="form-group col-md-12 fileDropZone">
            <ngx-file-drop id="employeeUploadDragDrop"
            contentClassName="dragDropText" dropZoneClassName="dropZone" dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
            style="margin:0 auto;width:100%;height: 10rem;border-radius: 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color:#ffffff;">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  <p class="dragDroptext">Drag & Drop OR
                    <input type="file" (change)="selectFile($event.target.files)" id="select2">
                  </p>
                </ng-template>
            </ngx-file-drop>

          </div>
        </div>

      </div>

      <div class="subcontainerLower">
        <div class="row lowerHeading">
          <h3 class="lowerHeader" style="flex: 1;">Master File Management</h3>
          <div class="col-4 btnCol" style="flex: 1;"><button type="button" (click)="publish()" class="btn createBtn"><p class="btnText">upload</p></button></div>

        </div>

        <!-- <div claa="pageNavDiv"> -->
          <nav aria-label="Page navigation example" class="navNav">
            <ul class="pagination pageNavDiv">
              <li class="page-item">
                <a class="page-link" href="javascript:" aria-label="Previous" (click)="stepPage('previous')">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li *ngFor="let page of pagesArray; let n = index" class="page-item"><a class="page-link" href="javascript:" (click)="stepPage(n+1)"><span class="pageNumberHighlight" *ngIf="currentPage == n+1">{{n+1}}</span><span *ngIf="currentPage != n+1">{{n+1}}</span></a></li>
              <li class="page-item">
                <a class="page-link" href="javascript:" aria-label="Next" (click)="stepPage('next')">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        <!-- </div> -->

        <div class="row lowerTable">
          <table class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">First name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Last name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">TRN</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">NIS</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Date of Birth</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Gender</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Occupation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Department</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
            </tr>
            <tr *ngFor="let item of employees">
              <td class="tableCels">{{item.firstName}}</td>
              <td class="tableCels">{{item.lastName}}</td>
              <td class="tableCels">{{item.trn}}</td>
              <td class="tableCels">{{item.nis}}</td>
              <!-- <td class="tableCels">{{item.dob}}</td> -->
              <td *ngIf="item.dob == '...'" class="tableCels">{{item.dob}}</td>
              <td *ngIf="item.dob != '...'" class="tableCels">{{item.dob | date}}</td>
              <td class="tableCels">{{item.gender}}</td>
              <td class="tableCels">{{item.employment.occupation.code}}</td>
              <td class="tableCels">{{item.employment.department.code}}</td>
              <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,item)" ><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
            </tr>

          </table>
        </div>

      </div>
    </div>

</div>


<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="viewEmployee()">
        <fa-icon [icon]="faSearch" class=" actionsIcon" ></fa-icon><p class="cellText">View</p>
      </a>
    </li>
  </ul>
</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="!loading" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeEmpView()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <img *ngIf="loading" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="!loading" >{{modalMessage}}</h5>
        <h5 *ngIf="publishing == true" >{{modalMessage2}}</h5>

        <ul *ngIf="viewingEmployee == true" class="viewEmployeeList">
          <li class="viewEmployeeListItem">
            <div class="row viewEmpLiRow">
              <div class="col-sm-4 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  First name:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.firstName}}
                </p>
              </div>
              <div class="col-sm-4 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  Middle name:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.middleName}}
                </p>
              </div>
              <div class="col-sm-4 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  Last name:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.lastName}}
                </p>
              </div>
            </div>
          </li>
          <li class="viewEmployeeListItem">
            <div class="row viewEmpLiRow">
              <div class="col-sm-6 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  Gender:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.gender}}
                </p>
              </div>
              <div class="col-sm-6 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  Dob:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.dob}}
                </p>
              </div>
            </div>
          </li>
          <li class="viewEmployeeListItem">
            <div class="row viewEmpLiRow">
              <div class="col-sm-6 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  TRN:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.trn}}
                </p>
              </div>
              <div class="col-sm-6 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  NIS:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.nis}}
                </p>
              </div>
            </div>
          </li>
          <li class="viewEmployeeListItem">
            <div class="row viewEmpLiRow">
              <div class="col-sm-6 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  Occupation code:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.employment.occupation.code}}
                </p>
              </div>
              <div class="col-sm-6 viewEmpLiCol">
                <p class="viewEmpText bold2">
                  Department code:
                </p>
                <p class="viewEmpText">
                  {{actionsBoxEmployee.employment.department.code}}
                </p>
              </div>
            </div>
          </li>

        </ul>

        <button type="button" class="btn createBtn" *ngIf="viewingEmployee != true && publishing != true"  (click)="finish()">Finish</button>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
