<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Payroll History</h3>
      </div>

      <div class="row input">

        <div class=" btnCol">
          <button type="button" class="btn showBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Show Open Payrolls</p></button>
        </div>
        <div class=" btnCol">
          <button type="button" class="btn createBtn"><p class="btnText ">Open Payroll</p></button>
        </div>
        <div class=" btnCol">
          <button type="button" class="btn createBtn"><p class="btnText ">Pay Schedule</p></button>
        </div>

        <div class=" btnCol">
          <button type="button" class="btn createBtn"><p class="btnText ">Commit Payroll</p></button>
        </div>

        <div class=" btnCol">
          <button type="button" class="btn createBtn"><p class="btnText ">Upload Timesheet</p></button>
        </div>


      </div>

      <div class="upperColCont" *ngIf="setting == 'Detail'">

        <!-- <div class="col-4 upperCol">
          <ul class="upperColList">
            <li class="upperColListItem">
              <p class="upperListItemText">Gross: <span class="bold" span>$ {{companyPaySummary.gross}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Net: <span class="bold" span>$ {{companyPaySummary.net}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Employees: <span class="bold" span>{{companyPaySummary.totalEmployees}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">NIS: <span class="bold" span>{{companyPaySummary.nis}}</span></p>
            </li>
          </ul>
        </div>
        <div class="col-4 upperCol">
          <ul class="upperColList">
            <li class="upperColListItem">
              <p class="upperListItemText">TRN: <span class="bold" span>{{company.tax}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Edu Tax: <span class="bold" span>$ {{companyPaySummary.edu}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">NHT: <span class="bold" span>$ {{companyPaySummary.nht}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Heart: <span class="bold" span>$ {{companyPaySummary.heart}}</span></p>
            </li>
          </ul>
        </div>
        <div class="col-4 upperCol">
          <ul class="upperColList">
            <li class="upperColListItem">
              <p class="upperListItemText">Total Payroll: <span class="bold" span>{{companyPaySummary.totalPayroll}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Payroll Start Date: <span class="bold" span>{{companyPaySummary.payrollStartDate}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Payroll End Date: <span class="bold" span>{{companyPaySummary.payrollEndDate}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Pay Cycle: <span class="bold" span>{{companyPaySummary.payCycle.period}}</span></p>
            </li>
          </ul>
        </div> -->

      </div>


    </div>

    <div class="subcontainerLower">

        <div class="row lowerTable" *ngIf="setting == 'Schedule'">

          <!-- <div class="form-row">
            <div class="col-md-4 form-group">
                <select class="form-control" aria-label="Default select example"  (change)="selectSheduleCycle($event.target.value)" id="inputCycleSelect"  name="typeSelect">
                  <option value="weekly" selected="selected">Weekly</option>
                  <option value="fortnightly">Fortnighty</option>
                  <option value="bimonthly">Bi-Monthly</option>
                  <option value="monthly">Monthly</option>
                </select>
            </div>
            <div class="col-md-4 form-group">
              <div class=" btnCol">
                <button (click)="showPaySchedule()" type="button" class="btn createBtn longBtn"><p class="btnText ">Get Pay Schedule</p></button>
              </div>
            </div>
          </div>

          <table class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">Pay Period</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Start Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">End Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Pay Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
            </tr>
            <tr *ngFor="let item of paySchedule; let i = index" class="tableRow">
              <td class="tableCels"><p class="cellText">{{item.payPeriod}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.startDate}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.endDate}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.payDate}}</p></td>
            </tr>
          </table> -->

        </div>

        <div class="row lowerTable" *ngIf="setting == 'List'">

          <!-- <h3 class="lowerHeader" *ngIf="error == true">{{modalMessage}}</h3>
          <ul class="dashboardClientList">
            <li class="dashboardClientListItem" *ngFor="let payroll of openPayrolls">
              <div class="payrollCard">

                <table class="customerCardTable">
                  <tr class="customerCardTableRowTop">
                      <h6 class="customerCardHeading">{{payroll.payCycle.period}} - {{payroll.type}}</h6>
                  </tr>
                  <tr class="customerCardTableRow">
                    <td class="tableLeftCol">
                      <fa-icon [icon]="faUserFriends" class="fa-lg customerCardTableIcon"></fa-icon>
                      <p class="customerCardText">Pay Date</p>
                    </td>
                    <td class="tableRightCol">
                      <p class="customerCardText">{{payroll.payCycle.payDateOffset}}</p>
                    </td>
                  </tr>
                  <tr class="customerCardTableRow">
                    <td class="tableLeftCol">
                      <fa-icon [icon]="faRecycle" class="fa-lg customerCardTableIcon"></fa-icon>
                      <p class="customerCardText">Cycle</p>
                    </td>
                    <td class="tableRightCol">
                      <p class="customerCardText">{{payroll.type}}</p>
                    </td>
                  </tr>
                  <tr class="customerCardTableRow">
                    <td class="tableLeftCol">
                      <fa-icon [icon]="faMoneyCheckAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                      <p class="customerCardText">Start Date</p>
                    </td>
                    <td class="tableRightCol">
                      <p class="customerCardText">{{payroll.payCycle.startDate}}</p>
                    </td>
                  </tr>
                  <tr class="editBtnRow">
                    <button type="button" (click)="openPayroll(payroll)" class="btn customerEditBtn">Open</button>
                  </tr>
                </table>
              </div>
            </li>
          </ul> -->

        </div>

        <div class="row lowerTable" *ngIf="setting == 'Add'">

          <!-- <div class="codeAdd">
            <form (ngSubmit)="onSubmit('department')">

              <div class="form-row">

                <div class="form-group col">
                  <label for="inputPayrollType">Type</label>
                  <select class="form-control" aria-label="Default select example" [(ngModel)]="data.payrollType" id="inputTypeSelect"  name="typeSelect">
                    <option>weekly</option>
                    <option>fortnightly</option>
                    <option>bimonthly</option>
                    <option>monthly</option>
                  </select>
                </div>
              </div>

              <button type="submit" class="btn btn-primary">Create</button>
            </form>
          </div> -->

        </div>

      <div class="row lowerTable" *ngIf="setting == 'Detail'">


        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="paySummary-tab" data-toggle="tab" href="#paySummary" type="button" role="tab" aria-controls="paySummary" aria-selected="true">Pay Summary</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="mtdData-tab" data-toggle="tab" href="#mtdData" type="button" role="tab" aria-controls="mtdData" aria-selected="false">MTD Data</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="ytdData-tab" data-toggle="tab" href="#ytdData" type="button" role="tab" aria-controls="ytdData" aria-selected="false">YTD Data</button>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="paySummary" role="tabpanel" aria-labelledby="paySummary-tab">

            <!-- <table class="table">
              <tr class="tableRow">
                <th class="tableHeadings">
                  <p class="headText">Employee Name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Employee Id</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Gross</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Statutory Deduction</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Net</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
              </tr>
              <tr *ngFor="let payItem of paySummary; let i = index" class="tableRow">
                <td class="tableCels"><p class="cellText">{{payItem.employee}}</p></td>
                <td class="tableCels"><p class="cellText">{{payItem.employeeId}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{payItem.gross}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{payItem.statDeduction}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{payItem.net}}</p></td>
                <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,payItem)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
              </tr>
            </table> -->

          </div>

          <div class="tab-pane fade" id="mtdData" role="tabpanel" aria-labelledby="mtdData-tab">

          </div>

          <div class="tab-pane fade" id="ytdData" role="tabpanel" aria-labelledby="ytdData-tab">

            <!-- <table class="table">
              <tr class="tableRow">
                <th class="tableHeadings">
                  <p class="headText">Employee Name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Gross</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Statutory Deduction</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Net</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
              </tr>
              <tr *ngFor="let ytdData of selectedPayrollYtd; let j = index" class="tableRow">
                <td class="tableCels"><p class="cellText">{{ytdData[0].employee}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{ytdData[0].gross}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{ytdData[0].statDeduction}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{ytdData[0].net}}</p></td>
              </tr>
            </table> -->


          </div>

        </div>

      </div>

    </div>
  </div>

</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>

      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
