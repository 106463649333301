import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Defaults } from 'src/app/Models/Defaults';
import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { CompanyPaySummary } from 'src/app/Models/CompanyPaySummary';
import { Code } from 'src/app/Models/Code';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';

import { Employee } from 'src/app/Models/Employee';

import { Payroll } from 'src/app/Models/Payroll';

import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from '@angular/common';

import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const $: any;

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-payroll-management',
  templateUrl: './payroll-management.component.html',
  styleUrls: ['./payroll-management.component.css']
})
export class PayrollManagementComponent implements OnInit {

  faPlus = faPlus;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faUserFriends = faUserFriends;
  faRecycle = faRecycle;
  faMoneyCheckAlt = faMoneyCheckAlt;
  faFileAlt = faFileAlt;
  faCalendarAlt = faCalendarAlt;

  public url: string;
  url2 : string;
  url3 : string;
  url4 : string;
  url5 : string;
  url6 : string;

  public id: string = '';
  public currentUser: any = {};
  public setting: string = 'List';
  public listType: string = 'open';
  public payrollId: string = '';
  public openPayrolls: Array<Payroll> = [];
  public closedPayrolls: Array<Payroll> = [];
  public allPayrolls: Array<Payroll> = [];
  public selectedPayroll: Payroll = Defaults.PayrollDefault();
  public selectedPayrollCommitted: Boolean = false;
  public showReports: Boolean = false;

  public paySummary: Array<any> = [];
  public paySummarySrch: Array<any> = [];
  public paySummarySearchField: any = 'employee';
  public paySumSearchQuery: any = '';

  public paySummaryLength: number;
  public selectedPayItem: any = {};
  public s01PaySummary: Array<any> = [];
  public s01PaySummaryLength: number;
  public companyPaySummary: CompanyPaySummary = Defaults.CompanyPaySummaryDefault();

  public selectedPayrollYtd: Array<any> = [];
  public selectedYtdData: any = {};


  public payScheduleCycle: string = 'weekly';
  public paySchedule: Array<any> = [];
  public showUpload: Boolean = false;

  employee: Employee = Defaults.EmployeeDefault();
  public payitemEmployee: Employee = Defaults.EmployeeDefault();
  public payslipEmployee: Employee = Defaults.EmployeeDefault();
  public employees: Array<Employee> = [];
  public employeeYtd: any = {};
  public employeeSrch: Array<Employee> = [];
  public addEmpList: Array<Employee> = [];
  public searchField: any = 'firstName';
  public toggleSearch: boolean = false;
  public newEmpSearchQuery: string = "";
  public payItemEmployeeList: Array<Employee> = [];

  public totalEmployeeCount: number = 0;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];

  public sortDirection; string = "Ascending";

  public newEmployeeNewTransaction: boolean = false;
  public newEmployee: Employee = Defaults.EmployeeDefault();

  company: Company = Defaults.CompanyDefaults();
  public companyLogoBase64: any;
  public payslipLogo: boolean = true;
  public addLogo: boolean = false;
  public uploadLogo: boolean = false;
  public filePresent: boolean = false;

  public data: any = {};

  public error: Boolean = false;
  public loading: Boolean = false;
  public modalMessage: string = '';

  public uploadTimesheet: Boolean;
  public files: NgxFileDropEntry[] = [];

  actionsBoxState: Boolean = false;
  public s01Select: Boolean = false;

  selectedS01: Array<any> = [];
  s01Selected: Boolean = false;


  allCodes: Array<any> = [];
  departmentCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  healthCode: HealthCode = Defaults.HealthCodeDefault();
  occupationCode: DepartmentOccupationCode = Defaults.DepartmentOccupationCodeDefault();
  transactionCode: TransactionCode = Defaults.TransactionCodeDefault();
  deductionCode: DeductionCode = Defaults.DeductionCodeDefault();
  loanCode: LoanCode = Defaults.LoanCodeDefault();
  bankCode: BankCode = Defaults.BankCodeDefault();
  pensionCode: PensionCode = Defaults.PensionCodeDefault();

  departmentCodes: Array<DepartmentOccupationCode> = [];
  healthCodes: Array<HealthCode> = [];
  occupationCodes: Array<DepartmentOccupationCode> = [];
  transactionCodes: Array<TransactionCode> = [];
  deductionCodes: Array<DeductionCode> = [];
  loanCodes: Array<LoanCode> = [];
  pensionCodes: Array<PensionCode> = [];
  bankCodes: Array<BankCode> = [];

  bankingCodes: Array<BankCode> = [];

  public showTransactions: boolean = false;
  public employeeTransactions: Array<any> = [];
  public transactionEdit: boolean = false;
  public transactionAdd: boolean = false;
  public selectedTransaction: any = {};
  public newTransaction: any = {};
  public newEmployeeTransactions: Array<any> = [];
  public newEmpMessage: string = '';
  public transactionAmtDisable: boolean = false;
  public transactionHrsDisable: boolean = false;

  downloadBankFIleNCB: boolean = false;
  downloadBankFIleBNS: boolean = false;
  textFile: any = undefined;
  link: any = undefined;

  selectedTaxHeader: any = {};
  selectedTaxTier: any = {};
  payrollTaxHeaders: Array<any> = [];
  taxHeadIndx: number = 0;
  editTaxHeader: boolean = false;
  selectedTaxTierType: string = '';
  selectedTaxTierIndex: number = 0;
  deptSummaryDateInput: boolean = false;
  deptSummarySelectedYear: any = "";

  canClose: boolean = true;
  canNCB: boolean = false;
  canFGB: boolean = false;
  canBNS: boolean = false;
  S02ASCIILink: string = "";
  showS02Link: boolean = false;
  showBNSLink: boolean = false;
  showNCB: boolean = false;
  BNSUrl: string = "";
  S01FormLink: string = "";
  showS01FormLink: boolean = false;

  ytdArray: Array<any> = [];
  isPayCalc: boolean = false;

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log('file!!',droppedFile.relativePath, file, droppedFile, file.type);

          const formData = new FormData()
          formData.append(file.name, file)

          if (this.uploadLogo === true) {
            this.uploadLogoFunc(formData)
          } else {
            this.parseTimesheetUpload(file)
          }

          // this.payslipUpload(formData)
          // add file to data

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  public fileOver(event){

  }
  public fileLeave(event){}


  constructor(
    private api: ApiService,
    private location:Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) {

  }

  ngOnInit() {

    this.link = document.getElementById('downloadlink');

    $('.actionsBox').toggle();
    $('.actionsBox2').toggle();
    $('.actionsBox3').toggle();
    $('.s01Action').toggle();

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.currentUser = JSON.parse(localStorage.getItem("admin"));
    if (this.currentUser.permissions.includes('close payroll') === true) {
      this.canClose = false;
    }


    this.getOpenPayrolls();

    (async () => {
      await this.getBankingCodes();
      this.getCompany();
    })();



    $('#errorModal').on('hidden.bs.modal', () => {
      this.s01Select = false;
      this.allPayrolls = [];
      this.selectedS01 = [];

    })


    let codeTypes = ['department','occupation','transaction','deduction','loan','pension','health'];
    for (const type of codeTypes) {
      this.getCode(type)
    }


    window.addEventListener('click', (e) => this.handleClick(e.target));

    $(".dropdown").on("click", ".dropdown-toggle", (e) => {this.handleDropdownClick(e.target)});

  }

  stepPage(args) {


    if (
      args === 'previous' &&
      this.currentPage > 1
    ) {
      this.getEmployeesByPage(false,'previous');
    }

    if (args === 'next' && this.currentPage < this.totalPages) {
      this.getEmployeesByPage(false,'next');
    }
  }
  getEmployeesByPage(init,direction) {

    this.loading = true;
    $("#errorModal").modal("show");

    let start = 0;
    let count = 20;
    if (direction === 'next') {
      this.currentPage++
    }
    if (direction === 'previous') {
      this.currentPage--
    }

    let customCurrentPage = 1;
    if (direction !== 'next' && direction !== 'previous') {
      customCurrentPage = this.currentPage;
      this.currentPage = direction;
    }
    start = (this.currentPage-1)*20;
    if (init === true) {
      start = 0;
    }

    this.api.getEmployeesByPage(start,count,this.id).subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");


        if (init === true) {
          this.currentPage = 1;
          this.totalPages = Math.ceil(next.count/20);
          this.pagesArray = [];
          for (let index = 0; index < this.totalPages; index++) {
            this.pagesArray.push("")
          }
        } else {

          if (next.count !== this.totalEmployeeCount) {
            this.totalPages = Math.ceil(next.count/20);
            console.log('emp count has changed');
            this.pagesArray = [];
            for (let index = 0; index < this.totalPages; index++) {
              this.pagesArray.push("")
            }
          }

        }

        this.employeeSrch = next.data;
        this.employees = next.data;
        this.totalEmployeeCount = next.count;

        this.loading = false;
        $("#errorModal").modal("hide");


      },
      error => {

        if (direction === 'next') {
          this.currentPage--
        }
        if (direction === 'previous') {
          this.currentPage++
        }
        if (direction !== 'next' && direction !== 'previous') {
          this.currentPage = customCurrentPage;
        }

        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getEmployeesByPage!";
        }
        $("#errorModal").modal("show");
      }
    )

  }
  getEmployees() {

    this.loading = true;
    // $("#errorModal").modal("show");

    this.api.getEmployees(this.id).subscribe(next => {

      this.employeeSrch = next;
      this.employees = next;
      console.log('this.employeeSrch',this.employeeSrch);
      this.loading = false;
    },
    error => {
      this.loading = false;
      console.log('api request errorx',error);
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployees!"
      }
      $("#errorModal").modal("show");
    })

  }
  clearSearch() {
    this.toggleSearch = false;
    this.getEmployeesByPage(true,'');
  }

  selectSortDirection(args) {
    this.sortDirection = args;
  }

  handleClick(eventTarget) {

    let arr1 = ['','actionsBoxLink','actionsBox','actionsBox2','actionsBox3','actionsBoxList','actionsBoxListItem','s01Action','fa-ellipsis-v'];
    if (eventTarget.classList) {

      let action = '';
      for (const elem of arr1) {
        if (eventTarget.classList.value.split(' ').includes(elem) || eventTarget.classList.value === '') {
          action = 'show'
        }
        if (!eventTarget.classList.value.split(' ').includes(elem) && action !== 'show') {
          action = 'hide'
        }
      }
      if (action === 'hide') {
        $('.actionsBox').hide();
        $('.actionsBox2').hide();
        $('.actionsBox3').hide();
        $('.s01Action').hide();
      }

    }

  }
  handleDropdownClick(eventTarget) {

    // console.log('clicked dropdown',eventTarget);

    $(eventTarget).parent().addClass("show");
    $(eventTarget).attr("aria-expanded", "true");
    $(eventTarget).next().addClass("show");

  }

  getEmployeesBySearch(query) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getEmployeesBySearch(query,this.id).subscribe(next =>{

      console.log('getEmployeesBySearch',next);
      this.employeeSrch = [];

      for (const elem of next) {
        // console.log(elem.data);
        this.employeeSrch.push(elem.data)
      }
      // this.employeeSrch = next;

      this.loading = false;
      $("#errorModal").modal("hide");


    },
    error => {
      this.loading = false;
      this.modalMessage = "Error: getEmployeesBySearch!";
      $("#errorModal").modal("show");
    })

  }

  getCode(type) {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCode(this.id,type).subscribe(codes => {;

      this[type+'Codes'] = codes;

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCode "+type+""
      }

      $("#errorModal").modal("show");
    })

  }

  async getBankingCodes() {

    this.loading = true;
    $("#errorModal").modal("show");

    let next = await this.api.getBankingCodes().toPromise();

    // console.log("testing sub", next);

    this.bankingCodes = next;
      // console.log('getBankingCodes',next);


    this.loading = false;
    $("#errorModal").modal("hide");

    // this.api.getBankingCodes().subscribe(next => {



    // error => {
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error: getBankingCodes!"
    //   }
    //   $("#errorModal").modal("show");
    // })

  }

  getCompany () {

    this.api.getCompany(this.id).subscribe(companyData =>{

      this.company = companyData;
      this.api.getCompanyBaseImage(companyData.id,companyData.type).subscribe(companyLogo =>{
        this.companyLogoBase64 = companyLogo;

      },
      // error => {
      //   this.loading = false;
      //   this.error = true;
      //   if (error.error) {
      //     this.modalMessage = error.error.status
      //   } else {
      //     this.modalMessage = "Error: getEmployees!"
      //   }
      //   $("#errorModal").modal("show");
      // }
    );


      let whichBank;
      // let whichBank2 = this.bankingCodes.find(x=>x.id === this.company.banking[0].bank)
      // let whichBank = this.bankingCodes.filter(x=>x.id === this.company.banking[0].bank)[0]
      for (const bankCode of this.bankingCodes) {
        if (bankCode.id === this.company.banking[0].bank) {
          whichBank = bankCode;
          // console.log('beep',whichBank,bankCode,whichBank.name.substr(0,3));
        }
      };


      if (whichBank && whichBank.name.substr(0,3) === 'NCB') {
        this.canNCB = true;
      }
      if (whichBank && whichBank.name.substr(0,3) === 'FGB') {
        this.canFGB = true;
      }
      if (whichBank && whichBank.name.substr(0,3) === 'BNS') {
        this.canBNS = true;
      }

      });



      this.api.getEmployees(this.id).subscribe(
        next => {
          this.employees = next;
          this.employeeSrch = next;
          // this.loading = false;
          // $("#errorModal").modal("hide");
        },
        error => {
          this.loading = false;
          this.error = true;
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: getEmployees!"
          }
          $("#errorModal").modal("show");
        }
      )

    // });



  }
  getOpenPayrolls () {

    $('.actionsBox').hide();
    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");
    this.listType = 'open';
    this.api.getOpenPayrolls(this.id).subscribe(next => {


      for (const proll of next) {


        proll.payCycle.startDate = proll.payCycle.startDate.substr(0,10)
        proll.payCycle.endDate = proll.payCycle.endDate.substr(0,10)
        proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substr(0,10)

      }
      this.openPayrolls = next;
      console.log('getOpenPayrolls',next);


      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getOpenPayrolls!"
      }
      $("#errorModal").modal("show");
    })



  }

  payrollAction (args) {

    if (args === 'payslipGenerate') {
      this.prePayslipGen();
    }


    if (this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === this.selectedPayItem.employeeId)) {
      this.payslipEmployee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === this.selectedPayItem.employeeId).employee;
    } else {

      // console.log('this.selectedPayroll.employeeData1',this.selectedPayroll.employeeData);
      // console.log('this.selectedPayItem.employeeId',this.selectedPayItem);

    }
    // this.payslipEmployee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
    // console.log('this.payslipEmployee',this.payslipEmployee);

    $('.actionsBox').toggle();
    if (args === 'delete') {

    }

    if (args === 'payrollRegister') {
      this.prePayrollRegisterGen();
    }
    if (args === 'employeeRegister') {
      this.preEmployeeRegisterGen();
    }
    if (args === 'deductionsListing') {
      this.preDeductionListingGen();
    }
    if (args === 'lodgementListing') {
      this.preLodgementListingGen();
    }
    if (args === 'departmentSummary') {
      this.preDepartmentSummaryGen();
      // this.deptSummaryDateInput = true;
      // $("#errorModal").modal("show");

    }
    if (args === 'getTransactions') {
      this.getEmployeeTransactions()
    }
    if (args === 'addTransaction') {
      this.getEmployeeTransactions()
      this.transactionAdd = true;
      this.showTransactions = true;
    }
    if (args === 'delete') {
      this.payrollRemoveEmployee()
    }
    if (args === 'bankFileFGB') {
      this.preGenerateBankFileFGB()
    }
    if (args === 'bankFileNCB') {
      this.preGenerateBankFileNCB()
    }
    if (args === 'bankFileBNS') {
      this.preGenerateBankFileBNS()
    }

  }
  transactionAction(args) {

    $('.actionsBox2').toggle();

    if (args === 'edit') {
      this.transactionEdit = true;
      this.transactionAdd = false;
    }

    if (args === "delete") {
      this.deleteTransaction()
    }
  }
  taxHeaderAction(args) {

    if (args === 'edit') {
      this.editTaxHeader = true;
    }
  }

  actionsBox (event,payItem) {

    if (this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === payItem.employeeId)) {
      this.payitemEmployee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === payItem.employeeId).employee;
      this.selectedPayItem = payItem;
    } else {

      console.log('this.selectedPayroll.employeeData3',this.selectedPayroll.employeeData);
      // console.log('payItem.employeeId',payItem.employeeId);

    }
    // this.payitemEmployee = this.employees.find(x=>x.employeeId === payItem.employeeId)
    console.log('payitemEmployee',payItem,this.payitemEmployee);


    if (this.listType === 'closed') {
      $('.actionsBox').css({'top':event.pageY-70,'left':event.pageX-150,'height':'3rem'})
    } else {
      $('.actionsBox').css({'top':event.pageY-110,'left':event.pageX-150,'height':'9rem'})
    }

    $('.actionsBox').toggle();
    this.actionsBoxState = !this.actionsBoxState;

  }
  actionsBox2 (event,transaction) {

    this.selectedTransaction = transaction;
    // console.log('this.selectedTransaction',this.selectedTransaction );

    $('.actionsBox2').css({'top':event.pageY-90,'left':event.pageX-30})
    $('.actionsBox2').toggle();

  }
  actionsBox3 (event,taxHeader,taxTier,tierType,tierIndex) {

    this.selectedTaxHeader = taxHeader;
    this.selectedTaxTier = taxTier;
    this.selectedTaxTierType = tierType;
    this.selectedTaxTierIndex = tierIndex;

    $('.actionsBox3').css({'top':event.pageY-90,'left':event.pageX-30})
    $('.actionsBox3').toggle();

    // console.log('this.selectedTaxTier',this.selectedTaxTier);


  }

  toggleBox() {
    $('.actionsBox').hide();
  }

  yTDActionsBox (event,ytdData) {

    this.selectedYtdData = ytdData;
    $('.actionsBox').css({'top':event.pageY-100,'left':event.pageX-150})
    $('.actionsBox').toggle();

  }

  showPayrolls () {
    this.setting = 'List';
    this.listType = 'open';
    this.selectedPayrollCommitted = false;
    $('.actionsBox').hide();
  }

  async openPayroll (args) {
    // this.setting = 'Detail';

    this.selectedPayroll = args;
    // console.log('this.selectedPayroll',this.selectedPayroll);

    if (this.selectedPayroll.committed === true) {
      this.selectedPayrollCommitted = true;
    }
    if (this.selectedPayroll.committed !== true) {
      this.selectedPayrollCommitted = false;
    }

    await this.getPaySummary()
    await this.getS01PaySummary()
    await this.getYtdData('openPayroll')
    this.getCompanyPaySummary()
    this.getPayrollTaxHeaders()


    $('.actionsBox').hide();

  }
  editPayroll(args) {
    if (args !== '') {
      this.selectedPayroll = args;
    }

    if (this.setting !== 'Edit') {
      this.setting = 'Edit'
    } else {
      this.setting = 'List';
      this.listType = 'open';
    }
    $('.actionsBox').hide();
  }

  async getS01PaySummary() {

    this.loading = true;
    // this.error = false;
    $("#errorModal").modal("show");

    try {
      let next = await this.api.getSO1ScheduleReport([{payrollId:this.selectedPayroll.id,type:this.selectedPayroll.type}]).toPromise();
    } catch (error) {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getS01PaySummary!"
      }
      $("#errorModal").modal("show");
    }

    // this.api.getSO1ScheduleReport([{payrollId:this.selectedPayroll.id,type:this.selectedPayroll.type}]).subscribe(next => {

    //   this.s01PaySummary = next;
    //   this.s01PaySummaryLength = next.length;

    //   // this.loading = false;
    //   // $("#errorModal").modal("hide");

    // },
    // error => {
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error: getS01PaySummary!"
    //   }
    //   $("#errorModal").modal("show");
    // })

  }

  async getPaySummary() {

    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");

    // console.log('getPaySummary args',this.selectedPayroll.id,this.selectedPayroll.type);

    try {
      let next = await this.api.getPaySummary(this.selectedPayroll.id,this.selectedPayroll.type).toPromise();

      this.paySummary = next;
      this.paySummarySrch = next;
      this.paySummaryLength = next.length;

      if (this.isPayCalc === true) {
        this.loading = false;
        this.modalMessage = "PayCalc Success"
        setTimeout(()=>{
          $("#errorModal").modal("hide");
        }, 3000);

      }

      this.isPayCalc = false;


      for (const item of this.paySummary) {

        let payItemEmployee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === item.employeeId)
        if (payItemEmployee) {
          console.log('payitemEmployee',payItemEmployee);

          this.payItemEmployeeList.push(payItemEmployee)
        }
      }

    } catch (error) {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getPaySummary!"
      }
    }

    // this.api.getPaySummary(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

    //   // console.log('getPaySummary',next);

    //   this.paySummary = next;
    //   this.paySummarySrch = next;
    //   this.paySummaryLength = next.length;

    //   this.loading = false;
    //   $("#errorModal").modal("hide");

    // },
    // error => {
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error: getPaySummary!"
    //   }
    //   // $("#errorModal").modal("hide");
    // })

  }
  getCompanyPaySummary() {

    // this.loading = true;
    // this.error = false;
    // $("#errorModal").modal("show");

    this.api.getCompanyPaySummary(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      this.companyPaySummary = next;
      // console.log('this.companyPaySummary',this.companyPaySummary);

      this.companyPaySummary.payrollStartDate = this.companyPaySummary.payrollStartDate.substr(0,10);
      this.companyPaySummary.payrollEndDate = this.companyPaySummary.payrollEndDate.substr(0,10);

      if (next.statutories.length > 0) {
        this.companyPaySummary.statNisEmployee = next.statutories[0].employeeAmount;
        this.companyPaySummary.statNisEmployer = next.statutories[0].employerAmount;
        this.companyPaySummary.statNhtEmployee = next.statutories[1].employeeAmount;
        this.companyPaySummary.statNhtEmployer = next.statutories[1].employerAmount;
        this.companyPaySummary.statPensionEmployee = next.statutories[2].employeeAmount;
        this.companyPaySummary.statPensionEmployer = next.statutories[2].employerAmount;
        this.companyPaySummary.statEduEmployee = next.statutories[3].employeeAmount;
        this.companyPaySummary.statEduEmployer = next.statutories[3].employerAmount;
        this.companyPaySummary.statHeartEmployee = next.statutories[4].employeeAmount;
        this.companyPaySummary.statHeartEmployer = next.statutories[4].employerAmount;
        if (next.statutories[5]) {
          this.companyPaySummary.statPayeEmployee = next.statutories[5].employeeAmount;
          this.companyPaySummary.statPayeEmployer = next.statutories[5].employerAmount;
        }

      }


      // this.loading = false;
      // $("#errorModal").modal("hide");

    },
    error => {
      console.log('error',error);

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getCompanyPaySummary!"
      }
      $("#errorModal").modal("show");
    })

  }
  searchPaySummary (val) {

    if (val.length === 0) {
      this.paySummarySrch = this.paySummary;

    } else if (val.length > 0) {

      let field = this.paySummarySearchField;
      console.log('field',field);

      let temp = this.paySummary.filter(d => {

        if (
          typeof val === 'string' &&
          field !== 'statutoryDeductionTotal' &&
          field !== 'gross' &&
          field !== 'net'
        ) {
          val = val.toLowerCase();
          console.log('ystring field',field,d[field]);
          return d[field].toLowerCase().indexOf(val) !== -1 || !val;
        }
        if (typeof val === 'number' && field !== 'employeeId') {
          val = val.toFixed(2);
          console.log('xnumber field',field,d[field]);

          return d[field].indexOf(val) !== -1 || !val;
        }
        if (field === 'employeeId') {
          // val = val.toString();
          return d[field].indexOf(val) !== -1 || !val;
          // return d[field].toLowerCase().indexOf(val) !== -1 || !val;
        }
        if (field === 'statutoryDeductionTotal' || field === 'gross' || field === 'net') {
          val = parseInt(val).toFixed(2);
          console.log('d[field',d[field],this.paySummary.filter(x=>x[field].toFixed(2) === val));

          // return this.paySummary.filter(x=>x[field].toFixed(2) === val)
          return d[field].indexOf(val) !== -1 || !val;
        }

      });

      this.paySumSearchQuery = val;
      this.paySummarySrch = [...temp];

    }

  }
  clearPaySummarySearch () {
    this.paySummarySrch = this.paySummary;
    this.paySummarySearchField = "";
  }
  selectPaySumSearchField (field) {

    this.paySummarySearchField = field;
  }
  sortPaySummary() {

    let direction = this.sortDirection;
    if (this.paySummarySearchField === "employeeId") {
      if (direction === 'Ascending') {
        this.paySummarySrch = this.paySummary.sort((a, b) => (a[this.paySummarySearchField].substr(1) > b[this.paySummarySearchField].substr(1)) ? 1 : -1);
      }
      if (direction === 'Descending') {
        this.paySummarySrch = this.paySummary.sort((a, b) => (a[this.paySummarySearchField].substr(1) < b[this.paySummarySearchField].substr(1)) ? 1 : -1);
      }
    }
    if (typeof this.paySummarySrch[0][this.paySummarySearchField] === "number") {
      // console.log('num',this.paySummarySearchField);
      // console.log('num3',this.paySummary.sort((a, b) => (a[this.paySummarySearchField] > b[this.paySummarySearchField]) ? 1 : -1));
      // console.log('num4',this.paySummary.sort((a, b) => a[this.paySummarySearchField] - b[this.paySummarySearchField]));
      // console.log('num5',this.paySummary.sort((a,b) => (a[this.paySummarySearchField] > b[this.paySummarySearchField]) ? 1 : ((b[this.paySummarySearchField] > a[this.paySummarySearchField]) ? -1 : 0)));




      if (direction === 'Ascending') {
        this.paySummarySrch = this.paySummary.sort((a,b) => (a[this.paySummarySearchField] > b[this.paySummarySearchField]) ? 1 : ((b[this.paySummarySearchField] > a[this.paySummarySearchField]) ? -1 : 0))
      }
      if (direction === 'Descending') {
        this.paySummarySrch = this.paySummary.sort((a,b) => (a[this.paySummarySearchField] > b[this.paySummarySearchField]) ? 1 : ((b[this.paySummarySearchField] < a[this.paySummarySearchField]) ? -1 : 0))
      }
      // if (direction === 'Ascending') {
      //   this.paySummarySrch = this.paySummary.sort((a, b) => (a[this.paySummarySearchField].toFixed(2) > b[this.paySummarySearchField].toFixed(2)) ? 1 : -1);
      // }
      // if (direction === 'Descending') {
      //   this.paySummarySrch = this.paySummary.sort((a, b) => (a[this.paySummarySearchField].toFixed(2) < b[this.paySummarySearchField].toFixed(2)) ? 1 : -1);
      // }
    }
    else {
      if (direction === 'Ascending') {
        this.paySummarySrch = this.paySummary.sort((a, b) => (a[this.paySummarySearchField] > b[this.paySummarySearchField]) ? 1 : -1);
      }
      if (direction === 'Descending') {
        this.paySummarySrch = this.paySummary.sort((a, b) => (a[this.paySummarySearchField] < b[this.paySummarySearchField]) ? 1 : -1);
      }
    }


    // if (this.paySummarySearchField.split(".")[2]) {
    //
    //   if (direction === 'Ascending') {
    //     this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] > b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? 1 : -1);
    //   }
    //   if (direction === 'Descending') {
    //     this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]] < b[this.searchField.split(".")[0]][this.searchField.split(".")[1]][this.searchField.split(".")[2]]) ? 1 : -1);
    //   }
    //
    // } else {
    //   if (direction === 'Ascending') {
    //     this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField] > b[this.searchField]) ? 1 : -1);
    //   }
    //   if (direction === 'Descending') {
    //     this.employeeSrch = this.employees.sort((a, b) => (a[this.searchField] < b[this.searchField]) ? 1 : -1);
    //   }
    // }


    this.loading = false;
    $("#errorModal").modal("hide");

  }

  addPayroll() {
    this.setting = 'Add';
  }
  closePayroll() {

    $('.actionsBox').hide();
    $('.actionsBox2').hide();
    this.loading = true;
    $("#errorModal").modal("show");


    this.api.closePayroll(this.selectedPayroll.id).subscribe(next => {

      this.loading = false;
      this.modalMessage = "Payroll Closed"
      // $("#errorModal").modal("hide");
      this.setting = 'List';

      setTimeout(()=>{
        location.reload()
      }, 3000);


      // this.getOpenPayrolls()
    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: closePayroll!"
      }
      $("#errorModal").modal("show");
    }
  );



  }
  commitPayroll() {
    $('.actionsBox').hide();
    $('.actionsBox2').hide();
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.commitPayroll(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      this.loading = false;
      this.modalMessage = next.status;

      // $("#errorModal").modal("hide");
      this.setting = 'List';

      setTimeout(()=>{
        this.getOpenPayrolls()
      }, 3000);

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status;

      } else {
        this.modalMessage = "Error: commitPayroll!"
      }
      $("#errorModal").modal("show");
    }
  );

  }
  uncommitPayroll () {
    $('.actionsBox').hide();
    $('.actionsBox2').hide();
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.uncommitPayroll(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      // console.log('uncommitPayroll',next);

      this.modalMessage = next.status;
      this.loading = false;
      // $("#errorModal").modal("hide");
      this.setting = 'List';

      setTimeout(()=>{
        this.getOpenPayrolls()
      }, 3000);

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
        console.log('xx',error.error.status);

      } else {
        this.modalMessage = "Error: uncommitPayroll!"
      }
      $("#errorModal").modal("show");
    }
  );

  }
  resetPayroll () {
    $('.actionsBox').hide();
    $('.actionsBox2').hide();
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.resetPayroll(this.selectedPayroll.id).subscribe(next => {

      this.loading = false;
      this.modalMessage = "Payroll Reset"
      // $("#errorModal").modal("hide");

      setTimeout(()=>{
        $("#errorModal").modal("hide");
        this.setting = 'List';
        this.getOpenPayrolls()
      }, 3000);

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: resetPayroll!"
      }
      $("#errorModal").modal("show");
    }
  );

  }
  showClosedPayrolls() {
    $('.actionsBox').hide();
    $('.actionsBox2').hide();
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getClosedPayrolls(this.id).subscribe(next => {

      for (const proll of next) {
        proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
        proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
        proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)
      }
      this.closedPayrolls = next;

      this.loading = false;
      $("#errorModal").modal("hide");
      this.setting = 'List';
      this.listType = 'closed';
    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: showClosedPayrolls!"
      }
      $("#errorModal").modal("show");
    }
  );

  }

  startUploadTimesheet() {

    $("#uploadModal").modal("show");

  }
  closeModal () {
    this.uploadTimesheet = false;
    this.s01Select = false;
    this.allPayrolls = [];
    this.selectedS01 = [];
  }


  parseTimesheetUpload (file) {

    $("#uploadModal").modal("hide");
    // document.getElementById("select").value = "";
    $("#select").val('')

    const reader: FileReader = new FileReader();
    let workbook;
    let XL_row_object;
    let json_object;
    let payroll = this.selectedPayroll;
    let transactions: any = {};
    reader.readAsBinaryString(file);
    new Promise((resolve, reject) => {
      reader.onload = function() {

        let data = reader.result;
        workbook = XLSX.read(data,{type: 'binary'});

        XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

           transactions.payrollId = payroll.id;
           transactions.type = payroll.type;
           transactions.Hours = [];

           for(const row of XL_row_object) {

            let hour: any = {};

            let id  = row["employee_id"]
            
            // let id  = row["employee_id"].toString()
            
            let i = 1;

            for(const [key, value] of Object.entries(row)){
              if(key != 'employee_id'){
                hour.employeeId = Number.parseInt(id);

                if (i % 2 == 0) {
                  hour.code = key;
                  try {
                    hour.hours = Number.parseFloat(value as string);
                  } catch (error) {
                    hour.hours = value;
                  }
                }else{
                  hour.rate = value;
                  if (hour.hours != 0) {
                    transactions.Hours.push(hour);
                    console.log(hour);
                    hour = {};
                  }
                }
              }



              i++;
            }




              // for (const [key, value] of Object.entries(row)) {

              //   let hour: any = {};

              //   if(key != "employee_id"){



              //     if (index % 2 == 0) {
              //       console.log('excel places' ,key);

              //       hour.hours = value;
              //       hour.code = key;
              //     }else{
              //       hour.rate = value;
              //     }

              //     if (hour.hours !== 0) {
              //       transactions.Hours.push(hour);
              //     }
              //   }

              //   index++;
              // }
           }
           // json_object;

            resolve(transactions);


      };

    })
    .then((json_object: any) => {


      this.timeSheetUpload(json_object)

    });
  }
  async timeSheetUpload (json_object) {


    $('.actionsBox').hide();
    this.loading = true;
    $("#errorModal").modal("show");
    this.filePresent = false;


    this.api.uploadHours(json_object).subscribe(next => {

      if (next.status) {
        this.loading = false;
        this.modalMessage = next.status;

        this.getPaySummary()
        this.getS01PaySummary()
        this.getYtdData('')
        this.getCompanyPaySummary()

        // setTimeout(()=>{
        //   $("#errorModal").modal("hide");
        //
        //   await this.getPaySummary()
        //   await this.getS01PaySummary()
        //   await this.getYtdData('')
        //   this.getCompanyPaySummary()
        // }, 3000);

      } else {
        this.loading = false;
        this.modalMessage = 'Upload Success!';

        this.getPaySummary()
        this.getS01PaySummary()
        this.getYtdData('')
        this.getCompanyPaySummary()

        // setTimeout(()=>{
        //   $("#errorModal").modal("hide");
        //
        //   await this.getPaySummary()
        //   await this.getS01PaySummary()
        //   await this.getYtdData('')
        //   this.getCompanyPaySummary()
        // }, 3000);
      }

      // setTimeout(()=>{
      //   // $("#errorModal").modal("hide");
      //
      //   await this.getPaySummary()
      //   await this.getS01PaySummary()
      //   await this.getYtdData('')
      //   this.getCompanyPaySummary()
      // }, 3000);

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: timeSheetUpload!"
      }
      $("#errorModal").modal("show");
    }
  );

    this.data.Employees = json_object;


  }

  onSubmit (args) {

    this.selectedPayrollCommitted = false;

    this.error = false;
    let canAdd = true;
    let payRollsTypeCount = {
      weekly: 0,
      fortnightly: 0,
      bimonthly: 0,
      monthly: 0
    }
    for (const payroll of this.openPayrolls) {

      payRollsTypeCount[payroll.type]++
    }
    for (const [key, value] of Object.entries(payRollsTypeCount)) {
      if (key === this.data.payrollType && value > 0) {
        this.modalMessage = "Only 1 open payroll of each type allowed!";
        $("#errorModal").modal("show");
        canAdd = false;
      }
    }

    if (canAdd === true ) {
      this.loading = true;
      $("#errorModal").modal("show");

      let payroll = {
        status: "open",
        type: this.data.payrollType,
        companyID: this.id
      }

      console.log(JSON.stringify(payroll));

      this.api.startPayroll(JSON.stringify(payroll)).subscribe(next => {

        this.modalMessage = next;

        this.loading = false;


        setTimeout(()=>{
          $("#errorModal").modal("hide");

          this.getOpenPayrolls();
          this.setting = "List";
        }, 3000);

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: addNewPayroll!"
        }
        $("#errorModal").modal("show");
      })
    }


  }

  selectPaySummaryItem(args) {
    // console.log('select pay summary',args);

  }

  selectFile(files) {

    let file = files[0]

    this.filePresent = true;

    const formData = new FormData()
    formData.append(file.name, file)

    if (this.uploadLogo === true || this.addLogo === true) {
      this.uploadLogoFunc(formData)
    } else {
      this.parseTimesheetUpload(file)
    }


  }

  uploadLogoFunc(fileData) {

    this.loading = true;

    // $("#uploadModal").modal("hide");

    this.api.upload(fileData).subscribe(
      next => {

        // $("#errorModal").modal("hide");

        this.loading = false;

        this.company.logo = next.path;
        this.payslipLogo = true;
        this.uploadLogo = false;

        this.modalMessage = "Upload Success"

        setTimeout(()=>{
          $("#errorModal").modal("hide");
          this.getEmployeeYtd()
        }, 3000);

        this.getEmployeeYtd()

      },
      error => {
        this.loading = false;
        this.uploadLogo = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: uploadLogo !"
        }
        $("#errorModal").modal("show");
      }
    );


  }
  startUploadLogo() {
    this.addLogo = false;
  }
  addLogoSelect(args) {

    if (args === 'yes') {
      this.uploadLogo = true;
      this.addLogo = false;
    } else {
      this.addLogo = false;
      $("#uploadModal").modal("hide");

      this.getEmployeeYtd()
    }

  }

  prePayslipGen() {
    // this.companyLogoBase64.image = ''

    if (!this.companyLogoBase64.image || this.companyLogoBase64.image === '' || this.company.logo === '') {
      this.payslipLogo = false;

      $("#uploadModal").modal("show");

      this.addLogo = true;
    } else {
      this.getEmployeeYtd()
    }

  }
  payslipGen() {

    // let employee: Employee = Defaults.EmployeeDefault();
    let employee = this.payitemEmployee;
    console.log('employee',employee);
    // console.log('this.paySummary',this.paySummary);

    // if (this.employees.length > 0) {
    //   employee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
    // }


    let company = this.company;
    let payroll = this.selectedPayroll;
    let payItem = this.selectedPayItem;



    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    var showRate: boolean = false;
    let args = {
      rate: payItem.pay[0].rate,
      payDate: payroll.payCycle.payDateOffset,
      netPay: payItem.net,
      pay: payItem.pay,
      statutory: payItem.statutory,
      // deductions: payItem.statutory,
      deductions: employee.deductions,
      yearToDate: this.employeeYtd,
    }

    // console.log("payslipGen: payItem",payItem);
    // console.log("payslipGen args",args);
    console.log('ytd gross check',args.yearToDate);



    // this.companyLogoBase64.image = '';
    let logo;
    if (this.payslipLogo === false) {
      logo = {text:'',style: 'content'}
    } else {
      logo = {
        image: 'data:'+this.companyLogoBase64.image,
        width: 200,
        height: 80,
      }
    }

    let payslipPaySummary = this.paySummary.find(paySum => paySum.employeeId === employee.employeeId)


    let payDate: Date = new Date(args.payDate);

    let usdRate = '';
    if (employee.banking.currency === "USD") {
      usdRate = '*Net pay of JA$'+args.netPay+' shown above is paid as USD$'+ (args.netPay / company.usdRate).toFixed(2) +' per agreement using the Friday prior to payroll rate of USD$' + company.usdRate +'';
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let user = this.currentUser;

    args.payDate = args.payDate.substr(0,10);
    let earningsCol = [
      [
          {text:'Description',style: 'innerHeading2',},
          {text:'Hours',style: 'innerHeading2'},
          {text:'Rate',style: 'innerHeading2'},
          {text:'Amount',style: 'innerHeading2'}
      ],
    ]
    let deductionsCol = [
      [
         {text:'Description',style: 'innerHeading2'},
         {text:'Amount',style: 'innerHeading2'},
      ],
    ]
    let yearToDateCol = [
      [
         {text:'Description',style: 'innerHeading2'},
         {text:'Amount',style: 'innerHeading2'},
      ],
    ]

    let totalPay: number = 0;
    let totalDeduction: number = payslipPaySummary.totalDeductions;

    for(const value of args.pay) {
      if (!value.description) {
        console.log('no desc',value);

      }

      let payRow = [
          {text:''+value.description+'',style: 'content'},
          // {text:''+codeDesc.code.description+'',style: 'content'},
          {text:''+value.hours.toFixed(2)+'',style: 'content'},
          {text:'$ '+value.rate.toFixed(2)+'',style: 'content'},
          {text:'$ '+value.amount.toFixed(2)+'',style: 'content'},
      ]
      earningsCol.push(payRow);

      totalPay += value.amount;
    }

    for (const value2 of payItem.statutory) {
      if (value2.tax !== "HEART") {
        deductionsCol.push([
          {text:''+value2.tax+'',style: 'content'},
          {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
        ]);
      }

      //totalDeduction += value2.employeeAmount;
    }

    // for (const deduct of args.deductions) {

    //   deductionsCol.push([
    //       {text:''+deduct.code+'',style: 'content'},
    //       {text:'$ '+deduct.deductionAmount.toFixed(2)+'',style: 'content'},
    //   ]);

    //   //totalDeduction += deduct.deductionAmount;

    // }

    for(const [key, value] of Object.entries(payslipPaySummary.deductions)) {
    // for(const value of payslipPaySummary.deductions) {

      let val: any = value;
      // console.log('val 2',val,this.deductionCodes.find(code => code.code === val.code));


      if (val.code !== "HEART") {
        let deductionsRow2 = [
            {text:''+val.code.split('-')[0]+'',style: 'content'},
            {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
        ]
        deductionsCol.push(deductionsRow2);

        //totalDeduction += val.amount;
      }

    }

    if (payItem.pension !== 0) {
      deductionsCol.push([
          {text:'Employee Pension',style: 'content'},
          {text:'$ '+payItem.pension.toFixed(2)+'',style: 'content'},
      ]);
    }

    for(const [key, value] of Object.entries(payslipPaySummary.loans)) {

      let val: any = value;
      // console.log(val.code,this.loanCodes,'code match',this.loanCodes.find(code => code.code === val.code));


      let deductionsRow2 = [
          {text:''+val.description+'',style: 'content'},
          {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
      ]
      deductionsCol.push(deductionsRow2);

      //totalDeduction += val.amount;
    }

    for (const [key, value] of Object.entries(args.yearToDate)) {

      let val: any = value;

      let ytdRow;
      switch(key) {
        case 'pay':
        if (val === []) {
          ytdRow = [
            // {text:''+codeDesc.code.description+'',style: 'content'},
            {text:''+val[0].code+'',style: 'content'},
            // {text:''+val[0].code+'',style: 'content'},
            {text:'$ '+val[0].amount.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        }

        break;
        case 'taxCalc':

          // for (const [key, tax] of Object.entries(val)) {
          //   ytdRow = [
          //     {text:''+tax.tax+'',style: 'content'},
          //     {text:'$ '+tax.employeeAmount.toFixed(2)+'',style: 'content'},
          //   ]
          //   yearToDateCol.push(ytdRow);
          // }
        break;
        case 'generalGross':
          ytdRow = [
            {text:'Gross',style: 'content'},
            {text:'$ '+val.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        break;
        case 'gross':
          ytdRow = [
            {text:'TaxableGross',style: 'content'},
            {text:'$ '+val.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        break;
        case 'net':
          ytdRow = [
            {text:'Net',style: 'content'},
            {text:'$ '+val.toFixed(2)+'',style: 'content'},
          ]
          yearToDateCol.push(ytdRow);
        break;
        case 'statutory':
          for (const value2 of val) {
            if (value2.tax !== "HEART") {
              ytdRow = [
                {text:''+value2.tax+'',style: 'content'},
                {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            }
          }
        break;
      }

    }

    let modDate = "...";

    modDate = formatDate(payroll.payCycle.endDate, 'longDate', 'en')

    let modDate2 = formatDate(payDate, 'longDate', 'en')

    const def = {
      content:
      [
        {
          text: 'PAY ADVICE FOR PERIOD '+payroll.payCycle.period+', ' +modDate+".",
          fontSize: 18,
          styles:'topHeader',
          alignment: 'center',
          bold: true,
        },

        {
          style: 'tableExample',
          table: {
          widths: [ '20%', '40%', '40%'],
            body: [
              [
                  {text:'NPJF',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName + '\('+payroll.type+'\)',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName,fontSize:13,decoration:'underline',bold:true},
              ],
              [
                  {text:'Pay Date',style: 'content'},
                  {text: modDate2 ,style: 'content'},
                  {text: company.address.street + ', ' + company.address.city + ', ' +company.address.parish ,style: 'content',bold:true,fontSize:13},
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          style: 'tableExample',
          table: {
          widths: [ '60%', '40%'],
            body: [
              [
                [
                  {
                    style: 'tableExample',
                    fillColor: '#e5e5e5',
                    margin: [0,20,0,0],
                   table: {
                     body: [
                      [
                       {
                         type: 'none',
                         margin: [50,0,60,0],
                         ul: [
                           {text:''+payItem.employeeId+' : '+payItem.employee+'',bold: true,fontSize:14,decoration:'underline'},
                           {text:''+employee.employment.occupation.code+' : '+employee.employment.occupation.description+'',fontSize:11,bold: true},
                           {text:'Department: '+employee.employment.department.description},
                          //  {text:'Salary Destination:  ... '},
                         ]
                       },
                      ],
                     ]
                   }
                 }
                ],
                [
                  logo
                ],
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          margin: [0,30,0,0],
          table: {
          widths: [ '18%', '20%', '30%', '32%'],
            body: [
              [
                  {border: [false, true, false, false],text:'TRN: '+company.tax+'',fontSize:11},
                  {border: [false, true, false, false],text:'NIS Ref#: '+company.nis+'',fontSize:11},
                  // {border: [false, true, false, false],text:'Vacation Bal: '+args.deductions.vacBal+' Hours',fontSize:11},
                  {border: [false, true, false, false],text:'Company Pension YTD: ',fontSize:11},
              ],
            ]
          },
          layout: 'noBorders',
        },

        {
          style: 'tableExample',
          layout: 'noBorders',
          table: {
            widths: ['40%', '30%', '30%'],
            body: [
              [
                  {text:'EARNINGS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'DEDUCTIONS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'YEAR TO DATE',style:'heading',bold: true, decoration: 'underline', fontSize: 9}
               ],
              [
               [
                  {
                    table: {
                      body: earningsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      widths: ['60%', '40%'],
                      body: deductionsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      body: yearToDateCol
                    },
                    layout: 'noBorders',
                  }
                ],
              ]
            ]
          }
        },

        {
            alignment: 'right',
            margin: [0,20,0,10],
            columns: [
            {
              text: '... ',
              style: 'content',
              bold: true
            },
            {
              text: 'RUN DATE: '+args.payDate+' ',

              style: 'content'
            }
          ]
        },

        {
          alignment: 'right',
          layout: 'noBorders',
          margin: [0,5,0,10],
          style: 'totalsTable',
          table: {
              widths: [90, '*', '*', "*", '*', '*'],
            body: [
              [
                  {text:' **Total Earnings'  ,fontSize: 10, bold: true},
                  {text:' $' +payItem.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),fontSize: 10},
                  {text:' **Total Deductions'  ,fontSize: 10, bold: true},
                  {text:'$ '+payItem.totalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ,fontSize: 10,},
                  {text:' ** Net Pay',fontSize: 10,},
                  {text: '$'+args.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),bold:true,decoration:'underline', fillColor: '#e5e5e5' ,borders:[false,true,false,true]},
               ],
            ]
          }
        },

        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: usdRate,
        },

        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: ''
        }

      ],

      footer:  function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };
    pdfMake.createPdf(def).open();

  }
  preGetAllPayslips() {

    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");

    // let ytdArray = [];
    // let indx = 0;

    for (const item of this.paySummary) {
      let indx = this.paySummary.indexOf(item);
      // console.log('indx',indx);


      this.api.getEmployeeYtd(item.employeeId,this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

        this.ytdArray.push({empId:item.employeeId,ytd: next});
        // console.log('getEmployeeYtd: indx',indx,+item.employeeId+'',next);

        if (this.ytdArray.length === this.paySummary.length) {
        // if (indx === this.paySummary.length-1) {
          this.getAllPayslips()
          // this.getAllPayslips(ytdArray)
        }
      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: preGetAllPayslips!"
        }
        $("#errorModal").modal("show");
        this.ytdArray = [];
      })

    }

  }
  // getAllPayslips(ytdArray) {
  getAllPayslips() {

    // console.log('getAllPayslips',this.ytdArray);

    let body = [];

    for (const employ of this.paySummary) {

      let empPage = [];
      // let employee: Employee = Defaults.EmployeeDefault();

      // if (this.employees.length > 0) {
      //   employee = this.employees.filter( emp => emp.employeeId === employ.employeeId)[0];
      // }
      let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === employ.employeeId).employee;

      let company = this.company;
      let payroll = this.selectedPayroll;
      let payItem = employ;

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let ytdArrayElem;
      if (this.ytdArray.find(x=>x.empId === employee.employeeId)) {
        ytdArrayElem = this.ytdArray.find(x=>x.empId === employee.employeeId).ytd;
      }

      // console.log('ytdArrayElem',employee.employeeId,ytdArrayElem);

      // console.log('getAllPayslips',payItem.pay);

      var showRate: boolean = false;
      // console.log('error check', payItem);
      let args = {
        rate: payItem.pay[0].rate,
        payDate: payroll.payCycle.payDateOffset,
        netPay: payItem.net,
        pay: payItem.pay,
        statutory: payItem.statutory,
        deductions: employee.deductions,
        yearToDate: ytdArrayElem,
      }


      // console.log('this.companyLogoBase64',this.companyLogoBase64);

      let logo;
      if (this.payslipLogo === false) {
        logo = {text:'',style: 'content'}
      } else {
        logo = {
          image: 'data:'+this.companyLogoBase64.image,
          width: 200,
          height: 80,
        }
      }


      let payslipPaySummary = this.paySummary.find(paySum => paySum.employeeId === employee.employeeId)

      let payDate: Date = new Date(args.payDate);

      let usdRate = '';
      if (employee.banking.currency === "USD") {
        usdRate = '*Net pay of JA$'+args.netPay+' shown above is paid as USD$'+ (args.netPay / company.usdRate).toFixed(2) +' per agreement using the Friday prior to payroll rate of USD$' + company.usdRate +'';
      }

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      args.payDate = args.payDate.substr(0,10);
      let earningsCol = [
        [
            {text:'Description',style: 'innerHeading2',},
            {text:'Hours',style: 'innerHeading2'},
            {text:'Rate',style: 'innerHeading2'},
            {text:'Amount',style: 'innerHeading2'}
        ],
      ]
      let deductionsCol = [
        [
           {text:'Description',style: 'innerHeading2'},
           {text:'Amount',style: 'innerHeading2'},
        ],
      ]
      let yearToDateCol = [
        [
           {text:'Description',style: 'innerHeading2'},
           {text:'Amount',style: 'innerHeading2'},
        ],
      ]

      let totalPay: number = 0;
      let totalDeduction: number = payslipPaySummary.totalDeductions;

      for(const value of args.pay) {

        let payRow = [
            {text:''+value.description+'',style: 'content'},
            // {text:''+codeDesc.code.description+'',style: 'content'},
            {text:''+value.hours+'',style: 'content'},
            {text:'$ '+value.rate.toFixed(2)+'',style: 'content'},
            {text:'$ '+value.amount.toFixed(2)+'',style: 'content'},
        ]
        earningsCol.push(payRow);

        totalPay += value.amount;
      }

      for (const value2 of payItem.statutory) {
        if (value2.tax !== "HEART") {
          deductionsCol.push([
            {text:''+value2.tax+'',style: 'content'},
            {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
          ]);
        }

        //totalDeduction += value2.employeeAmount;
      }

      for (const deduct of args.deductions) {
        if (!deduct.description) {
          // console.log('no desc',deduct);

        }
        deductionsCol.push([
            {text:''+deduct.description+'',style: 'content'},
            {text:'$ '+deduct.deductionAmount.toFixed(2)+'',style: 'content'},
        ]);

        //totalDeduction += deduct.deductionAmount;

      }

      for(const [key, value] of Object.entries(payslipPaySummary.deductions)) {
      // for(const value of payslipPaySummary.deductions) {

        let val: any = value;
        // console.log('val 2',val,this.deductionCodes.find(code => code.code === val.code));


        if (val.code !== "HEART") {
          let deductionsRow2 = [
              {text:''+val.code.split('-')[0]+'',style: 'content'},
              {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
          ]
          deductionsCol.push(deductionsRow2);

          //totalDeduction += val.amount;
        }

      }

      if (payItem.pension !== 0) {
        deductionsCol.push([
            {text:'Employee Pension',style: 'content'},
            {text:'$ '+payItem.pension.toFixed(2)+'',style: 'content'},
        ]);
      }

      for(const [key, value] of Object.entries(payslipPaySummary.loans)) {

        let val: any = value;
        // console.log(val.code,this.loanCodes,'code match',this.loanCodes.find(code => code.code === val.code));


        let deductionsRow2 = [
            {text:''+val.code+'',style: 'content'},
            {text:'$ '+val.amount.toFixed(2)+'',style: 'content'},
        ]
        deductionsCol.push(deductionsRow2);

        //totalDeduction += val.amount;
      }

      if (args.yearToDate) {
        for (const [key, value] of Object.entries(args.yearToDate)) {

          let val: any = value;

          let ytdRow;
          switch(key) {
            case 'pay':
              ytdRow = [
                // {text:''+codeDesc.code.description+'',style: 'content'},
                {text:''+val[0].code+'',style: 'content'},
                // {text:''+val[0].code+'',style: 'content'},
                {text:'$ '+val[0].amount.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'taxCalc':

              // for (const [key, tax] of Object.entries(val)) {
              //   ytdRow = [
              //     {text:''+tax.tax+'',style: 'content'},
              //     {text:'$ '+tax.employeeAmount.toFixed(2)+'',style: 'content'},
              //   ]
              //   yearToDateCol.push(ytdRow);
              // }
            break;
            case 'generalGross':
              ytdRow = [
                {text:'Gross',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'gross':
              ytdRow = [
                {text:'TaxableGross',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'net':
              ytdRow = [
                {text:'Net',style: 'content'},
                {text:'$ '+val.toFixed(2)+'',style: 'content'},
              ]
              yearToDateCol.push(ytdRow);
            break;
            case 'statutory':
              for (const value2 of val) {
                if (value2.tax !== "HEART") {
                  ytdRow = [
                    {text:''+value2.tax+'',style: 'content'},
                    {text:'$ '+value2.employeeAmount.toFixed(2)+'',style: 'content'},
                  ]
                  yearToDateCol.push(ytdRow);
                }
              }
            break;
          }

        }
      }

      let modDate = "...";

      modDate = formatDate(payroll.payCycle.endDate, 'longDate', 'en')

      let modDate2 = formatDate(payroll.payCycle.payDateOffset, 'longDate', 'en')

      empPage.push(
        {
          text: 'PAY ADVICE FOR PERIOD '+payroll.payCycle.period+', ' +modDate+".",
          fontSize: 18,
          styles:'topHeader',
          alignment: 'center',
          bold: true,
        },
        {
          style: 'tableExample',
          table: {
          widths: [ '20%', '40%', '40%'],
            body: [
              [
                  {text:'NPJF',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName + '\('+payroll.type+'\)',fontSize:11,decoration:'underline',bold:true},
                  {text:company.companyName,fontSize:13,decoration:'underline',bold:true},
              ],
              // [
              //     {text:'423',style: 'content',bold:true},
              //     {text:'Accounts Receivables',style: 'content',bold:true},
              //     {text:'Data Entry Building # 2',style: 'content',bold:true,fontSize:13},
              // ],
              [
                  {text:'Pay Date',style: 'content'},
                  {text: modDate2 ,style: 'content'},
                  {text: company.address.street + ', ' + company.address.city + ', ' +company.address.parish ,style: 'content',bold:true,fontSize:13},
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          style: 'tableExample',
          table: {
          widths: [ '60%', '40%'],
            body: [
              [
                [
                  {
                    style: 'tableExample',
                    fillColor: '#e5e5e5',
                    margin: [0,20,0,0],
                   table: {
                     body: [
                      [
                       {
                         type: 'none',
                         margin: [50,0,60,0],
                         ul: [
                           {text:''+payItem.employeeId+' : '+payItem.employee+'',bold: true,fontSize:14,decoration:'underline'},
                           {text:''+employee.employment.occupation.code+' : '+employee.employment.occupation.description+'',fontSize:11,bold: true},
                           {text:'Department: '+employee.employment.department.description},
                         ]
                       },
                      ],
                     ]
                   }
                 }
                ],
                [
                  logo
                ],
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          margin: [0,30,0,0],
          table: {
          widths: [ '18%', '20%', '30%', '32%'],
            body: [
              [
                  {border: [false, true, false, false],text:'TRN: '+company.tax+'',fontSize:11},
                  {border: [false, true, false, false],text:'NIS Ref#: '+company.nis+'',fontSize:11},
                  // {border: [false, true, false, false],text:'Vacation Bal: '+args.deductions.vacBal+' Hours',fontSize:11},
                  {border: [false, true, false, false],text:'Company Pension YTD: ',fontSize:11},
              ],
            ]
          },
          layout: 'noBorders',
        },
        {
          style: 'tableExample',
          layout: 'noBorders',
          table: {
            widths: ['40%', '30%', '30%'],
            body: [
              [
                  {text:'EARNINGS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'DEDUCTIONS',style:'heading',bold: true, decoration: 'underline', fontSize: 9},
                  {text:'YEAR TO DATE',style:'heading',bold: true, decoration: 'underline', fontSize: 9}
               ],
              [
               [
                  {
                    table: {
                      body: earningsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      widths: ['60%', '40%'],
                      body: deductionsCol
                    },
                    layout: 'noBorders',
                  }
                ],
                [
                  {
                    table: {
                      body: yearToDateCol
                    },
                    layout: 'noBorders',
                  }
                ],
              ]
            ]
          }
        },
        {
            alignment: 'right',
            margin: [0,20,0,10],
            columns: [
            {
              text: '... ',
              style: 'content',
              bold: true
            },
            {
              text: 'RUN DATE: '+args.payDate+' ',

              style: 'content'
            }
          ]
        },
        {
          alignment: 'right',
          layout: 'noBorders',
          margin: [0,5,0,10],
          style: 'totalsTable',
          table: {
              widths: [90, '*', '*', "*", '*', '*'],
            body: [
              [
                  {text:' **Total Earnings'  ,fontSize: 10, bold: true},
                  {text:' $' +payItem.totalEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),fontSize: 10},
                  {text:' **Total Deductions'  ,fontSize: 10, bold: true},
                  {text:'$ '+payItem.totalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ,fontSize: 10,},
                  {text:' ** Net Pay',fontSize: 10,},
                  {text: '$'+args.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),bold:true,decoration:'underline', fillColor: '#e5e5e5' ,borders:[false,true,false,true]},
               ],
            ]
          }
        },
        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: usdRate,
        },
        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: ''
        },


        {
          alignment: 'center',
          bold: false,
          fontSize: 9,
          text: '',
          pageBreak: "after",
        }
      )

      body.push(empPage)

    }

    let user = this.currentUser;

    const def = {
      content:
      body,


      footer:  function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };
    pdfMake.createPdf(def).open();

    this.ytdArray = [];
    this.loading = false;
    $("#errorModal").modal("hide");

  }

  async getYtdData(args) {

    this.loading = true;
    // this.error = false;
    $("#errorModal").modal("show");
    console.log('selectedPayroll getYtdData',this.company);

    try {
      let next = await this.api.getPayrollYtd(this.company.id, this.selectedPayroll.type, this.selectedPayroll.payCycle.endDate).toPromise();
      this.selectedPayrollYtd = next;

      this.loading = false;
      $("#errorModal").modal("hide");
      this.setting = 'Detail';

    } catch (error) {
      console.log('api request errorx',error);
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getYtdData!"
      }
      $("#errorModal").modal("show");
    }

    // this.api.getPayrollYtd(this.company.id, this.selectedPayroll.type, this.selectedPayroll.payCycle.endDate).subscribe(next => {

    //   if (next[0]) {
    //     this.selectedPayrollYtd = next;
    //     // console.log('getYtdData',next);

    //   }

    // },
    // error => {
    //   // this.loading = false;

    // })

  }
  getEmployeeYtd() {


    // let employee;
    // if (this.employees.length > 0) {
    //   employee = this.employees.filter( emp => emp.employeeId === this.selectedPayItem.employeeId)[0];
    // }

    let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === this.payitemEmployee.employeeId).employee;


    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");
    this.api.getEmployeeYtd(employee.employeeId,this.selectedPayroll.id, this.selectedPayroll.type).subscribe(next => {

      this.employeeYtd = next;

      this.loading = false;
      $("#errorModal").modal("hide");
      this.payslipGen();

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployeeYtd!"
      }
      $("#errorModal").modal("show");
    })

  }

  startShowPaySchedule() {

    this.setting = "Schedule";
  }
  selectSheduleCycle (cycle) {
    this.payScheduleCycle = cycle;
  }
  showPaySchedule() {
    $('.actionsBox').hide();

    // this.loading = true;
    // $("#errorModal").modal("show");

    this.api.getPaySchedule(this.company.id,this.payScheduleCycle).subscribe(next => {

      for (const item of next) {
        item.startDate = item.startDate.substr(0,10)
        item.endDate = item.endDate.substr(0,10)
        item.payDate = item.payDate.substr(0,10)
      }
      this.paySchedule = next;
    },
    error => {
      this.loading = false;
      console.log('api request errorx',error);
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: showPaySchedule!"
      }
      $("#errorModal").modal("show");
    })


  }

  selectMultipleS01(args) {
    this.selectedS01.push(args)
  }
  startSelectSO1() {

    this.loading = true;
    $("#errorModal").modal("show");
    this.s01Select = true;

      this.api.getOpenPayrolls(this.id).subscribe(next => {

        console.log('s01 payroll select getOpenPayrolls',next);

        for (const proll of next) {
          proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
          proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
          proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)

          this.allPayrolls.push(proll)

        }


      },
      error => {
        this.loading = false;
        console.log('api request error',error);
      })


      this.api.getClosedPayrolls(this.id).subscribe(next => {

        console.log('s01 payroll select getClosedPayrolls',next);


        for (const proll of next) {
          proll.payCycle.startDate = proll.payCycle.startDate.substring(0,10)
          proll.payCycle.endDate = proll.payCycle.endDate.substring(0,10)
          proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substring(0,10)

          this.allPayrolls.push(proll)
        }

        this.loading = false;

        this.allPayrolls = this.allPayrolls.filter(elem => elem.type ===this.selectedPayroll.type)

        // $("#errorModal").modal("hide");

      },
      error => {
        this.loading = false;
        console.log('api request error',error);
      });

  }
  selectSO1 () {

    this.s01Select = false;
    this.loading = true;
    $("#errorModal").modal("show");

    let requestData = this.selectedS01.map(elem=> elem = {payrollId:elem.id,type:elem.type})
    // console.log('getSO1ScheduleReport req data',JSON.stringify(requestData));

    this.api.getSO1ScheduleReport(JSON.stringify(requestData)).subscribe(next => {

      this.s01PaySummary = next;
      this.s01PaySummaryLength = next.length;
      this.loading = false;
      $("#errorModal").modal("hide");
      this.startGenerateSO1()

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: selectSO1!"
      }
      $("#errorModal").modal("show");
    })


  }
  highlightS01(args) {
    let elem = document.getElementById(args.target.id)
    $(elem).addClass('highlight')

  }
  unhighlightS01(args) {

    let elem = document.getElementById(args.target.id)
    $(elem).removeClass('highlight');

  }
  startGenerateSO1() {
    $('.actionsBox').hide();
    // this.loading = true;
    // $("#errorModal").modal("show");

    let newPaySummary = [];
    let paySum = this.s01PaySummary;

    for (const elem of paySum) {
      let newPaySumItem;
      for (const elem2 of this.employees) {
        if (elem.employeeId === elem2.employeeId) {
          newPaySumItem = Object.assign({}, elem, elem2);
        }
      }
      newPaySummary.push(newPaySumItem)
    }

    let XL_row_object;

    var workbook = XLSX.utils.book_new();
    var ws_name = "Sheet1";

    var ws_data1 = [
      [
        "Surname",
        "Firstname",
        "Middle Initials",
        "Employee TRN",
        "Employee NIS",
        "Gross Emoluments Received in Cash \n Salaries, Wages, Fees, Bonuses, Overtime pay, Commissions, etc...",
        "Gross Emoluments Received in Kind",
        "Superannuation / Pension, Agreed Expenses, Employees Share Ownership Plan",
        "Number of weekly NIS and NHT Contributions for the month",
        "NIS \n (Employee's Rate + Employer's Rate) x (Total Gross Emoluments)",
        "NHT \n (Employee's Rate + Employer's Rate) x (Total Gross Emoluments)",
        "Education Tax \n (Employee's Rate + Employer's Rate) x (Total Gross Emoluments after Deductions and NIS)",
        "PAYE Income Tax / (Refunds) \n (Rate) x (Total Gross Emoluments after Deductions, NIS and Nil-Rate (NR))",
      ],
    ];
    var ws = XLSX.utils.aoa_to_sheet(ws_data1);
    XLSX.utils.book_append_sheet(workbook, ws, ws_name);

    for (const [key, value] of Object.entries(paySum)) {
      let empRow = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined];
      for (const [key2, value2] of Object.entries(value)) {

        let xlRowDataRef = [
          'lastName',
          'firstName',
          'initials',
          'trn',
          'nis',
          'gross',
          'grossKind',
          'pension',
          'weeklys',
          'nisTax',
          'nhtTax',
          'edTax',
          'payeTax',
        ];
        let propKey = key2
        let propVal: any = value2
        if (key2 === 'sO1Calc') {
          for (const [key3, value3] of Object.entries(value2)) {
            propKey = key3;
            if (propKey === 'weeklyNIS') {
              propVal = value3;
            }

            else {
              propVal = value3.toFixed(2);
            }

            if (propKey === 'nis') {
              propKey = 'nis2';
            }

            if (xlRowDataRef.includes(propKey)) {
              let indx2 = xlRowDataRef.indexOf(propKey);
              empRow[indx2] = propVal;
            }
          }
        }

        else {
          if (xlRowDataRef.includes(propKey)) {
            let indx = xlRowDataRef.indexOf(propKey);
            if (
              propKey === 'gross' ||
              propKey === 'net' ||
              propKey === 'nisTax' ||
              propKey === 'nhtTax' ||
              propKey === 'edTax' ||
              propKey === 'payeTax' ||
              propKey === 'pension'
            ) {
              empRow[indx] = propVal.toFixed(2);
            } else {
              empRow[indx] = propVal;
            }
          }
        }

      }
      // console.log('----------final emp row-----------',empRow);
      XLSX.utils.sheet_add_aoa(ws, [empRow], {origin: -1});
    }

    // XLSX.utils.sheet_add_aoa(ws, [[4,5,6,7,8]], {origin: -1});
    XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    // console.log('XL_row_object',XL_row_object);

    XLSX.writeFile(workbook, 'SO1Schedule.xlsx');



  }
  async startSelectSO1Form() {

    this.loading = true;
    $("#errorModal").modal("show");

    try {
      let result = await this.api.getSO1Form(this.selectedPayroll.id,this.selectedPayroll.type).toPromise();
      // console.log('result',result);


      window.location.href = result.file;

      this.loading = false;
      $("#errorModal").modal("hide");

    } catch (error) {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getSO1Form!"
      }
      $("#errorModal").modal("show");
    }


    // this.S01FormLink = `https://payrollapidev.caribbeanhrsolutions.com/api/report/SO1Form/${this.selectedPayroll.id}/${this.selectedPayroll.type}`
    // this.showS01FormLink = true;
    // $("#specialModal").modal("show");

  }

  upload(files) {


    const reader: FileReader = new FileReader();
    let XL_row_object;
    let json_object;
    let workbook;
    reader.readAsBinaryString(files[0]);
    reader.onload = function() {
      let data = reader.result;

    };


    new Promise((resolve, reject) => {
      reader.onload = function() {

        let data = reader.result;
         workbook = XLSX.read(data,{type: 'binary'});

        XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

        XLSX.utils.sheet_add_aoa(workbook.Sheets[workbook.SheetNames[0]], [[4,5,6,7,8]], {origin: -1});

        XLSX.writeFile(workbook, 'outandaboutx.xlsx');


      };
    })
    .then((json_object: any) => {

    });

  }

  prePayrollRegisterGen () {

    this.loading = true;
    $("#errorModal").modal("show");


    this.selectedPayroll.payCycle.startDate = new Date(this.selectedPayroll.payCycle.startDate).toISOString()
    this.selectedPayroll.payCycle.endDate = new Date(this.selectedPayroll.payCycle.endDate).toISOString()
    this.selectedPayroll.payCycle.payDateOffset = new Date(this.selectedPayroll.payCycle.payDateOffset).toISOString()

    this.api.setPayrollRegister(JSON.stringify(this.selectedPayroll)).subscribe(next => {

      this.payrollRegisterGen(next)

      // this.loading = false;
      // $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'setPayrollRegister'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  payrollRegisterGen (args) {
    this.loading = false;
    $("#errorModal").modal("hide");

    // console.log('payrollRegisterGen',args);

    let company = this.company;

    let empNo = args.ytdEmployees.length;

    // console.log('args',args.deductions);


    let mainTable = [];
    let summaryTotal = 0;
    let earningsTotal = 0;
    let statutoryEmployeeTotal = 0;
    let statutoryEmployerTotal = 0;
    let hrsTotal = 0;
    let nonStatTotal = 0;

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Summary',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ]
    )
    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Description',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Current Period',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Year-to-Date',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
      [
        {border: [false, false, false, false], text: 'GROSS PAY',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.gross.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.generalGross.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
      [
        {border: [false, false, false, false], text: 'TAXABLE GROSS PAY',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.taxable.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.gross.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],

      [
        {border: [false, false, false, false], text: 'TOTAL DEDUCTION',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.deductions.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.totalDeductions.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
      [
        {border: [false, false, false, false], text: 'TOTAL NETPAY',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '$ '+args.total.net.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '$ '+args.ytdEmployees.net.toFixed(2),fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
      [
        {border: [false, false, false, false], text: 'EMPLOYEE COUNT',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: ''+args.total.employeeCount+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 8,},
        {border: [false, false, false, false], text: '',fontSize: 8,},
      ],
    )
    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Earnings',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Unit/Hours',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'Amount',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )
    for (const earning of args.earnings) {
      // console.log('earning',earning);

      mainTable.push(
        [
          {border: [false, false, false, false], text: ''+earning.description+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: ''+earning.hours+'',fontSize: 8},
          {border: [false, false, false, false], text: '$ '+earning.currentAmount.toFixed(2)+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
        ]
      )

      earningsTotal += earning.currentAmount;
      hrsTotal += earning.hours;
    }

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'TOTAL',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '$ '+earningsTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )
    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Deduction Statutory',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'EMPLOYEE',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: 'EMPLOYER',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )

    for (const stat of args.statutory) {

      mainTable.push(
        [
          {text: ''+stat.tax+'',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '$ '+stat.employeeAmount.toFixed(2)+'',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '$ '+stat.employerAmount.toFixed(2)+'',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
          {text: '',fontSize: 8,alignment: 'left',border: [false, false, false, false]},
        ]
      )

      statutoryEmployeeTotal += stat.employeeAmount;
      statutoryEmployerTotal += stat.employerAmount;

    }

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'TOTAL',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '$ '+statutoryEmployeeTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '$ '+statutoryEmployerTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )
    mainTable.push(
      [
        {border: [false, false, false, false], text: 'Payroll Deduction Non-Statutory',fontSize: 10,bold:true,margin:[0,8,0,4],decoration:'underline'},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ]
    )
    for (const deduct of args.deductions) {
      console.log('non statii deduct',deduct);

      if (!deduct.description) {
        // console.log('no desc',deduct);
      }

      mainTable.push(
        [
          {border: [false, false, false, false], text: ''+deduct.description+'',fontSize: 8},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '$ '+deduct.amount.toFixed(2)+'',fontSize: 8},
          {border: [false, false, false, false], text: '$ '+deduct.employerAmount.toFixed(2)+'',fontSize: 8,bold:true},
          {border: [false, false, false, false], text: '',fontSize: 8,bold:true},
        ]
      )

      nonStatTotal += deduct.amount;

    }

    mainTable.push(
      [
        {border: [false, false, false, false], text: 'TOTAL',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '$ '+nonStatTotal.toFixed(2)+'',fontSize: 8},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
        {border: [false, false, false, false], text: '',fontSize: 9,bold:true},
      ],
    )


    let runDate = new Date();

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let user = this.currentUser;

    const def = {

      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'Run-Date: '+formatDate(runDate, 'longDate', 'en')+'', alignment: 'left', margin: 20, fontSize: 9},
          ],
        };
      },


      content:
      [

        // PAGE THREE
        {
          text: 'Payroll Summary',
          fontSize: 20,
          alignment: 'center',
          bold: true,
          margin:3
        },
        {
          text: '  ',
          margin:0,
        },
        {
          text: this.company.legalName,
          fontSize: 15,
          alignment: 'center',
          margin:3,
        },

        {
          text: 'Period Ending Date: '+ formatDate(this.selectedPayroll.payCycle.endDate, 'longDate', 'en')+' || Period Number: '+this.selectedPayroll.payCycle.period+' || Payroll Cycle: ' +this.selectedPayroll.type,
          fontSize: 10,
          bold: true,
          alignment: 'center',
          margin:3
        },

        {
    			style: 'tableExample',
          margin: [0,0, 0, -1],
    			table: {
    				headerRows: 1,
            widths: ['100%'],
    				body: [
              [
                {text: 'Employer NIS: '+this.company.nis+' -  Employer TRN: '+this.company.tax+'', fontSize: 9, alignment: 'center'},
              ],
    				]
    			},
    			layout: 'noBorders',
    		},
        {
          text: '  ',
          margin:0,
        },


        {
    			style: 'tableExample',
          margin: [0,0, 0, -1],
    			table: {
            widths: ['32%','16%','18%','18%','16%'],
    				headerRows: 1,
    				body: mainTable
    			},
          // layout: 'noBorders',
    		},
        {
          text: 'Employee count by Status : Total Active - '+args.total.active+', Inactive - '+args.total.inactive+', Total Employee Count - '+args.total.employeeCount+'',
          fontSize: 11,
          alignment: 'left',
          margin: [0, 15]
        },

      ],


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }

  preEmployeeRegisterGen () {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.setEmployeeRegister(this.selectedPayroll.id).subscribe(next => {

      this.employeeRegisterGen(next)
      //
      // this.loading = false;
      // $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      console.log('error',error);

      this.loading = false;
      $("#errorModal").modal("hide");
    })

  }
  employeeRegisterGen (args) {
    this.loading = false;
    $("#errorModal").modal("hide");

    // console.log('employeeRegisterGen',args);

    let company = this.company;
    let payroll = this.selectedPayroll;
    let payItem = this.selectedPayItem;

    let body: Array<any> = [
      {
        text: this.company.legalName,
        fontSize: 20,
        alignment: 'center',
        bold: true,
        margin:3,
        decoration: 'underline',
      },
      {
        text: 'Employee Register',
        fontSize: 15,
        alignment: 'center',
        margin:3,
        decoration: 'underline',
      },

      {
        text: '',
        fontSize: 10,
        alignment: 'center',
        margin:3
      },


      {
        style: 'tableExample1',
        margin: [0,0, 0, -2],
        table: {
          headerRows: 1,
          widths: ['25%','25%','25%','25%'],
          body: [
            [
              {border: [true, true, true ,true], text: 'Period Ending Date: '+this.selectedPayroll.payCycle.endDate+'', fontSize: 9, alignment: 'center'},
              {border: [true, true, true ,true], text: 'Period Number: '+this.selectedPayroll.payCycle.period+'', fontSize: 9, alignment: 'center'},
              {border: [true, true, true ,true], text: 'Period/Payroll Cycle:'+this.selectedPayroll.type+'', fontSize: 9, alignment: 'center'},
              {border: [true, true, true ,true], text: 'Run Date : '+new Date().toISOString().substr(0,10)+'', fontSize: 9, alignment: 'center'},
            ],
          ]
        },
        // layout: 'noBorders',
      },


      {
        text: '',
        fontSize: 9,
        alignment: 'center',
        margin:3
      },

    ];

    for (const emp of args) {
      let indx = args.indexOf(emp);

      let employee;
      if (this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === emp.employeeID)) {
        employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === emp.employeeID).employee;
      } else {
        console.log('this.selectedPayroll.employeeData2',this.selectedPayroll.employeeData);
        console.log('emp.employeeId',emp.employeeId);
      }
      // console.log('emp',emp);
      // console.log('employee',employee);

      body.push(
        // {
        //   style: 'tableExample',
        //   margin: [0,3,0,1],
        //   table: {
        //     // margin: [-2,-3],
        //     headerRows: 1,
        //     widths: ['100%'],
        //     body: [
        //       [
        //         {border: [true, true , true, true], text: 'Department: '+employee.employment.department.code+' - '+employee.employment.department.abbreviation+'', fontSize: 10, alignment: 'left',margin: [0,-1]},
        //       ],
        //     ]
        //   },
        //   // layout: 'noBorders',
        // },

        {
          style: 'tableExample',
          margin: [0,1],
          table: {
            // margin: [-2,-3],
            headerRows: 1,
            widths: ['20%','20%','20%','20%','20%'],

            body: [
              [
                {border: [true, true , true, true], text: 'Department', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: 'EMPLOYEE # ', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: 'JOB TITLE: ', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: 'TRN: ', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: 'NIS: ', fontSize: 9, alignment: 'left'},
              ],
              [
                {border: [true, true , true, true], text: ''+employee.employment.department.code+' _ '+employee.employment.department.abbreviation+'', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: ''+emp.employeeID+' - '+emp.employee+'', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: ''+employee.employment.department.description+'', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: ''+employee.trn+'', fontSize: 9, alignment: 'left'},
                {border: [true, true , true, true], text: ''+employee.nis+'', fontSize: 9, alignment: 'left'},
              ],
            ]
          },
          // layout: 'noBorders',
        },
      )

      let summaryData = [
        [
          {text: 'Summary', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {text: '', fontSize: 9, alignment: 'left'},
          {text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
        ]
      ];

      let earningsData = [
        [
          {border: [true, true, false, false], text: 'Description', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, true, false, false], text: 'Hours', fontSize: 9, alignment: 'left'},
          {border: [false, true, false, false], text: 'Current', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, true, false, false], text: 'Previous', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, true, true, false], text: 'YTD', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
        ]
      ];
      let deductionData = [];
      let deductionData2 = [];

      let totalHours = 0;
      let totalCurrent = 0;
      let totalPrevious = 0;
      let totalYtd = 0;
      for (const earning of emp.earnings) {
        earningsData.push(
          [
            {border: [true, false, false, false], text: ''+earning.description+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, false, false], text: ''+earning.hours+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, false, false], text: '$ '+earning.currentAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, false, false], text: '$ '+earning.previousAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '$ '+earning.ytd.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          ]
        )
        totalHours += earning.hours;
        totalCurrent += earning.currentAmount;
        totalPrevious += earning.previousAmount;
        totalYtd += earning.ytd;
      }

      earningsData.push(
        [
          {border: [true, false, false, false], text: 'Totals', fontSize: 10, alignment: 'left'},
          {border: [false, false, false, false], text: ''+totalHours.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          {border: [false, false, false, false], text: '$ '+totalCurrent.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          {border: [false, false, false, false], text: '$ '+totalPrevious.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          {border: [false, false, true, false], text: '$ '+totalYtd.toFixed(2)+'', fontSize: 9, alignment: 'left'},
        ]
      )
      deductionData.push(
        [
          {border: [true, true, false, true], text: 'Statutory', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, true, true, true], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, true, true, true], text: 'Employee Amt', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [true, true, true, true], text: 'Employer Amt', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
        ],
      )

      let totalEmployee = 0;
      let totalEmployer = 0;
      for (const stat of emp.statutory) {
        deductionData.push(
          [
            {border: [true, false, false, false], text: ''+stat.tax+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '$ '+stat.employeeAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '$ '+stat.employerAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          ]
        )
        totalEmployee += stat.employeeAmount;
        totalEmployer += stat.employerAmount;
      }

      deductionData2.push(
        [
          {border: [true, false, false, false], text: 'Non-Statutory', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [true, false, true, false], text: '', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
        ],
      )

      let totalEmployee2 = 0;
      let totalEmployer2 = 0;
      // console.log("EmpDeduction4040", emp.deductions);
      for (const nonStat of emp.deductions) {
        // console.log('non stat deduct', nonStat);

        deductionData2.push(
          [
            {border: [true, false, false, false], text: ''+nonStat.code+'', fontSize: 9, alignment: 'left'},
            {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '$ '+nonStat.amount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
            {border: [true, false, true, false], text: '$ '+nonStat.employerAmount.toFixed(2)+'', fontSize: 9, alignment: 'left'},
          ],

        )
        totalEmployee2 += nonStat.amount;
      }

      deductionData2.push(
        [
          {border: [true, false, false, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
        ],
        [
          {border: [true, false, false, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
        ],
        [
          {border: [true, false, false, false], text: 'Total Deduction', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '$ '+(totalEmployee+totalEmployee2).toFixed(2)+'', fontSize: 9, alignment: 'left',decoration: 'underline'},
          {border: [true, false, true, false], text: '$ '+(totalEmployer+totalEmployer2).toFixed(2)+'', fontSize: 9, alignment: 'left',decoration: 'underline'},
        ],
        [
          {border: [true, false, false, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [false, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, false], text: '', fontSize: 9, alignment: 'left'},
        ],
        [
          {border: [true, false, false, true], text: 'NET PAY', fontSize: 10, alignment: 'left',decoration: 'underline', bold: true},
          {border: [false, false, true, true], text: '', fontSize: 9, alignment: 'left'},
          {border: [true, false, true, true], text: '$ '+emp.total.net.toFixed(2)+'', fontSize: 9, alignment: 'left',decoration: 'underline'},
          {border: [true, false, true, true], text: '', fontSize: 9, alignment: 'left'},
        ],
      );

      let summaryTable = {
        style: 'tableExample',
        margin: [0,1],
        table: {
          headerRows: 1,

          widths: ['40%','14%','23%','23%'],
          body: summaryData
        },
        layout: 'noBorders',
        // pageBreak: 'after'
      };
      let earningsTable = {
        style: 'tableExample',
        margin: [0,0,0,-1],
        table: {
          headerRows: 1,

          widths: ['28%','18%','18%','18%','18%'],
          body: earningsData
        },
        // layout: 'noBorders',
      };
      let deductionTable = {
        style: 'tableExample',
        // margin: [0,2, 0, -1],
        margin: [0,0,0,-1],
        table: {
          headerRows: 1,

          widths: ['40%','14%','23%','23%'],
          body: deductionData
        },
        // layout: 'noBorders',
      };

      let breakPg = '';
      if (indx%2 !== 0) {
        breakPg = 'after'
      } else {
        breakPg = '';
      }
      let deductionTable2 = {
        style: 'tableExample',
        // margin: [0,2, 0, -1],
        margin: [0,0,0,2],
        table: {
          headerRows: 1,

          widths: ['40%','14%','23%','23%'],
          body: deductionData2,
        },
        // layout: 'noBorders',
        pageBreak: breakPg
      };

      body.push(summaryTable);
      body.push(earningsTable);
      body.push(deductionTable);
      body.push(deductionTable2);
      body.push(
        //{canvas: [ { type: 'line', x1: 10, y1: 10, x2: 500, y2: 10, lineWidth: 3, color: 'black', alignment:'center'} ]},
      );

    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let user = this.currentUser;

    const def = {
      content: body,

      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }

  preDeductionListingGen () {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getDeductionListingReport(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      // console.log('getDeductionListingReport',next);
      this.deductionListingGen(next)

      // this.loading = false;
      // $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getDeductionListingReport!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  deductionListingGen(args) {
    this.loading = false;
    $("#errorModal").modal("hide");
    console.log('deductionListingGen',args);


    // let employee: Employee = Defaults.EmployeeDefault();

    let company = this.company;
    let payroll = this.selectedPayroll;
    let payItem = this.selectedPayItem;

    let deductionBody: Array<any> = [
      {
        text: 'Deduction Listing',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        // margin: [0, 5]
      },
      {
        text: ''+company.companyName+'',
        fontSize: 12,
        alignment: 'center',
        bold: true,
        margin: [0, 5]
      },

      {
        text: 'DEDUCTION LISTING FOR PERIOD '+formatDate(payroll.payCycle.startDate, 'longDate', 'en')+' TO '+formatDate(payroll.payCycle.endDate, 'longDate', 'en')+'',
        fontSize: 9,
        alignment: 'center',
      },

      {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

      {
        text: 'FILTER CRITERIA: ALL RECORDS',
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5]
      },
    ];

    for (const elem of args) {

      let thisCode: any = '';
      if (this.deductionCodes.length > 0) {
        for (const code of this.deductionCodes) {
          if (code.abbreviation === elem[0].code) {
            thisCode = code;
          }
        }
      }

      let deductionListing = [];
      let deductionHead = [];
      let totalBalance = 0;
      let totalDeduction = 0;
      let deductionCount = 0;
      let employeeCount = 0;
      let deductionRow1 = {
        style: 'tableExample',
        table: {
          widths: ['50%','10%','10%','10%','10%','10%'],
          body: deductionHead
        },
        // layout: 'noBorders'
      };


      deductionHead.push(
        [
          // {text: 'DEDUCTION ABBR: '+elem.code.substr(0,6)+'', fontSize: 9, bold: true, border: [false, false, false, false]},
          {text: 'DEDUCTION ABBR: '+elem[0].code+'', fontSize: 9, bold: true, border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
        ]
      )

      let desc = '';

      if (elem[0].description) {
        desc = elem[0].description;
      }
      deductionHead.push(
        [
          // {text: 'DEDUCTION Desc: '+elem.code.substr(6,elem.code.length)+'', fontSize: 9, border: [false, false, false, true], bold: true},
          {text: 'DEDUCTION Desc: '+desc+'', fontSize: 9, border: [false, false, false, true], bold: true},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
          {text: '', border: [false, false, false, false]},
        ],
      )

      let deductionRow2 = {
        style: 'tableExample',
        table: {
          widths: ['8%','8%','11%','26%','12%','15%','12%','20%'],
          body: deductionListing
        },
      };


      deductionListing.push(
        [
          {text: 'EMP. #',fontSize: 9, border: [false, false, false, true]},
          {text: 'DEPT CODE',fontSize: 9, border: [false, false, false, true]},
          {text: 'POLICY',fontSize: 9, border: [false, false, false, true]},
          {text: 'EMPLOYEE NAME',fontSize: 9, border: [false, false, false, true]},
          {text: 'EMPLOYEE AMT',fontSize: 9, border: [false, false, false, true]},
          {text: 'EMPLOYER AMT',fontSize: 9, border: [false, false, false, true]},
          {text: 'TOTAL AMT',fontSize: 9, border: [false, false, false, true]},
          {text: 'BALANCE',fontSize: 9, border: [false, false, false, true]},
        ]
      )

      let employeeTotal = 0;
      let employerTotal = 0;

      for (const elem2 of elem) {

        let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === elem2.deductions[0].employeeId).employee;
        // employee = employee = this.employees.filter( emp => emp.employeeId === elem2.employeeId)[0];

        let deductionPreTotal = elem2.deductions[0].employeeAmount + elem2.deductions[0].employerAmount;

        let row = [
          {text: ''+elem2.deductions[0].employeeId+'', fontSize: 9, bold: true, border: [false, false, false, false]},
          {text: ''+employee.employment.department.code+'', fontSize: 9, border: [false, false, false, false]},
          {text: ''+elem2.deductions[0].policy+'', fontSize: 9, border: [false, false, false, false]},
          {text: ''+elem2.deductions[0].employee+'', fontSize: 9, border: [false, false, false, false]},
          {text: '$ '+elem2.deductions[0].employeeAmount.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
          {text: '$ '+elem2.deductions[0].employerAmount.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
          {text: '$ '+deductionPreTotal.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
          {text: '$ '+elem2.deductions[0].balance.toFixed(2)+'', fontSize: 9, border: [false, false, false, false]},
        ]

        employeeTotal += elem2.deductions[0].employeeAmount;
        employerTotal += elem2.deductions[0].employerAmount;
        totalBalance += elem2.deductions[0].balance;
        totalDeduction += deductionPreTotal;
        deductionCount ++;
        employeeCount ++;


        deductionListing.push(row)
      }

      let deductionRow3 = {
        style: 'tableExample',
        table: {
          widths: ['30%','10%','15%','15%','15%','15%'],
          margin: [0, -5],
          body: [
            [
              {text: '** TOTALS: ', fontSize: 9, bold: true, border: [false, false, false, true]},
              {text: '', fontSize: 9, border: [false, false, false, false]},
              {text: 'Employee Amt', fontSize: 9, border: [false, true, false, true]},
              {text: 'Employer Amt', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
              {text: 'Grand Total', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
              {text: 'Total Balance', fontSize: 9, border: [false, true, false, true]},
            ],
            [
              {text: ''+employeeCount+' EMPLOYEE(S) LISTED W/ '+deductionCount+' DEDUCTIONS(S)', fontSize: 9, bold: true, border: [false, false, false, true]},
              {text: '', fontSize: 9, border: [false, false, false, false]},
              {text: '$ '+employeeTotal.toFixed(2)+'', fontSize: 9, border: [false, true, false, true]},
              {text: '$ '+employerTotal.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
              {text: '$ '+totalDeduction.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0]},
              {text: '$'+totalBalance.toFixed(2)+'', fontSize: 9, border: [false, true, false, true]},
            ]
          ]
        },
        // layout: 'noBorders'
        pageBreak: "after"
      };

      deductionBody.push(deductionRow1)
      deductionBody.push(deductionRow2)
      deductionBody.push(deductionRow3)

    }


    let user = this.currentUser;
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      content: deductionBody,


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();


  }

  updateOpenPayroll() {


    let updateData = {
      payrollId: this.selectedPayroll.id,
      startDate: new Date(this.selectedPayroll.payCycle.startDate),
      endDate: new Date(this.selectedPayroll.payCycle.endDate),
      payDate: new Date(this.selectedPayroll.payCycle.payDateOffset),
      payPeriod: this.selectedPayroll.payCycle.period
    }


    this.loading = true;
    this.error = false;
    $("#errorModal").modal("show");


    this.api.modifyPayCycle(JSON.stringify(updateData)).subscribe(next => {

      this.getOpenPayrolls();

      this.setting = 'List';
      this.listType = 'open';

      this.loading = false;
      $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: modifyPayCycle!"
      }
      $("#errorModal").modal("show");
    })

  }

  preDepartmentSummaryGen () {

    // if (
    //   this.deptSummarySelectedYear === "" ||
    //   !this.deptSummarySelectedYear
    // ) {
    //   alert('choose a valid date');
    //   console.log('choose a valid date');
    //   return
    // }
    // console.log('deptSummarySelectedYear',this.deptSummarySelectedYear);


    this.loading = true;
    $("#errorModal").modal("show");

    this.deptSummaryDateInput = false;

    this.selectedPayroll.payCycle.startDate = new Date(this.selectedPayroll.payCycle.startDate).toISOString();
    this.selectedPayroll.payCycle.endDate = new Date(this.selectedPayroll.payCycle.endDate).toISOString();
    this.selectedPayroll.payCycle.payDateOffset = new Date(this.selectedPayroll.payCycle.payDateOffset).toISOString();

    this.api.getDepartmentSummary(this.selectedPayroll.id).subscribe(next => {

      this.departmentSummaryTotalsGen(next)

      // this.loading = false;
      // $("#errorModal").modal("hide");

    },
    error => {
      this.error = true;
      this.modalMessage = 'getDepartmentSummary';
      this.loading = false;
      $("#errorModal").modal("show");
    })
  }
  departmentSummaryTotalsGen(args) {

    this.loading = false;
    $("#errorModal").modal("hide");

    let company = this.company;
    let payroll = this.selectedPayroll;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let summaryBody = [];
    summaryBody.push(
      {
        text: 'Department Summary Totals',
        fontSize: 18,
        styles:'topHeader',
        alignment: 'center',
        bold: true,
      }
    )

    for (const summaryItem of args) {
      let index = args.indexOf(summaryItem);
      let summaryPage = [];
      let mainTable = [];
      console.log('summaryItem',summaryItem);


      mainTable.push(
        [
          {border: [false, false, false, true],text:'Description',fontSize:9},
          {border: [false, false, false, true],text:'Current',fontSize:9},
          {border: [false, false, false, true],text:'MTD',fontSize:9},
          {border: [false, false, false, true],text:'YTD',fontSize:9},
          {border: [false, false, false, true],text:'Current',fontSize:9},
          {border: [false, false, false, true],text:'MTD',fontSize:9},
          {border: [false, false, false, true],text:'YTD',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'',fontSize:9},
          {border: [false, false, false, false],text:'Earnings Summary',fontSize:9,decoration:'underline',bold:true,colSpan:2},
            '',
          {border: [false, false, false, false],text:'',fontSize:9},
          {border: [false, false, false, false],text:'',fontSize:9},
          {border: [false, false, false, false],text:'',fontSize:9},
          {border: [false, false, true, false],text:'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'Gross Pay',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.grossPay.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.grossPay.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.grossPay.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.grossPay.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.grossPay.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.earnings.grossPay.employer.current.toFixed(2)+'',fontSize:9},
        ],


        // -----------------

        [
          {border: [true, false, false, false],text:'Tax Refund',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.taxRefund.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.taxRefund.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.taxRefund.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.taxRefund.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.earnings.taxRefund.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.earnings.taxRefund.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        // -----------------

        // -----------------
        [
          {border: [true, true, false, true],text:'Tot. Earnings',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.totalEarnings.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.totalEarnings.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.totalEarnings.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.totalEarnings.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.totalEarnings.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, true, true],text:'$ '+summaryItem.earnings.totalEarnings.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        // -----------------

        // -----------------
        [
          {border: [true, true, false, true],text:'Taxable Pay',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.taxablePay.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.taxablePay.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.taxablePay.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.taxablePay.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.earnings.taxablePay.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, true, true],text:'$ '+summaryItem.earnings.taxablePay.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        // -----------------


        // -----------------
        [
          {border: [true, true, false, false],text:'',fontSize:9},
          {border: [false, true, false, false],text:'Deductions Summary',fontSize:9,decoration:'underline',bold:true,colSpan:2},
            '',
          {border: [false, true, false, false],text:'',fontSize:9},
          {border: [false, true, false, false],text:'',fontSize:9},
          {border: [false, true, false, false],text:'',fontSize:9},
          {border: [false, true, true, false],text:'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'Income Tax',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.income.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.income.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.income.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.income.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.income.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.deductions.income.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'N.I.S',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nis.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nis.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nis.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nis.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nis.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.deductions.nis.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'N.H.T',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nht.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nht.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nht.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nht.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.nht.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.deductions.nht.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'Edu Tax',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.edu.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.edu.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.edu.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.edu.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.edu.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.deductions.edu.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'Heart',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.heart.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.heart.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.heart.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.heart.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.heart.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.deductions.heart.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        [
          {border: [true, false, false, false],text:'Misc Ded.',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.misc.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.misc.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.misc.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.misc.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, false, false, false],text:'$ '+summaryItem.deductions.misc.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, false, true, false],text:'$ '+summaryItem.deductions.misc.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        // -----------------


        // -----------------
        [
          {border: [true, true, false, true],text:'Tot. Ded',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.totalDed.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.totalDed.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.totalDed.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.totalDed.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.totalDed.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, true, true],text:'$ '+summaryItem.deductions.totalDed.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        // -----------------

        // -----------------
        [
          {border: [true, true, false, true],text:'Net Pay',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.net.employee.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.net.employee.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.net.employee.ytd.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.net.employer.current.toFixed(2)+'',fontSize:9},
          {border: [false, true, false, true],text:'$ '+summaryItem.deductions.net.employer.mtd.toFixed(2)+'',fontSize:9},
          {border: [false, true, true, true],text:'$ '+summaryItem.deductions.net.employer.ytd.toFixed(2)+'',fontSize:9},
        ],
        // -----------------


      )

      let totalHrsCurrent = 0;
      let totalHrsMTD = 0;
      let totalHrsYTD = 0;

      for (const hr of summaryItem.hours) {
        mainTable.push(
          [
            {border: [true, false, false, false],text:''+hr.description+'',fontSize:9},
            {border: [false, false, false, false],text:''+hr.employee.current+'',fontSize:9},
            {border: [false, false, false, false],text:''+hr.employee.mtd+'',fontSize:9},
            {border: [false, false, false, false],text:''+hr.employee.ytd+'',fontSize:9},
            {border: [false, false, false, false],text:'',fontSize:9},
            {border: [false, false, false, false],text:'',fontSize:9},
            {border: [false, false, true, false],text:'',fontSize:9},
          ],
        )

        totalHrsCurrent += hr.employee.current;
        totalHrsMTD += hr.employee.mtd;
        totalHrsYTD += hr.employee.ytd;

      }

      mainTable.push(
        [
          {border: [true, true, false, true],text:'Total Hours',fontSize:9},
          {border: [false, true, false, true],text:''+totalHrsCurrent+'',fontSize:9},
          {border: [false, true, false, true],text:''+totalHrsMTD+'',fontSize:9},
          {border: [false, true, false, true],text:''+totalHrsYTD+'',fontSize:9},
          {border: [false, true, false, true],text:'',fontSize:9},
          {border: [false, true, false, true],text:'',fontSize:9},
          {border: [false, true, true, true],text:'',fontSize:9},
        ],
      )


      summaryPage.push(
        {
          text: ''+summaryItem.department+'('+summaryItem.code+')',
          fontSize: 12,
          styles:'topHeader',
          alignment: 'left',
          bold: true,
        },
        {
          // margin: [0,30,0,0],
          table: {
          widths: [ '22%', '39%', '39%'],
            body: [
              [
                  {border: [false, false, false, false],text:'',fontSize:11,alignment:'center', bold:true, margin:[2,2,2,2]},
                  {border: [false, false, false, true],text:'Employee',fontSize:11,alignment:'center', bold:true, margin:[2,2,2,2]},
                  {border: [false, false, false, true],text:'Employer',fontSize:11,alignment:'center', bold:true, margin:[2,2,2,2]},
              ],
            ]
          },
          // layout: 'noBorders',
        },

        {
          // margin: [0,30,0,0],
          table: {
          widths: [ '22%','13%','13%','13%','13%','13%','13%'],
            body: mainTable
          },
          pageBreak: 'after',
          layout: {
            defaultBorder:false,
            paddingLeft: function (i, node) { return 1; },
            paddingRight: function (i, node) { return 1; },
            paddingTop: function (i, node) { return 5; },
            paddingBottom: function (i, node) { return 5; },
            }
        },
      )


      summaryBody.push(summaryPage)
    }

    let user = this.currentUser;

    const def = {


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'Period: '+payroll.payCycle.period+'', alignment: 'left', margin: 20, fontSize: 9,bold:true},
            { text: 'Run Date: '+new Date(payroll.payCycle.startDate).toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9,bold:true}
          ],
        };
      },

      content: summaryBody,


      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },

      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();

  }
  cancelDeptSum() {
    this.deptSummaryDateInput = false;
    $("#errorModal").modal("hide");
  }

  preLodgementListingGen () {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getLodgementListingReport(this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      this.lodgementListingGen(next)

    },
    error => {
      this.error = true;
      this.modalMessage = 'Error: getLodgementListingReport!'
      this.loading = false;
      $("#errorModal").modal("show");
    })

  }
  lodgementListingGen(args) {
    this.loading = false;
    $("#errorModal").modal("hide");

    let company = this.company;
    let payroll = this.selectedPayroll;
    let payItem = this.selectedPayItem;


    let totalAmount = 0;
    let totalUsdAmount = 0;
    let employeeCount = 0;

    let lodgementListing = [];
    let lodgementBody = [];

    let startDate = payroll.payCycle.startDate;
    let endDate = payroll.payCycle.endDate;
    let usdRateCol: boolean = false;
    for (const elem2 of args) {
      if (elem2.bank.currency === "USD") {
        usdRateCol = true;
      }
    }


    if (usdRateCol === true) {

      lodgementBody = [
        {
          text: 'Lodgement Listing',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          // margin: [0, 5]
        },
        {
          text: ''+company.companyName+'',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          margin: [0, 5]
        },

        {
          text: 'LODGEMENT LISTING FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
          fontSize: 9,
          alignment: 'center',
        },

        {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

        {
          text: 'FILTER CRITERIA: ALL RECORDS',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 5]
        },


        {
          style: 'tableExample',
          table: {
            widths: ['7%','6%','20%','8%','22%','11%','8%','9%','9%'],
            body: lodgementListing
          },
        },


      ];


      lodgementListing.push(
        [
          {text: 'EMP. #',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'DEPT CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'PAYEE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'BANK CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'BANK NAME',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'ACCOUNT #',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'TYPE',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'AMOUNT',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'USD AMT',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
        ]
      )

      for (const elem of args) {



        let bank = this.bankingCodes.filter(x=>x.id === elem.bank.bank)[0];
        let usdAmount = (elem.amount / company.usdRate);

        lodgementListing.push(
          [
            {text: ''+elem.employeeId+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+elem.department+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+elem.employee+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+bank.code+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+bank.name+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: ''+elem.bank.account+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: ''+elem.bank.type+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: '$ '+elem.amount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: '$ '+usdAmount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
          ]
        )


        totalAmount += elem.amount;
        totalUsdAmount += usdAmount;
        employeeCount ++;

      }

      lodgementBody.push({
        style: 'tableExample',
        table: {
          widths: ['25%','50%','25%'],
          body: [
            [
              {text: '* * TOTAL EMPLOYEES: '+employeeCount+'', fontSize: 9, bold: true, border: [false, false, false, false]},
              {text: '* * GRAND TOTAL AMOUNT FOR ALL BANKS', fontSize: 9, bold: true, border: [false, false, false, false]},
              {text: '$ '+totalAmount.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0], alignment: 'right'},
            ]
          ]
        },
        // layout: 'noBorders'
      })


    }
    else {

      lodgementBody = [
        {
          text: 'Lodgement Listing',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          // margin: [0, 5]
        },
        {
          text: ''+company.companyName+'',
          fontSize: 12,
          alignment: 'center',
          bold: true,
          margin: [0, 5]
        },

        {
          text: 'LODGEMENT LISTING FOR PERIOD '+formatDate(startDate, 'longDate', 'en')+' TO '+formatDate(endDate, 'longDate', 'en')+'',
          fontSize: 9,
          alignment: 'center',
        },

        {canvas: [ { type: 'line', x1: 50, y1: 5, x2: 450, y2: 5, lineWidth: 3, color: 'black', alignment:'center'} ]},

        {
          text: 'FILTER CRITERIA: ALL RECORDS',
          fontSize: 9,
          alignment: 'center',
          margin: [0, 5]
        },


        {
          style: 'tableExample',
          table: {
            widths: ['7%','6%','20%','8%','28%','11%','8%','12%'],
            body: lodgementListing
          },
        },


      ];


      lodgementListing.push(
        [
          {text: 'EMP. #',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'DEPT CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'PAYEE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'BANK CODE',fontSize: 9, alignment: 'left',border: [false, false, false, true]},
          {text: 'BANK NAME',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'ACCOUNT #',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'TYPE',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
          {text: 'AMOUNT',fontSize: 9, alignment: 'right',border: [false, false, false, true]},
        ]
      )

      for (const elem of args) {



        let bank = this.bankingCodes.filter(x=>x.id === elem.bank.bank)[0];

        lodgementListing.push(
          [
            {text: ''+elem.employeeId+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+elem.department+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+elem.employee+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+bank.code+'',fontSize: 9, border: [false, false, false, false], alignment: 'left'},
            {text: ''+bank.name+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: ''+elem.bank.account+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: ''+elem.bank.type+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
            {text: '$ '+elem.amount.toFixed(2)+'',fontSize: 9, border: [false, false, false, false], alignment: 'right'},
          ]
        )


        totalAmount += elem.amount;
        employeeCount ++;

      }


      lodgementBody.push({
        style: 'tableExample',
        table: {
          widths: ['25%','50%','25%'],
          body: [
            [
              {text: '* * TOTAL EMPLOYEES: '+employeeCount+'', fontSize: 9, bold: true, border: [false, false, false, false]},
              {text: '* * GRAND TOTAL AMOUNT FOR ALL BANKS', fontSize: 9, bold: true, border: [false, false, false, false]},
              {text: 'USD $ '+totalUsdAmount.toFixed(2)+'', fontSize: 9, border: [false, true, false, true], margin: [1,0], alignment: 'right'},
            ]
          ]
        },
        // layout: 'noBorders'
      })


    }


    let user = this.currentUser;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const def = {


      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            { text: 'REPORT: IR513100', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'PAGE: '+currentPage, alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      content: lodgementBody,

      pageMargins: [ 15, 15, 15, 15 ],

      footer: function (currentPage, pageCount, pageSize) {

        return {
          columns: [
            { text: 'Printed by: '+user.username+'', alignment: 'left', margin: 20, fontSize: 9},
            { text: 'Printed on: '+new Date().toISOString().slice(0,10)+'', alignment: 'right', margin: 20, fontSize: 9}
          ],
        };
      },


      styles: {
        topHeader: {
          margin: [0, 0, 0, 10],
        },
        header: {
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 10, 0, 10]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        innerHeading: {
            fontSize: 8
        },
        innerHeading2: {
            fontSize: 8,
            bold: true
        },
        content: {
            fontSize: 10
        }
      },
    };

    pdfMake.createPdf(def).open();


  }

  getEmployeeTransactions() {

    this.loading = true;
    $("#errorModal").modal("show");

    let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === this.payitemEmployee.employeeId)
    console.log("getEmployeeTransactions", employee);
    // console.log('employee',employee,'proll emp data',this.selectedPayroll.employeeData,'payitem emp id',this.selectedPayItem.employeeId);

    // let employee = this.employees.find(x=>x.employeeId === this.selectedPayItem.employeeId)
    // console.log('empid',employee.employee.employeeId,'prollid',this.selectedPayroll.id,'proll type',this.selectedPayroll.type);

    this.api.getEmployeeTransactions(employee.employee.employeeId,this.selectedPayroll.id,this.selectedPayroll.type).subscribe(next => {

      if (next[0]) {

        this.showTransactions = true;
        this.employeeTransactions = next;

        this.loading = false;
        $("#errorModal").modal("hide");
        console.log('get emp trans',next);


      } else {

        this.loading = false;
        this.modalMessage = "No Transactions found."

      }


    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getEmployeeTransactions!"
      }
      $("#errorModal").modal("show");
    })

  }
  closeTransactions() {
    this.showTransactions = false;
    this.transactionEdit = false;
    this.transactionAdd = false;
  }
  cancelTransEdit() {
    this.transactionEdit = false;
  }
  cancelTransAdd() {
    this.transactionAdd = false;
  }
  updateTransaction() {


    this.loading = true;
    $("#errorModal").modal("show");

    // let employee = this.employees.find(x=>x.employeeId === this.selectedPayItem.employeeId);
    let employee = this.selectedPayroll.employeeData.find(x=>x.employee.employeeId === this.selectedPayItem.employeeId)

    var amount = 0;

    if(this.selectedTransaction.amount == 0){
      amount = this.selectedTransaction.hours;
    }
    else{
      amount = this.selectedTransaction.amount;
    }

    this.api.editEmployeeTransaction(this.selectedPayroll.id,employee.employee.employeeId,this.selectedTransaction.code.code,amount).subscribe(next => {

      this.loading = false;
      // $("#errorModal").modal("hide");
      // this.transactionEdit = false;
      this.modalMessage = "Transaction Updated"

      setTimeout(()=>{
        this.transactionEdit = false;
        $("#errorModal").modal("hide");
      }, 3000);

    },
    error => {

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: editEmployeeTransaction!"
      }
      $("#errorModal").modal("hide");
    })

  }
  deleteTransaction() {

    let code = this.transactionCodes.find(x=>x.code === this.selectedTransaction.code);
    let index = this.employeeTransactions.findIndex(x=>x === this.selectedTransaction)
    let data = {
      payrollID: this.selectedPayroll.id,
      employeeID: this.payitemEmployee.employeeId,
      transactionIndex: index,
    }


    this.api.removeEmployeeTransaction(JSON.stringify(data)).subscribe(next => {

      this.loading = false;
      this.modalMessage = "Transaction Removed"
      // $("#errorModal").modal("hide");
      // this.transactionEdit = false;

      // this.getEmployeeTransactions();

      setTimeout(()=>{
        this.transactionEdit = false;
        $("#errorModal").modal("hide");

        this.getEmployeeTransactions();
      }, 3000);

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: removeEmployeeTransaction!"
      }
      $("#errorModal").modal("hide");
    })

  }
  startAddTransaction() {
    this.transactionAdd = true;
    this.transactionEdit = false;
  }
  addEmployeeTransaction() {


    let code = this.transactionCodes.find(x=>x.code === this.newTransaction.code);
    let index = this.employeeTransactions.findIndex(x=>x === this.selectedTransaction)
    let data = {
      payrollID: this.selectedPayroll.id,
      employeeID: this.selectedPayItem.employeeId,
      transactionIndex: index,
    }
    // console.log('addEmployeeTransaction',this.newTransaction,JSON.stringify(this.newTransaction),this.selectedPayroll);


    this.newTransaction.code = code;

    let emp = this.selectedPayroll.employeeData.find(x=> x.employee.employeeId == data.employeeID);
    console.log('this.newTransaction',this.newTransaction);

    emp.transactions.push(this.newTransaction)
    console.log('emp.transactions',emp.transactions);

    this.api.addEmployeeTransaction(data.payrollID,JSON.stringify(emp)).subscribe(next => {

      this.loading = false;
      this.modalMessage = "Employee & Transaction Added"
      // $("#errorModal").modal("hide");
      // console.log('addEmployeeTransaction',next);

      // this.transactionAdd = false;

      // this.newTransaction = {
      //   code: '',
      //   hours: 0,
      //   amount: 0,
      // };

      // this.getEmployeeTransactions();

      setTimeout(()=>{

        this.newTransaction = {
          code: '',
          hours: 0,
          amount: 0,
        };
        this.getEmployeeTransactions();

        $("#errorModal").modal("hide");
      }, 3000);

    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: addEmployeeTransaction!"
      }
      $("#errorModal").modal("show");
    })

  }

  payCalc() {
    this.isPayCalc = true;
    this.getCompanyPaySummary()
    this.getPaySummary()

  }

  startAddEmployee() {

    // console.log('startAddEmployee');

    $("#addEmployeeModal").modal("show");
    this.addEmpList = [];
    // let yFilter = this.paySummary.map(a => { return a.employeeId; });
    // let filteredX = this.employeeSrch.filter(b => !yFilter.includes(b.employeeId));
    // this.addEmpList = filteredX;

    // this.addEmpList = this.allEmployees;
    this.addEmpList = this.employeeSrch;

    // this.getEmployees();

    this.newEmployeeTransactions = [];

  }
  preAddEmployee(emp) {
    console.log('preAddEmployee');

    this.newEmployeeNewTransaction = true;
    this.newEmployee = emp;
    this.newTransaction = {
      code: '',
      hours: 0,
      amount: 0,
    };

  }
  cancelNewEmp() {
    this.newEmployeeNewTransaction = false;
    this.newEmployeeTransactions = [];
    this.transactionAmtDisable = false;
    this.transactionHrsDisable = false;
  }
  newEmployeeAddTransaction() {

    // console.log('this.newTransaction',this.newTransaction);

    this.newEmployeeTransactions.push(this.newTransaction);

    this.newEmpMessage = 'Transaction added';
    this.modalMessage = 'Transaction added';

    setTimeout(()=>{
      $("#errorModal").modal("hide");
      this.newEmpMessage = '';
    }, 3000);

    this.newTransaction = {
      code: '',
      hours: 0,
      amount: 0,
    };

  }
  addEmployee() {

    let emp = this.newEmployee;
    this.newEmployeeNewTransaction = false;

    $("#addEmployeeModal").modal("hide");
    this.loading = true;
    $("#errorModal").modal("show");


    let data = {
      employee: emp,
      transactions: [],
      loanCodes: [],
      deductionCodes: [],
      pensionCodes: [],
    };

    for (const tran of this.newEmployeeTransactions) {

      let code = this.transactionCodes.find(x=>x.code === tran.transactionCode)

      let trans = {
        hours: tran.transactionHours,
        amount: tran.transactionAmount,
        code: code,
      };
      data.transactions.push(trans)

    }

    console.log('addEmployeeTransactions',this.selectedPayroll.id,JSON.stringify(data));


    this.api.addEmployeeTransactions(this.selectedPayroll.id,JSON.stringify(data)).subscribe(next => {


      // this.loading = false;
      // $("#errorModal").modal("hide");
      this.transactionAdd = false;

      this.listType = 'open';
      this.api.getOpenPayrolls(this.id).subscribe(next => {


        for (const proll of next) {

          var stateDateYear = new Date(proll.payCycle.startDate).getFullYear();
          var endDateYear = new Date(proll.payCycle.startDate).getFullYear();
          var payDateYear = new Date(proll.payCycle.startDate).getFullYear();

          proll.payCycle.startDate = proll.payCycle.startDate.substr(0,10)
          proll.payCycle.endDate = proll.payCycle.endDate.substr(0,10)
          proll.payCycle.payDateOffset = proll.payCycle.payDateOffset.substr(0,10)

        }
        this.openPayrolls = next;
        let pRoll = next.find(x=>x.id === this.selectedPayroll.id )
        // console.log('proll',pRoll);


        // this.openPayroll(pRoll)


        this.loading = false;
        this.modalMessage = "Transaction Added"
        // $("#errorModal").modal("hide");

        setTimeout(()=>{
          $("#errorModal").modal("hide");
          this.openPayroll(pRoll)
        }, 3000);

      },
      error => {
        this.loading = false;
        this.error = true;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error!"
        }
        $("#errorModal").modal("hide");
      })


    },
    error => {
      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: addEmployeeTransactions!"
      }
      $("#errorModal").modal("show");
    })

  }
  payrollRemoveEmployee() {

    this.loading = false;
    $("#errorModal").modal("hide");

    this.api.removeEmployeeInPayroll(this.selectedPayroll.id,this.payitemEmployee.employeeId).subscribe(next => {

      this.loading = false;
      $("#errorModal").modal("hide");

      console.log("payrollRemoveEmployee", next);

      this.getPaySummary();
      this.getS01PaySummary();
      this.getYtdData('');
      this.getCompanyPaySummary();

    },
    error => {

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: removeEmployeeInPayroll!"
      }
      $("#errorModal").modal("show");
    })


  }
  searchEmployees(val) {

    $('.actionsBox').hide();
    $('.actionsBox2').hide();
    // this.rows.splice(0, this.rows.length);

    if (val.length >= 3) {

      this.getEmployeesBySearch(val);

      // let field = this.searchField;
      // let temp = this.employees.filter(function(d) {
      //   val = val.toLowerCase();
      //   return d[field].toLowerCase().indexOf(val) !== -1 || !val;
      // });

      // this.employeeSrch = [...temp];
    } else if (val.length === 0 || val === "") {

      this.getEmployees();
      // return
    }
    this.newEmpSearchQuery = val;

  }
  selectSearchField(args) {
    this.searchField = args;
  }
  addEmpSetCode(args) {

    this.transactionAmtDisable = false;
    this.transactionHrsDisable = false;

    let code = this.transactionCodes.find(x=> x.code === args)
    this.newTransaction.transactionType = code.type;

    if (this.newTransaction.transactionType.split(" ")[2] === "Hours") {
      this.transactionAmtDisable = true;
      this.newTransaction.transactionAmount = 0;
    }
    if (this.newTransaction.transactionType.split(" ")[2] === "Amount") {
      this.transactionHrsDisable = true;
      this.newTransaction.transactionHours = 0;
    }

  }

  preGenerateBankFileFGB() {

    this.api.getBankFile(this.selectedPayroll.id).subscribe(next => {

      this.loading = false;
      $("#errorModal").modal("hide");

      this.generateBankFileFGB(next)

    },
    error => {

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error!"
      }
      $("#errorModal").modal("hide");
    })



  }
  generateBankFileFGB(args) {
    console.log('generateBankFile',args);

    $('.actionsBox').hide();
    this.loading = true;
    $("#errorModal").modal("show");


    let XL_row_object;

    var workbook = XLSX.utils.book_new();
    var ws_name = "Sheet1";
    let row1 = [];


    let ws_data2 = [];
    for (let index = 0; index < 23; index++) {
      ws_data2.push("")
      row1.push(undefined)
    }

    var ws_data1 = [row1];
    var ws = XLSX.utils.aoa_to_sheet(ws_data1);
    var wscols = [];
    for (let index = 0; index < 23; index++) {
      wscols.push({wch:15})
    }
    ws['!cols'] = wscols;


    XLSX.utils.book_append_sheet(workbook, ws, ws_name);


    let count1 = 1;
    row1[0] = "H";
    for (const [key, value] of Object.entries(args.result.header)) {

      if (typeof value === 'number') {
        row1[count1] = value.toFixed(2);
      } else {
        row1[count1] = value;
      }
      count1++;

    }
    XLSX.utils.sheet_add_aoa(ws, [row1], {origin: -1});


    for (const item of args.result.data) {

      let row2 = [];
      for (let index = 0; index < 23; index++) {
        row2.push(undefined)
      }
      row2[0] = "D";

      let count2 = 1;
      for (const [key, value] of Object.entries(item)) {

        if (typeof value === 'number') {
          // console.log('count2b',count2);
          row2[count2] = value.toFixed(2);
          count2++;
        } else {
          // console.log('count2c',count2);
          row2[count2] = value;
          count2++;
        }
        // console.log('count2',count2);

        // if (count2 > 18 && count2 < 22) {
        //   for (let index = 0; index < 3; index++) {
        //     // console.log('count2a',count2);
        //
        //     row2[count2] = '';
        //     count2++;
        //   }
        // } else {
        //   if (typeof value === 'number') {
        //     // console.log('count2b',count2);
        //     row2[count2] = value.toFixed(2);
        //     count2++;
        //   } else {
        //     // console.log('count2c',count2);
        //     row2[count2] = value;
        //     count2++;
        //   }
        // }

      }


      XLSX.utils.sheet_add_aoa(ws, [row2], {origin: -1});
    }


    function ec(r, c){
      return XLSX.utils.encode_cell({r:r,c:c});
    }
    function delete_row(wsh, row_index){
      var variable = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < variable.e.r; ++R){
        for(var C = variable.s.c; C <= variable.e.c; ++C){
          wsh[ec(R,C)] = wsh[ec(R+1,C)];
        }
      }
      variable.e.r--
      wsh['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
    }
    delete_row(ws, 0)


    // XLSX.utils.sheet_add_aoa(ws, [[4,5,6,7,8]], {origin: -1});
    XL_row_object = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
    // XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    // console.log('XL_row_object',XL_row_object);

    this.loading = false;
    $("#errorModal").modal("hide");

    XLSX.writeFile(workbook, 'bankFile.csv');
    // XLSX.writeFile(workbook, 'bankFile.xlsx');





  }
  preGenerateBankFileNCB() {

    this.loading = true;
    $("#specialModal").modal("show");
    this.api.getBankFileNCB(this.selectedPayroll.id).subscribe(next => {

      // this.loading = false;
      // $("#specialModal").modal("show");
      this.downloadBankFIleNCB = true;
      console.log('next.bankFile',next.bankFile,'2',next.bankFile.split(" "));


      setTimeout(()=>{
        this.generateBankFileNCB(next.bankFile)
        // this.loading = false;
      }, 2000);



    },
    error => {

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error!"
      }
      $("#specialModal").modal("hide");
    })

  }
  generateBankFileNCB(args) {

    this.showS02Link = false;
    this.link = document.getElementById('downloadlink');
    // let fileName = 'test.txt'
    this.link.href = this.makeTextFile(args);
    // this.link.setAttribute('download',fileName)
    this.link.style.display = 'block';
    $("#downloadlink").css('display','block');
    this.showNCB = true;

  }
  preGenerateBankFileBNS() {

    // this.loading = true;
    $("#specialModal").modal("show");

    this.showBNSLink = true;
    this.link = document.getElementById('downloadlink3');
    this.link.download = `https://payrollapidev.caribbeanhrsolutions.com/api/payroll/BNS/${this.selectedPayroll.id}/`;

    // this.api.getBankFileBNS(this.selectedPayroll.id).subscribe(next => {
    //
    //   this.downloadBankFIleBNS = true;
    //   console.log('next.bankFile',next.bankFile,'2',next.bankFile.split(" "));
    //
    //
    //   setTimeout(()=>{
    //     this.generateBankFileBNS(next)
    //     // this.loading = false;
    //   }, 2000);
    //
    //
    //
    // },
    // error => {
    //
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error!"
    //   }
    //   $("#specialModal").modal("hide");
    // })

  }


  selectTaxHeadType(index) {
    this.taxHeadIndx = index;
  }
  cancelEditTaxHead() {
    this.editTaxHeader = false;
  }
  async getPayrollTaxHeaders() {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getPayrollTaxHeaders(this.selectedPayroll.id).subscribe(next => {

      // console.log('getPayrollTaxHeaders',next);

      this.loading = false;
      $("#errorModal").modal("hide");
      this.payrollTaxHeaders = next;

    },
    error => {

      if (error.status == 0 || error.status == 401) {
        location.href = "/login";
      }

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error!"
      }
      $("#specialModal").modal("hide");
    })

  }
  updatePayrollTaxHeader() {

    this.loading = true;
    $("#errorModal").modal("show");

    // console.log('cc',this.selectedTaxHeader,JSON.stringify(this.selectedTaxHeader));
    if (!this.selectedTaxTier.effectiveDate || this.selectedTaxTier.effectiveDate === '' || this.selectedTaxTier.effectiveDate === 0) {
      this.selectedTaxTier.effectiveDate = undefined;
    }
    this.selectedTaxHeader['taxTier'+this.selectedTaxTierType][this.selectedTaxTierIndex] = this.selectedTaxTier;

    this.api.updatePayrollTaxHeader(this.selectedPayroll.id,JSON.stringify(this.selectedTaxHeader)).subscribe(next => {

      this.loading = false;
      // $("#errorModal").modal("hide");
      this.payrollTaxHeaders = next;
      // console.log('updatePayrollTaxHeader',next);

      this.editTaxHeader = false;
      this.modalMessage = "Tax Header Updated"
      // this.getPayrollTaxHeaders();

      setTimeout(()=>{
        $("#errorModal").modal("hide");
        this.getPayrollTaxHeaders();

      }, 3000);

    },
    error => {

      this.loading = false;
      this.error = true;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getPayrollTaxHeaders!"
      }

      this.editTaxHeader = false;
      $("#specialModal").modal("hide");
    })

  }

  makeTextFile(args) {

    let data = new Blob([args], {type: 'text/plain'});

    if (this.textFile !== null) {
      window.URL.revokeObjectURL(this.textFile);
    }

    this.textFile = window.URL.createObjectURL(data);

    // $('.actionsBox').hide();
    this.loading = false;
    // $("#specialModal").modal("hide");

    this.downloadBankFIleNCB = false;

    return this.textFile;

  }

  preS02ASCII() {

    let year = new Date().toISOString().split("-")[0]
    this.S02ASCIILink = `https://payrollapidev.caribbeanhrsolutions.com/api/report/SO2ASCII/${this.id}/${year}`
    this.showS02Link = true;
    $("#specialModal").modal("show");

    // this.loading = true;
    // $("#errorModal").modal("show");
    //
    // let year = new Date().toISOString().split("-")[0]
    //
    // this.api.getS02ASCII(this.id,year).subscribe(next => {
    //
    //   this.loading = false;
    //   $("#errorModal").modal("hide");
    //   console.log('getS02ASCII',next);
    //
    //
    //   this.generateS02ASCII(next)
    //
    // },
    // error => {
    //
    //   this.loading = false;
    //   this.error = true;
    //   if (error.error) {
    //     this.modalMessage = error.error.status
    //   } else {
    //     this.modalMessage = "Error!"
    //   }
    //   $("#errorModal").modal("show");
    // })

  }

  toggleS02() {

    this.showS02Link = false;
    $("#specialModal").modal("hide");

  }

  // generateS02ASCII(args) {
  //
  //   this.link.href = this.makeTextFile(args);
  //   let fileName = 'S02ASCII.'
  //   // this.link.setAttribute('download',fileName)
  //   this.link.style.display = 'block';
  //
  // }


}
