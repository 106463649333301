import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ApiService } from 'src/app/api.service';
import * as XLSX from 'xlsx';
// import moment from 'moment';
import { Employee } from 'src/app/Models/Employee';
import { Defaults } from 'src/app/Models/Defaults';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


declare const $: any;


import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-upload-employee',
  templateUrl: './upload-employee.component.html',
  styleUrls: ['./upload-employee.component.css']
})
export class UploadEmployeeComponent implements OnInit {

  faCaretDown = faCaretDown;
  faEllipsisV = faEllipsisV;
  faTrash = faTrash;
  faSearch = faSearch;

  public url: string;

  employee: Employee = Defaults.EmployeeDefault();
  employeeBatchesToUpload: Array<Employee> = [];
  employees: Array<Employee> = [];
  batchCount: number = 0;
  batchUploading: number = 0;
  public modalMessage: String = '';
  public modalMessage2: String = '';
  public files: NgxFileDropEntry[] = [];
  uploading: Boolean = false;
  loading: Boolean = false;
  companyId: string = '';
  data: any = {
    CompanyID: '',
    Employees: []
  }
  showEmployeeAmount: number = 50;
  publishIndx: number = 0;
  publishing: boolean = false;
  errorBatches: Array<any> = [];


  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagesArray: Array<string> = [];

  public actionsBoxEmployee: any = {};
  public viewingEmployee: Boolean = false;

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log('file!!',droppedFile.relativePath, file, droppedFile, file.type);

          const formData = new FormData()
          formData.append(file.name, file)

          this.employeeBatchParse(file)
          // this.payslipUpload(formData)
          // add file to data

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event){

  }

  public fileLeave(event){

  }

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');

    this.companyId = id;

    $('.actionsBox').toggle();

  }

  stepPage(args) {

    this.employees = [];
    let start = 0
    let end = start+this.showEmployeeAmount;
    if (args === 'previous' && this.currentPage > 1) {
      this.currentPage--;
      start = ((this.currentPage*this.showEmployeeAmount)-this.showEmployeeAmount);
      for (let index = 0; index < this.showEmployeeAmount; index++) {
        this.employees.push(this.data.Employees[start])
        start++;
      }
    }

    if (args === 'next' && this.currentPage < this.totalPages) {
      this.currentPage++;
      start = ((this.currentPage*this.showEmployeeAmount)-this.showEmployeeAmount);
      for (let index = 0; index < this.showEmployeeAmount; index++) {
        this.employees.push(this.data.Employees[start])
        start++;
      }
    }

    if (args !== 'next' && args !== 'previous') {
      this.currentPage = args;
      start = ((this.currentPage*this.showEmployeeAmount)-this.showEmployeeAmount);
      for (let index = 0; index < this.showEmployeeAmount; index++) {
        this.employees.push(this.data.Employees[start])
        start++;
      }
    }
  }

  employeeBatchParse (file) {
    this.publishing = false;
    // document.getElementById("select2").value = "";
    $("#select2").val('')

    const id = this.companyId;
    const reader: FileReader = new FileReader();
    let employees = [];
    let workbook;
    let XL_row_object;
    // let json_object;

    let codeCheck = false;
    reader.readAsBinaryString(file);
    new Promise((resolve, reject) => {
      reader.onload = () => {
         // console.log('reader.result',reader.result);
        let data = reader.result;
        workbook = XLSX.read(data,{type: 'binary'});
        workbook.SheetNames.forEach((sheetName) => {

        XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        XL_row_object.shift();

        // console.log(XL_row_object);

        this.batchCount = Math.ceil(XL_row_object.length/50);
        let batchIndx = 0;
        let thisBatch: Array<any> = [];
        for(const employee of XL_row_object) {

          let indx = XL_row_object.indexOf(employee);
          console.log('indx',indx);


          // let employeeData: any = {};
          let employeeData: Employee = Defaults.EmployeeDefault();
          let occupation: any = Defaults.Codes();
            // console.log(employee['dob']);
          let json_object;


          let current = new Date('1/1/1900');
            // console.log(employee['dob']);
          if(typeof employee['dob'] == 'number'){
              current.setDate(current.getDate() + (employee['dob'] - 2));
          }
          else {
            if (employee.dob) {

              if (employee.dob.charAt(0) === "" || employee.dob.charAt(0) === " ") {
                current = null
              }
              else {

                let xDate = employee.dob.split('/')[2]+"-"+employee.dob.split('/')[1]+"-"+employee.dob.split('/')[0];
                current = new Date(xDate);
              }
              // current = new Date(employee.dob.toString());
              // let xDate = employee.dob.split('/')[2]+"-"+employee.dob.split('/')[1]+"-"+employee.dob.split('/')[0];
              // current = new Date(xDate);
            }

          }
          // console.log('indx',indx,current,employee['dob'],employee.dob,typeof employee['dob'],typeof employee.dob,typeof current);


          let current2 = new Date('1/1/1900');
          if(typeof employee['start_date'] == 'number'){
              current2.setDate(current2.getDate() + (employee['start_date'] - 2));
          }
          else {
            if (employee['start_date']) {

              if (employee['start_date'].charAt(0) === "" || employee['start_date'].charAt(0) === " ") {

                current2 = null
              }
              else {

                let x2Date = employee['start_date'].split('/')[2]+"-"+employee['start_date'].split('/')[1]+"-"+employee['start_date'].split('/')[0];
                current2 = new Date(x2Date);
              }

              // current2 = new Date(employee['start_date'].toString());
              // let x2Date = employee.dob.split('/')[2]+"-"+employee.dob.split('/')[1]+"-"+employee.dob.split('/')[0];
              // current2 = new Date(x2Date);
            }

          }

          if(typeof employee["trn"] === 'number') {
              employee["trn"] = employee["trn"].toString();
            }
          if (employee["trn_payable"].toString().trim() == 'true') {
              employeeData.trnPayable = true;
            }
          else{
              employeeData.trnPayable = false;
            }
          if (employee["nis_payable"].toString().trim() == 'true') {
              employeeData.nisPayable = true;
            }
          else{
              employeeData.nisPayable = false;
            }
          if (employee["nht_payable "].toString().trim() == 'true') {
              employeeData.nhtPayable = true;
            }
          else{
              employeeData.nhtPayable = false;
            }


          for(const [key, value] of Object.entries(employee)) {
              let val: any = value;

              if (key.toLowerCase() === "first_name") {
                employeeData.firstName = val;
              }
              if (key.toLowerCase() === "middle_name") {
                employeeData.middleName = val;
              }
              if (key.toLowerCase() === "last_name") {
                employeeData.lastName = val;
              }
              if (key.toLowerCase() === "dept_code") {
                employeeData.employment.department.code = typeof(val) == 'number' ? val.toString() : val;
              }
              if (key.toLowerCase() === "occup_code") {
                employeeData.employment.occupation.code = typeof(val) == 'number' ? val.toString() : val;
              }
              if (key.toLowerCase() === "pay_type") {
                employeeData.employment.payCycle = val;
              }
              if (key.toLowerCase() === "rate") {
                employeeData.employment.rate = val;
              }
              if (key.split("_")[0].toLowerCase() === 'address') {
                employeeData.address[key.split("_")[1].toLowerCase()] = val;
              }
              if (key.split("_")[0].toLowerCase() === 'bank') {
                if (key.split("_")[1].toLowerCase() === 'method') {
                  employeeData.banking.method = val;
                }
                if (key.split("_")[1].toLowerCase() === 'code') {
                  employeeData.banking.bank = typeof(val) == 'number' ? val.toString() : val;
                }
                if (key.split("_")[2]) {
                  if (key.split("_")[2].toLowerCase() === 'type') {
                    employeeData.banking.type = val;
                  }
                  // if (key.split("_")[2].toLowerCase() === 'number') {
                  //   employeeData.banking.account = val;
                  // }
                }
                if (key.split("_")[1].toLowerCase() === 'acc' && key.split("_")[2].toLowerCase() === 'acc') {
                  employeeData.banking.account = val;
                }
                employeeData.banking[key.split("_")[1].toLowerCase()] = val;
              }
              else {
                employeeData[key.toLowerCase()] = val;
              }

            }


          employeeData.parentId = id;
          // employeeData.firstName = employee["first_name"];
          // employeeData.middleName = employee["middle_name"];
          // employeeData.lastName = employee["last_name"];
          // employeeData.trn = employee["trn"];
          employeeData.gender = employee["gender"];
          // employeeData.nis = employee["nis"];
          employeeData.employeeId = employee["employee_id"];
          // employeeData.employeeId = employee["employee_id"].toString();


          // employeeData.employment.department.code = typeof(employee["dept_code"]) == 'number' ? employee["dept_code"].toString() : employee["dept_code"];
          // employeeData.employment.occupation.code = typeof(employee["occup_code"]) == 'number' ? employee["occup_code"].toString() : employee["occup_code"];
          // employeeData.employment.rate = employee["rate"];
          // employeeData.email = employee["email"];
          // employeeData.address.street = employee["address_street"];
          // employeeData.address.city = employee["address_city"];
          // employeeData.address.parish = employee["address_parish"];
          // employeeData.address.country = employee["address_country"];
          employeeData.dob = current;
          // employeeData.gender = employee["gender"];
          // employeeData.type = employee["type"];
          // // employeeData.bank.code = employee["bank_code"].toString();
          // // employeeData.bank.description = employee["bank_desc"];
          // // employeeData.bank.branch = employee["bank_branch"];
          // employeeData.banking.bank = typeof(employee["bank_code"]) == 'number' ? employee["bank_code"].toString() : employee["bank_code"];
          // employeeData.banking.type = employee["bank_acc_type"];
          // employeeData.banking.method = employee["bank_method"];
          // employeeData.banking.account = Number(employee["bank_acc_number"]);
          // employeeData.employment.payCycle = employee["pay_type"];


          this.data.Employees.push(employeeData);
          thisBatch.push(employeeData)
          // console.log('thisBatch.length',thisBatch.length)

          if ((indx+1)%50 === 0 || indx === XL_row_object.length-1) {

            console.log('batch ',batchIndx+1,' parse complete');

            json_object = thisBatch;
            resolve(json_object);
            // console.log('thisBatch.length @ batch step',thisBatch.length,'json_object',json_object);

            this.employeeBatchesToUpload.push(json_object)

            thisBatch = [];
            batchIndx++;

            if (indx === XL_row_object.length-1) {
              batchIndx = 0;
            }

          }
           // this.employees.push(employeeData);
           // console.log('employeeData',employeeData);

         }

         for (let index = 0; index < this.showEmployeeAmount; index++) {
           this.employees.push(this.data.Employees[index]);
         }

         this.totalPages = Math.ceil(this.data.Employees.length/this.showEmployeeAmount);
         this.pagesArray = [];
         for (let index2 = 0; index2 < this.totalPages; index2++) {
           this.pagesArray.push("")
         }
         // console.log('this.employees',this.employees,'this.data.Employees',this.data.Employees,'this.totalPages',this.totalPages,'this.pagesArray',this.pagesArray);
         this.employees = this.data.Employees;

        // json_object = employees;
        // resolve(json_object);

        });

      };

    })

    // .then((json_object: any) => {
    //
    //   console.log(json_object);
    //
    //   this.data.Employees = json_object;
    //
    // })
    .catch(err => {
      console.log('sheet parse error',err);
    });

  }

  multiPublish() {

    console.log('json batches to upload',JSON.stringify(this.employeeBatchesToUpload[this.publishIndx]));


    console.log('uploading batch ',this.publishIndx+1);
    this.modalMessage2 = 'uploading batch '+(this.publishIndx+1)+''
    // $("#errorModal").modal("show");
    // this.loading = true;

    this.api.addEmployeeBatch(JSON.stringify(this.employeeBatchesToUpload[this.publishIndx])).subscribe(next =>{

      this.publishIndx++;
      // console.log('batch upload success?',next);
      this.modalMessage2 = 'batch '+(this.publishIndx+1)+' upload success';

      if (this.publishIndx < this.employeeBatchesToUpload.length) {
        this.multiPublish()
      }

      if (this.publishIndx === this.employeeBatchesToUpload.length) {
        this.loading = false;
        this.publishing = false;
        this.modalMessage = "Success!"+this.publishIndx+" Batches uploaded"
        // $("#errorModal").modal("show");
      }

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: addEmployeeBatch! Terminated at batch"+this.publishIndx+1;
      }
      this.errorBatches.push(this.employeeBatchesToUpload[this.publishIndx])
      this.publishIndx = 0;
      this.publishing = false;
      $("#errorModal").modal("show");

    });

  }

  publish(){
    this.publishing = true;
    this.loading = true;
    $("#errorModal").modal("show");

    console.log('goodup goodup',JSON.stringify(this.employeeBatchesToUpload[this.publishIndx]));
    console.log('uploading batch 1');
    this.modalMessage2 = 'uploading batch '+(this.publishIndx+1)+''


    this.api.addEmployeeBatch(JSON.stringify(this.employeeBatchesToUpload[this.publishIndx])).subscribe(next =>{

      this.publishIndx++;
      // console.log('batch upload success?',next);
      this.modalMessage2 = 'batch '+(this.publishIndx+1)+' upload success';

      if (this.publishIndx < this.employeeBatchesToUpload.length-1) {
        this.multiPublish()
      }

      if (this.publishIndx === this.employeeBatchesToUpload.length-1) {
        this.loading = false;
        this.publishing = false;
        this.modalMessage = "Success!"+this.publishIndx+" Batches uploaded"
        // $("#errorModal").modal("show");
      }
    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: addEmployeeBatch! Terminated at batch"+this.publishIndx+1;
      }
      this.publishIndx = 0;
      this.publishing = false;
      $("#errorModal").modal("show");

    });


  }

  actionsBox (event,employee) {

    this.actionsBoxEmployee = employee;
    this.actionsBoxEmployee.dob = this.actionsBoxEmployee.dob.toString().slice(0,10);
    $('.actionsBox').css({'top':event.pageY-50,'left':event.pageX+10})
    $('.actionsBox').toggle();
  }

  viewEmployee () {

    this.viewingEmployee = true;
    this.modalMessage = "Employee Details"
    $("#errorModal").modal("show");
    $('.actionsBox').toggle();

  }

  closeEmpView () {
    this.viewingEmployee = false;
  }

  finish() {
    $("#errorModal").modal("hide");
    this.loading = false;
    this.publishing = false;
    this.router.navigate(['pages/company', this.companyId, 'details']);
  }

  selectFile(files) {
    let file = files[0];
    const formData = new FormData()
    formData.append(file.name, file)

    this.employeeBatchParse(file)
  }


}
