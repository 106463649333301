import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { enableProdMode } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AuthGuardService } from './guards/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateCompanyComponent } from './pages/create-company/create-company.component';
import { CreateAdminComponent } from './pages/create-admin/create-admin.component';
import { UserControlComponent } from './user-control/user-control.component';
import { EditCompanyComponent } from './pages/edit-company/edit-company.component';
import { DetailsCompanyComponent } from './pages/details-company/details-company.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DatamanagementCompanyComponent } from './pages/datamanagement-company/datamanagement-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateEmployeeComponent } from './pages/create-employee/create-employee.component';
import { UploadEmployeeComponent } from './pages/upload-employee/upload-employee.component';
import { CodesComponent } from './pages/codes/codes.component';
import { TaxesDeductionsComponent } from './pages/taxes-deductions/taxes-deductions.component';
import { ChartsOfAccountsComponent } from './pages/charts-of-accounts/charts-of-accounts.component';
import { PayrollManagementComponent } from './pages/payroll-management/payroll-management.component';
import { PayrollHistoryComponent } from './pages/payroll-history/payroll-history.component';
import { BankingComponent } from './pages/banking/banking.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { GeneralReportsComponent } from './pages/general-reports/general-reports.component';

enableProdMode();

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'pages/company/create', component: CreateCompanyComponent, canActivate: [AuthGuardService]},
  { path: 'pages/company/:id/details', component: DetailsCompanyComponent, canActivate: [AuthGuardService]},
  { path: 'pages/company/:id/datamanagement', component: DatamanagementCompanyComponent, canActivate: [AuthGuardService]},
  { path: 'pages/company/:id/employee/upload', component: UploadEmployeeComponent, canActivate: [AuthGuardService]},
  { path: 'pages/company/:id/employee/:action', component: CreateEmployeeComponent, canActivate: [AuthGuardService]},
  { path: 'pages/company/:id/employee/:edit/:employee', component: CreateEmployeeComponent, canActivate: [AuthGuardService]},

  { path: 'pages/company/edit', component: EditCompanyComponent, canActivate: [AuthGuardService]},
  { path: 'pages/admin/create', component: CreateAdminComponent, canActivate: [AuthGuardService]},
  { path: 'pages/company/:id/codes', component: CodesComponent},
  { path: 'pages/company/:id/charts-of-accounts', component: ChartsOfAccountsComponent},
  { path: 'pages/company/:id/payroll-management', component: PayrollManagementComponent},
  { path: 'pages/company/:id/payroll-history', component: PayrollHistoryComponent},
  { path: 'pages/company/:id/reports', component: ReportsComponent},
  { path: 'pages/company/:id/general-reports', component: GeneralReportsComponent},
  { path: 'pages/taxes-deductions', component: TaxesDeductionsComponent},
  { path: 'pages/banking', component: BankingComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },

  { path: '**', redirectTo: 'login' }
];


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SidebarComponent,
    CreateCompanyComponent,
    DatamanagementCompanyComponent,
    CreateAdminComponent,
    UserControlComponent,
    EditCompanyComponent,
    DetailsCompanyComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DatamanagementCompanyComponent,
    CreateEmployeeComponent,
    UploadEmployeeComponent,
    CodesComponent,
    TaxesDeductionsComponent,
    ChartsOfAccountsComponent,
    PayrollManagementComponent,
    PayrollHistoryComponent,
    BankingComponent,
    ReportsComponent,
    GeneralReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxFileDropModule,
    RouterModule.forRoot(routes),
    NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
