import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from '../api.service';
import { AppService } from '../app.service';

import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Ach } from 'src/app/Models/Ach';
import { Defaults } from 'src/app/Models/Defaults';

import * as $ from 'jquery';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  faHome = faHome;
  faDatabase = faDatabase;
  faList = faList;
  faChartBar = faChartBar;
  faCog = faCog;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faSignOutAlt = faSignOutAlt;
  faUserTie = faUserTie;
  public url: String;
  url2 : String;
  url3 : String;
  url4 : String;
  public showSidebar: Boolean = true;
  public toggleState: Boolean = false;
  public item3: Boolean = false;
  public item5: Boolean = false;
  public item4: Boolean = false;
  public page: String = "";
  public showManagementProcessing: Boolean = false;

  public loading: Boolean = false;
  public modalMessage: string = '';

  public companyId: String = '';

  selectedCompany: Company = Defaults.CompanyDefaults();
  companies: Array<Company> = [];
  companiesSrch: Array<Company> = [];
  public showResults: boolean = false;
  companiesSrchShow: Array<Company> = [];

  address: Address = Defaults.AddressDefaults();
  ach: Ach = Defaults.achDefault();

  currentUser: any = {};
  createCompany: boolean = false;



  constructor(
    private api: ApiService,
    private appService: AppService,
    private location:Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer:Renderer2
  ) {

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.showSidebar = false;
        // this.url = '';
        // this.page = '';
        // $("#sidebar-"+this.page+"").removeClass('liHighlight');
      }

      if (event instanceof NavigationEnd) {

        let companySelected = localStorage.getItem('companySelected')
        if (companySelected === 'true') {
          this.showManagementProcessing = true;
        }

        this.url = event.url.split('/')[1];
        this.url2 = event.url.split('/')[2];
        this.url3 = event.url.split('/')[3];
        this.url4 = event.url.split('/')[4];


        if (
          this.url === 'pages' ||
          this.url === 'dashboard'
        ) {
          this.showSidebar = true;

          if (this.toggleState === true) {
            $(".sidebar").addClass('sidebarOpen');
            $(".pageContainerSidebar").addClass('pageContainerSidebarOpen');
          }

          if (this.url === 'dashboard') {
            this.page = 'dashboard';
            this.showManagementProcessing = false;
          }


        } else {

          this.showSidebar = false;
          $(".sidebar").removeClass('sidebarOpen');
          $(".pageContainerSidebar").removeClass('pageContainerSidebarOpen');

        }

        if (this.url2 === 'company') {
          if (this.url3.split('?')[0] === 'details') {
            this.companyId = this.url3.split('?')[1];
            this.showManagementProcessing = true;
          }
          this.companyId = this.url3;
          this.showManagementProcessing = true;
        } else {
          // this.showManagementProcessing = false;
        }

        if (this.url2 && this.url2 === 'company' && this.url3 && this.url3.split("?")[0] === 'create') {
          this.showManagementProcessing = false;
          this.page = "createCompany"
        }

        if (this.url2) {
          if (this.url2 === 'admin') {
            this.page = "admin"
          }
          if (this.url2 === 'taxes-deductions') {
            this.page = "taxes"
          }
          if (this.url2 === 'banking') {
            this.page = "banking"
          }
        }

        if (this.url4) {
          if (this.url4 === 'codes') {
            this.page = "codes"
          }
          if (this.url4 === 'charts-of-accounts') {
            this.page = "charts-of-accounts"
          }
          if (this.url4 === 'reports') {
            this.page = "reports-reports"
          }
          if (this.url4 === 'general-reports') {
            this.page = "reports-general"
          }
          if (this.url4 === 'payroll-management') {
            this.page = "payroll-management"
          }
          if (this.url4 === 'datamanagement') {
            this.page = "datamanagement"
          }
        }


      }

      if (event instanceof NavigationError) {

      }

    })

  }

  ngOnInit() {


    let user = localStorage.getItem('user');

    $("#sidebar-dashboard").addClass('liHighlight');

    let companySelected = localStorage.getItem('companySelected')
    if (companySelected === 'true') {
      this.showManagementProcessing = true;
    }
    this.currentUser = JSON.parse(localStorage.getItem("admin"))

    // this.getCompanies ();

    if (this.currentUser) {
      if (this.currentUser.permissions.includes('create company') === true) {
        this.createCompany = true;
      }
    }


  }

  toggle() {

    if (this.toggleState === false) {

      $(".sidebar").addClass('sidebarOpen');
      $(".pageContainerSidebar").addClass('pageContainerSidebarOpen');
      this.toggleState = !this.toggleState;
    } else {
      $(".sidebar").removeClass('sidebarOpen');
      $(".pageContainerSidebar").removeClass('pageContainerSidebarOpen');
      this.toggleState = !this.toggleState;
    }
  }

  toggleIndicator (args) {
    if (args === 'item3') {
      this.item3 = !this.item3;
    }
    if (args === 'item4') {
      this.item4 = !this.item4;
    }
    if (args === 'item5') {
      this.item5 = !this.item5;
    }
  }

  logout () {

    localStorage.clear();
    this.router.navigate(['/login']);

  }

  // highlight changes w/ component, @ create company, hide non-company lis

  getCompanies () {

    this.loading = true;
    $("#errorModal").modal("show");

    this.api.getCompanies().subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");
        // console.log('this.customers',next);

        this.companies = next;
        this.companiesSrch = next;

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: getCompanies!"
        }
        $("#errorModal").modal("show");
      }
    )

  }

  closeModal() {

  }


  searchCompanies(val) {

    // this.rows.splice(0, this.rows.length);
    let field = 'companyName';
    let temp = this.companies.filter(function(d) {
      val = val.toLowerCase();
      return d[field].toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.companiesSrch = [...temp];
    this.companiesSrchShow = [];
    for (let index = 0; index < 5; index++) {
      const element = this.companiesSrch[index];
      this.companiesSrchShow.push(element)
    }

  }

  // goToDataManagement () {
  //   this.router.navigate(['pages/company/'], { queryParams: { 'id': this.companyId }},['/datamanagement']);
  // }

}
