import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Company } from 'src/app/Models/Company';
declare const $: any;

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

  data: any = {};
  companyId: String = '';

  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {

      // if (params.id) {}
    })

  }

  getCompany () {

    // this.api.getCompany(this.companyId).subscribe(
    //   next => {

    //   },
    //   error => {

    //   }
    // )
  }

  onSubmit () {

    // this.api.editCompany(this.data).subscribe(
    //   next => {
    //     console.log(next);
    //   },
    //   error => {
    //     console.log('api request error',error);
    //   }
    // )

  }

}
