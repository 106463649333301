import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
declare const $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  data: any = {}
  public success: Boolean = false;
  public modalMessage: string = '';
  public loading: Boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {

  }

  onSubmit () {
    this.loading = true;
    $("#errorModal").modal("show");

    this.api.adminForgetPassword(this.data.email).subscribe(
      next => {
        this.loading = false;
        $("#errorModal").modal("hide");
        // on recieving a token show a success message
      },
      error => {
        this.loading = false;
        this.modalMessage = "Error";
        $("#errorModal").modal("show");
      }
    )

  }

}
