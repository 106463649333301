import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Company } from 'src/app/Models/Company';
import { Address } from 'src/app/Models/Address';
import { Banking } from 'src/app/Models/Banking';
import { BankCode } from 'src/app/Models/BankCode';
import { DeductionCode } from 'src/app/Models/DeductionCode';
import { DepartmentOccupationCode } from 'src/app/Models/DepartmentOccupationCode';
import { HealthCode } from 'src/app/Models/HealthCode';
import { LoanCode } from 'src/app/Models/LoanCode';
import { PensionCode } from 'src/app/Models/PensionCode';
import { TransactionCode } from 'src/app/Models/TransactionCode';

declare const $: any;

import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Defaults } from 'src/app/Models/Defaults';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css']
})
export class CreateCompanyComponent implements OnInit {


  faWindowClose = faWindowClose;
  data: Company = {} as any;
  public loaded: Boolean = false;
  public modalMessage: String = '';

  public files: NgxFileDropEntry[] = [];
  public filePresent: Boolean = false;
  public fileName: string = '';
  uploading: Boolean = false;
  public loading: Boolean = false;
  public imgPreview: Boolean = false;
  id: string = "";

  company: Company = Defaults.CompanyDefaults();
  address: Address = Defaults.AddressDefaults();

  path: string = '';

  // bankCode: BankCode = Defaults.BankCodeDefault()
  bankingCodes: Array<BankCode> = [];

  public addNewBanking: boolean = false;
  public newBanking: Banking = Defaults.BankingDefault();
  public canAddNewBanking: Boolean = false;

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.imgPreview = true;
    this.modalMessage = 'Image Preview';
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log('file!!',droppedFile.relativePath, file, droppedFile, file.type);


          // this.imgPreview = true;
          // // $(".previewImg").attr("src", file);
          // $("#errorModal").modal("show");

          previewImg(file)
          function previewImg(file) {
              let reader = new FileReader();
              reader.onload = function() {
                $(".previewImg").attr("src", reader.result);
                $("#errorModal").modal("show");

              };
              reader.readAsDataURL(file);
          }


          this.filePresent = true;
          this.fileName = droppedFile.relativePath;

          const formData = new FormData()
          formData.append(file.name, file)
          this.uploadFile(formData)
          // add file to data

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;

      }
    }
  }

  public fileOver(event){

  }

  public fileLeave(event){

  }


  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {

    this.loaded = true;

    this.route.queryParams.subscribe(params =>{

      if(params.id != undefined){

        this.api.getCompany(params.id).subscribe(companyDat =>{

          this.id = params.id

          //this.data = companyDat;
          this.company = companyDat;
          this.address = companyDat.address;
          this.company.payrollStartDates = companyDat.payrollStartDates.substring(0,10)
          this.company.usdEffectiveDate = companyDat.usdEffectiveDate.substring(0,10)
          this.company.monthly.payDay = companyDat.monthly.payDay.substring(0,10)
          this.company.biMonthly.firstPayDay = companyDat.biMonthly.firstPayDay.substring(0,10)
          this.company.biMonthly.secondPayDay = companyDat.biMonthly.secondPayDay.substring(0,10)
          // console.log('this.company',companyDat);

          if (this.company.banking.length < 5) {
            this.canAddNewBanking = true;
          }

        });
      }
    });

    this.api.getBankingCodes().subscribe(next => {

      // console.log('banking Codes',next);
      this.bankingCodes = next;


      // this.loading = false;
      // $("#errorModal").modal("hide");

    },
    error => {
      this.loading = false;
      if (error.error) {
        this.modalMessage = error.error.status
      } else {
        this.modalMessage = "Error: getBankingCodes!"
      }
      $("#errorModal").modal("show");
    })

    // this.uploading = true;
    // $("#loadingModal").modal("show");

  }

  onSubmit() {

    this.loading = true;
    $("#errorModal").modal("show");

    this.company.logo = this.path;
    this.company.address = this.address;

    this.company.password = btoa(this.company.password);

    if (
      !this.company.usdEffectiveDate ||
      this.company.usdEffectiveDate === '' ||
      this.company.usdEffectiveDate === '0001-01-01'
    ) {
      // this.loading = false;
      // this.modalMessage = 'Set USD Effective Date first'
      // $("#errorModal").modal("show");
      // console.log('Set USD Effective Date first');
      this.company.usdEffectiveDate = new Date().toISOString().substr(0,10);
    }



    if(this.company.id == ''){

      if (this.addNewBanking === true) {
        this.company.banking.push(this.newBanking)
      }


      this.api.createCompany(this.company).subscribe(
        next => {

          if (next.status === 'created') {
            this.loading = false;
            this.modalMessage = "Company Created Successfully!"
            $("#errorModal").modal("show");
            // document.location.href = "/dashboard";

            setTimeout(()=>{
              document.location.href = `/pages/company/${this.id}/details`;
            }, 3000);

          }
        },
        error => {
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: createCompany!"
          }
          $("#errorModal").modal("show");
        }
      );
    }
    else {

      if (this.addNewBanking === true) {
        this.company.banking.push(this.newBanking)
      }


      this.api.editCompany(this.company).subscribe(
        next => {
          // console.log('edited company',this.company);


          this.loading = false;
          // document.location.href = "/dashboard";
          // document.location.href = `/pages/company/${this.id}/details`;
          this.modalMessage = "Company Updated Successfully!"
          $("#errorModal").modal("show");

          setTimeout(()=>{
            document.location.href = `/pages/company/${this.id}/details`;
          }, 3000);

          // if (next.status === 'updated') {
          //   this.loading = false;
          //   document.location.href = "/dashboard";
          //   this.modalMessage = "Company Created Successfully!"
          //   $("#errorModal").modal("show");
          //
          // }
        },
        error => {
          this.loading = false;
          if (error.error) {
            this.modalMessage = error.error.status
          } else {
            this.modalMessage = "Error: editCompany!"
          }
          $("#errorModal").modal("show");
        }
      )
    }

  }

  clearFiles() {
    this.filePresent = false;
    this.fileName = '';


  }

  uploadFile (fileData) {
    this.uploading = true;

    this.modalMessage = 'Uploading'
    $("#errorModal").modal("show");


    this.api.upload(fileData).subscribe(
      next => {
        // $("#errorModal").modal("hide");


        this.uploading = false;

        this.path = next.path
        this.modalMessage = "Upload Success"

        setTimeout(()=>{
          $("#errorModal").modal("hide");
        }, 3000);

      },
      error => {
        this.uploading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: uploadLogo!"
        }
        $("#errorModal").modal("show");
      }
    );

    $("#errorModal").modal("hide");
  }

  startAddNewBanking() {
    this.addNewBanking = true
  }
  cancelAddNewBanking() {
    this.addNewBanking = false
  }

  selectFile(files) {

    let file = files[0]
    previewImg(file)
    function previewImg(file) {
        let reader = new FileReader();
        reader.onload = function() {
          $(".previewImg").attr("src", reader.result);
          $("#errorModal").modal("show");

        };
        reader.readAsDataURL(file);
    }


    this.filePresent = true;

    const formData = new FormData()
    formData.append(file.name, file)
    this.uploadFile(formData)

  }


}
