<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Reports</h3>
      </div>

      <div class="row input">
        <div class=" btnCol">
          <button type="button" class="btn createBtn" (click)="toggleListType('Open')"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Open Payrolls</p></button>
        </div>
        <div class=" btnCol">
          <button type="button" class="btn showBtn" (click)="toggleListType('Closed')"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Closed Payrolls</p></button>
        </div>

      </div>

    </div>

    <div class="subcontainerLower">


      <div class="row lowerTable" *ngIf="setting == 'List'">

        <div class="row upperHeading">
          <h4 class="lowerHeader">Payrolls {{listType}}</h4>
        </div>

        <table class="table" *ngIf="listType == 'Open'">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Pay Period</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Pay Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Cycle</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Start Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">End Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Reports</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let payroll of openPayrolls; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.period}} - {{payroll.type}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.payDateOffset | date}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.period}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.startDate | date}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.endDate | date}}</p></td>
            <td class="tableCels">
              <a href="javascript:" class="actionLink" (click)="selectPayroll(payroll)">
                <fa-icon [icon]="faList" ></fa-icon>
              </a>
            </td>
          </tr>
        </table>

        <table class="table" *ngIf="listType == 'Closed'">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Pay Period</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Pay Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Cycle</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Start Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">End Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Reports</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let payroll of closedPayrolls; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.period}} - {{payroll.type}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.payDateOffset | date}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.period}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.startDate | date}}</p></td>
            <td class="tableCels"><p class="cellText">{{payroll.payCycle.endDate | date}}</p></td>
            <td class="tableCels">
              <a href="javascript:" class="actionLink" (click)="selectPayroll(payroll)">
                <fa-icon [icon]="faList" ></fa-icon>
              </a>
            </td>
          </tr>
        </table>

      </div>


      <div class="row lowerTable" *ngIf="setting == 'Report'">


        <div class="row upperHeading">
        <h4 class="lowerHeader">Current Payroll</h4>
        </div>

        <ul class="dashboardClientList">
          <li class="dashboardClientListItem" *ngFor="let report of reports.current">
            <div class="payrollCard">

              <table class="customerCardTable">
                <tr class="customerCardTableRowTop">
                  <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                    <h5 class="customerCardHeading">{{report.name}}</h5>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <p class="customerCardText">{{report.description}}</p>
                  </td>
                </tr>
                <tr class="editBtnRow">

                  <div *ngIf="report.name != 'Payslip'" class="dropdown" id="drop1">
                    <button class="btn btn-secondary dropdown-toggle xlsBtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">XLS</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('current',report,'xls',false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('current',report,'xls',true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>

                  <div *ngIf="report.name != 'Payslip'" class="dropdown" id="drop2">
                    <button class="btn btn-secondary dropdown-toggle pdfBtn" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">PDF</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('current',report,'pdf',false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('current',report,'pdf',true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>

                  <div *ngIf="report.name == 'Payslip'" class="dropdown" id="drop3">
                    <button class="btn btn-secondary dropdown-toggle pdfBtn" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">PDF</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="payslipReportHandler('single','current',report)"><p class="btnText2">Single Employee</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="payslipReportHandler('allFiltered','current',report)"><p class="btnText2">All Employees (Filtered)</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="payslipReportHandler('allUnfiltered','current',report)"><p class="btnText2">All Employees (Un-Filtered)</p></a>
                    </div>
                  </div>

                  <!-- <button type="button" (click)="docPreGen('current',report,'xls')" class="btn xlsBtn"><p>XLS</p></button>
                  <button type="button" (click)="docPreGen('current',report,'pdf')" class="btn pdfBtn"><p>PDF</p></button> -->
                </tr>
              </table>

            </div>
          </li>
        </ul>


        <div class="row upperHeading">
        <h4 class="lowerHeader" >Year To Date</h4>
        </div>

        <ul class="dashboardClientList">
          <li class="dashboardClientListItem" *ngFor="let report of reports.ytd">
            <div class="payrollCard">

              <table class="customerCardTable">
                <tr class="customerCardTableRowTop">
                  <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                    <h5 class="customerCardHeading">{{report.name}}</h5>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <p class="customerCardText">{{report.description}}</p>
                  </td>
                </tr>
                <tr class="editBtnRow">

                  <div class="dropdown" id="drop3">
                    <button class="btn btn-secondary dropdown-toggle xlsBtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">XLS</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('ytd',report,'xls',false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('ytd',report,'xls',true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>

                  <div class="dropdown" id="drop4">
                    <button class="btn btn-secondary dropdown-toggle pdfBtn" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">PDF</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('ytd',report,'pdf',false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('ytd',report,'pdf',true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>
                  <!-- <button type="button" (click)="docPreGen('ytd',report,'xls')" class="btn xlsBtn"><p>XLS</p></button>
                  <button type="button" (click)="docPreGen('ytd',report,'pdf')" class="btn pdfBtn"><p>PDF</p></button> -->
                </tr>
              </table>

            </div>
          </li>
        </ul>


        <div class="row upperHeading">
        <h4 class="lowerHeader" >Error Reports</h4>
        </div>

        <ul class="dashboardClientList">
          <li class="dashboardClientListItem" *ngFor="let report of reports.previous">
            <div class="payrollCard">

              <table class="customerCardTable">
                <tr class="customerCardTableRowTop">
                  <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                    <h5 class="customerCardHeading">{{report.name}}</h5>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <p class="customerCardText">{{report.description}}</p>
                  </td>
                </tr>
                <tr class="editBtnRow">

                  <div class="dropdown" id="drop5">
                    <button class="btn btn-secondary dropdown-toggle xlsBtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">XLS</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('previous',report,'xls',false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('previous',report,'xls',true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>

                  <div class="dropdown" id="drop6">
                    <button class="btn btn-secondary dropdown-toggle pdfBtn" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <p class="btnText">PDF</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('previous',report,'pdf',false)"><p class="btnText2">Un-Filtered</p></a>
                      <a class="dropdown-item" href="javascript:" (click)="reportHandler('previous',report,'pdf',true)"><p class="btnText2">Filtered</p></a>
                    </div>
                  </div>

                  <!-- <button type="button" (click)="docPreGen('previous',report,'xls')" class="btn xlsBtn"><p>XLS</p></button>
                  <button type="button" (click)="docPreGen('previous',report,'pdf')" class="btn pdfBtn"><p>PDF</p></button> -->
                </tr>
              </table>

            </div>
          </li>
        </ul>


      </div>

    </div>
  </div>

</div>


<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Generate Payslip</p>
      </a>
    </li>
  </ul>
</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>


      </div>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg" id="reportFilterModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="max-width:fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <img *ngIf="loading == true" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5> -->

        <div>

          <form *ngIf="filterInputs[0]" (ngSubmit)="setReportFilter()">

            <div class="formHead">
              <h4 class="formHeading">Choose Report Filters</h4>
            </div>


            <div  *ngFor="let input of filterInputs; let i = index;" class="form-row">


              <div class="form-group col-md-3">
                <label for="filterInput{{i}}Property">Property</label>
                <select  class="form-control" aria-label="Default select example" [(ngModel)]="filterInputs[i].data.Property"  id="filterInput{{i}}Property"  name="filterInput{{i}}Property" (change)="handleFilterInputTypeChange(i,$event.target.value)">
                  <option *ngFor="let property of filterProperties1[i];let j = index;">{{property}}</option>
                </select>
              </div>

              <div class="form-group col-md-2">
                <label for="filterInput{{i}}Condition">Condition</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="filterInputs[i].data.Condition" id="filterInput{{i}}Condition"  name="filterInput{{i}}Condition">
                  <option *ngFor="let condition of filterConditions[filterInputs[i].inputType];let index = k;">{{condition}}</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="filterInput{{i}}Value">Value</label>
                <input type="text" class="form-control" [(ngModel)]="filterInputs[i].data.Value" id="filterInput{{i}}Value" name="filterInput{{i}}Value" >
              </div>

              <div *ngIf="filterInputs[i].data.Property !== ''" class="form-group col-md-1">
                <label for="filterInput{{i}}AndOr">.</label>
                <input disabled type="text" class="form-control" [(ngModel)]="filterInputs[i].data.Type" id="filterInput{{i}}AndOr" name="filterInput{{i}}AndOr" >
              </div>

              <!-- <div *ngIf="filterInputs[i].data.Property !== ''" class="form-group col-md-3" style="display: inline-flex;margin-top: auto;"> -->
              <div  class="form-group col-md-3" style="display: inline-flex;margin-top: auto;">
                <button *ngIf="filterInputs[i].data.Property !== ''" type="button" class="btn btn-primary" style="margin-right:.2rem;" (click)="addFilterInput(i,'AND')">AND</button>
                <button disabled *ngIf="filterInputs[i].data.Property !== ''" type="button" class="btn btn-primary" style="margin-right:.2rem;" (click)="addFilterInput(i,'OR')">OR</button>
                <!-- <button *ngIf="i > 0" type="button" class="btn btn-danger" (click)="removeFilterInput(i)">Clear</button> -->
                <button *ngIf="i > 0 && i == filterInputs.length-1" type="button" class="btn btn-danger" (click)="removeFilterInput(i)">Clear</button>
              </div>

            </div>

            <button type="submit" class="btn btn-primary m-4">Filter</button>

            <button type="button" class="btn btn-danger" (click)="closeReportFiltering()">Cancel</button>
          </form>

        </div>


      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="selectEmployeeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <table *ngIf="paySummarySrch[0]" class="table">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Employee ID</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Select</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let employee of paySummarySrch; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{employee.employee}}</p></td>
            <td class="tableCels"><p class="cellText">{{employee.employeeId}}</p></td>
            <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="setPayslipEmployee(i,employee)"><fa-icon [icon]="faPlus" class=" tableIcon"></fa-icon></a></td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</div>
