<div class="pageContainerSidebar">

  <!-- <div class="dashboardUserMiniModal">
    <button type="button" class="btn createBtn">+ Create</button>
    <img class="createModalImage" src="../../assets/img/welcome_image.png" alt="...">
    <h6 class="createModalText">John Smith</h6>
    <a href=".."><fa-icon [icon]="faChevronDown" class="fa-lg createModalIcon"></fa-icon></a>
  </div> -->

  <div class="dashboardSearchBox">
    <div class="form-group form-focus">
      <!-- <label class="control-label"><p class="companySearchLabel">Search</p></label> -->
      <input type="text" class="form-control floating" (input)="searchCompany($event.target.value)" placeholder="search company"/>
    </div>
  </div>

  <div class="dashboardSubcontainer">
    <ul class="dashboardClientList">
      <li class="dashboardClientListItem" *ngFor="let customer of customerSrch; let i = index">
        <div class="dashboardClientCard">

          <table class="customerCardTable">
            <tr class="customerCardTableRowTop">
              <a href="javascript:" (click)="selectCompany(customer.id)">
              <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                <h6 class="customerCardHeading hoverLine">{{customer.companyName}}</h6>
              </a>
            </tr>
            <tr class="customerCardTableRow">
              <td class="tableLeftCol">
                <fa-icon [icon]="faUserFriends" class="fa-lg customerCardTableIcon"></fa-icon>
                <p class="customerCardText">Emp Count</p>
              </td>
              <td class="tableRightCol">
                <p class="customerCardText">{{customer.employeeCount}}</p>
                <!-- <p class="customerCardText">{{companyEmpCounts[i]}}</p> -->
              </td>
            </tr>
            <tr class="customerCardTableRow">
              <td class="tableLeftCol">
                <fa-icon [icon]="faRecycle" class="fa-lg customerCardTableIcon"></fa-icon>
                <p class="customerCardText">Cycle & Status</p>
              </td>
              <td class="tableRightCol">
                <p class="customerCardText">...</p>
              </td>
            </tr>
            <tr class="customerCardTableRow">
              <td class="tableLeftCol">
                <fa-icon [icon]="faMoneyCheckAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                <p class="customerCardText">Type</p>
              </td>
              <td class="tableRightCol">
                <p class="customerCardText">{{customer.type}}</p>
              </td>
            </tr>
            <tr class="editBtnRow">
              <button type="button" (click)="editCompany(customer)" class="btn customerEditBtn">Edit</button>
            </tr>
          </table>
        </div>
      </li>
    </ul>
  </div>

</div>

<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
