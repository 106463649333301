<div class="pageContainerSidebar">

  <div class="dataManagementMainContainer">

    <div class="subcontainerUpper row">
      <div class="row upperHeading">
      <h2 class="dataManagementHeading bold">Admin Management</h2>
      </div>

      <div class="form-row">
        <div class="form-group col-md-3">
          <input type="text" (input)="searchUsers($event.target.value)" id="inputSearch" class="form-control floating searchInput">
        </div>
        <div class="form-group col-md-2">
          <button type="button" class="btn createBtn">
            <p class="btnText ">Search</p></button>
        </div>

        <div class="col-md-3 form-group">

            <select class="form-control" aria-label="Default select example" (change)="selectSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
              <option value="username">Username</option>
              <option value="email">Email</option>
              <option value="type">Type</option>
              <option value="permissions">Permission</option>
            </select>
        </div>

        <div class="col-md-2 form-group">
          <div class=" btnCol">
            <button (click)="getUsers()" type="button" class="btn createBtn"><p class="btnText ">List</p></button>
          </div>
        </div>

        <div class="col-md-2">
          <div class=" btnCol">
            <button (click)="createUser()" type="button" class="btn createBtn"><p class="btnText ">New</p></button>
          </div>
        </div>

      </div>

    </div>

    <div class="subcontainerLower">

      <div *ngIf="setting == 'List' && showResetPassword != true" class="row lowerTable">
        <table class="table">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Username</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Email</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Type</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let usr of userSrch; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{usr.username}}</p></td>
            <td class="tableCels"><p class="cellText">{{usr.email}}</p></td>
            <td class="tableCels"><p class="cellText">{{usr.type}}</p></td>
            <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,usr)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
          </tr>
        </table>
      </div>

      <div class="editBox" *ngIf="setting == 'Create' || setting == 'Edit'">

        <form (ngSubmit)="onSubmit(setting)">

          <div class="formHead">
            <h4 class="formHeading">{{this.setting}} Admin</h4>
          </div>

          <div class="form-row">

            <div class="form-group col-md-6">
              <label for="inputUsername">Username</label>
              <input type="text" class="form-control" [(ngModel)]="selectedUser.username" id="inputUsername" name="username">
            </div>

            <div class="form-group col-md-6">
              <label for="inputType">Type</label>
              <select class="form-control" aria-label="Default select example" id="inputType" [(ngModel)]="selectedUser.type" name="type">
                <option>admin</option>
                <option>super admin</option>
                <option>company</option>
                <option>guest</option>
              </select>
            </div>

          </div>

          <div class="form-row">

            <div class="form-group col-md-6">
              <label for="inputEmail">Email</label>
              <input type="email" class="form-control" [(ngModel)]="selectedUser.email" id="inputEmail" name="email">
            </div>
            <div *ngIf="setting == 'Create'" class="form-group col-md-6">
              <label for="inputPassword">Password</label>
              <input type="password" class="form-control" [(ngModel)]="selectedUser.password" id="inputPassword" name="password">
            </div>

          </div>

          <div class="formHead">
            <h4 class="formHeading">Permissions</h4>
          </div>

          <div class="form-row">

            <ul class="permissionCheckGrid">
              <li class="permissionCheckGridItem" *ngFor="let perm of permissionOptions; let i = index">
                <div class="form-group col-md-3">
                  <div class="form-check">
                    <label for="permission">{{perm}}</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="permStates[i]" (change)="choosePermission(perm,i)" [name]="'permission'+i"  [id]="'permission'+i">
                  </div>
                </div>
              </li>
            </ul>

          </div>

          <!-- <button type="submit" class="btn btn-primary m-4">Create</button> -->

          <button type="submit" class="btn btn-primary m-4" *ngIf="setting == 'Create'">Create</button>
          <button type="submit" class="btn btn-primary m-4" *ngIf="setting == 'Edit'">Update</button>

          <button type="button" class="btn btn-danger" (click)="cancelForm()">Cancel</button>
        </form>

      </div>

      <div *ngIf="showResetPassword == true">

        <form (ngSubmit)="resetPassword()">

          <div class="formHead">
            <h4 class="formHeading">Reset Password</h4>
          </div>

          <div class="form-row">

            <div class="form-group col-md-4">
              <label for="inputEmail">Email</label>
              <input type="email" class="form-control" [(ngModel)]="email" id="inputEmail" name="email" autocomplete="email">
            </div>

            <div class="form-group col-md-4">
              <label for="current-password">Old Password</label>
              <input type="password" class="form-control" [(ngModel)]="oldPassword" id="current-password" name="current-password" autocomplete="current-password" aria-describedby="current-password" aria-label="current-password">
              <button id="toggle-password" type="button" aria-label="Show password as plain text. Warning: this will display your password on the screen.">Show</button>
            </div>

            <div class="form-group col-md-4">
              <label for="new-password">New Password</label>
              <input type="password" class="form-control" [(ngModel)]="new-password" id="new-password" name="new-password" autocomplete="new-password" aria-describedby="new-password" aria-label="new-password">
              <button id="toggle-passwordNew" type="button" aria-label="Show password as plain text. Warning: this will display your password on the screen.">Show</button>
            </div>

          </div>

          <button type="submit" class="btn btn-primary m-4">Reset</button>

          <button type="button" class="btn btn-danger" (click)="cancelPasswordReset()">Cancel</button>
        </form>

      </div>


    </div>

  </div>



</div>



<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="adminAction('Edit')">
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Update</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="adminAction('Delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="adminAction('resetPassword')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Reset Password</p>
      </a>
    </li>
  </ul>
</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>

      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
      </div>
    </div>
  </div>
</div>
