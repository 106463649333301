<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Banking Codes</h3>
      </div>

      <div class="row input">
        <div class=" btnCol">
          <button type="button" (click)="newCode()" class="btn createBtn"><fa-icon [icon]="faPlus" class=" btnIcon"></fa-icon><p class="btnText ">Add</p></button>
        </div>
        <div class=" btnCol">
          <button type="button" (click)="showCode()" class="btn showBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Show</p></button>
        </div>
      </div>

    </div>

    <div class="subcontainerLower">

      <div class="row lowerTable">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="bank-tab" data-toggle="tab" href="#bank" type="button" role="tab" aria-controls="bank" aria-selected="true">Bank</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="ach-tab" data-toggle="tab" href="#ach" type="button" role="tab" aria-controls="ach" aria-selected="false">ACH</button>
          </li> -->
        </ul>

        <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="bank" role="tabpanel" aria-labelledby="bank-tab">
            <div class="codeList" *ngIf="setting == 'list'">


              <div class="row input">

                <div class="col-md-4 ">
                  <div class=" form-group " >
                    <input type="text" [(ngModel)]="bankingCodeSearchQuery" (input)="searchBankingCode($event.target.value)" id="inputSearch" class="form-control floating">

                  </div>

                </div>

                <div class="col-md-4 ">
                  <div class="formInput form-group">
                      <select class="form-control" aria-label="Default select example" (change)="selectBankingSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                        <option value="code">Code</option>
                        <option value="name" >Name</option>
                        <option value="branch" >Branch</option>
                        <option value="type" >Type</option>
                      </select>
                  </div>
                </div>

                <div class="col-md-4 ">
                <div class="formInput form-group">
                  <button type="button" style="margin-top: 0;" class="btn createBtn" (click)="clearBankingSearch()">
                    <p class="btnText ">Clear</p></button>
                </div>
                </div>



              </div>

              <div class="row input">
                <div class="col-md-4 ">
                  <select class="form-control" aria-label="Default select example" (change)="selectSortDirection($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                    <option value="Ascending">Ascending</option>
                    <option value="Descending">Descending</option>
                  </select>
                </div>

                <div class="col-md-4 ">
                  <button type="button" class="btn createBtn" (click)="sortBankCodes()">
                    <p class="btnText ">Sort</p>
                  </button>
                </div>
              </div>


              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Branch</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Type</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Action</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let code of bankingCodeSrch" class="tableRow">
                  <td class="tableCels"><p class="cellText">{{code.code}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.name}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.branch}}</p></td>
                  <td class="tableCels"><p class="cellText">{{code.type}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,code)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>

            </div>

            <div class="codeAdd" *ngIf="setting == 'Add' || setting == 'Edit'">

              <div class="upperUpper">
                <h3 class="lowerHeader">Upload Banking Codes: {{addBankType}}</h3>
              </div>


              <div class="row input" *ngIf="setting == 'Add'">
                <div class=" btnCol">
                  <button type="button" (click)="toggleAddBankType('batch')" class="btn createBtn"><p class="btnText ">Batch</p></button>
                </div>
                <div class=" btnCol">
                  <button type="button" (click)="toggleAddBankType('single')" class="btn showBtn"><p class="btnText ">Single</p></button>
                </div>
              </div>


              <form (ngSubmit)="addBankCodeSingle()" *ngIf="addBankType == 'single'">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputBankCode">Code</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.code" id="inputBankCode" name="bankCode">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputName">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.name" id="inputName" name="name">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputBranch">Branch</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.branch" id="inputBranch" name="branch">
                  </div>

                </div>


                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputBankType">Type</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.type" id="inputBankType" name="bankType">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputGlAccount">Gl Account</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.glAccount" id="inputGlAccount" name="glAccount">
                  </div>

                </div>

                <div class="upperUpper">
                  <h5 class="lowerHeader">Address</h5>
                </div>


                <div class="form-row">

                  <div class="form-group col-md-4">
                    <label for="inputStreet">Street</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.address.street" id="inputStreet" name="street">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputDistrict">District</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.address.district" id="inputDistrict" name="district">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputCity">City</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.address.city" id="inputCity" name="city">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputParish">Parish</label>
                    <select class="form-control" aria-label="Default select example"  [(ngModel)]="bankCode.address.parish" id="inputParish" name="parish">
                      <option value="Hanover">Hanover</option>
                      <option value="Saint Elizabeth">Saint Elizabeth</option>
                      <option value="Saint James">Saint James</option>
                      <option value="Trelawny">Trelawny</option>
                      <option value="Westmoreland">Westmoreland</option>
                      <option value="Clarendon">Clarendon</option>
                      <option value="Manchester">Manchester</option>
                      <option value="Saint Ann">Saint Ann</option>
                      <option value="Saint Catherine">Saint Catherine</option>
                      <option value="Saint Mary">Saint Mary</option>
                      <option value="Kingston">Kingston</option>
                      <option value="Portland">Portland</option>
                      <option value="Saint Andrew">Saint Andrew</option>
                      <option value="Saint Thomas">Saint Thomas</option>
                    </select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputCountry">Country</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.address.country" id="inputCountry" name="country">
                  </div>

                </div>


                <div class="upperUpper">
                  <h5 class="lowerHeader">ACH</h5>
                </div>


                <div class="form-row">

                  <!-- <div class="form-group col-md-4">
                    <label for="inputId">Id</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.ach.achId" id="inputId" name="id">
                  </div> -->

                  <div class="form-group col-md-4">
                    <label for="inputAba">ABA</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.ach.aba" id="inpuAba" name="aba">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputRouting">Routing</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.ach.routing" id="inputRouting" name="routing">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputTransit">Transit</label>
                    <input type="text" class="form-control" [(ngModel)]="bankCode.ach.transit" id="inputTransit" name="transit">
                  </div>

                </div>

                <button type="submit" class="btn btn-primary">Add</button>
              </form>

              <div class="dragDropBox" *ngIf="addBankType == 'batch'">

                <div class="form-group col-md-12 fileDropZone">
                  <ngx-file-drop id="employeeUploadDragDrop"
                  contentClassName="dragDropText" dropZoneClassName="dropZone" dropZoneLabel="Drop files here"
                  (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
                  style="margin:0 auto;width:100%;height: 10rem;border-radius: 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color:#ffffff;">
                      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <p class="dragDroptext">Drag & Drop OR
                          <input type="file" (change)="selectFile($event.target.files)" id="select">
                        </p>
                      </ng-template>
                  </ngx-file-drop>

                </div>
              </div>

              <button *ngIf="sheetParsed == true" type="btn" class="btn btn-primary" (click)="uploadBankingCodes()">Upload</button>

            </div>

          </div>


        </div>

      </div>

    </div>
  </div>

</div>


<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)=" codeAction('details')">
        <fa-icon [icon]="faClipboardList" class=" actionsIcon" ></fa-icon><p class="cellText">Details</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)=" codeAction('edit')">
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Edit</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="codeAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>

          <div class="dashboardClientCard" *ngIf="setting == 'Details'">

            <h5 >Details</h5>

            <table class="customerCardTable">
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Name</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.name}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Code</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.code}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Branch</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.branch}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Gl Account</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.glAccount}}</p>
                </td>
              </tr>

              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Address: Street</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.address.street}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Address: District</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.address.district}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Address: City</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.address.city}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Address: Parish</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.address.parish}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">Address: Country</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.address.country}}</p>
                </td>
              </tr>

              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">ACH: ABA</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.ach.aba}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">ACH: Routing</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.ach.routing}}</p>
                </td>
              </tr>
              <tr class="customerCardTableRow">
                <td class="tableLeftCol">
                  <p class="customerCardText">ACH: Transit</p>
                </td>
                <td class="tableRightCol">
                  <p class="customerCardText">{{bankCode.ach.transit}}</p>
                </td>
              </tr>


              <tr class="editBtnRow">
                <button type="button" (click)="hideDetails()" class="btn showBtn"><p class="btnText">Close</p></button>
              </tr>
            </table>
          </div>

      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
