<div class="pageContainerSidebar">

  <div class="dataManagementMainContainer">

    <div class="subcontainerUpper row">
      <div class="row upperHeading">
      <h2 class="dataManagementHeading bold">Data Management</h2>
      </div>

      <div class="row upperHeading">
      <p class="dataManagementHeading bold">Total Employee Count: {{totalEmployeeCount}}</p>
      </div>

      <div class="row input" >
        <div class="col-md-4">
          <input type="text" [(ngModel)]="employeeSearchQuery" (input)="searchEmployees($event.target.value)" id="inputSearch" class="form-control floating">
        </div>
        <div class="col-md-4">
          <button type="button" class="btn createBtn" (click)="searchEmployees(employeeSearchQuery)">
            <p class="btnText ">Search</p></button>
        </div>
        <div class="col-md-4">
          <button type="button" class="btn showBtn" (click)="clearSearch()">
            <p class="btnText ">Clear</p></button>
        </div>




      </div>

      <div class="row input flex" >

        <div class="col-md-4">

          <select class="form-control" aria-label="Default select example" (change)="selectSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
            <option value="firstName" selected="selected">First Name</option>
            <option value="lastName">Last Name</option>
            <option value="employeeId">Employee Id</option>
            <option value="trn">TRN</option>
          </select>

        </div>

        <div class="col-md-4">

          <select class="form-control" aria-label="Default select example" (change)="selectSortDirection($event.target.value)" id="inputTypeSelect"  name="typeSelect">
            <option value="Ascending">Ascending</option>
            <option value="Descending">Descending</option>
          </select>

        </div>

        <div class="col-md-4">
          <!-- <button type="button" class="btn createBtn" (click)="preSortEmployees()">
            <p class="btnText ">Sort</p></button> -->
        </div>

      </div>

      <div class="row input flex">

        <div class="col-md-4">
          <!-- <div class=" btnCol"> -->
            <button (click)="listEmployees()" type="button" class="btn createBtn"><p class="btnText ">List Employees</p></button>
          <!-- </div> -->
        </div>

        <div class="col-md-4">

          <div id="dropdown1" class="dropdown" >
            <button [disabled]="!this.employeeSrch[0]" style="margin-left:1rem;" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <p class="btnText">Export</p>
            </button>
            <div class="dropdown-menu dmenu" aria-labelledby="dropdownMenuButton">
              <a (click)="preExportEmployees('PDF')" class="dropdown-item" href="javascript:">PDF</a>
              <a (click)="preExportEmployees('XLS')" class="dropdown-item" href="javascript:">XLS</a>
              <a (click)="preExportEmployees('CSV')" class="dropdown-item" href="javascript:">CSV</a>
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="subcontainerLower">

      <!-- <div claa="pageNavDiv"> -->
        <nav aria-label="Page navigation example" class="navNav">
          <ul class="pagination pageNavDiv">
            <li class="page-item">
              <a class="page-link" href="javascript:" aria-label="Previous" (click)="stepPage('previous')">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li *ngFor="let page of pagesArray; let n = index" class="page-item"><a class="page-link" href="javascript:" (click)="getEmployeesByPage(false,n+1)"><span class="pageNumberHighlight" *ngIf="currentPage == n+1">{{n+1}}</span><span *ngIf="currentPage != n+1">{{n+1}}</span></a></li>
            <li class="page-item">
              <a class="page-link" href="javascript:" aria-label="Next" (click)="stepPage('next')">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      <!-- </div> -->


      <div *ngIf="update != true" class="row lowerTable">

        <!-- <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="javascript:" aria-label="Previous" (click)="stepPage('previous')">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li *ngFor="let page of pagesArray; let n = index" class="page-item"><a class="page-link" href="#">{{n+1}}</a></li>
            <li class="page-item">
              <a class="page-link" href="javascript:" aria-label="Next" (click)="stepPage('next')">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav> -->

        <table class="table">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Employee</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">ID</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">TRN</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">NIS</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Email</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Department</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Occupation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Pension</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let emp of employeeSrch; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{emp.firstName}} - {{emp.lastName}}</p></td>
            <td class="tableCels"><p class="cellText">{{emp.employeeId}}</p></td>
            <td class="tableCels"><p class="cellText">{{emp.trn}}</p></td>
            <td class="tableCels"><p class="cellText">{{emp.nis}}</p></td>
            <td class="tableCels"><p class="cellText">{{emp.email}}</p></td>
            <td class="tableCels"><p class="cellText">{{emp.employment.department.abbreviation}}</p></td>
            <td class="tableCels"><p class="cellText">{{emp.employment.occupation.abbreviation}}</p></td>
            <td class="tableCels"><p class="cellText">Pension Amt</p></td>
            <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,emp)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
          </tr>
        </table>
      </div>


      <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="update == true">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="personal-tab" data-toggle="tab" href="#personalData" type="button" role="tab" aria-controls="personal" aria-selected="true">Personal Data</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="employment-tab" data-toggle="tab" href="#employment" type="button" role="tab" aria-controls="employment" aria-selected="false">Employment</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="banking-tab" data-toggle="tab" href="#banking" type="button" role="tab" aria-controls="banking" aria-selected="false">Banking</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="loan-tab" data-toggle="tab" href="#loan" type="button" role="tab" aria-controls="loan" aria-selected="false">Loan</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="ytd-tab" data-toggle="tab" href="#ytd" type="button" role="tab" aria-controls="tyd" aria-selected="false">YTD Data</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="deductions-tab" data-toggle="tab" href="#deductions" type="button" role="tab" aria-controls="deduction" aria-selected="false">Deductions</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pension-tab" data-toggle="tab" href="#pension" type="button" role="tab" aria-controls="pension" aria-selected="false">Pension</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="previousEmployment-tab" data-toggle="tab" href="#previousEmployment" type="button" role="tab" aria-controls="previousEmployment" aria-selected="false">Previous Employment</button>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent" *ngIf="update == true">

        <div class="tab-pane fade show active" id="personalData" role="tabpanel" aria-labelledby="personal-tab">

          <form (ngSubmit)="onSubmit('personal')">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputCompanyCode">First Name</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.firstName" id="inputFirstName" name="firstName">
              </div>
              <div class="form-group col-md-4">
                <label for="inputMiddleName">Middle Name</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.middleName" id="inputMiddleName" name="middleName">
              </div>
              <div class="form-group col-md-4">
                <label for="inputLastName">Last Name</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.lastName" id="inputLastName" name="lastName">
              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputGender">Gender</label>
                <select class="form-control" aria-label="Default select example" id="inputGender" [(ngModel)]="selectedEmployee.gender" name="gender">
                  <option  selected >{{selectedEmployee.gender}}</option>
                  <option  selected >male</option>
                  <option>female</option>
                </select>
              </div>

              <div class="form-group col-md-4">
                <label for="inputDob">Date of Birth</label>
                <input type="date" class="form-control" [(ngModel)]="selectedEmployee.dob" id="inputDob" name="dob">
              </div>

              <div class="form-group col-md-4">
                <label for="inputEmail">Email</label>
                <input type="email" class="form-control" [(ngModel)]="selectedEmployee.email" id="inputEmail" name="email">
              </div>
            </div>

            <div class="form-row">
              <!-- <div class="form-group col-md-6">
                <label for="inputEmployeeId">Employment ID</label>
                <input type="text" class="form-control" [(ngModel)]="employee.id" id="inputEmploymentId" name="employmentId" placeholder="xxx-xxx">
              </div> -->

              <div class="form-group col-md-6">
                <label for="inputSecondaryId">Employee ID</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.employeeId" id="inputSecondaryId" name="empid" >
              </div>

              <div class="form-group col-md-6">
                <label for="inputGrade">Grade</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.grade" id="inputGrade" name="grade">
              </div>

              <div class="form-group col-md-6">
                <label for="inputPhone">Phone</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.phone" id="inputPhone" name="phone">
              </div>
            </div>


            <div class="formHead">
              <h4 class="formHeading">Tax Information</h4>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputBank">NIS Ref*</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.nis" name="nis" id="inputNis">
              </div>
              <div class="form-group col-md-6">
                <label for="inputAchCompanyDetails">TRN</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.trn" name="achName" id="inputTrn">
              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <div class="form-check">
                  <label for="payableTrn">TRN Payable</label>
                  <input class="form-control" type="checkbox" [(ngModel)]="selectedEmployee.trnPayable" name="selectedEmployee.trnPayable" id="payableTrn">
                </div>
              </div>
              <div class="form-group col-md-3">
                <div class="form-check">
                  <label for="payableNht">NHT Payable </label>
                  <input class="form-control" type="checkbox" [(ngModel)]="selectedEmployee.nhtPayable" name="selectedEmployee.nhtPayable" id="payableNht">
                </div>
              </div>
              <div class="form-group col-md-3">
                <div class="form-check">
                  <label for="payableNis">NIS Payable</label>
                  <input class="form-control" type="checkbox" [(ngModel)]="selectedEmployee.nisPayable" name="selectedEmployee.nisPayable" id="payableNis">
                </div>
              </div>
              <!-- <div class="form-group col-md-3">
                <label for="inputUsdRate">USD Rate?</label>
                <input class="form-control" type="checkbox"  [(ngModel)]="selectedEmployee.usdRate" name="selectedEmployee.usdRate"  id="usdRate">
              </div> -->
            </div>




            <button type="submit" class="btn btn-primary">Update</button>
            <button type="button" class="btn btn-danger" (click)="listEmployees()" style="margin-left:0.5rem;">Cancel</button>
          </form>

        </div>

        <div class="tab-pane fade" id="employment" role="tabpanel" aria-labelledby="employment-tab">

          <form (ngSubmit)="onSubmit('employment')">


            <div class="form-row">

              <div class="form-group col-md-4">
                <label for="inputPayCycle">Pay Cycle Type</label>
                <select class="form-control" aria-label="Default select example" id="inputPayCycle" [(ngModel)]="selectedEmployee.employment.payCycle" name="payCycle">
                  <option value="weekly" selected="selected">Weekly</option>
                  <option value="fortnightly">Fortnighty</option>
                  <option value="bimonthly">Bi-Monthly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

              <div class="form-group col-md-4">
                <label for="inputStatus">Status</label>

                <select class="form-control" aria-label="Default select example" id="inputStatus" [(ngModel)]="selectedEmployee.employment.status" name="status" (change)="handleStatusChange($event.target)">
                  <option value="active">active</option>
                  <option value="suspended">suspended</option>
                  <option value="terminated">terminated</option>
                  <option value="onLeave">On Leave</option>
                </select>
              </div>


              <div class="form-group col-md-4">
                <label for="inputStartDate">Start Date</label>
                <input type="date" class="form-control" [(ngModel)]="selectedEmployee.employment.startDate" id="inputStartDate" name="startDate">
              </div>

              <div class="form-group col-md-4">
                <label for="inputEmploymentTerminationDate">Termination Date</label>
                <input [disabled]="hideTerminationDate" type="date" class="form-control" [(ngModel)]="selectedEmployee.employment.terminationDate" id="inputEmploymentTerminationDate" name="terminationDate">
              </div>

              <div class="form-group col-md-4">
                <label for="inputDuration">Duration (Month)</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.employment.duration" id="inputDuration" name="duration">
              </div>

              <div class="form-group col-md-4">
                <label for="inputRate">Rate</label>
                <input type="number" class="form-control" [(ngModel)]="selectedEmployee.employment.rate" id="inputRate" name="rate" >
              </div>

              <div class="form-group col-md-4">
                <label for="inputBasePay">Base Salary</label>
                <input type="number" class="form-control" [(ngModel)]="selectedEmployee.employment.basePay" id="inputBasePay" name="basePay" >
              </div>
            </div>


            <div class="formHead">
              <h4 class="formHeading">Department & Occupation</h4>
            </div>

            <div class="form-row">

              <div class="form-group col-md-6 centerMargin">
                <label for="inputDepartmentCode">Department Code</label>
                <select class="form-control " aria-label="Default select example" [(ngModel)]="selectedEmployee.employment.department.code" id="inputDepartmentCode" name="departmentCode">
                  <option *ngFor="let code of companyCodesDepartment">{{code.code}}</option>
                </select>
              </div>

              <div class="form-group col-md-6 centerMargin">
                <label for="inputOccupationCode">Occupation Code</label>
                <select class="form-control " aria-label="Default select example2" [(ngModel)]="selectedEmployee.employment.occupation.code" id="inputOccupationCode" name="occupationCode">
                  <option *ngFor="let code of companyCodesOccupation">{{code.code}}</option>
                </select>
              </div>

            </div>


            <div class="formHead" style="display: inline-flex">
              <h4 class="formHeading">Health</h4>

              <button style="margin: 0 .5rem;" (click)="resetHealth()" *ngIf="addDeduction != true" type="button" class="btn btn-warning">Reset</button>
            </div>

            <div class="form-row">

              <div class="form-group col-md-6 centerMargin">
                <label for="inputHealthCode">Health Code</label>
                <select class="form-control " aria-label="Default select example" [(ngModel)]="selectedEmployee.health.code" id="inputHealthCode" name="healthCode">
                  <option *ngFor="let code of companyCodesHealth">{{code.code}}</option>
                </select>
              </div>

              <div class="form-group col-md-6 centerMargin">
                <label for="inputHealthType">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.health.type" id="inputHealthType" name="healthType" >
                  <option >Single</option>
                  <option >Family</option>
                  <option >Multiple</option>
                </select>
              </div>

            </div>


            <button type="submit" class="btn btn-primary">Update</button>
            <button type="button" class="btn btn-danger" (click)="listEmployees()" style="margin-left:0.5rem;">Cancel</button>
          </form>

        </div>

        <div class="tab-pane fade" id="banking" role="tabpanel" aria-labelledby="banking-tab">

          <form (ngSubmit)="onSubmit('banking')">


            <div class="formHead">
              <h4 class="formHeading">Banking</h4>
            </div>

            <div class="form-row" >

              <div class="form-group col-md-3">
                <label for="inputBankBank">Bank</label>
                <select class="form-control" aria-label="Default select example" id="inputBankBank" name="bankBank" [(ngModel)]="selectedEmployee.banking.bank" >
                  <option *ngFor="let bankCode of bankingCodes; let x = index" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputBankType">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.banking.type" id="inputBankType" name="bankType">
                  <option value="Savings" >savings</option>
                  <option value="Chequing">chequing</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputMethod">Method</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.banking.method" id="inputMethod" name="bankMethod">
                  <option value="cash">Cash</option>
                  <option value="directDeposit">Direct Deposit</option>
                  <option value="cheque">Cheque</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputAccountNumber">Account Number</label>
                <input type="number" class="form-control" [(ngModel)]="selectedEmployee.banking.account" id="inputAccountNumber" name="bankAccountNumber">
              </div>

              <div class="form-group col-md-3">
                <label for="inputCurrency">Currency</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.banking.currency" id="inputCurrency" name="bankCurrency">
                  <option value="USD">USD</option>
                  <option value="JMD">JMD</option>
                </select>
              </div>

            </div>


            <button type="submit" class="btn btn-primary">Update</button>
            <button type="button" class="btn btn-danger" (click)="listEmployees()" style="margin-left:0.5rem;">Cancel</button>
          </form>

        </div>

        <div class="tab-pane fade" id="loan" role="tabpanel" aria-labelledby="loan-tab">

          <div class="row" style='padding: 1rem;'>
            <button (click)="toggleSpecialAdd('loan')" *ngIf="addLoan != true" type="button" class="btn btn-primary">Add Loan</button>
            <button (click)="toggleSpecialAdd('loan')" *ngIf="addLoan == true" type="button" class="btn btn-danger">Cancel</button>
          </div>

          <table *ngIf="addLoan != true" class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Initial Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Policy</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Installment</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">No of Payments</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Balance</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
            </tr>
            <tr *ngFor="let item of selectedEmployee.loans; let i = index" class="tableRow">
              <td class="tableCels"><p class="cellText">{{item.code}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.initialAmount}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.policy}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.repaymentAmount}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.numPayments}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.outstanding}}</p></td>
              <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBoxSpecial(i,'loan',$event,item)" ><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
            </tr>
          </table>

          <form *ngIf="addLoan == true" (ngSubmit)="onSubmit('loan')">

            <div class="formHead">
              <h4 class="formHeading">Loan</h4>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanCode">Code</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeLoan.code" id="inputEmployeeLoanCode" name="employeeLoanCode">
                  <option *ngFor="let code of companyCodesLoan">{{code.code}}</option>
                </select>
              </div>

              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanInitialAmount">Initial Amount</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="employeeLoan.initialAmount" id="inputEmployeeLoanInitialAmount" name="employeeLoanInitialAmount" (input)="handleLoanAmount($event)">
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanPolicy">Policy</label>
                <input type="text" class="form-control" [(ngModel)]="employeeLoan.policy" id="inputEmployeeLoanPolicy" name="employeeLoanPolicy">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanStartDate">Start Date</label>
                <input type="date" class="form-control" [(ngModel)]="employeeLoan.startDate" id="inputLoanStartDate" name="startDate">
              </div>


              <!-- <div class="form-group col-md-4">
                <label for="inputLoanPreEndDate">Continuous ?</label>
                <select class="form-control" aria-label="Default select example" id="inputLoanPreEndDate" (change)="handlePreEndDate('loan',$event)" name="inputLoanPreEndDate" [(ngModel)]="employeeLoan.continuous">
                  <option>continuous</option>
                  <option>date</option>
                </select>
              </div> -->
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanContinuous">Continuous ?</label>
                <input class="form-control" type="checkbox" [(ngModel)]="employeeLoan.continuous" name="employeeLoan.continuous" id="inputEmployeeLoanContinuous" (change)="handlePreEndDate('loan',$event)">
              </div>

              <div class="form-group col-md-4" *ngIf="loanContinuous != true">
                <label for="inputEmployeeLoanEndDate">End Date</label>
                <input type="date" class="form-control" [(ngModel)]="employeeLoan.endDate" id="inputLoanEndDate" name="endDate">
              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanRepaymentAmount">Installment</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="employeeLoan.repaymentAmount" id="inputEmployeeLoanRepaymentAmount" name="employeeLoanRepaymentAmount" (input)="handleLoanPaymentCalc($event)">
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanNumPayments">Number of Payments</label>
                <input type="number" class="form-control" [(ngModel)]="employeeLoan.numPayments" id="inputEmployeeLoanNumPayments" name="employeeLoanNumPayments" (input)="handleLoanPaymentCalc($event)">
              </div>
              <div class="form-group col-md-4" >
                <label for="inputEmployeeLoanOutstanding">Balance</label>
                <input type="number" class="form-control" [disabled]="specialEdit != true" [(ngModel)]="employeeLoan.outstanding"  id="inputEmployeeLoanOutstanding" name="employeeLoanOutstanding" placeholder="employeeLoan.outstanding">
              </div>
            </div>

            <div class="form-row">
              <!-- <div class="form-group col-md-4">
                <label for="inputEmployeeLoanBeforeTax">Before Tax</label>
                <input class="form-control" type="checkbox" [(ngModel)]="employeeLoan.beforeTax" name="employeeLoan.beforeTax" id="inputEmployeeLoanBeforeTax">
              </div> -->
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanPause">Suspend</label>
                <input class="form-control" type="checkbox" [(ngModel)]="employeeLoan.paused" name="employeeLoan.paused" id="inputEmployeeLoanPause" (change)="pauseEnableToggle()">
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanEnable">Active</label>
                <input class="form-control" type="checkbox" [disabled]="loanEnabledInput" [(ngModel)]="employeeLoan.enabled" name="employeeLoan.enabled" id="inputEmployeeLoanEnable">
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmployeeLoanGlAccount">Account Gl</label>
                <select class="form-control" aria-label="Default select example"  id="inputEmployeeLoanGlAccount" [(ngModel)]="employeeLoan.glAccount" name="loanGlAccount" >
                  <option *ngFor="let coa of chartsOfAccounts; let i = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                </select>
              </div>
            </div>

            <div class="formHead">
              <h6 class="formHeading">Banking</h6>
            </div>

            <div class="form-row" >
              <div class="form-group col-md-3">
                <label for="inputBankBank2">Bank</label>
                <select class="form-control" aria-label="Default select example" id="inputBankBank2" name="bankBank" [(ngModel)]="employeeLoan.banking.bank" >
                  <option *ngFor="let bankCode of bankingCodes; let x = index" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputBankType2">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeLoan.banking.type" id="inputBankType2" name="bankType">
                  <option selected >savings</option>
                  <option selected >chequing</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputBankMethod2">Method</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeLoan.banking.method" id="inputBankMethod2" name="inputBankMethod2">
                  <option value="cash">Cash</option>
                  <option value="directDeposit">Direct Deposit</option>
                  <option value="cheque">Cheque</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputAccountNumber2">Account Number</label>
                <input type="number" class="form-control" [(ngModel)]="employeeLoan.banking.account" id="inputAccountNumber2" name="bankAccountNumber">
              </div>

            </div>

            <button type="submit" class="btn btn-primary">Update</button>
            <button type="button" class="btn btn-danger" (click)="listEmployees()" style="margin-left:0.5rem;">Cancel</button>

          </form>

        </div>

        <div class="tab-pane fade" id="ytd" role="tabpanel" aria-labelledby="ytd-tab">

          <form (ngSubmit)="getYtd()" *ngIf="ytdRetrieved != true && loading != true && mtdRetrieved != true">

            <div class="formHead">
              <h4 class="formHeading">Year-to-Date</h4>
            </div>

            <div class="form-row">

              <select class="form-control col-6 mx-auto" aria-label="Default select example" [(ngModel)]="ytdArgs.type" id="inputYtdType" name="ytdType">
                <option value="ytd">YTD</option>
                <option value="mtd">MTD</option>
              </select>

              <!-- <select class="form-control col-4 mx-auto" aria-label="Default select example" [(ngModel)]="ytdArgs.period" id="inputYtdPeriod" name="ytdPeriod">
                <option value="weekly">Weekly</option>
                <option value="fortnightly">Fortnighty</option>
                <option value="bimonthly">Bi-Monthly</option>
                <option value="monthly">Monthly</option>
              </select> -->

              <button type="submit" class="btn btn-primary">Submit</button>
            </div>


            <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
          </form>

          <div *ngIf="ytdRetrieved == true && mtdRetrieved != true" class="ytdCloBox">

            <div class="formHead">
              <h4 class="formHeading">YTD</h4>
            </div>

            <button type="button" class="btn createBtn btn-warning" (click)="resetYtd()">
              <!-- <fa-icon [icon]="faList" class=" btnIcon"></fa-icon> -->
              <p class="btnText ">Reset</p>
            </button>

            <ul class="ytdColList">
              <li class="ytdColListItem" *ngFor="let item of selectedEmployeeYtd; let i = index">
                <p class="ytdColListText"><span class="ytdColListKey">{{item.key}} : </span>
                  <span class="ytdColListValue">$ {{item.value}}</span>
              </li>
            </ul>

          </div>

          <div *ngIf="mtdRetrieved == true" class="ytdCloBox">

            <div class="formHead">
              <h4 class="formHeading">MTD</h4>
            </div>

            <button type="button" class="btn createBtn btn-warning" (click)="resetYtd()">
              <!-- <fa-icon [icon]="faList" class=" btnIcon"></fa-icon> -->
              <p class="btnText ">Reset</p>
            </button>

            <ul class="ytdColList">
              <li class="ytdColListItem" *ngFor="let item of selectedEmployeeMtd; let i = index">
                <p class="ytdColListText"><span class="ytdColListKey">{{item.key}} : </span> <span class="ytdColListValue">$ {{item.value}}</span></p>
              </li>
            </ul>

          </div>


        </div>

        <div class="tab-pane fade" id="deductions" role="tabpanel" aria-labelledby="deductions-tab">

          <div class="row" style='padding: 1rem;'>
            <button (click)="toggleSpecialAdd('deduction')" *ngIf="addDeduction != true" type="button" class="btn btn-primary">Add Deduction</button>
            <button (click)="toggleSpecialAdd('deduction')" *ngIf="addDeduction == true" type="button" class="btn btn-danger">Cancel</button>
          </div>


          <table *ngIf="addDeduction != true" class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Deduction Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Policy</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Employer Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
              <th class="tableHeadings">
                <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
              </th>
            </tr>

            <tr *ngFor="let item of selectedEmployee.deductions; let c = index" class="tableRow">
              <td class="tableCels"><p class="cellText">{{item.code}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.deductionAmount}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.policy}}</p></td>
              <td class="tableCels"><p class="cellText">{{item.employerAmount}}</p></td>
              <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBoxSpecial(c,'deduction',$event,item)" ><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
            </tr>
          </table>

          <form *ngIf="addDeduction == true" (ngSubmit)="onSubmit('deduction')">

            <div class="formHead">
              <h4 class="formHeading">Deduction</h4>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionCode">Code</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeDeduction.code" id="inputEmployeeDeductionCode" name="EmployeeDeductionCode">
                  <option *ngFor="let code of companyCodesDeduction">{{code.code}}</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionDeductionAmount">Deduction Amount</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="employeeDeduction.deductionAmount" id="inputEmployeeDeductionDeductionAmount" name="EmployeeDeductionDeductionAmount">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionEmployerAmount">Employer Amount</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="employeeDeduction.employerAmount" id="inputEmployeeDeductionEmployerAmount" name="EmployeeDeductionEmployerAmount">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionPolicy">Policy</label>
                <input type="text" class="form-control" [(ngModel)]="employeeDeduction.policy" id="inputEmployeeDeductionPolicy" name="EmployeeDeductionPolicy" >
              </div>
              <!-- <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionEmployerAmount">Employer Amount</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="employeeDeduction.employerAmount" id="inputEmployeeDeductionEmployerAmount" name="EmployeeDeductionEmployerAmount" placeholder="xxx-xxxx-xxxx">
              </div> -->
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputEmployeeDeductionStartDate">Start Date</label>
                <input type="date" class="form-control" [(ngModel)]="employeeDeduction.startDate" id="inputDeductionStartDate" name="startDate">
              </div>

              <!-- <div class="form-group col-md-4" >
                <label for="inputDeductionPreEndDate">Continuous ?</label>
                <select class="form-control" aria-label="Default select example" id="inputDeductionPreEndDate" (change)="handlePreEndDate('deduction',$event)" name="deductionPreEndDate" [(ngModel)]="employeeDeduction.continuous">
                  <option selected>continuous</option>
                  <option>date</option>
                </select>
              </div> -->
              <div class="form-group col-md-4">
                <label for="inputEmployeeDeductionContinuous">Continuous ?</label>
                <input class="form-control" type="checkbox" [(ngModel)]="employeeDeduction.continuous" name="employeeDeduction.continuous" id="inputEmployeeDeductionContinuous" (change)="handlePreEndDate('deduction',$event)">
              </div>

              <div class="form-group col-md-4" *ngIf="deductionContinuous != true">
                <label for="inputEmployeeDeductionEndDate">End Date</label>
                <input type="date" class="form-control" [(ngModel)]="employeeDeduction.endDate" id="inputDeductionEndDate" name="endDate">
              </div>
            </div>

            <div class="form-row">
              <!-- <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionBeforeTax">Before Tax</label>
                <input class="form-control" type="checkbox" [(ngModel)]="employeeDeduction.beforeTax" name="employeeDeduction.beforeTax" id="inputEmployeeDeductionBeforeTax">
              </div> -->
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionPause">Pause</label>
                <input class="form-control" type="checkbox" [(ngModel)]="employeeDeduction.paused" name="employeeDeduction.paused" id="inputEmployeeDeductionPause" (change)="pauseEnableToggle()">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionEnable">Enable</label>
                <input class="form-control" type="checkbox" [disabled]="deductionEnabledInput" [(ngModel)]="employeeDeduction.enabled" name="employeeDeduction.enabled" id="inputEmployeeDeductionEnable">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionGlAccount">G/L Account</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeDeduction.glAccount" id="inputEmployeeDeductionGlAccount"  name="employeeDeductionGlAccount">
                  <option *ngFor="let coa of chartsOfAccounts; let f = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                </select>
              </div>
            </div>

            <div class="formHead">
              <h6 class="formHeading">Bank</h6>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputBankBank3">Bank</label>
                <select class="form-control" aria-label="Default select example" id="inputBankBank3" name="bankBank" [(ngModel)]="employeeDeduction.banking.bank" >
                  <option *ngFor="let bankCode of bankingCodes; let x = index" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
                </select>
              </div>

            <div class="form-group col-md-4">
              <label for="inputBankType3">Type</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeDeduction.banking.type" id="inputBankType3" name="bankType">
                <option selected >savings</option>
                <option >chequing</option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <label for="inputBankMethod3">Method</label>
              <select class="form-control" aria-label="Default select example" [(ngModel)]="employeeDeduction.banking.method" id="inputBankMethod3" name="inputBankMethod3">
                <option value="cash">Cash</option>
                <option value="directDeposit">Direct Deposit</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label for="inputAccountNumber3">Account Number</label>
              <input type="number" class="form-control" [(ngModel)]="employeeDeduction.banking.account" id="inputAccountNumber3" name="bankAccountNumber" >
            </div>

            </div>


            <button type="submit" class="btn btn-primary">Update</button>
          </form>

        </div>

        <div class="tab-pane fade" id="pension" role="tabpanel" aria-labelledby="pension-tab">

          <form (ngSubmit)="onSubmit('pension')">

            <div class="formHead">
              <h4 class="formHeading">Pension</h4>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputEmployeePensionCode">Code</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.pension.code" id="inputEmployeePensionCode" name="EmployeePensionCode">
                  <option *ngFor="let code of companyCodesPension">{{code.code}}</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmployeePensionPensionPercent">Pension %</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedEmployee.pension.employeePercent" id="inputEmployeePensionPensionPercent" name="EmployeePensionPensionPercent" >
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmployeePensionPensionAmount">Pension Amount</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedEmployee.pension.employeeAmount" id="inputEmployeePensionPensionAmount" name="EmployeePensionPensionAmount" >
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmployeePensionEmployerPercent">Employer %</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedEmployee.pension.employerPercent" id="inputEmployeePensionEmployerPercent" name="EmployeePensionEmployerPercent" >
              </div>

              <!-- <div class="form-group col-md-3">
                <label for="inputEmployeePensionDescription">Description</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.pension.descriptions" id="inputEmployeePensionDescription" name="EmployeePensionDescription">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeePensionAbbreviation">Abbreviation</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.pension.abbreviation" id="inputEmployeePensionAbbreviation" name="EmployeePensionAbbreviation">
              </div> -->

            </div>

            <!-- <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputEmployeePensionCompanyID">CompanyID</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.pension.companyID" value="id" id="inputEmployeePensionCompanyID" name="EmployeePensionCompanyID">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeePensionEmployeeId">EmployeeId</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.pension.employeeId" value="selectedEmployee.employeeId" id="inputEmployeePensionEmployeeId" name="EmployeePensionEmployeeId">
              </div>
            </div> -->


            <div class="form-row">

              <div class="form-group col-md-3">
                <label for="inputEmployeePensionEmployerAmount">Employer Amount</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedEmployee.pension.employerAmount" id="inputEmployeePensionEmployerAmount" name="EmployeePensionEmployerAmount" >
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmployeePensionEnable">Enable</label>
                <input class="form-control" type="checkbox" [(ngModel)]="selectedEmployee.pension.enable" name="employeePensionEnabled">
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmployeeDeductionGlAccount">G/L Account</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.pension.glAccount" id="inputEmployeeDeductionGlAccount"  name="employeeDeductionGlAccount">
                  <option *ngFor="let coa of chartsOfAccounts; let f = index" [ngValue]="coa.id">{{coa.description}} - {{coa.account}}</option>
                </select>
              </div>

              <!-- <div class="form-group col-md-3">
                <label for="inputEmployeePensionPolicy">Policy</label>
                <input type="text" class="form-control" [(ngModel)]="selectedEmployee.pension.policy" id="inputEmployeePensionPolicy" name="EmployeePensionPolicy">
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmployeePensionEnable">Enable</label>
                <input class="form-control" type="checkbox" [(ngModel)]="selectedEmployee.pension.enable" name="employeePensionEnabled">
              </div> -->
            </div>


            <!-- <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputAccountNumber4">Account #</label>
                <input type="number" class="form-control" [(ngModel)]="selectedEmployee.pension.accountNumber" id="inputAccountNumber4" name="bankAccountNumber" placeholder="xxx-xxxx-xxxx">
              </div> -->

              <!--
              <div class="form-group col-md-3">
                <label for="inputMaxPayable">MaxPayable</label>
                <input type="number" class="form-control" [(ngModel)]="selectedEmployee.pension.maxPayable" id="inputMaxPayable" name="bankMaxPayable">
              </div> -->


            <div class="formHead">
              <h6 class="formHeading">Banking</h6>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="inputBankBank2">Bank</label>
                <select class="form-control" aria-label="Default select example" id="inputBankBank2" name="bankBank" [(ngModel)]="selectedEmployee.pension.bank.bank" >
                  <option *ngFor="let bankCode of bankingCodes; let x = index" [ngValue]="bankCode.id">{{bankCode.name}} - {{bankCode.branch}}</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputBankType2">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.pension.bank.type" id="inputBankType2" name="bankType">
                  <option selected >savings</option>
                  <option selected >chequing</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputBankMethod2">Method</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="selectedEmployee.pension.bank.method" id="inputBankMethod2" name="inputBankMethod2">
                  <option value="cash">Cash</option>
                  <option value="directDeposit">Direct Deposit</option>
                  <option value="cheque">Cheque</option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="inputAccountNumber2">Account Number</label>
                <input type="number" class="form-control" [(ngModel)]="selectedEmployee.pension.bank.account" id="inputAccountNumber2" name="bankAccountNumber">
              </div>

            </div>


            <button type="submit" class="btn btn-primary">Update</button>

            <button type="button" class="btn btn-danger" (click)="listEmployees()" style="margin-left:0.5rem;">Cancel</button>
          </form>

        </div>

        <div class="tab-pane fade" id="previousEmployment" role="tabpanel" aria-labelledby="previousEmployment-tab">

          <form (ngSubmit)="onSubmit('previousEmployment')">

            <div class="formHead">
              <h4 class="formHeading">Previous Employment</h4>
            </div>

            <div class="form-row">

              <div class="form-group col-md-6">
                <label for="inputPreviousEmpGross">Gross</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedEmployee.previousEmployment.gross" id="inputPreviousEmpGross" name="previousEmpGross" >
              </div>
              <div class="form-group col-md-6">
                <label for="inputPreviousEmpNis">NIS</label>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedEmployee.previousEmployment.nis" id="inputPreviousEmpNis" name="previousEmpNis" >
              </div>


            </div>


            <button type="submit" class="btn btn-primary">Update</button>

            <button type="button" class="btn btn-danger" (click)="listEmployees()" style="margin-left:0.5rem;">Cancel</button>
          </form>

        </div>

      </div>

    </div>

  </div>

</div>

<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="dataManagementAction('update')">
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Update</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="dataManagementAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>

<div class="actionsBox2">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="employeeAction('edit')">
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Update</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="employeeAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>

<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>

      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
