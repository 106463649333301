<div class="pageContainerSidebar">

  <div class="codesContainer">
    <div class="subcontainerUpper row">

      <div class="row upperHeading">
        <h3 class="lowerHeader">Payroll Management</h3>
      </div>

      <div class="row input">


        <div class="dropdown" id="drop3">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <p class="btnText">Payroll Action</p>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 120%;">
            <a class="dropdown-item" href="javascript:" (click)="showPayrolls()"><p class="btnText2">Open Payrolls</p></a>
            <a class="dropdown-item" href="javascript:" (click)="showClosedPayrolls()"><p class="btnText2 ">Closed Payrolls</p></a>
            <a class="dropdown-item" href="javascript:" (click)="addPayroll()"><p class="btnText2 ">New Payroll</p></a>
            <a *ngIf="setting != 'Detail'" class="dropdown-item" href="javascript:" (click)="startShowPaySchedule()"><p class="btnText2 ">Pay Schedule</p></a>
            <a *ngIf="setting == 'Detail' && selectedPayrollCommitted != true && listType != 'closed'" class="dropdown-item" href="javascript:" (click)="commitPayroll()"><p class="btnText2 ">Commit Payroll</p></a>
          </div>
        </div>


        <div class=" btnCol">
          <button *ngIf="setting == 'List'" (click)="preS02ASCII()" type="button" class="btn createBtn"><p class="btnText ">S02 ASCII</p></button>
        </div>

        <!-- <div class=" btnCol">
          <button (click)="showPayrolls()" type="button" class="btn createBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Open Payrolls</p></button>
        </div>
        <div class=" btnCol">
          <button (click)="showClosedPayrolls()" type="button" class="btn showBtn"><fa-icon [icon]="faList" class=" btnIcon"></fa-icon><p class="btnText ">Closed Payrolls</p></button>
        </div>

        <div class=" btnCol">
          <button (click)="addPayroll()" type="button" class="btn createBtn"><p class="btnText ">Open Payroll</p></button>
        </div>

        <div class=" btnCol" *ngIf="setting != 'Detail'">
          <button (click)="startShowPaySchedule()" type="button" class="btn createBtn"><p class="btnText ">Pay Schedule</p></button>
        </div>


        <div class=" btnCol" *ngIf="setting == 'Detail' && selectedPayrollCommitted == false && listType != 'closed'">
          <button (click)="commitPayroll()" type="button" class="btn createBtn"><p class="btnText ">Commit Payroll</p></button>
        </div> -->

        <div class=" btnCol" *ngIf="setting == 'Detail' && selectedPayrollCommitted == false && listType != 'closed'">
          <button (click)="startUploadTimesheet()" type="button" class="btn createBtn"><p class="btnText ">Upload Timesheet</p></button>
        </div>


        <div class=" btnCol" *ngIf="setting == 'Detail' && selectedPayrollCommitted == true && listType != 'closed'">
          <button [disabled]="canClose" (click)="closePayroll()" type="button" class="btn createBtn"><p class="btnText ">Close Payroll</p></button>
        </div>

        <div class=" btnCol" *ngIf="setting == 'Detail' && selectedPayrollCommitted != true && listType != 'closed'">
          <button [disabled]="canClose" (click)="payCalc()" type="button" class="btn createBtn"><p class="btnText ">Pay Calc</p></button>
        </div>

        <div class="dropdown" id="drop1" *ngIf="setting == 'Detail' && selectedPayrollCommitted == true && listType != 'closed'">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <p class="btnText">Reset</p>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a *ngIf="selectedPayrollCommitted == true" class="dropdown-item" href="javascript:" (click)="uncommitPayroll()">Uncommit</a>
            <a class="dropdown-item" href="javascript:" (click)="resetPayroll()">Hard Reset</a>
            <!-- <a class="dropdown-item" href="javascript:" (click)="payCalc()">Pay Calc</a> -->
          </div>
        </div>

        <div class="dropdown" id="drop2" *ngIf="setting == 'Detail' && selectedPayrollCommitted == true">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <p class="btnText">Reports</p>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="javascript:" (click)="startSelectSO1()">S01</a>
            <a class="dropdown-item" href="javascript:" (click)="startSelectSO1Form()">S01 Form</a>
            <a href="javascript:" class="dropdown-item" (click)="payrollAction('payrollRegister')">Payroll Register</a>
            <a href="javascript:" class="dropdown-item" (click)="payrollAction('employeeRegister')">Employee Register</a>
            <a href="javascript:" class="dropdown-item" (click)="payrollAction('deductionsListing')">Deductions Listing</a>
            <a href="javascript:" class="dropdown-item" (click)="payrollAction('lodgementListing')">Lodgement Listing</a>
            <a href="javascript:" class="dropdown-item" (click)="payrollAction('departmentSummary')">Department Summary</a>
            <a *ngIf="canFGB == true" href="javascript:" class="dropdown-item" (click)="payrollAction('bankFileFGB')">FGB bank file</a>
            <a *ngIf="canNCB == true" href="javascript:" class="dropdown-item" (click)="payrollAction('bankFileNCB')">NCB bank file (ELink)</a>
            <a *ngIf="canBNS == true" href="javascript:" class="dropdown-item" (click)="payrollAction('bankFileBNS')">BNS bank file</a>
          </div>
        </div>



      </div>


      <div class="upperColCont" *ngIf="setting == 'Detail'">

        <div class="col-4 upperCol">
          <ul class="upperColList">
            <li class="upperColListItem">
              <p class="upperListItemText">Gross: <span class="bold" span>$ {{companyPaySummary.gross.toFixed(2)}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Net: <span class="bold" span>$ {{companyPaySummary.net.toFixed(2)}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Employees: <span class="bold" span>{{companyPaySummary.totalEmployees}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">NIS: <span class="bold" span>{{companyPaySummary.nis}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">TRN: <span class="bold" span>{{company.tax}}</span></p>
            </li>


          </ul>
        </div>
        <div class="col-4 upperCol">
          <ul class="upperColList">
            <li class="upperColListItem">
              <p class="upperListItemText" style="text-decoration: underline">Statutories:</p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Edu Tax (Employee): <span *ngIf="companyPaySummary.statEduEmployee" class="bold" span>$ {{companyPaySummary.statEduEmployee.toFixed(2)}}</span></p>
            </li>

            <li class="upperColListItem">
              <p class="upperListItemText">Edu Tax (Employer): <span *ngIf="companyPaySummary.statEduEmployer" class="bold" span>$ {{companyPaySummary.statEduEmployer.toFixed(2)}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">NHT (Employee): <span *ngIf="companyPaySummary.statNhtEmployee" class="bold" span>$ {{companyPaySummary.statNhtEmployee.toFixed(2)}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">NHT (Employer): <span *ngIf="companyPaySummary.statNhtEmployer" class="bold" span>$ {{companyPaySummary.statNhtEmployer.toFixed(2)}}</span></p>
            </li>

            <!-- <li class="upperColListItem">
              <p class="upperListItemText">Heart (Employee): <span *ngIf="companyPaySummary.statHeartEmployee" class="bold" span>$ {{companyPaySummary.statHeartEmployee.toFixed(2)}}</span></p>
            </li> -->

          </ul>
        </div>
        <div class="col-4 upperCol">
          <ul class="upperColList">
            <li class="upperColListItem">
              <p class="upperListItemText">NIS (Employee): <span *ngIf="companyPaySummary.statNisEmployee" class="bold" span>$ {{companyPaySummary.statNisEmployee.toFixed(2)}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">NIS (Employer): <span *ngIf="companyPaySummary.statNisEmployer" class="bold" span>$ {{companyPaySummary.statNisEmployer.toFixed(2)}}</span></p>
            </li>

            <li class="upperColListItem">
              <p class="upperListItemText">Heart (Employer): <span *ngIf="companyPaySummary.statHeartEmployer" class="bold" span>$ {{companyPaySummary.statHeartEmployer.toFixed(2)}}</span></p>
            </li>

            <li class="upperColListItem">
              <p class="upperListItemText">PAYE (Employee): <span *ngIf="companyPaySummary.statPayeEmployee" class="bold" span>$ {{companyPaySummary.statPayeEmployee.toFixed(2)}}</span></p>
            </li>
            <!-- <li class="upperColListItem">
              <p class="upperListItemText">PAYE (Employer): <span *ngIf="companyPaySummary.statPayeEmployer" class="bold" span>$ {{companyPaySummary.statPayeEmployer.toFixed(2)}}</span></p>
            </li> -->
            <!-- <li class="upperColListItem">
              <p class="upperListItemText">Total Payroll: <span class="bold" span>{{companyPaySummary.totalPayroll}}</span></p>
            </li> -->
            <!-- <li class="upperColListItem">
              <p class="upperListItemText">Payroll Start Date: <span class="bold" span>{{companyPaySummary.payrollStartDate}}</span></p>
            </li>
            <li class="upperColListItem">
              <p class="upperListItemText">Payroll End Date: <span class="bold" span>{{companyPaySummary.payrollEndDate}}</span></p>
            </li> -->
            <li class="upperColListItem">
              <p class="upperListItemText">Pay Cycle: <span class="bold" span>{{companyPaySummary.payCycle.period}}</span></p>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class="subcontainerLower">
      <!-- <input *ngIf="showUpload == true" type="file" (change)="upload($event.target.files)" accept=".xlsx,.xls"> -->

      <div class="row lowerTable" *ngIf="setting == 'Schedule'">

        <div class="form-row">
          <div class="col-md-6 form-group mt-3">
              <!-- <label for="inputCycleSelect">Cycle</label> -->
              <select class="form-control" aria-label="Default select example"  (change)="selectSheduleCycle($event.target.value)" id="inputCycleSelect"  name="typeSelect">
                <option value="weekly" selected="selected">Weekly</option>
                <option value="fortnightly">Fortnighty</option>
                <option value="bimonthly">Bi-Monthly</option>
                <option value="monthly">Monthly</option>
              </select>

          </div>
          <div class="col-md-6 form-group">
            <div class=" btnCol">
              <button (click)="showPaySchedule()" type="button" class="btn createBtn longBtn"><p class="btnText ">Get Pay Schedule</p></button>
            </div>
          </div>
        </div>

        <table class="table">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Pay Period</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Start Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">End Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Pay Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let item of paySchedule; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{item.payPeriod}}</p></td>
            <td class="tableCels"><p class="cellText">{{item.startDate}}</p></td>
            <td class="tableCels"><p class="cellText">{{item.endDate}}</p></td>
            <td class="tableCels"><p class="cellText">{{item.payDate}}</p></td>
          </tr>
        </table>


      </div>

      <div class="row lowerTable" *ngIf="setting == 'List' && listType == 'open'">

        <!-- <h3 class="lowerHeader" *ngIf="error == true">{{modalMessage}}</h3> -->

        <ul class="dashboardClientList">
          <li class="dashboardClientListItem" *ngFor="let payroll of openPayrolls">
            <div class="payrollCard">

              <table class="customerCardTable">
                <tr class="customerCardTableRowTop">
                  <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                    <h6 class="customerCardHeading">{{payroll.payCycle.period}} - {{payroll.type}}</h6>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faCalendarAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Pay Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payroll.payCycle.payDateOffset | date}}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faRecycle" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Cycle</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payroll.payCycle.period }}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faCalendarAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Start Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payroll.payCycle.startDate | date }}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faCalendarAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">End Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payroll.payCycle.endDate | date}}</p>
                  </td>
                </tr>
                <tr class="editBtnRow">
                  <button type="button" (click)="openPayroll(payroll)" class="btn customerEditBtn">Open</button>
                  <button type="button" (click)="editPayroll(payroll)" class="btn customerEditBtn2">Edit</button>
                </tr>
              </table>
            </div>
          </li>
        </ul>
      </div>

      <div class="row lowerTable" *ngIf="setting == 'List' && listType == 'closed'">

        <!-- <h3 class="lowerHeader" *ngIf="error == true">{{modalMessage}}</h3> -->

        <ul class="dashboardClientList">
          <li class="dashboardClientListItem" *ngFor="let payrollClosed of closedPayrolls">
            <div class="payrollCard closedPayrollItem">

              <table class="customerCardTable">
                <tr class="customerCardTableRowTop">
                  <!-- <a href="javascript:" [routerLink]="['/pages/company',customer.id,'details']"> -->
                    <h6 class="customerCardHeading">{{payrollClosed.payCycle.period}} - {{payrollClosed.type}}</h6>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faUserFriends" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Pay Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payrollClosed.payCycle.payDateOffset}}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faRecycle" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Cycle</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payrollClosed.payCycle.period}}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faMoneyCheckAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Start Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payrollClosed.payCycle.startDate}}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faMoneyCheckAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">End Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payrollClosed.payCycle.endDate}}</p>
                  </td>
                </tr>
                <tr class="customerCardTableRow">
                  <td class="tableLeftCol">
                    <fa-icon [icon]="faMoneyCheckAlt" class="fa-lg customerCardTableIcon"></fa-icon>
                    <p class="customerCardText">Pay Date</p>
                  </td>
                  <td class="tableRightCol">
                    <p class="customerCardText">{{payrollClosed.payCycle.payDateOffset}}</p>
                  </td>
                </tr>
                <tr class="editBtnRow">
                  <button type="button" (click)="openPayroll(payrollClosed)" class="btn customerEditBtn">Open</button>
                </tr>
              </table>
            </div>
          </li>
        </ul>
      </div>

      <div class="row lowerTable" *ngIf="setting == 'Add'">

        <div class="codeAdd">
          <form (ngSubmit)="onSubmit('department')">

            <!-- <div class="formHead">
              <h4 class="formHeading">Department</h4>
            </div> -->

            <div class="form-row">

              <div class="form-group col">
                <label for="inputPayrollType">Type</label>
                <select class="form-control" aria-label="Default select example" [(ngModel)]="data.payrollType" id="inputTypeSelect"  name="typeSelect">
                  <option>weekly</option>
                  <option>fortnightly</option>
                  <option>bimonthly</option>
                  <option>monthly</option>
                </select>
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Create</button>
          </form>
        </div>

      </div>

      <div class="row lowerTable" *ngIf="setting == 'Detail'">


        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button (click)="toggleBox()" class="nav-link active" id="paySummary-tab" data-toggle="tab" href="#paySummary" type="button" role="tab" aria-controls="paySummary" aria-selected="true">Pay Summary</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button (click)="toggleBox()" class="nav-link" id="mtdData-tab" data-toggle="tab" href="#mtdData" type="button" role="tab" aria-controls="mtdData" aria-selected="false">MTD Data</button>
          </li> -->
          <li class="nav-item" role="presentation">
            <button (click)="toggleBox()" class="nav-link" id="ytdData-tab" data-toggle="tab" href="#ytdData" type="button" role="tab" aria-controls="ytdData" aria-selected="false">YTD Data</button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="toggleBox()" class="nav-link" id="taxTables-tab" data-toggle="tab" href="#taxTablesData" type="button" role="tab" aria-controls="taxTablesData" aria-selected="false">Tax Tables</button>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="paySummary" role="tabpanel" aria-labelledby="paySummary-tab">

            <div class=" btnCol transactionBtn" *ngIf="transactionAdd != true && showTransactions != true">
              <button (click)="startAddEmployee()" type="button" class="btn createBtn" *ngIf="listType != 'closed'"><p class="btnText " >+ Employee</p></button>
              <button (click)="preGetAllPayslips()" type="button" class="btn createBtn"><p class="btnText ">All Payslips</p></button>
            </div>
            <hr>

            <div *ngIf="showTransactions != true" class="ytdEmployeeBox">

              <div class="row input">

                <div class="col-md-4 btnCol">
                  <div class=" form-group " >
                    <input type="text" [(ngModel)]="paySumSearchQuery" (input)="searchPaySummary($event.target.value)" id="inputSearch" class="form-control floating">

                  </div>

                </div>

                <div class="col-md-3 btnCol">
                  <div class="formInput form-group">
                      <select class="form-control" aria-label="Default select example" (change)="selectPaySumSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                        <option value="employee">Name</option>
                        <option value="employeeId" >Employee Id</option>
                        <option value="statutoryDeductionTotal" >Deduction Total</option>
                        <option value="gross" >Gross</option>
                        <option value="net" >Net</option>
                      </select>
                  </div>
                </div>

                <div class="col-md-3 btnCol">
                  <div class="formInput form-group">
                    <button type="button" style="margin-top: 0;" class="btn createBtn" (click)="clearPaySummarySearch()">
                      <p class="btnText ">Clear</p></button>
                  </div>
                </div>

                <div class="col-md-3 form-group" style="margin-top:1rem;">

                    <select class="form-control" aria-label="Default select example" (change)="selectSortDirection($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                      <option value="Ascending">Ascending</option>
                      <option value="Descending">Descending</option>
                    </select>

                </div>

                <div class="form-group col-md-3">
                  <button type="button" class="btn createBtn" (click)="sortPaySummary()">
                    <p class="btnText ">Sort</p>
                  </button>
                </div>

              </div>

              <!-- <div class="row input flex">

                <div class="col-md-4 form-group" style="margin-top:1rem;">

                    <select class="form-control" aria-label="Default select example" (change)="selectSortDirection($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                      <option value="Ascending">Ascending</option>
                      <option value="Descending">Descending</option>
                    </select>

                </div>

                <div class="form-group col-md-4">
                  <button type="button" class="btn createBtn" (click)="sortPaySummary()">
                    <p class="btnText ">Sort</p>
                  </button>
                </div>

              </div> -->

              <table class="table">
                <tr class="tableRow">
                  <th class="tableHeadings">
                    <p class="headText">Employee Name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Employee Id</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Gross</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Statutory Deduction</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Net</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                  <th class="tableHeadings">
                    <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                  </th>
                </tr>
                <tr *ngFor="let payItem of paySummarySrch; let i = index" class="tableRow" [ngClass]="{'terminatedHighlight': payItemEmployeeList[i].employee.employment.status == 'terminated'}">
                  <td class="tableCels"><p class="cellText">{{payItem.employee}}</p></td>
                  <td class="tableCels"><p class="cellText">{{payItem.employeeId}}</p></td>
                  <td class="tableCels"><p class="cellText">$ {{payItem.gross.toFixed(2)}}</p></td>
                  <td class="tableCels">
                    <p class="cellText">$ {{payItem.statutoryDeductionTotal.toFixed(2)}}</p>
                  </td>
                  <td class="tableCels"><p class="cellText">$ {{payItem.net.toFixed(2)}}</p></td>
                  <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,payItem)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                </tr>
              </table>
            </div>

            <div *ngIf="showTransactions == true" class="ytdEmployeeBox">

              <h4>Transactions</h4>

              <h6 class="m-2">Employee: {{payitemEmployee.firstName}}, {{payitemEmployee.lastName}}, ID: {{payitemEmployee.employeeId}}, TRN: {{payitemEmployee.trn}}, NIS: {{payitemEmployee.nis}},</h6>

              <div class=" btnCol transactionBtn">
                <button (click)="closeTransactions()" type="button" class="btn createBtn"><p class="btnText ">Back</p></button>

                <button (click)="startAddTransaction()" type="button" class="btn createBtn"><p class="btnText ">New</p></button>
              </div>


              <div>
                <table class="table">
                  <tr class="tableRow">
                    <th class="tableHeadings">
                      <p class="headText">Code</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                    </th>
                    <th class="tableHeadings">
                      <p class="headText">Abbreviation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                    </th>
                    <th class="tableHeadings">
                      <p class="headText">Hours</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                    </th>
                    <th class="tableHeadings">
                      <p class="headText">Amount</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                    </th>
                    <th class="tableHeadings">
                      <p class="headText">Rate</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                    </th>
                    <th class="tableHeadings">
                      <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                    </th>
                  </tr>
                  <tr *ngFor="let transaction of employeeTransactions; let i = index" class="tableRow">
                    <td class="tableCels"><p class="cellText">{{transaction.code.code}}</p></td>
                    <td class="tableCels"><p class="cellText">{{transaction.code.abbreviation}}</p></td>
                    <td class="tableCels"><p class="cellText">{{transaction.hours}}</p></td>                    
                    <td class="tableCels"><p class="cellText">$ {{transaction.amount.toFixed(2)}}</p></td>
                    <td class="tableCels"><p class="cellText">$ {{transaction.rate.toFixed(2)}}</p></td>
                    <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox2($event,transaction)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                  </tr>
                </table>
              </div>

              <div *ngIf="transactionEdit == true" >
                <form (ngSubmit)="updateTransaction()">

                  <!-- <div class="formHead">
                    <h4 class="formHeading">Department</h4>
                  </div> -->

                  <div class="form-row">

                    <div class="form-group col-md-4">
                      <label for="inputTransactionCode">Code</label>
                      <select class="form-control " aria-label="Default select example2" [(ngModel)]="selectedTransaction.code.code" id="inputTransactionCode" name="tansactionCode">
                        <option *ngFor="let code of transactionCodes">{{code.code}}</option>
                      </select>
                    </div>

                    
                    <div class="form-group col-md-2">
                      <label for="inputTransactionHours">Transaction Hours</label>
                      <input type="number" class="form-control" [(ngModel)]="selectedTransaction.hours" id="inputTransactionHours" name="transactionHours">
                    </div>
                    <div class="form-group col-md-2">
                      <label for="inputTransactionAmount">Transaction Amount</label>
                      <input type="number" step="0.01" class="form-control" [(ngModel)]="selectedTransaction.amount" id="inputTransactionAmount" name="transactionAmount">
                    </div>
                    <div class="form-group col-md-2">
                      <label for="inputTransactionHours">Rate</label>
                      <input type="number" class="form-control" [(ngModel)]="selectedTransaction.rate" id="inputTransactionHours" name="transactionHours">
                    </div>

                  </div>

                  <button type="submit" class="btn btn-primary m-4">Update</button>

                  <button type="button" class="btn btn-danger" (click)="cancelTransEdit()">Cancel</button>
                </form>
              </div>

              <div *ngIf="transactionAdd == true" >
                <form (ngSubmit)="addEmployeeTransaction()">

                  <!-- <div class="formHead">
                    <h4 class="formHeading">Department</h4>
                  </div> -->

                  <div class="form-row">

                    <div class="form-group col-md-4">
                      <label for="inputNewTransactionCode">Code</label>
                      <select class="form-control " aria-label="Default select example2" [(ngModel)]="newTransaction.code" id="inputNewTransactionCode" name="newtansactionCode" placeholder="Select a Code">
                        <!-- <option [value]="transactionCodes[0]" [selected]="true">Select a Code</option> -->
                        <option *ngFor="let code of transactionCodes" [value]="code.code">{{code.code}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputNewTransactionHours">Transaction Hours</label>
                      <input type="number" class="form-control" [(ngModel)]="newTransaction.hours" id="inputNewTransactionHours" name="newtransactionHours">
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputNewTransactionAmount">Transaction Amount</label>
                      <input type="number" step="0.01" class="form-control" [(ngModel)]="newTransaction.amount" id="inputNewTransactionAmount" name="newtransactionAmount">
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputNewTransactionRate">Rate </label>
                      <input type="number" step="0.01" class="form-control" [(ngModel)]="newTransaction.rate" id="inputNewTransactionRate" name="newtransactionRate">
                    </div>

                  </div>

                  <button type="submit" class="btn btn-primary m-4">Add</button>

                  <button type="button" class="btn btn-danger" (click)="cancelTransAdd()">Cancel</button>
                </form>
              </div>

            </div>

          </div>

          <!-- <div class="tab-pane fade" id="mtdData" role="tabpanel" aria-labelledby="mtdData-tab">

          </div> -->

          <div class="tab-pane fade" id="ytdData" role="tabpanel" aria-labelledby="ytdData-tab">

            <table class="table">
              <tr class="tableRow">
                <th class="tableHeadings">
                  <p class="headText">Employee Name</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Gross</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Statutory Deduction</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <th class="tableHeadings">
                  <p class="headText">Net</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th>
                <!-- <th class="tableHeadings">
                  <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                </th> -->
              </tr>
              <tr *ngFor="let ytdData of selectedPayrollYtd; let j = index" class="tableRow">
                <td class="tableCels"><p class="cellText">{{ytdData[0].employee}}</p></td>
                <td class="tableCels"><p class="cellText">$ {{ytdData[0].gross.toFixed(2)}}</p></td>
                <td class="tableCels">
                  <!-- <p  *ngIf="!ytdData[0].statDeduction" class="cellText">$ {{ytdData[0].statDeduction)}}</p> -->
                  <p  *ngIf="ytdData[0].statutoryDeductionTotal" class="cellText">$ {{ytdData[0].statutoryDeductionTotal.toFixed(2)}}</p>
                </td>
                <td class="tableCels"><p class="cellText">$ {{ytdData[0].net.toFixed(2)}}</p></td>
                <!-- <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="yTDActionsBox($event,payItem)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td> -->
              </tr>
            </table>


          </div>

          <div class="tab-pane fade" id="taxTablesData" role="tabpanel" aria-labelledby="taxTablesData-tab">


            <ul *ngIf="payrollTaxHeaders.length > 0"  class="nav nav-tabs" id="myTab2" role="tablist">
              <li class="nav-item" role="presentation" *ngFor="let taxHeader of payrollTaxHeaders; let i = index">
                <button (click)="selectTaxHeadType(i)" class="nav-link active" [attr.id]="taxHeader.type +'-tab'" data-toggle="tab" [attr.href]="'#tab_' + taxHeader.type" type="button" role="tab" aria-controls="paySummary" aria-selected="true">{{taxHeader.type}}</button>
              </li>
            </ul>

            <div  *ngIf="payrollTaxHeaders.length > 0" class="tab-content" id="myTabContent">

              <div  class="tab-pane fade show active" [attr.id]="'tab_' + payrollTaxHeaders[taxHeadIndx].type" role="tabpanel" [attr.aria-labelledby]="payrollTaxHeaders[taxHeadIndx].type +'-tab'">

                <div class="formHead">
                  <h6 class="formHeading">Region: {{payrollTaxHeaders[taxHeadIndx].region}}, Code: {{payrollTaxHeaders[taxHeadIndx].taxCode}}</h6>
                </div>

                <div *ngIf="editTaxHeader != true">

                  <p class="headText">Employee</p>

                  <table class="table">
                    <tr class="tableRow">
                      <th class="tableHeadings">
                        <p class="headText">Effective Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                      <th class="tableHeadings">
                        <p class="headText">Rate</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                      <th class="tableHeadings">
                        <p class="headText">Threshold</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                      <th class="tableHeadings">
                        <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                    </tr>
                    <tr *ngFor="let taxTier of payrollTaxHeaders[taxHeadIndx].taxTierEmployee; let j = index" class="tableRow">
                      <td *ngIf="taxTier.effectiveDate" class="tableCels"><p class="cellText">{{taxTier.effectiveDate.split("T")[0]}}</p></td>
                      <td *ngIf="!taxTier.effectiveDate" class="tableCels"><p class="cellText">---</p></td>
                      <td class="tableCels"><p class="cellText">{{(taxTier.rate*100).toFixed(2)}} %</p></td>
                      <td class="tableCels"><p class="cellText">$ {{taxTier.threshold.toFixed(2)}}</p></td>
                      <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox3($event,payrollTaxHeaders[taxHeadIndx],taxTier,'Employee',j)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                    </tr>
                  </table>


                  <p class="headText">Employer</p>

                  <table class="table">
                    <tr class="tableRow">
                      <th class="tableHeadings">
                        <p class="headText">Effective Date</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                      <th class="tableHeadings">
                        <p class="headText">Rate</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                      <th class="tableHeadings">
                        <p class="headText">Threshold</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                      <th class="tableHeadings">
                        <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
                      </th>
                    </tr>
                    <tr *ngFor="let taxTier2 of payrollTaxHeaders[taxHeadIndx].taxTierEmployer; let k = index" class="tableRow">
                      <td *ngIf="taxTier2.effectiveDate" class="tableCels"><p class="cellText">{{taxTier2.effectiveDate.split("T")[0]}}</p></td>
                      <td *ngIf="!taxTier2.effectiveDate" class="tableCels"><p class="cellText">---</p></td>
                      <td class="tableCels"><p class="cellText">{{(taxTier2.rate*100).toFixed(2)}} %</p></td>
                      <td class="tableCels"><p class="cellText">$ {{taxTier2.threshold.toFixed(2)}}</p></td>
                      <td class="tableCels actionCell"><a href="javascript:" class="actionsBoxLink" (click)="actionsBox3($event,payrollTaxHeaders[taxHeadIndx],taxTier2,'Employer',k)"><fa-icon [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
                    </tr>
                  </table>

                </div>


                <div *ngIf="editTaxHeader == true">

                  <form (ngSubmit)="updatePayrollTaxHeader()">

                    <!-- <div class="formHead">
                      <h4 class="formHeading">Department</h4>
                    </div> -->

                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="inputEffectiveDate">Effective Date</label>
                        <input type="date" class="form-control" [(ngModel)]="selectedTaxTier.effectiveDate" id="inputEffectiveDate" name="effectiveDate">
                      </div>
                      <div class="form-group col-md-4">
                        <label for="inputRate">Rate</label>
                        <input type="number" class="form-control" [(ngModel)]="selectedTaxTier.rate" id="inputRate" name="rate">
                      </div>
                      <div class="form-group col-md-4">
                        <label for="inputThreshold">Threshold</label>
                        <input type="number" class="form-control" [(ngModel)]="selectedTaxTier.threshold" id="inputThreshold" name="threshold">
                      </div>
                    </div>

                    <button type="submit" class="btn btn-primary m-4">Update</button>
                    <button type="submit" class="btn btn-danger m-4" (click)="cancelEditTaxHead()">Cancel</button>

                  </form>

                </div>



              </div>

            </div>




          </div>

        </div>

      </div>

      <div class="row lowerTable" *ngIf="setting == 'Edit'">

        <div class="codeAdd">
          <form (ngSubmit)="updateOpenPayroll()">

            <!-- <div class="formHead">
              <h4 class="formHeading">Department</h4>
            </div> -->

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputStartDate">Start Date</label>
                <input type="date" class="form-control" [(ngModel)]="selectedPayroll.payCycle.startDate" id="inputStartDate" name="startDate">
              </div>
              <div class="form-group col-md-6">
                <label for="inputEndDate">End Date</label>
                <input type="date" class="form-control" [(ngModel)]="selectedPayroll.payCycle.endDate" id="inputEndDate" name="endDate">
              </div>
            </div>

            <div class="form-row">


              <div class="form-group col-md-6">
                <label for="inputPayDate">Pay Date</label>
                <input type="date" class="form-control" [(ngModel)]="selectedPayroll.payCycle.payDateOffset" id="inputPayDate" name="PayDate">
              </div>
              <div class="form-group col-md-6">
                <label for="inputPayPeriod">Pay Period</label>
                <input type="number" class="form-control" [(ngModel)]="selectedPayroll.payCycle.period" id="inputPayPeriod" name="PayPeriod">
              </div>

            </div>

            <button type="submit" class="btn btn-primary m-4">Update</button>

            <button type="button" class="btn btn-danger" (click)="editPayroll('')">Cancel</button>
          </form>
        </div>

      </div>

    </div>
  </div>

</div>



<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="payrollAction('payslipGenerate')">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Generate Payslip</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <!-- check -->
      <a href="javascript:" class="actionLink" (click)="payrollAction('getTransactions')" *ngIf="listType != 'closed'">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Get Transactions</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="payrollAction('addTransaction')" *ngIf="listType != 'closed'">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Add Transaction</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="payrollAction('delete')" *ngIf="listType != 'closed'">
        <fa-icon [icon]="faTrash" class=" actionsIcon"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>

<div class="actionsBox2">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" disabled *ngIf="listType != 'closed'" (click)="transactionAction('edit')">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Edit</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="transactionAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon" ></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>

<div class="actionsBox3">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" disabled *ngIf="listType != 'closed'" (click)="taxHeaderAction('edit')">
        <fa-icon [icon]="faFileAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Edit</p>
      </a>
    </li>
    <!-- <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="transactionAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon" ></fa-icon><p class="cellText">Delete</p>
      </a>
    </li> -->
  </ul>
</div>


<!-- Modal -->
<div class="modal fade " id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true && s01Select != true" >{{modalMessage}}</h5>

        <div *ngIf="s01Select == true">

          <p>Select a Payroll</p>
          <table class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">...</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Type</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Start Date</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">End Date</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Pay Date</p>
              </th>
            </tr>
              <!-- <tr *ngFor="let item of allPayrolls; let j = index" class="tableRow s01Select" attr.id="{{j}}" (click)="selectSO1(item)" (mouseenter)="highlightS01($event)" (mouseleave)="unhighlightS01($event)"> -->
              <tr *ngFor="let item of allPayrolls; let j = index" class="tableRow s01Select" attr.id="{{j}}" >
                <td class="tableCels">
                  <input class="form-control" type="checkbox" (change)="selectMultipleS01(item)" name="s01" >
                </td>
                <td class="tableCels"><p class="cellText">{{item.type}}</p></td>
                <td class="tableCels"><p class="cellText">{{item.payCycle.startDate | date:'mediumDate'}}</p></td>
                <td class="tableCels"><p class="cellText">{{item.payCycle.endDate | date:'mediumDate'}}</p></td>
                <td class="tableCels"><p class="cellText">{{item.payCycle.payDateOffset | date:'mediumDate'}}</p></td>
              </tr>
          </table>

          <button type="button" class="btn btn-secondary" (click)="selectSO1()">Submit</button>
        </div>

        <div *ngIf="downloadBankFIleNCB == true">
          <a download="NCBBankFile.txt" id="downloadlink" style="display: none" href="javascript:">
            <button id="create" class="createBtn">Download</button>
          </a>
        </div>


      </div>
      <!-- <div class="modal-footer" *ngIf="s01Select == true">
        <button type="button" class="btn btn-secondary" (click)="selectSO1()" >Submit</button>
      </div> -->
    </div>
  </div>
</div>


<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div *ngIf="addLogo != true">
          <h5 *ngIf="uploadLogo != true">Upload Timesheet</h5>
          <h5 *ngIf="uploadLogo == true">Upload Logo</h5>

          <div class="dragDropBox">
            <div class="form-group col-md-12 fileDropZone">
              <ngx-file-drop id="employeeUploadDragDrop"
              contentClassName="dragDropText" dropZoneClassName="dropZone" dropZoneLabel="Drop files here"
              (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
              style="margin:0 auto;width:100%;height: 10rem;border-radius: 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color:#ffffff;">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <p class="dragDroptext">Drag & Drop OR
                      <input type="file" (change)="selectFile($event.target.files)" id="select">
                    </p>
                  </ng-template>
              </ngx-file-drop>
            </div>
          </div>
        </div>

        <div *ngIf="addLogo == true">

          <h5>Upload a logo?</h5>

          <div class=" btnCol">
            <button (click)="addLogoSelect('yes')" type="button" class="btn createBtn"><p class="btnText ">Yes</p></button>
            <button (click)="addLogoSelect('no')" type="button" class="btn showBtn"><p class="btnText ">No</p></button>
          </div>


        </div>

      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>


<div class="modal fade" id="addEmployeeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="max-height: 50vh;">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body xmodal" id="modalWideBody">

        <h5 *ngIf="newEmpMessage != ''" >{{newEmpMessage}}</h5>

        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <div *ngIf="newEmployeeTransactions[0] && loading != true" >
          <table class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">Code</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Amount</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Hours</p>
              </th>
            </tr>
            <tr *ngFor="let tran of newEmployeeTransactions; let j = index" class="tableRow" attr.id="{{j}}">
              <td class="tableCels"><p class="cellText">{{tran.transactionCode}}</p></td>
              <td class="tableCels"><p class="cellText">$ {{tran.transactionAmount.toFixed(2)}}</p></td>
              <td class="tableCels"><p class="cellText">{{tran.transactionHours}}</p></td>
            </tr>
          </table>
        </div>

        <div *ngIf="newEmployeeNewTransaction == true && loading != true" >
          <form (ngSubmit)="addEmployee()">

            <!-- <div class="formHead">
              <h4 class="formHeading">Department</h4>
            </div> -->
            <div class="form-row" *ngIf="newEmployeeNewTransaction === true" style="display: block;">
              <h6>Selected Employee</h6>
            </div>

            <div class="form-row" *ngIf="newEmployeeNewTransaction === true" style="display: block;margin-bottom: 1rem;">
              <p class="cellText">
                ID: {{newEmployee.employeeId}}, Name: {{newEmployee.firstName}} {{newEmployee.lastName}}
              </p>
            </div>
            <div class="form-row" *ngIf="newEmployeeNewTransaction === true" style="display: block;margin-bottom: 2rem;">
              <p class="cellText">
                TRN: {{newEmployee.trn}}, NIS: {{newEmployee.nis}}
              </p>
            </div>

            <div class="form-row">

              <div class="form-group col-md-6">
                <label for="inputNewTransactionCode2">Code</label>
                <select class="form-control " aria-label="Default select example2" [(ngModel)]="newTransaction.transactionCode" id="inputNewTransactionCode2" name="newtansactionCode2" (change)="addEmpSetCode($event.target.value)">
                  <option *ngFor="let code of transactionCodes">{{code.code}}</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="inputNewTransactionType">Type</label>
                <select disabled class="form-control" aria-label="Default select example" [(ngModel)]="newTransaction.transactionType" id="inputNewTransactionType" name="newtransactionType">
                  <option value="Payment of Amount">Payment of Amount</option>
                  <option value="Payment of Hours">Payment of Hours</option>
                  <option value="Deduction of Amount">Deduction of Amount</option>
                  <option value="Deduction of Hours">Deduction of Hours</option>
                  <option value="Piece Work Deduction">Piece Work Deduction</option>
                </select>
              </div>
            </div>

            <div class="form-row">

              <div class="form-group col-md-6">
                <label for="inputNewTransactionHours">Transaction Hours</label>
                <input [disabled]="transactionHrsDisable" type="number" class="form-control" [(ngModel)]="newTransaction.transactionHours" id="inputNewTransactionHours" name="newtransactionHours">
              </div>
              <div class="form-group col-md-6">
                <label for="inputNewTransactionAmount">Transaction Amount</label>
                <input [disabled]="transactionAmtDisable" type="number" step="0.01" class="form-control" [(ngModel)]="newTransaction.transactionAmount" id="inputNewTransactionAmount" name="newtransactionAmount">
              </div>

            </div>

            <button type="button" class="btn btn-primary m-2" (click)="newEmployeeAddTransaction()" *ngIf="newTransaction.transactionAmount + newTransaction.transactionHours > 0 && newTransaction.transactionCode && newTransaction.transactionCode != ''">+ Transaction</button>

            <button type="submit" class="btn btn-primary m-2" *ngIf="newEmployeeTransactions[0]">Finish</button>

            <button type="button" class="btn btn-danger m-2" (click)="cancelNewEmp()">Cancel</button>

          </form>
        </div>


        <h5 *ngIf="newEmployeeNewTransaction !== true && loading !== true">Search</h5>

        <div class="form-row" *ngIf="newEmployeeNewTransaction !== true && loading !== true">
          <div class="form-group col-md-8" style="margin: .5rem auto;">
            <input type="text" [(ngModel)]="newEmpSearchQuery" (input)="searchEmployees($event.target.value)" id="inputSearch" class="form-control floating searchInput">
          </div>
          <!-- <div class="col-md-6 form-group">

              <select class="form-control" aria-label="Default select example" (change)="selectSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
                <option value="firstName" selected="selected">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="employeeId">Employee Id</option>
                <option value="trn">TRN</option>
                <option value="nis">NIS</option>
              </select>
          </div> -->
        </div>

        <div *ngIf="newEmployeeNewTransaction !== true">
          <table class="table">
            <tr class="tableRow">
              <th class="tableHeadings">
                <p class="headText">ID</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Name</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">TRN</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">NIS</p>
              </th>
              <th class="tableHeadings">
                <p class="headText">Actions</p>
              </th>
            </tr>
            <tr *ngFor="let emp of employeeSrch; let j = index" class="tableRow" attr.id="{{j}}">
            <!-- <tr *ngFor="let emp of addEmpList; let j = index" class="tableRow" attr.id="{{j}}"> -->
              <td class="tableCels"><p class="cellText">{{emp.employeeId}}</p></td>
              <td class="tableCels"><p class="cellText">{{emp.firstName}} {{emp.lastName}}</p></td>
              <td class="tableCels"><p class="cellText">{{emp.trn}}</p></td>
              <td class="tableCels"><p class="cellText">{{emp.nis}}</p></td>
              <td class="tableCels actionCell">
                <a href="javascript:" class="actionsBoxLink" (click)="preAddEmployee(emp)">
                  <fa-icon [icon]="faPlus" class=" tableIcon"></fa-icon>
                </a>
              </td>
            </tr>
          </table>
        </div>

      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>


<div class="modal fade " id="specialModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <!-- <div> -->
          <a  download="NCBBankFile.txt" *ngIf="showNCB == true" id="downloadlink" href="javascript:">
            <button id="create" class="createBtn"><p style="color: #ffffff;">Download NCB Bank File</p></button>
          </a>
          <a *ngIf="showS02Link == true" id="downloadlink2" [href]="S02ASCIILink" target="_blank" (click)="toggleS02()">
            <button id="create" class="createBtn"><p style="color: #ffffff;">Download S02ASCII</p></button>
          </a>
          <a *ngIf="showS01FormLink == true" id="downloadlink3" [href]="S01FormLink" target="_blank" (click)="toggleS02()">
            <button id="create" class="createBtn"><p style="color: #ffffff;">Download S01 Form</p></button>
          </a>
          <a download="BNS.ach" *ngIf="showBNSLink == true" id="downloadlink3" href="javascript:">
            <button id="create" class="createBtn"><p style="color: #ffffff;">Download BNS</p></button>
          </a>
        <!-- </div> -->


      </div>
      <!-- <div class="modal-footer" *ngIf="s01Select == true">
        <button type="button" class="btn btn-secondary" (click)="selectSO1()" >Submit</button>
      </div> -->
    </div>
  </div>
</div>
