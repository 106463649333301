import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
// import * as $ from 'jquery';
declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  data: any = {};
  public modalMessage: string = '';
  public loading: Boolean = false;
  passwordInput: any = undefined;
  togglePasswordButton: any = undefined;

  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {

    this.data = {
      email: 'test@test.com',
      password: '12345'
    }

    this.passwordInput = document.getElementById('current-password')
    this.togglePasswordButton = document.getElementById('toggle-password')

    document.getElementById('toggle-password').addEventListener('click', (e) => this.togglePassword());

  }

  togglePassword() {

  if (this.passwordInput.type === 'password') {
    this.passwordInput.type = 'text';
    this.togglePasswordButton.textContent = 'Hide password';
    this.togglePasswordButton.setAttribute('aria-label',
      'Hide password.');
  } else {
    this.passwordInput.type = 'password';
    this.togglePasswordButton.textContent = 'Show password';
    this.togglePasswordButton.setAttribute('aria-label',
      'Show password as plain text. ' +
      'Warning: this will display your password on the screen.');
  }
}

  onSubmit() {
    this.loading = true;
    $("#errorModal").modal("show");
    this.api.loginAdmin(encodeURIComponent(this.data.email), encodeURIComponent(btoa(this.data.password))).subscribe(
      next => {
        // console.log('loginAdmin',next.token);

        if (next.token != null) {
          this.loading = false;
          $("#errorModal").modal("hide");
          localStorage.setItem("token", next.token);
          localStorage.setItem("admin", JSON.stringify(next.admin));
          localStorage.setItem("type", "employee");
          this.router.navigate(['/dashboard']);
        }

      },
      error => {
        this.loading = false;
        if (error.error) {
          this.modalMessage = error.error.status
        } else {
          this.modalMessage = "Error: loginAdmin!"
        }
        $("#errorModal").modal("show");
      }
    );

  }

}
