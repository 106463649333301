import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from '../api.service';
declare const $: any;

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-user-control',
  templateUrl: './user-control.component.html',
  styleUrls: ['./user-control.component.css']
})
export class UserControlComponent implements OnInit {

  public url: String;
  url2 : String;
  url3 : String;
  url4 : String;
  url5 : String;
  url6 : String;
  public controlsShow: Boolean = false;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faFileUpload = faFileUpload;
  faBell = faBell;
  public setting: String = 'dashboard';

  companyId: String = '';
  public companyName: String = '';
  public companyLogo: String = '';
  currentUser: any = {};
  createCompany: boolean = true;

  constructor(
    private api: ApiService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.controlsShow = false;
        this.url = '';
      }

      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')[1];
        this.url2 = event.url.split('/')[2];
        this.url3 = event.url.split('/')[3];
        this.url4 = event.url.split('/')[4];
        this.url5 = event.url.split('/')[5];
        this.url6 = event.url.split('/')[6];

        if (this.url === 'dashboard' || this.url === 'pages') {

          this.controlsShow = true;

          if (this.url === 'dashboard') {

            this.setting = 'dashboard';
          }

          if (this.url2 === 'company') {

            this.setting = 'company';
            this.companyId = this.url3;

            this.getCompany()

            if (this.url4 === 'employee') {

              this.companyId = this.url3;

            }
          }

        }
        else {
          this.controlsShow = false;

        }
      }

      if (event instanceof NavigationError) {
        //console.log(event.error);
      }

    })
  }


  ngOnInit() {
    let user = localStorage.getItem('user');
    this.currentUser = JSON.parse(localStorage.getItem("admin"));

    if (this.currentUser) {
      if (this.currentUser.permissions.includes('create company') === true) {
        this.createCompany = false;
      }
    }


    // console.log('this.currentUser',this.currentUser);
    // $(".dropdown").on("click", ".dropdown-toggle", function(e) {
    //   e.preventDefault();
    //   console.log('here');
    //
    //   $(this).parent().addClass("show");
    //   $(this).attr("aria-expanded", "true");
    //   $(this).next().addClass("show");
    // });
    $(".dropdown").on("click", ".dropdown-toggle", (e) => {this.handleDropdownClick(e.target)});
  }

  getCompany () {

    this.api.getCompany(this.companyId).subscribe(companyData =>{

      this.companyName = companyData.companyName;
      this.companyLogo = companyData.logo;

      this.controlsShow = true;
    },
    error => {

    }
  );
  }

  editCompany () {

    this.router.navigate(['pages/company/create'], { queryParams: { 'id': this.companyId } });

  }

  handleDropdownClick(eventTarget) {
    // console.log('eventTarget2',eventTarget);

    $(eventTarget).parent().addClass("show");
    $(eventTarget).attr("aria-expanded", "true");
    $(eventTarget).next().addClass("show");

  }

}
