import { Ach } from "./Ach";
import { Address } from "./Address";
import { Admin } from "./Admin";
import { Company } from "./Company";
import { Monthly } from "./Monthly";
import { BiMonthly } from "./BiMonthly";
import { Employee } from "./Employee";
import { EmployeeDepartment } from "./EmployeeDepartment";
import { EmployeeOccupation } from "./EmployeeOccupation";
import { EmployeeHealth } from "./EmployeeHealth";
import { EmployeePosition } from "./EmployeePosition";
import { EmployeeEmployment } from "./EmployeeEmployment";
import { Code } from "./Code";
import { BankCode } from './BankCode';
import { DeductionCode } from './DeductionCode';
import { DepartmentOccupationCode } from './DepartmentOccupationCode';
import { HealthCode } from './HealthCode';
import { HealthCodeTiers } from "./HealthCodeTiers";
import { LoanCode } from './LoanCode';
import { PensionCode } from './PensionCode';
import { TransactionCode } from './TransactionCode';
import { Tax } from "./Tax";
import { TaxTierEmployee } from "./TaxTierEmployee";
import { TaxTierEmployer } from "./TaxTierEmployer";
import { ChartOfAccounts } from "./ChartOfAccounts";
import { Transaction } from "./Transaction";
import { PayCycle } from "./PayCycle";
import { Payroll } from "./Payroll";
import { Banking } from "./Banking";
import { EmployeeDeduction } from "./EmployeeDeduction";
import { EmployeeLoan } from "./EmployeeLoan";
import { EmployeePension } from "./EmployeePension";
import { EmployeePreviousEmployment } from "./EmployeePreviousEmployment";
import { CompanyPaySummary } from "./CompanyPaySummary";


export class Defaults {

  static UserDefault() {

    let user: Admin = {
      id: null,
      parentID: '',
      email: '',
      username: '',
      password: '',
      type: '',
      permissions: [],
      resetCode: 0,
    }

    return user;
  }

  static BankingDefault() {

    let banking: Banking = {
      id: '',
      bank: "",
      type: "",
      method: "",
      account: 0,
      currency: "JMD",
      achId: "",
      achName: "",
      originatorId: "",
    }

    return banking;
  }

  static CompanyDefaults() {

    let address = this.AddressDefaults();

    let banking: Banking = {
      id: '',
      bank: "",
      type: "",
      method: "",
      account: 0,
      currency: "JMD",
      achId: "",
      achName: "",
      originatorId: "",
    }

    let monthly: Monthly = {
      payDay: undefined,
    }

    let biMonthly: BiMonthly = {
      firstPayDay: undefined,
      secondPayDay: undefined,
    }

    let company: Company = {
      id: '',
      code: '',
      companyName: '',
      legalName: '',
      address: address,
      type: 'standard',
      nis: '',
      tax: '',
      contact: '',
      logo: '',
      email: '',
      password: undefined,
      banking: [banking],
      payrollStartDates: undefined,
      payDayOffsets: 0,
      monthly: monthly,
      biMonthly: biMonthly,
      usdRate: 0,
      usdEffectiveDate: '',
    };

    return company;
  }

  static achDefault() {
    let ach: Ach = {
      achId: null,
      aba: 0,
      routing: 0,
      transit: 0,
    }

    return ach;
  }

  static AddressDefaults(){
    let address: Address = {
      street: "",
      city: "",
      parish: "",
      // country: ""
    }

    return address;
  }

  static TaxDefault() {

    let tax: Tax = {
      id: undefined,
      region: "",
      taxCode: "",
      type: "",
      taxTierEmployee: [],
      taxTierEmployer: [],
    }

    return tax

  }

  static TaxTierEmployeeDefault() {

    let taxTierEmployee: TaxTierEmployee = {
      threshold: 0,
      rate: 0,
    }

    return taxTierEmployee

  }

  static TaxTierEmployerDefault() {

    let taxTierEmployer: TaxTierEmployer = {
      threshold: 0,
      rate: 0,
    }

    return taxTierEmployer

  }

  static Codes() {
    return {
      id: "",
      code: "",
      name: "",
      category: "",
      subCategory: ""
    };
  }

  static CodeDefault() {
    let code: Code = {
      id: null,
      shortDescription: '',
      code: '',
      name: '',
      type: '',
      category: '',
      subCategory: '',
      description: '',
      abbreviation: '',
      policyNumber: '',
      payableTo: '',
      employeeGl: '',
      companyGl: '',
      expenseGl: '',
      glAccount: '',
      companyID: '',
      amount: 0,
      rate: 0,
      rateType: '',
      rateMultiplier: 0,
      percentOrFlat: 0,
      initYTD: false,
      isTaxable: false,
      statutoryDeduction: false,
      separateCheque: false,
      taxType: '',
      pensionType: '',
      customFormula: false,
      accumulate: false,
      loanTerm: 0,
      dueDate: null,
      defaultCompanyAmount: 0,
      defaultEmployeeAmount: 0,
      defaultCompanyPercent: 0,
      defaultEmployeePercent: 0,
      banking: this.BankingDefault(),
      endTaxBeforeAfter: 0,
      pensionBeforeAfter: 0,
    }

    return code;
  }

  static BankCodeDefault() {

    let ach = this.achDefault();

    let address = this.AddressDefaults();

    let bankCode: BankCode = {

      id:	null,

      code:	"",

      name:	"",

      address:	address,

      glAccount:	"",

      branch:	"",

      type:	"",

      ach: ach,
    }

    return bankCode
  }

  static TransactionCodeDefault() {
    let transactionCode: TransactionCode = {
      id: null,
      code: '',
      companyID:	'',
      name: '',
      description:	'',
      abbreviation: '',
      type: '',
      isTaxable:	false,
      rateMultiplier: 0,
      glAccount: '',
    }

    return transactionCode;
  }

  static PensionCodeDefault() {
    let pensionCode: PensionCode = {
      id: null,
      code: '',
      companyID: '',
      description: '',
      abbreviation: '',
      employeePercent: 0,
      isEnable: false,
      employeeAmount: 0,
      employerPercent: 0,
      employerAmount: 0,
      account: '',
      maxPayable: 0
    }

    return pensionCode;
  }

  static LoanCodeDefault() {
    let loanCode: LoanCode = {
      id: null,
      code: '',
      companyID:	'',
      description:	'',
      abbreviation: '',
      glAccount: '',
    }

    return loanCode;
  }

  static HealthCodeDefault() {
    let healthCode: HealthCode = {
      id: null,
      code: '',
      companyID: '',
      description:	'',
      isEnable: false,
      employeeAmount: this.HealthCodeTiersDefault(),
      employerAmount: this.HealthCodeTiersDefault(),
      glAccount: '',
    }

    return healthCode;
  }

  static HealthCodeTiersDefault() {
    let healthCodeTiers: HealthCodeTiers = {
      single: 0,
      multiple:	0,
      family: 0,
    }

    return healthCodeTiers;
  }

  static DepartmentOccupationCodeDefault() {
    let departmentOccupationCode: DepartmentOccupationCode = {
      id: null,
      code: '',
      type: '',
      companyID:	'',
      description:	'',
      abbreviation:	'',
      rate: 0,
    }

    return departmentOccupationCode;
  }

  static DeductionCodeDefault() {
    let deductionCode: DeductionCode = {
      id:	'',
      code:	'',
      companyID:	'',
      description:	'',
      abbreviation: '',
      rateType: '',
      taxType:	'',
      accumulate: false,
      seperateCheque: false,
      glAccount: '',
    }

    return deductionCode;
  }

  static EmployeeLoanDefault(){

    let banking: Banking = {
      id: "",
      bank: "",
      type: "",
      method: "",
      account: 0,
      currency: "JMD",
      achId: "",
      achName: "",
      originatorId: "",
    }

    let employeeLoan: EmployeeLoan = {
      code: "x",
      initialAmount: 0,
      policy: "",
      repaymentAmount: 0,
      numPayments: 0,
      outstanding: 0,
      paused: false,
      enabled: false,
      beforeTax: false,
      glAccount: "",
      banking: banking,
      startDate: "",
      endDate: "",
      continuous: false,
    }

    return employeeLoan;

  }

  static EmployeeDeductionDefault(){

    let employeeDeduction: EmployeeDeduction = {

      code: "",
      deductionAmount: 0,
      policy: "",
      employerAmount: 0,
      paused: false,
      enabled: false,
      beforeTax: false,
      glAccount: "",
      banking: {
        id: "",
        bank: "",
        type: "",
        method: "",
        account: 0,
        currency: "JMD",
        achId: "",
        achName: "",
        originatorId: "",
      },
      startDate: "",
      endDate: "",
      continuous: false,
    }

    return employeeDeduction;

  }

  static EmployeeDefault(){

    let banking: Banking = {
      id: "",
      bank: "",
      type: "",
      method: "",
      account: 0,
      currency: "JMD",
      achId: "",
      achName: "",
      originatorId: "",
    }

    let department: DepartmentOccupationCode = {
      id: null,
      code: '',
      type: '',
      companyID: '',
      description: '',
      abbreviation: '',
      rate: 0,
    }

    let occupation: DepartmentOccupationCode = {
      id: null,
      code: '',
      type: '',
      companyID: '',
      description: '',
      abbreviation: '',
      rate: 0,
    }

    let health: EmployeeHealth = {
      id: "",
      code: "",
      type: "",
      // category: "",
      // subCategory: "",
    }

    let position: EmployeePosition = {
      id: null,
      shortDescription: '',
      code: '',
      name: '',
      type: '',
      category: '',
      subCategory: '',
      description: '',
      abbreviation: '',
      policyNumber: 0,
      payableTo: '',
      employeeGl: '',
      glAccount: '',
      companyID: '',
      amount: 0,
      rate: 0,
      rateType: 0,
      rateMultiplier: 0,
      isTaxable: false,
      statutoryDeduction: false,
      separateCheque: false,
      taxType: '',
      pensionType: '',
      customFormula: false,
      accumulate: false,
      loanTerm: 0,
      dueDate: null,
      defaultCompanyAmount: 0,
      defaultEmployeeAmount: 0
    }

    let employment: EmployeeEmployment = {
      id: '',
      occupation: occupation,
      department: department,
      rate: 0,
      basePay: 0,
      // position: position,
      payCycle: "",
      status: "",
      startDate: null,
      duration: 0,
      terminationDate: null,
      eType: "",
      effectiveDate: new Date(),
    }

    let employeeDeduction: EmployeeDeduction = {
      code: "",
      deductionAmount: 0,
      policy: "",
      employerAmount: 0,
      paused: false,
      enabled: false,
      beforeTax: false,
      glAccount: "",
      banking: {
        id: '',
        bank: "",
        type: "",
        method: "",
        account: 0,
        currency: "JMD",
        achId: "",
        achName: "",
        originatorId: "",
      },
      startDate: "",
      endDate: "",
      continuous: false,
    }

    let employeePreviousEmployment: EmployeePreviousEmployment = {
      id: '',
      gross: 0,
      nis: 0,
    }

    let employeeLoan: EmployeeLoan = {
      code: "x",
      initialAmount: 0,
      policy: "",
      repaymentAmount: 0,
      numPayments: 0,
      outstanding: 0,
      paused: false,
      enabled: false,
      beforeTax: false,
      glAccount: "",
      banking: banking,
      startDate: "",
      endDate: "",
      continuous: false,
    }

    let employee: Employee = {
      id: null,
      parentId: "",
      trn: "",
      nis: "",
      nisPayable: false,
      trnPayable: false,
      nhtPayable: false,
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      address: this.AddressDefaults(),
      // type: null,
      dob: new Date(),
      employeeId: null,
      employment: employment,
      gender: "",
      health: health,
      banking: banking,
      loans: [],
      deductions: [],
      pension: this.EmployeePensionDefault(),
      usdRate: false,
      previousEmployment: employeePreviousEmployment,
      grade: "",
      phone: "",
      nhsPayable: false,
      eduPayable: false,
      status: false,
      type: "",
    }

    return employee;
  }

  static EmployeePensionDefault() {

    let employeePension: EmployeePension = {
      id: "",
      code: "",
      employeeId: null,
      bank: this.BankingDefault(),
      enable: false,
      employeePercent: 0,
      employeeAmount: 0,
      employerPercent: 0,
      employerAmount: 0,
      glAccount: "",
    }

    return employeePension;

  }

  static ChartOfAccountsDefault() {
    let chartOfAccounts: ChartOfAccounts = {
      id: null,
      account: "",
      type: "",
      description: "",
      companyID: "",
    }

    return chartOfAccounts;
  }

  static PayCycleDefault() {

    let payCycle: PayCycle = {

      period: '',

      endDateType: '',

      startDate: '',

      endDate: '',

      autoCalc: false,

      payDateOffset: '',

    }

  return payCycle

  }

  static CompanyPaySummaryDefault() {

    let companyPaySummary: CompanyPaySummary = {

      payrollStartDate: "",

      payrollEndDate: "",

      payCycle: this.PayCycleDefault(),

      nis: "",

      totalEmployees: 0,

      gross: 0,

      net: 0,

      statNisEmployee: 0,

      statNisEmployer: 0,

      statNhtEmployee: 0,

      statNhtEmployer: 0,

      statPensionEmployee: 0,

      statPensionEmployer: 0,

      statEduEmployee: 0,

      statEduEmployer: 0,

      statHeartEmployee: 0,

      statHeartEmployer: 0,

      statPayeEmployee: 0,

      statPayeEmployer: 0,

      totalPayroll: 0,

    }

    return companyPaySummary;
  }

  static PayrollDefault() {


    let payroll: Payroll = {
      id:	null,
      Status:	"",
      type:	"",
      companyID: "",
      committed: false,
      payCycle:	this.PayCycleDefault(),
      taxHeaders:	[],
      transactions: [],
      employeeData: [],
    }

    return payroll;
  }
}
