import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
declare const $: any;


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  data: any = {}
  public loading: boolean = false;
  public success: Boolean = false;
  public modalMessage: string = '';

  passwordInput: any = undefined;
  togglePasswordButton: any = undefined;

  constructor(
    private api: ApiService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {

    // this.route.queryParams.subscribe(params => {
    //   if (params.id) {}
    // }
    // get token from url

    this.passwordInput = document.getElementById('current-password')
    this.togglePasswordButton = document.getElementById('toggle-password')

    document.getElementById('toggle-password').addEventListener('click', (e) => this.togglePassword());


  }

  togglePassword() {

    if (this.passwordInput.type === 'password') {
      this.passwordInput.type = 'text';
      this.togglePasswordButton.textContent = 'Hide password';
      this.togglePasswordButton.setAttribute('aria-label',
        'Hide password.');
    } else {
      this.passwordInput.type = 'password';
      this.togglePasswordButton.textContent = 'Show password';
      this.togglePasswordButton.setAttribute('aria-label',
        'Show password as plain text. ' +
        'Warning: this will display your password on the screen.');
    }
  }

  onSubmit () {
    console.log('reset password form submit');
    $("#errorModal").modal("show");
    this.loading = true;

    this.api.resetPassword(this.data.password).subscribe(
      next => {
        console.log(next);

        this.loading = false;
        this.modalMessage = "Password Reset Success";

        setTimeout(()=>{
          $("#errorModal").modal("hide");
        }, 3000);

        // on recieving a token show a success message
      },
      error => {
        console.log('api request error',error);
        this.modalMessage = "Error";
        $("#errorModal").modal("show");
      }
    )

  }

}
