<div class="pageContainerSidebar">

  <div class="companyDetailsContainer">
    <div class="subcontainerUpper row">
      <div class="col-4 upperCol">
        <ul class="upperColList">
          <li class="upperColListItem">
            <p class="upperListItemText">Code: <span class="bold" span>{{company.code}}</span></p>
          </li>
          <li class="upperColListItem">
            <p class="upperListItemText">Company Name: <span class="bold" span>{{company.companyName}}</span></p>
          </li>
          <li class="upperColListItem">
            <p class="upperListItemText">Legal Name: <span class="bold" span>{{company.legalName}}</span></p>
          </li>
          <li class="upperColListItem">
            <p class="upperListItemText">Address: <span class="bold" span>{{address.street}}  {{address.city}}  {{address.parish}}</span></p>
          </li>
        </ul>
      </div>
      <div class="col-4 upperCol">
        <ul class="upperColList">
          <li class="upperColListItem">
            <p class="upperListItemText">NIS Ref: <span class="bold" span>{{company.nis}}</span></p>
          </li>
          <li class="upperColListItem">
            <p class="upperListItemText">Tax Ref: <span class="bold" span>{{company.tax}}</span></p>
          </li>
          <li class="upperColListItem">
            <p class="upperListItemText">USD Rate: <span class="bold" span>{{company.usdRate}}</span></p>
          </li>
          <li class="upperColListItem">
            <div class="col-md-6 flex">
              <!-- <button [routerLink]="['/pages/company/create?id=',id]" type="button" class="btn uploadBtn"><p class="btnText ">Edit Company</p></button> -->
              <button (click)="goToEdit()" type="button" class="btn createBtn"><p class="btnText ">Edit</p></button>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-4 upperCol">
        <ul class="upperColList">
          <li class="upperColListItem">
            <img class="logo" src="../../assets/img/logo.png" alt="...">
          </li>
        </ul>
      </div>
    </div>

    <div class="subcontainerLower">
      <div class="row lowerHeading">
        <h4 class="lowerHeader">Master File Management</h4>
      </div>

      <div class="row lowerInput">

        <div class="col-md-6 btnCol flex">
          <button [routerLink]="['/pages/company',id,'employee','upload']" type="button" class="btn createBtn"><fa-icon [icon]="faUpload" class=" btnIcon"></fa-icon><p class="btnText ">Upload</p></button>
          <button [routerLink]="['/pages/company',id,'employee','create']" type="button" class="btn createBtn"><fa-icon [icon]="faPlus" class=" btnIcon"></fa-icon><p class="btnText ">Create</p></button>
        </div>

      </div>

      <div class="row lowerInput">

        <div class="col-md-2" >
          <input type="text" style="margin-top:0.5rem !important;" class="form-control floating" [(ngModel)]="employeeSearchQuery" placeholder="Search" (input)="filterEmployee($event.target.value)"/>
        </div>

        <div class="form-group col-md-2 ">
          <!-- <button type="button" class="btn createBtn" (click)="filterEmployee(employeeSearchQuery)">
            <p class="btnText ">Search</p>
          </button> -->
          <button type="button" class="btn uploadBtn" (click)="clearSearch()" style="margin-top:0.5rem !important;">
            <p class="btnText ">Reset</p>
          </button>
        </div>

      </div>

      <div class="row lowerInput">



        <div class="col-md-3 form-group" style="margin-top:.5rem !important;">

            <select class="form-control" aria-label="Default select example" (change)="selectSearchField($event.target.value)" id="inputTypeSelect"  name="typeSelect">
              <option value="firstName">First Name</option>
              <option value="lastName">Last Name</option>
              <option value="employeeId">Employee Id</option>
              <option value="trn">TRN</option>
              <option value="nis">NIS</option>
              <option value="employment.department.code">Dept Code</option>
              <option value="employment.occupation.code">Occup Code</option>
            </select>

        </div>

        <div class="col-md-3 form-group" style="margin-top:.5rem;">

            <select class="form-control" aria-label="Default select example" (change)="selectSortDirection($event.target.value)" id="inputTypeSelect"  name="typeSelect">
              <option value="Ascending">Ascending</option>
              <option value="Descending">Descending</option>
            </select>

        </div>

        <div class="form-group col-md-2">
          <button type="button" class="btn createBtn" (click)="preSortEmployees()">
            <p class="btnText ">Sort</p>
          </button>
        </div>




      </div>


      <nav aria-label="Page navigation example" class="navNav" *ngIf="showPagination === true">
        <ul class="pagination pageNavDiv">
          <li class="page-item">
            <a class="page-link" href="javascript:" aria-label="Previous" (click)="stepPage('previous')">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li *ngFor="let page of pagesArray; let n = index" class="page-item"><a class="page-link" href="javascript:"><span class="pageNumberHighlight" *ngIf="currentPage == n+1">{{n+1}}</span><span *ngIf="currentPage != n+1">{{n+1}}</span></a></li>
          <li class="page-item">
            <a class="page-link" href="javascript:" aria-label="Next" (click)="stepPage('next')">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>



      <div class="row lowerTable">
        <table class="table">
          <tr class="tableRow">
            <th class="tableHeadings">
              <p class="headText">Employee</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Department</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Occupation</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">NIS</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">TRN</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Email</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
            <th class="tableHeadings">
              <p class="headText">Actions</p><fa-icon [icon]="faCaretDown" class=" tableIcon"></fa-icon>
            </th>
          </tr>
          <tr *ngFor="let employee of employeeSrch; let i = index" class="tableRow">
            <td class="tableCels"><p class="cellText">{{employee.firstName}} {{employee.lastName}} - {{employee.employeeId}}</p></td>
            <td class="tableCels"><p class="cellText">{{employee.employment.department.code}}</p></td>
            <td class="tableCels"><p class="cellText">{{employee.employment.occupation.code}}</p></td>
            <td class="tableCels"><p class="cellText">{{employee.nis}}</p></td>
            <td class="tableCels"><p class="cellText">{{employee.trn}}</p></td>
            <td class="tableCels"><p class="cellText">{{employee.email}}</p></td>
            <td class="tableCels" ><a href="javascript:" class="actionsBoxLink" (click)="actionsBox($event,employee.id)"><fa-icon id="1x" [icon]="faEllipsisV" class=" tableIcon"></fa-icon></a></td>
          </tr>

        </table>
      </div>

    </div>
  </div>

</div>


<div class="actionsBox">
  <ul class="actionsBoxList">
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" [routerLink]="['/pages/company',id,'employee','edit',actionsBoxEmployee]">
      <!-- <a href="javascript:" class="actionLink" (click)="employeeAction('edit')"> -->
        <fa-icon [icon]="faPencilAlt" class=" actionsIcon" ></fa-icon><p class="cellText">Edit</p>
      </a>
    </li>
    <li class="actionsBoxListItem">
      <a href="javascript:" class="actionLink" (click)="employeeAction('delete')">
        <fa-icon [icon]="faTrash" class=" actionsIcon" (click)="employeeAction('delete')"></fa-icon><p class="cellText">Delete</p>
      </a>
    </li>
  </ul>
</div>


<!-- Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button *ngIf="loading !== true" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="loading == true" class="loadingImg" src="../../assets/img/loader.gif" alt="...">

        <h5 *ngIf="loading != true" >{{modalMessage}}</h5>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
